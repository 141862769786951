<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col">
      <div class="pub_-flex-row pub_-self-center group space-x-40 pub_case">
        <left-nav></left-nav>
        <div class="pub_-flex-col pub_-flex-auto con_case" id="target">


          <div class="con_box">

            <div class="ord_det_top">
              <h3>订单信息</h3>

              <div class="ord_d_top_mess">
                <div class="ord_left">
                  <div class=" ord_txt">订单号：<span>{{ Info.orderNo }}</span></div>

                  <div class=" ord_txt">下单时间：<span>{{ Info.createTime }}</span></div>

                  <div class=" ord_txt">订单金额：<span>{{ Info.orderMoney }}</span></div>

                  <div class=" ord_txt">订单状态：<span>{{ Info.orderStatusName }}</span></div>

                </div>


                <div class="ord_right">
                  <div class=" ord_txt">支付单号：<span>{{ Info.payNo }}</span></div>
                  <div class=" ord_txt">
                    <template v-if="Info.payTime != '' && Info.payTime != undefined && Info.payTime != null">支付时间：<span>{{ Info.payTime }}</span></template>
                  </div>
                  <div class=" ord_txt">实付金额：<span>{{ Info.payMoney }}</span></div>
                  <div class=" ord_txt">支付方式：<span>{{ Info.payTypeName }}</span></div>
                </div>

              </div>
            </div>

            <div class="ord_det_con">
              <h3>课程信息</h3>
              <div class="ord_det_case">

                <div class="ord_det_mess">
                  <img class="ord_img" :src="Info.firstPicturePath" alt="">

                  <div class="ord_d_m_r">
                    <div class="ord_txt">课程名称：<span>{{ Info.title }}</span></div>
                    <div class="ord_txt" v-if="Info.skuValue != '' && Info.skuValue != undefined && Info.skuValue != null">课程属性：<span>{{ Info.skuValue }}</span></div>
                  </div>

                </div>

                <div class="ord_det_btm">
                  <div class="ord_txt">课时数：<span>{{ Info.classHour }}</span></div>
                  <div class="ord_txt">已用课时数：<span>{{ Info.usedClassHour }}</span></div>
                  <div class="ord_txt">学习周期：<span>{{ Info.learningCycle }}</span></div>
                  <div class="ord_txt" v-if="Info.expiredTime != '' || Info.expiredTime != undefined">有效期：<span>{{ Info.expiredTime }}</span></div>
                </div>

              </div>




            </div>


          </div>


        </div>
      </div>
    </div>
    <foot-box></foot-box>


  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: {NavBox, FootBox, LeftNav},
  data() {
    return {
      Info: {},
      goods_id: 0,
    };
  },
  onload(opt) {
    this.goods_id = this.getParam("id");
  },

  mounted() {
    this.ajaxH(this.getParam("id"));
  },
  methods: {
    async ajaxH(id) {
      let param = {
        id: id
      };
      const {data: res} = await this.$http.post(
        "/api.order/getOrderInfo",
        param
      );
      // console.log(res);

      this.Info = res.data;

    },

    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },

    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url + "?active=" + this.active;
      this.$router.push(path);
    },

    selShow() {
      if (this.selOn === false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}


.group {
  padding-top: 0.4rem;
  margin-bottom: 0.5rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}


.group_2 {
  padding: 0.34rem 0 0.14rem;
}


.section_5 {
  padding: 0.04rem 0.06rem 0.07rem;
  border-radius: 0.04rem;
  height: 0.28rem;
  border: solid 0.01rem #2080f7;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.text_21 {
  color: #f95d22;
}

.text_21_1 {
  color: rgba(32, 128, 247, 1);
}

.text_21_2 {
  color: rgba(161, 169, 178, 1);
}

.text_21_3 {
  color: rgba(0, 181, 146, 1);
}

.con_case {
  background-color: #fff;
  padding: .4rem .4rem 1rem;

  .ord_txt {
    font-size: .16rem;
    font-weight: 700;
    margin-bottom: .2rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 400;
    }
  }


  h3 {
    font-size: .2rem;
    line-height: 1.5;
    margin-bottom: .2rem;
  }

  .ord_det_top, .ord_det_con {
    background-color: rgba(32, 128, 247, .03);
    padding: .2rem;

  }

  .ord_det_top {
    margin-bottom: .4rem;

    .ord_d_top_mess {
      display: flex;
      justify-content: space-between;

      > div {
        width: 45%;
      }
    }
  }

  .ord_det_con{
    .ord_det_case{

      .ord_det_mess{
        display: flex;
        align-items: center;
        margin-bottom: .3rem;
        justify-content: space-between;
        .ord_img{
          width: 2.5rem;
        }
        .ord_d_m_r{
          width: calc(100% - 2.7rem);
        }

      }

      .ord_det_btm{
        display: flex;
        flex-wrap: wrap;
        >div{
          width: 45%;
        }
      }

    }
  }



}
@media screen and (orientation: portrait){
  .group{
    flex-wrap: wrap;
  }
  .space-x-40 > *:not(:first-child){
    margin-left: 0;
  }


  .con_case{
    padding: .3rem;
    margin-top: .4rem;



    .ord_txt {
      font-size: .22rem;
      margin-bottom: .15rem;
      line-height: 1.35;

      &:last-child {
        margin-bottom: .15rem;


      }



    }


    h3 {
      font-size: .28rem;
      line-height: 1.35;

    }

    .ord_det_top, .ord_det_con {


    }

    .ord_det_top {


      .ord_d_top_mess {

        flex-wrap: wrap;

        > div {
          width: 100%;display: flex;
          flex-wrap: wrap;

          .ord_txt{
            width: 100%;
            &:empty{
              margin-bottom: 0;
            }

            &:nth-child(3),
            &:nth-child(4){
              width: 50%;
            }
          }
        }
      }
    }

    .ord_det_con{
      .ord_det_case{

        .ord_det_mess{


          .ord_img{
            width: 45%;

          }
          .ord_d_m_r{
width: calc(100% - 45% - .2rem);
          }

        }

        .ord_det_btm{
          justify-content: space-between;
          >div{
            width: 45%;
            &:nth-child(2){
              width: calc(100% - 45% - .2rem);
            }
            &:last-child{
              width: 100%;
            }
          }
        }

      }
    }




  }

}


</style>
