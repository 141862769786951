<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col group space-y-80">
      <div class="pub_-flex-col pub_-self-center group_2 pub_case">
        <div class="pub_-flex-col pub_-self-center group_3 space-y-19">
          <div class="pub_title_box">
            <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
            <span>智能匹配英语课程</span>
            <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
          </div>
          <span class="pub_info">自创的庞大学习库，概括了工作与生活的方方面面，让你在任何情况下，都可以应对自如</span>
        </div>
        <div class="pub_-flex-row list space-x-024 sys_con">
          <div class="pub_-flex-col  section_03" :key="i" v-for="(item, i) in CourseList">
            <img class="image_02" :src="item.image" />
            <span class="font_03 text_012 ">{{item.title}}</span>
            <div class="group_04">
              <span class="font_04">{{item.info}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>

  </div>
</template>

<script>
import NavBox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'

  export default {
    components: {NavBox, FootBox},
    data() {
      return {
        CourseList: [
          {
            image:
              'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715219586793829505.png',
            title: '1V1私教课',
            info: '英语不行，出国寸步难行!旅游英语助你顺畅自由行',
          },
          {
            image:
              'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715219587098607825.png',
            title: '1V4小组课',
            info: '掌握发音规律、见词能读、听音能写让学习事半功倍',
          },
          {
            image:
              'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715219587368100126.png',
            title: '1V8小班课',
            info: '发音、词汇、语法及日常用语，打下夯实的英语基础',
          },
          {
            image:
              'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715219587666438909.png',
            title: '大班课',
            info: '英语不行，出国寸步难行!旅游英语助你顺畅自由行',
          },
        ],
      };
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #fbfbfb;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding: 0.17rem 3.7rem;
    background-color: #ffffff;
  }
  .text-wrapper {
    padding: 0.24rem 0;
    background-color: #000000;
    width: 1.34rem;
    height: 0.64rem;
  }
  .font_1 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.15rem;
  }
  .text {
    color: #ffffff;
  }
  .text_2 {
    color: #2b2c30;
  }
  .group {
    padding-top: .8rem;
    padding-bottom: .5rem;
  }
  .space-y-80 > *:not(:first-child) {
    margin-top: 0.8rem;
  }
  .section_2 {
    padding-left: 0.19rem;
    background-color: #f4f7fe;
  }
  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }
  .font_2 {
    font-size: 0.18rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    color: #2b2c30;
  }
  .text_4 {
    margin-left: 0.35rem;
  }
  .text_3 {
    margin-left: 0.42rem;
  }
  .text_5 {
    margin-left: 0.44rem;
  }
  .text-wrapper_2 {
    margin-left: 0.17rem;
    padding: 0.26rem 0;
    background-color: #2080f7;
    width: 1.08rem;
    height: 0.68rem;
  }
  .text_6 {
    color: #ffffff;
  }
  .text_7 {
    margin-left: 0.17rem;
  }
  .text_8 {
    margin-left: 0.43rem;
  }
  .space-x-43 > *:not(:first-child) {
    margin-left: 0.43rem;
  }
  .text_9 {
    line-height: 0.18rem;
  }

  .group_3 {
    width: 7.03rem;
  }
  .space-y-19 > *:not(:first-child) {
    margin-top: 0.19rem;
  }
  .space-x-18 > *:not(:first-child) {
    margin-left: 0.18rem;
  }
  .image {
    width: 0.53rem;
    height: 0.08rem;
  }
  .text_10 {
    color: #2b2c30;
    font-size: 0.36rem;
    font-family: OPPOSans;
    line-height: 0.35rem;
  }
  .text_11 {
    color: #2b2c30;
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.2rem;
  }
  .list {
    margin-top: 0.37rem;
  }
  .space-x-24 > *:not(:first-child) {
    margin-left: 0.24rem;
  }
  .section_3 {
    flex: 1 1 2.77rem;
    padding-bottom: 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem;

    border: solid 0.01rem #eeeeee;
  }
  .image_2 {
    border-radius: 0.14rem 0.14rem 0px 0px;
    width: 2.77rem;
    height: 1.95rem;
  }
  .font_3 {
    font-size: 0.2rem;
    letter-spacing: 0.026rem;
    line-height:1;
    color: #2b2c30;
  }
  .text_12 {
    margin-top: 0.2rem;
    line-height: 0.19rem;
    font-weight: 700;
  }
  .group_4 {
    margin-top: 0.1rem;
    line-height: 0.2rem;
    text-align: center;
    padding: 0 .25rem;
  }
  .font_4 {
    font-size: 0.12rem;
    letter-spacing: 0.016rem;
    line-height: 1.66;
    font-weight: 300;
    color: #666666;
    display: block;
    
  }
</style>