<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col">
      <div class="pub_-flex-row pub_-self-center group space-x-40 pub_case">
        <left-nav></left-nav>
        <div class="pub_-flex-col pub_-flex-auto con_case" id="target">
          <div class="sear_case">
            <div class="sear_box">
              <img
                class="sea_image"
                src="../../assets/8e844837e61b01c621820878e260fae4.png"
              /> <input type="text" placeholder="请输入订单号或课程名称进行搜索" v-model="search.name"/>
            </div>
            <button class="btn_sty" @click="ajaxH()">搜索</button>
          </div>

          <div class="con_box">

            <div class="con_block" v-for="(item,i) in showList" :key="i">

              <div class="con_left">
                <div class="con_id ord_txt">订单号：<span>{{ item.orderNo }}</span></div>
                <div class="con_det">
                  <img :src="item.firstPicturePath" class="con_img" alt="课程图片">
                  <div class="con_txt">
                    <div class="k_name ord_txt">课程名称：<span>{{ item.title }}</span></div>
                    <div class="k_attr ord_txt">
                      <template  v-if="item.skuValue != '' && item.skuValue != null && item.skuValue != undefined">课程属性：<span>{{ item.skuValue }}</span></template>

                    </div>
                    <div class="ord_time ord_txt m_sty">下单时间：<span>{{ item.createTime }}</span></div>
                    <div class="ord_per ord_txt m_sty">订单金额：<span>{{ item.orderMoney }}</span>元</div>
                    <div class="ord_sta ord_txt m_sty">订单状态：<span>{{ item.orderStatusName }}</span></div>
                    <div class=" btn_sty m_sty" @click="goHref($event)" :data-url="'/orderDetails?id='+item.id">订单详情</div>


                  </div>

                </div>

              </div>
              <div class="con_center pc_sty">
                <div class="ord_time ord_txt">下单时间：<span>{{ item.createTime }}</span></div>
                <div class="ord_per ord_txt">订单金额：<span>{{ item.orderMoney }}</span>元</div>
                <div class="ord_sta ord_txt">订单状态：<span>{{ item.orderStatusName }}</span></div>


              </div>
              <div class="con_right pc_sty">
                <div class=" btn_sty" @click="goHref($event)" :data-url="'/orderDetails?id='+item.id">订单详情</div>
              </div>


            </div>

          </div>

          <Pagination
            @change="changePage"
            :current="pagination.p"
            :pageSize="pagination.pageSize"
            :total="total"
            :hideOnSinglePage="hideOnSinglePage"
            :showQuickJumper="true"
            :showTotal="true"
            placement="right"
            v-if="pagination && total"
          />
        </div>
      </div>
    </div>
    <foot-box></foot-box>


  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: {NavBox, FootBox, LeftNav, Pagination},
  data() {
    return {
      ordShow: false,

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      showList: [], //显示的列表
      conC: [], //完整的列表

      search: {
        name: ''
      },


    };
  },

  mounted() {
    this.ajaxH();
  },
  methods: {
    async ajaxH() {
      let param = {
        keyword: this.search.name
      };
      const {data: res} = await this.$http.post(
        "/api.order/getList",
        param
      );
      // console.log(res);

      this.showList = res.data.data.slice(0, res.data.per_page);
      this.conC = res.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;
    },


    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url + "?active=" + this.active;
      this.$router.push(path);
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.conC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    selS(n, t, id) {
      this.value = "";
      this.typeN = n;
      this.typeT = t;
      this.wordOrder.contactType = id;
      this.selOn = false;
    },
    selShow() {
      if (this.selOn === false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.group {
  padding-top: 0.4rem;
  margin-bottom: 0.5rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.section_3 {
  padding: 0 0.4rem 1rem;
  position: relative;
  background-color: #ffffff;
  width: 9.42rem;
  min-height: 8.74rem;

  .m-pagination {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    width: 100%;
    padding-right: 0.5rem;
  }
}

.group_2 {
  padding: 0.34rem 0 0.14rem;
}

.section_5 {
  padding: 0.04rem 0.06rem 0.07rem;
  border-radius: 0.04rem;
  height: 0.28rem;
  border: solid 0.01rem #2080f7;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.text_21 {
  color: #f95d22;
}

.text_21_1 {
  color: rgba(32, 128, 247, 1);
}

.text_21_2 {
  color: rgba(161, 169, 178, 1);
}

.text_21_3 {
  color: rgba(0, 181, 146, 1);
}

.con_case {
  background-color: #fff;
  padding: .4rem .4rem 1rem;
}

.sear_case {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sear_box {
    width: calc(100% - 1.24rem);
    height: .48rem;
    display: flex;
    align-items: center;
    border: solid 0.01rem #2080f7;
    padding-left: .1rem;
    border-right: none;

    .sea_image {
      width: 0.22rem;
      height: 0.22rem;
    }

    input {
      width: calc(100% - .33rem);

      padding: .1rem .11rem;
      margin-left: .11rem;
      font-size: .16rem;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }

  button {
    width: 1.24rem;
    border-radius: 0;
    font-size: .16rem;
    height: .48rem;

  }

}

.con_box {
  margin-top: .4rem;

  .con_block {
    margin-bottom: .3rem;
    background-color: rgba(32, 128, 247, .03);
    display: flex;
    justify-content: space-between;
    padding: .2rem;

    .ord_txt {
      font-size: .16rem;
      font-weight: 700;
      margin-bottom: .2rem;
      line-height: 1.5;
      min-height: 1.5em;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 400;
      }
    }


    .con_left {
      width: 54%;

      .con_det {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .con_img {
          width: 1.8rem;
        }

        .con_txt {
          width: calc(100% - 2rem);
          .ord_txt {
            &:nth-child(1) {
              margin-bottom: .1rem;
            }
          }
        }
      }

    }

    .con_center {
      width: 28%;


    }

    .con_right {
      width: 16%;
      margin-left: -3%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .btn_sty {
        height: .48rem;
        width: 100%;
        border-radius: .06rem;
        font-size: .16rem;
      }
    }


  }


}

@media screen and (orientation: portrait){
  .group{
    flex-wrap: wrap;
  }
    .space-x-40 > *:not(:first-child){
      margin-left: 0;
    }
  .con_case{
    padding: .3rem;
    margin-top: .4rem;

    .sear_case {


      .sear_box {
        width: calc(100% - 1.8rem);
        height: .7rem;

        .sea_image {
          width: .3rem;
          height: .3rem;
        }

        input {
          width: calc(100% - .3rem);
          margin-left: 0;
          padding-left: .2rem;
          font-size: .24rem;
        }
      }

      button {
        width: 1.8rem;
        height: .7rem;
        font-size: .24rem;




      }

    }



    .con_box {



      .con_block {


        padding-top: .3rem;
        padding-bottom: .3rem;

        .ord_txt {
          font-size: .22rem;
margin-bottom: .15rem;
          line-height: 1.35;
          min-height: auto;

          &:last-child {
          }

          span {

          }
        }


        .con_left {
          width: 100%;

          .con_det {


            .con_img {
              width: 30%;
              height: 2rem;
            }

            .con_txt {
              width: 70%;
              margin-top:0;
              padding-left: .2rem;
              display: flex;
              flex-wrap: wrap;
              .ord_txt {
                width: 100%;
                &:nth-child(1) {
                  margin-bottom: .15rem;
                }
                &:nth-child(4),
                &:nth-child(5){
                  //width: 50%;
                }
              }
            }
          }

        }

        .con_center {
          width: 100%;


        }
        .btn_sty {
          width: 48%;
          height: .6rem;
display: flex !important;
          font-size: .2rem;
        }
        .con_right {
          width: 100%;
          margin-left: 0;

          .btn_sty {
            height: .7rem;

            font-size: .24rem;
          }
        }


      }


    }



  }

}


</style>
