<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col space-y-40">
      <div class="pub_-flex-col pub_-justify-start pub_-self-center group">
        <div class="pub_-flex-row pub_-relative group_5 pub_case">
          <left-nav></left-nav>

          <div class="group_3">
            <div class="pub_-flex-row group_4 space-x-47">
              <span
                class="font_3"
                :class="{ text_14: shisT == 0 }"
                @click="tabSafe(0)"
              >个人信息</span>
              <span
                class="font_3"
                :class="{ text_14: shisT == 1 }"
                @click="tabSafe(1)"
              >账户安全</span>
            </div>

            <div v-if="shisT == 0" class="pub_-flex-col section_4">
              <div class="pub_-flex-col space-y-10">
                <div class="pub_-flex-row space-x-60">
                  <div class="pub_-flex-col pub_-shrink-0 space-y-13">
                    <div class="updata_img">
                      <!--                        action="http://39.100.254.122/api.upload/upload"-->

                      <el-upload
                        class="avatar-uploader"
                        action="/api.upload/upload"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                      >
                        <img :src="imageUrl" class="avatar image"/>
                      </el-upload>
                    </div>

                    <div class="pub_-flex-row group_8 space-x-7" v-if="editS == false"  @click="focusMyInput">
                      <img
                        class="pub_-shrink-0 image_2"
                        src="../../assets/76fe4aacd7c5a78852e9401977a35745.png"
                      />
                      <span class="font_3 text_22"
                      >修改</span
                      >
                    </div>
                    <div class="sty_m">头像</div>
                  </div>

                  <div class="from_box" :class="editS == true ? 'edit_s' : ''">
                    <el-form ref="form" :model="form" label-width="1.2rem">
                      <el-form-item label="用户名">
                        <el-input disabled v-model="form.name" ></el-input>
                      </el-form-item>
                      <el-form-item label="昵称">
                        <el-input ref="myInput" v-model="form.nickname"></el-input>
                      </el-form-item>
                      <el-form-item label="真实姓名">
                        <el-input v-model="form.realName"></el-input>
                      </el-form-item>
                      <el-form-item label="英文姓名">
                        <el-input v-model="form.englishName"></el-input>
                      </el-form-item>
                      <el-form-item label="生日">
                        <el-col>
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="form.birthday"
                            style="width: 100%"
                          ></el-date-picker>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="性别">
                        <el-radio-group v-model="form.sex">
                          <el-radio label="1">男</el-radio>
                          <el-radio label="2">女</el-radio>
                          <el-radio label="3">保密</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="微信号">
                        <el-input v-model="form.weChat"></el-input>
                      </el-form-item>
                      <el-form-item label="QQ号">
                        <el-input v-model="form.qq"></el-input>
                      </el-form-item>
                      <el-form-item label="whatsapp">
                        <el-input v-model="form.whatsApp"></el-input>
                      </el-form-item>

                      <el-form-item label="国籍">
                        <el-select
                          v-model="form.nationalityName"
                          placeholder="国籍"
                          @change="handleLevelChange($event, 'nationality')"
                        >
                          <el-option
                            v-for="item in nationality"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="当前学习语言">
                        <el-select
                          v-model="form.languageName"
                          placeholder="当前学习语言"
                          @change="handleLevelChange($event, 'language')"
                        >
                          <el-option
                            v-for="item in language"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="级别">
                        <el-select v-model="form.levelName" placeholder="级别" @change="handleLevelChange($event, 'level')">
                          <el-option
                            v-for="item in level"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item v-if="editS">
                        <el-button type="primary" @click="onSubmit"
                        >确认修改
                        </el-button
                        >
                        <el-button @click="cancelEdit">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="pub_-flex-col section_4a space-y-20 safe_box">
              <div
                class="pub_-flex-row pub_-justify-between pub_-items-center section_5a"
              >
                <div class="pub_-flex-row pub_-items-center space-x-22a">
                  <img
                    class="image_2a"
                    src="../../assets/ec21b5f531f14b3c8b4b84afe09ff9f5.png"
                  />
                  <div class="pub_-flex-col space-y-11a">
                    <div class="pub_-flex-row space-x-5a">
                      <span class="font_3a text_18a">登录密码</span>
                      <span class="font_7a text_19a"></span>
                    </div>
                    <span class="font_6a text_21a"
                    >用于登录系统和保护账号信息</span
                    >
                  </div>
                </div>
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4a btn_sty btn_sty_line"
                  @click="changM(true)"
                >
                  <span class="font_5a">去修改</span>
                </div>
              </div>
              <div
                class="pub_-flex-row pub_-justify-between pub_-items-center section_5a"
              >
                <div class="pub_-flex-row pub_-items-center space-x-22a">
                  <img
                    class="image_2a"
                    src="../../assets/c302ff70bf9c138855f44701e7c32848.png"
                  />
                  <div class="pub_-flex-col space-y-11a">
                    <div class="pub_-flex-row space-x-5a">
                      <span class="font_3a text_18a">安全手机：</span>
                      <span class="font_7a text_19a">{{ form.mobile == '' ? '未绑定' : form.mobile }}</span>
                    </div>
                    <span class="font_6a text_21a"
                    >安全手机可以用于登录网页在线，找回密码，请及时绑定</span
                    >
                  </div>
                </div>
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4a btn_sty btn_sty_line"
                  @click="changH(true, 'mobile')"
                >
                  <span class="font_5a">去修改</span>
                </div>
              </div>
              <div
                class="pub_-flex-row pub_-justify-between pub_-items-center section_5a"
              >
                <div class="pub_-flex-row pub_-items-center space-x-22a">
                  <img
                    class="image_2a"
                    src="../../assets/c302ff70bf9c138855f44701e7c32848.png"
                  />
                  <div class="pub_-flex-col space-y-11a">
                    <div class="pub_-flex-row space-x-5a">
                      <span class="font_3a text_18a">安全邮箱：</span>
                      <span class="font_7a text_19a">{{ form.email == '' ? '未绑定' : form.email }}</span>
                    </div>
                    <span class="font_6a text_21a"
                    >安全手机可以用于登录网页在线，找回密码，请及时绑定</span>
                  </div>
                </div>
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4a btn_sty btn_sty_line"
                  @click="changH(true, 'email')"
                >
                  <span class="font_5a">去修改</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3s pos change_phone"
      v-if="changePhone == true"
    >
      <div class="pub_-flex-col" v-if="bindType == 'mobile'">
        <div class="pub_-flex-row section_10s space-x-264s">
          <span class="font_2s text_18s">修改手机号</span>
          <img
            class="pub_-shrink-0 image_2s"
            src="../../assets/674be32882f80b6de180476522853e61.png"
            @click="closedMobileEmailSha()"
          />
        </div>
        <div class="pub_-flex-col section_11s space-y-20s">
          <div class="form_box">
            <el-form ref="form" :model="phone" label-width="6.5em">


              <el-form-item label="手机号">
                <el-input v-model="phone.value"></el-input>
              </el-form-item>
              <el-form-item label="短信验证" class="code_box">
                <el-input v-model="phone.code"></el-input>
                <button
                  type="button"
                  class="font_2 text text_5 btn_sty"
                  @click="verify"
                  :disabled="disabled"
                >
                  {{ btntxt }}
                </button>
              </el-form-item>
            </el-form>
          </div>

          <div class="pub_-flex-row group_6s space-x-19s">
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8s btn_sty"
              @click="closedMobileEmailSha()">
              <span class="font_9s">取消</span>
            </div>
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_9s btn_sty"
              @click="bindSafety('mobile')">
              <span class="font_9s text_31s">确认修改</span>
            </div>
          </div>
        </div>
      </div>

      <div class="pub_-flex-col" v-else>
        <div class="pub_-flex-row section_10s space-x-264s">
          <span class="font_2s text_18s">绑定邮箱</span>
          <img
            class="pub_-shrink-0 image_2s"
            src="../../assets/674be32882f80b6de180476522853e61.png"
            @click="closedMobileEmailSha()"
          />
        </div>
        <div class="pub_-flex-col section_11s space-y-20s">
          <div class="form_box">
            <el-form ref="form" :model="mail" label-width="6.5em">

              <el-form-item label="邮箱">
                <el-input v-model="mail.value"></el-input>
              </el-form-item>
              <el-form-item label="验证码" class="code_box">
                <el-input v-model="mail.code"></el-input>
                <button
                  type="button"
                  class="font_2 text text_5 btn_sty"
                  @click="verify"
                  :disabled="disabled"
                >
                  {{ btntxt }}
                </button>
              </el-form-item>
            </el-form>
          </div>

          <div class="pub_-flex-row group_6s space-x-19s">
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8s btn_sty"
              @click="closedMobileEmailSha()">
              <span class="font_9s">取消</span>
            </div>
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_9s btn_sty"
            >
              <span class="font_9s text_31s" @click="bindSafety('email')">确认修改</span>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3s pos change_phone"
      v-if="changePassword == true"
    >
      <div class="pub_-flex-col">
        <div class="pub_-flex-row section_10s space-x-264s">
          <span class="font_2s text_18s">密码修改</span>
          <img
            class="pub_-shrink-0 image_2s"
            src="../../assets/674be32882f80b6de180476522853e61.png"
            @click="closePasswordSha()"
          />
        </div>
        <div class="pub_-flex-col section_11s space-y-20s">
          <div class="form_box">
            <el-form ref="form" :model="passwordAmend" label-width="6.5em">
              <el-form-item label="原密码">
                <el-input type="password" v-model="passwordAmend.password"></el-input>
              </el-form-item>
              <el-form-item label="新密码">
                <el-input type="password" v-model="passwordAmend.newPassword"></el-input>
              </el-form-item>
              <el-form-item label="确认密码">
                <el-input type="password" v-model="passwordAmend.confirmPassword"></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div class="pub_-flex-row group_6s space-x-19s">
            <div @click="closePasswordSha()"
                 class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8s btn_sty"
            >
              <span class="font_9s">取消</span>
            </div>
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_9s btn_sty"
            >
              <span class="font_9s text_31s" @click="newPassword()">确认修改</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import axios from "axios";

export default {
  components: {NavBox, FootBox, LeftNav},
  data() {
    return {
      shisT: 0,

      changePassword: false,
      changePhone: false,
      form: {
        nickname: "",
        region: "",
        realName: "",
        englishName: "",
        birthday: "",
        sex: "",
        weChat: "",
        qq: "",
        whatsApp: "",
        nationalityId: "",
        nationalityName:"",
        levelId: "",
        levelName: "",
        languageId: "",
        languageName: "",
      },


      nationality: [],
      language: [],
      level: [],

      disabled: false,
      phone: {
        type: 'mobile',
        value: "",
        code: "",
      },
      btntxt: "获取验证码",

      mail: {
        type: 'email',
        value: "",
        code: "",
      },

      bindType: '',


      passwordAmend: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      editS: false,

      // 上传头像
      file: null,
      imageUrl: "../../assets/aa85481b15b185683ced797666f26699.png",

      timer: null,
    };
  },

  created() {
    this.ajaxH();
  },
  methods: {
    //下拉选择
    handleLevelChange(e,opt){


      if(opt == 'level'){
        this.form.levelId = e.id;
        this.form.levelName = e.name;
      }else if(opt == 'language'){
        this.form.languageId = e.id;
        this.form.languageName = e.name;
      }else if(opt == 'nationality'){
        this.form.nationalityId = e.id;
        this.form.nationalityName = e.name;
      }
// 添加强制更新
      this.$forceUpdate();
    },
    handleAvatarSuccess(res, file) {

      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.avatarPath = this.imageUrl;
      this.form.avatar = res.data.fileId;
      this.focusMyInput();
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isImage = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'].includes(file.type);
      console.log(isImage)
      if (!isImage) {
        this.$message.error("只能上传图片文件!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },

    async ajaxH() {
      let  self = this;
      const {data: info} = await this.$http.post("/api.student/getInfo", {});

      this.form = info.data;
      this.form.nationalityName ='';
      this.form.levelName ='';
      this.form.languageName ='';
      this.imageUrl = this.form.avatarPath;


      const {data: res} = await this.$http.post(
        "/api.setUp/getNationalityList",
        {}
      );

      const {data: lan} = await this.$http.post(
        "/api.setUp/getLanguageList",
        {}
      );
      const {data: lev} = await this.$http.post("/api.setUp/getLevelList", {});

      this.nationality = res.data;
      this.language = lan.data;
      this.level = lev.data;


      this.level.forEach((item) => {
        if (item.id == info.data.levelId) {
          this.form.levelName = item.name;
        }
      });
      this.language.forEach((item) => {
        if (item.id == info.data.languageId) {
          this.form.languageName = item.name;
        }
      });
      this.nationality.forEach((item) => {

        if (item.id == info.data.nationalityId) {
          this.form.nationalityName = item.name;
        }
      });

      this.form.sex = info.data.sex.toString();
    },
    async onSubmit() {
      let str = this.form;


      const {data: res} = await this.$http.post("/api.student/setInfo", str);
      // console.log(res);
      if (res.code == 0) {
        this.$message({
          message: "信息更新成功",
          type: "success",
        });
        this.editS = false;


        //刷新当前页面
        location.reload();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    tabSafe(index) {
      this.shisT = index;
    },
    changH(str, type) {
      this.changePhone = str;
      this.bindType = type;

      this.stopTimer();

    },
    bindSafety(type) {
      if (type == 'mobile') {
        this.bindMobileEmail(this.phone)
      } else {
        this.bindMobileEmail(this.mail)
      }
    },
    async bindMobileEmail(str) {
      let param = str;
      const {data: res} = await this.$http.post(
        "/api.student/bindMobileEmail",
        param
      );

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.starN = null;
        this.textarea = '';
        this.changePhone=false;
        this.ajaxH();

      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    changM(str) {
      this.changePassword = str;
    },
    //发送验证码
    async sendVerifyCode(md ) {

      let param = {
        type: this.bindType,
        value: md,
        purpose: 3, //用途：1注册，2找回密码 3绑定手机邮箱
      };
      const {data: res} = await this.$http.post(
        "/api.verify/sendVerifyCode",
        param
      );
      // console.log(res);

      if (res.code === 1) {

        this.$message({
          message: res.msg,
          type: "warning",
        });

      } else {

        this.startTimer();

      }

      // this.code = res.data;
      // console.log(this.code);
    },
    verify(ste) {

      if (this.bindType == "mobile") {
        this.sendcode(ste);
      } else {
        this.sendEmail(ste);
      }
    },
    //验证手机号码部分
    sendcode(str) {
      var reg = 11 && /^[1][3,4,5,7,8,9][0-9]{9}$/;


      if (this.phone.value == "") {
        // alert("请输入手机号码");
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
      } else if (!reg.test(this.phone.value)) {
        this.$message({
          message: "手机格式不正确",
          type: "error",
        });
      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;
          this.sendVerifyCode(this.phone.value);
        }
      }
    },

    //邮箱验证
    sendEmail(str) {
      var regEmail =
        /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/;
      // console.log(this.mail.value)
      if (this.mail.value == "") {
        this.$message({
          message: "请输入邮箱",
          type: "warning",
        });
      } else if (!regEmail.test(this.mail.value)) {
        this.$message({
          message: "邮箱格式不正确",
          type: "error",
        });
      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;
          this.sendVerifyCode(this.mail.value);
        }
      }
    },


    startTimer() {
      this.timer = setInterval(() => {
        this.timerC()
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.time = 0;
      this.btntxt = "获取验证码";
      this.disabled = false;
    },
    timerC() {

      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";

        // setTimeout(this.timerC, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }


    },
    focusMyInput() {
      this.editS = true;
      this.$refs.myInput.focus();
    },
    cancelEdit() {
      this.editS = false;

      this.ajaxH();
    },

    //  关闭手机、邮箱修改绑定弹窗
    closedMobileEmailSha(){
      this.changePhone = false;
      this.mail.value= "";
      this.mail.code= "";
      this.phone.value= "";
      this.phone.code= "";
    },

  //  关闭密码修改弹窗
    closePasswordSha(){
      this.changePassword = false;
      this.passwordAmend.password= "";
      this.passwordAmend.newPassword= "";
      this.passwordAmend.confirmPassword= "";
    },
    //非空验证 确认密码相同  确认非旧密码
    passwordVerify(){
      if(this.passwordAmend.password == ""){
        this.$message({
          message: "请输入原密码",
          type: "warning",
        });
        return false;
      }else if(this.passwordAmend.newPassword == ""){
        this.$message({
          message: "请输入新密码",
          type: "warning",
        });
        return false;
      }else if(this.passwordAmend.confirmPassword == ""){
        this.$message({
          message: "请输入确认密码",
          type: "warning",
        });
        return false;
      }else if(this.passwordAmend.confirmPassword != this.passwordAmend.newPassword){
        this.$message({
          message: "新密码与确认密码需相同",
          type: "warning",
        });
        return false;
      }else if(this.passwordAmend.password == this.passwordAmend.newPassword){
        this.$message({
          message: "新密码不能与原密码相同",
          type: "warning",
        });
        return false;
      }
      return  true;

    },
    //修改密码
    async newPassword() {
    let verifyQ =  this.passwordVerify();
     if (verifyQ == false){

       return false
     }

      let param = this.passwordAmend;
      const {data: res} = await this.$http.post(
        "/api.student/resetPassword",
        param
      );
      console.log(res);

      if (res.code === 1) {

        this.$message({
          message: res.msg,
          type: "warning",
        });

      } else {

        this.changePassword = false;


        this.logout()

        this.$message({
          message: "密码修改成功，可以登录了",
          type: "success",
        });




      }

      // this.code = res.data;
      // console.log(this.code);
    },
  //退出登录
    async logout(id, i) {
      let param = {
        token: this.token,
      };
      // console.log(param);
      const { data: res } = await this.$http.post("/api.student/logout", param);

      if (res.code === 0) {
        // this.$message({
        //   message: "已退出",
        // });
        this.$cookies.remove("oatoken");

        this.stateLogin = false;

        this.$router.push("/login");

      } else {
        // this.$message({
        //   message: res.msg,
        // });
        let path = "/login";
        this.$router.push(path);
      }

      // console.log(this.config);
    },


  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.16rem;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.16rem;
  color: #2b2c30;
}

.text_4 {
  line-height: 0.17rem;
}

.text_5 {
  line-height: 0.17rem;
}

.text_8 {
  margin-left: 0.35rem;
  line-height: 0.17rem;
}

.text_6 {
  margin-left: 0.42rem;
  line-height: 0.17rem;
}

.text_9 {
  margin-left: 0.44rem;
  line-height: 0.17rem;
}

.text_10 {
  margin-left: 0.35rem;
  line-height: 0.17rem;
}

.text_11 {
  margin-left: 0.34rem;
  line-height: 0.17rem;
}

.text_12 {
  margin-left: 0.43rem;
  line-height: 0.17rem;
}

.text_3 {
  line-height: 0.17rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_7 {
  line-height: 0.18rem;
}

.text_13 {
  line-height: 0.17rem;
}

.group {
  padding: 0.4rem 0 0.8rem;
}

.group_5 {
  min-height: 8.74rem;
  justify-content: space-between;
}

.pos_2 {
  position: absolute;
  left: 0;
  top: 0;
}

.text-wrapper_3 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}

.text_15 {
  margin-left: 0.19rem;
}

.text_16 {
  margin-left: 0.17rem;
}

.section_5 {
  background-color: #ffffff;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.section_6 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.text_19 {
  margin-right: 1.27rem;
  color: #2080f7;
}

.text_20 {
  margin-left: 0.17rem;
}

.text_23 {
  margin-left: 0.16rem;
}

.text-wrapper_6 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_7 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #000000;
}

.text_24 {
  margin-left: 0.17rem;
}

.text_25 {
  margin-left: 0.17rem;
}

.text_27 {
  margin-left: 0.16rem;
}

.group_3 {
  width: 9.42rem;
}

.pos_3 {
  position: absolute;
  right: 0;
  top: 0;
}

.group_4 {
  padding: 0 0.14rem 0.1rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.space-x-47 > *:not(:first-child) {
  margin-left: 0.47rem;
}

.font_3 {
  font-size: 0.16rem;
  letter-spacing: 0.019rem;
  line-height: 0.16rem;
  font-weight: 300;
  color: #a1a9b2;
  cursor: pointer;

  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -0.11rem;
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    width: 0.28rem;
    height: 0.02rem;
    background-color: #2080f7;
  }

  @media screen and (orientation: landscape) {
    transition: color 0.5s ease;
    &:hover {
      color: #2080f7;
    }
  }
}

.divider {
  background-color: #e0e0e0;
}

.section_3 {
  margin-left: 0.35rem;
  background-color: #2080f7;
  width: 0.28rem;
  height: 0.02rem;
}

.section_4 {
  padding: 0.4rem;
  background-color: #ffffff;
  width: 100%;
  margin-top: 0.2rem;
}

.pos_4 {
  position: absolute;
  right: 0;
  top: 0.45rem;
}

.space-y-10 > *:not(:first-child) {
  margin-top: 0.1rem;
}

.space-x-60 > *:not(:first-child) {
  width: calc(8.62rem - 0.88rem);
}

.el-select {
  display: block;
}

.space-y-13 > *:not(:first-child) {
  margin-top: 0.13rem;
}

.image {
  width: 0.88rem;
  height: 0.88rem;
}

.group_8 {
  padding: 0 0.14rem;
}

.space-x-7 > *:not(:first-child) {
  margin-left: 0.07rem;
}

.image_2 {
  width: 0.16rem;
  height: 0.17rem;
}

.text_22 {
  color: #2080f7;
  line-height: 0.15rem;
}

.group_6 {
  margin-bottom: 0.1rem;
  width: 7.14rem;
}

.space-y-20 > *:not(:first-child) {
  margin-top: 0.2rem;
}

.text_17 {
  line-height: 0.15rem;
}

.text-wrapper_4 {
  padding: 0.14rem 0;
  background-color: #e0e0e080;
  width: 6.51rem;
  height: 0.44rem;
}

.text_18 {
  margin-left: 0.14rem;
  line-height: 0.15rem;
}

.group_7 {
  padding-left: 0.16rem;
}

.text-wrapper_5 {
  padding: 0.12rem 0;
  background-color: #ffffff;
  width: 6.51rem;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.font_5 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.16rem;
  font-weight: 300;
  color: #000000;
}

.group_9 {
  padding-left: 1.28rem;
}

.font_6 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.16rem;
  font-weight: 300;
  color: #dbdbdb;
}

.text_21 {
  margin-left: 0.12rem;
}

.view {
  margin-top: 0.2rem;
}

.text-wrapper_7 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 6.51rem;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.font_8 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #000000;
}

.text_26 {
  margin-left: 0.13rem;
}

.group_10 {
  margin-top: 0.2rem;
  padding-left: 1.64rem;
}

.text_28 {
  line-height: 0.14rem;
}

.section_7 {
  padding: 0.16rem 0.12rem;
  background-color: #ffffff;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.space-x-497 > *:not(:first-child) {
  margin-left: 4.97rem;
}

.image_3 {
  width: 0.15rem;
  height: 0.09rem;
}

.image_4 {
  margin-right: 0.09rem;
}

.group_11 {
  margin-top: 0.32rem;
  padding: 0 1.64rem;
}

.text_29 {
  line-height: 0.15rem;
}

.image_5 {
  margin-left: 0.12rem;
  border-radius: 0.1rem;
  width: 0.2rem;
  height: 0.2rem;
}

.text_30 {
  margin-left: 0.08rem;
  line-height: 0.14rem;
}

.section_8 {
  margin-left: 0.16rem;
  border-radius: 0.1rem;
  width: 0.2rem;
  height: 0.2rem;
  border: solid 0.01rem #f3f6fa;
}

.text_31 {
  margin-left: 0.08rem;
  line-height: 0.15rem;
}

.group_12 {
  margin-top: 0.2rem;
  padding-left: 1.46rem;
}

.space-x-11 > *:not(:first-child) {
  margin-left: 0.11rem;
}

.group_13 {
  margin-top: 0.2rem;
  padding-left: 1.55rem;
}

.text_32 {
  line-height: 0.17rem;
}

.group_14 {
  margin-top: 0.2rem;
  padding-left: 1.13rem;
}

.space-x-5 > *:not(:first-child) {
  margin-left: 0.05rem;
}

.text_34 {
  line-height: 0.17rem;
}

.text_33 {
  line-height: 0.18rem;
}

.group_15 {
  margin-top: 0.2rem;
  padding-left: 1.65rem;
}

.section_9 {
  padding: 0.12rem 0.13rem;
  background-color: #ffffff;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.space-x-565 > *:not(:first-child) {
  margin-left: 5.65rem;
}

.image_6 {
  margin-right: 0.08rem;
}

.group_16 {
  margin-top: 0.2rem;
  padding-left: 0.93rem;
}

.space-x-10 > *:not(:first-child) {
  margin-left: 0.1rem;
}

.section_10 {
  padding: 0.12rem;
  background-color: #ffffff;
  width: 6.51rem;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.image_7 {
  margin-right: 0.09rem;
}

.text_35 {
  line-height: 0.15rem;
}

.section_11 {
  padding: 0.16rem 0.13rem;
  background-color: #ffffff;
  height: 0.44rem;
  border: solid 0.01rem #f3f6fa;
}

.space-x-582 > *:not(:first-child) {
  margin-left: 5.82rem;
}

.image_8 {
  margin-right: 0.08rem;
}

.text_14 {
  color: #2080f7;
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  line-height: 0.16rem;
  letter-spacing: 0.019rem;
  position: relative;

  &::before {
    content: "";

    transform: translateX(-50%);
  }
}

.safe_box {
  &.section_4a {
    padding: 0.41rem 0.4rem 3.56rem;
    background-color: #ffffff;
    margin-top: 0.4rem;
  }

  .space-y-20a > *:not(:first-child) {
    margin-top: 0.2rem;
  }

  .section_5a {
    padding: 0.12rem 0.2rem 0.12rem 0.24rem;
    border: solid 0.01rem #f3f6fa;
  }

  .group_5a {
    width: 2.16rem;
    height: 0.4rem;
  }

  .imagea {
    margin-top: 0.07rem;
    width: 0.2rem;
    height: 0.27rem;
  }

  .text_14a {
    margin-left: 0.41rem;
    margin-top: -0.34rem;
    line-height: 0.16rem;
  }

  .font_6a {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.014rem;
    line-height: 0.14rem;
    font-weight: 300;
    color: #000000;
  }

  .text_16a {
    margin-top: 0.11rem;
    line-height: 0.13rem;
  }

  .text-wrapper_4a {
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
  }

  .font_5a {
    font-size: 0.14rem;
    letter-spacing: 0.02rem;
    line-height: 0.14rem;
    font-weight: 300;
  }

  .space-x-22a > *:not(:first-child) {
    margin-left: 0.22rem;
  }

  .image_2a {
    width: 0.19rem;
    height: 0.29rem;
  }

  .space-y-11a > *:not(:first-child) {
    margin-top: 0.11rem;
  }

  .space-x-5a > *:not(:first-child) {
    margin-left: 0.05rem;
  }

  .text_18a {
    line-height: 0.16rem;
  }

  .font_7a {
    font-size: 0.16rem;
    font-family: OPPOSans;
    letter-spacing: 0.019rem;
    color: #2080f7;
  }

  .text_19a {
    line-height: 0.15rem;
  }

  .text_21a {
    line-height: 0.13rem;
  }

  .group_6a {
    width: 3.8rem;
  }

  .image_3a {
    margin-top: 0.1rem;
    width: 0.25rem;
    height: 0.2rem;
  }

  .text_24a {
    margin-left: 0.19rem;
    line-height: 0.16rem;
  }

  .text_25a {
    margin-left: 0.05rem;
    line-height: 0.17rem;
  }

  .text_27a {
    line-height: 0.13rem;
  }
}

.change_phone {
  > div {
    //min-width: 4.2rem;
    //width: 420px;
    max-width: 80%;
  }

  &.section_3s {
    padding: 3.64rem 0;
    background-color: #051b3733;
  }

  &.pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
  }

  .section_10s {
    padding: 0.26rem 0.19rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0px 0px;
    border-bottom: solid 0.01rem #f3f6fa;
    justify-content: space-between;
  }

  .space-x-264s > *:not(:first-child) {
    margin-left: 2.64rem;
  }

  .text_18s {
    font-weight: 500;
    line-height: 0.18rem;
    font-size: .14rem;
  }


  .image_2s {
    margin-right: 0.05rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;
  }

  .section_11s {
    padding-top: 0.19rem;
    background-color: #ffffff;
    border-radius: 0px 0px 0.14rem 0.14rem;
  }

  .form_box {
    padding-left: 0.26rem;
    padding-right: 0.26rem;

    .code_box {
      ::v-deep .el-form-item__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-input {
          width: 60%;
        }

        .btn_sty {
          width: 35%;
          color: #fff;
          font: 0.14rem sans-serif;

          &:hover {
            color: #2080f7;
          }
        }
      }
    }
  }

  .space-y-20s > *:not(:first-child) {
    margin-top: 0.2rem;
  }

  .group_4s {
    padding: 0 0.2rem;
  }

  .space-x-9s > *:not(:first-child) {
    margin-left: 0.09rem;
  }

  .font_7s {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;

    width: 0.64rem;
    text-align: right;
  }

  .text_22s {
    line-height: 0.16rem;
  }

  .section_12s {
    padding: 0.06rem 0.13rem;
    background-color: #ffffff;
    border-radius: 0.04rem;
    height: 0.39rem;
    border: solid 0.01rem #c2c2c2;
  }

  .space-x-200s > *:not(:first-child) {
    margin-left: 2.05rem;
  }

  .group_5s {
    padding: 0.07rem 0 0.04rem;
  }

  .font_8s {
    font-size: 0.14rem;
    font-family: OPPOSans;
    letter-spacing: 0.014rem;
    line-height: 0.14rem;
  }

  .text_23s {
    text-transform: uppercase;
  }

  .text_24s {
    color: #082c47;
    line-height: 0.13rem;
    letter-spacing: unset;
  }

  .image_3s {
    width: 0.01rem;
    height: 0.24rem;
  }

  .pos_2s {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .image_4s {
    width: 0.13rem;
    height: 0.13rem;
  }

  .text-wrapper_6s {
    padding: 0.12rem 0;
    background-color: #ffffff;
    border-radius: 0.04rem;
    width: 3.05rem;
    height: 0.39rem;
    border: solid 0.01rem #c2c2c2;
  }

  .text_26s {
    margin-left: 0.13rem;
    color: #d9d9d9;
  }

  .group_1s {
    padding: 0 0.19rem;
  }

  .text_28s {
    line-height: 0.16rem;
  }

  .section_13s {
    margin-left: 0.1rem;
    padding: 0.12rem;
    background-color: #ffffff;
    border-radius: 0.04rem;
    height: 0.39rem;
    border: solid 0.01rem #c2c2c2;
  }

  .space-x-78s > *:not(:first-child) {
    margin-left: 0.78rem;
  }

  .text_30s {
    color: #082c47;
    line-height: 0.07rem;
  }

  .text-wrapper_7s {
    margin-left: 0.18rem;
    padding: 0.12rem 0;
    background-color: #ffffff;
    border-radius: 0.04rem;
    width: 1.01rem;
    height: 0.39rem;
    border: solid 0.01rem #c2c2c2;
  }

  .text_29s {
    color: #2080f7;
  }

  .group_6s {
    padding: 0.21rem 0.55rem 0.18rem;
    border-top: solid 0.01rem #f3f6fa;
    justify-content: center;
  }

  .space-x-19s > *:not(:first-child) {
    margin-left: 0.19rem;
  }

  .text-wrapper_8s {
    padding: 0.14rem 0;
    background-color: #c2c2c2;
    border-radius: 0.04rem;
    width: 2rem;
    height: 0.45rem;

    &:hover {
      background-color: #2080f7 !important;

      .font_9s {
        color: #fff;
      }
    }
  }

  .font_9s {
    font-size: 0.18rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.025rem;
    line-height: 0.17rem;
    font-weight: 300;
  }

  .text-wrapper_9s {
    border-radius: 0.04rem;
    width: 2rem;
    height: 0.45rem;
  }

  .text_31s {
    line-height: 0.18rem;
  }
}

.el-radio-group {
  margin-top: 12px;
}

.from_box {
  pointer-events: none;
  opacity: 0.9;

  &.edit_s {
    pointer-events: all;
    opacity: 1;
  }
}

::v-deep .el-form {
  .el-form-item__label {
    padding-right: 1em;
  }

  .el-form-item__label, .el-form-item__content {
    font-size: .14rem;
  }
  .el-input__suffix-inner{
    pointer-events: none;
  }
}
.sty_m{
  display: none;
}
@media screen and (orientation: portrait){
  .sty_m{
    display: block;
  }
  .group{
    width: 100%;
  }
  .group_5{
    flex-wrap: wrap;
    min-height: auto;
  }
  .group_3{
    width: 100%;
  }
  .group_4{
    padding: .2rem 0 .2rem;

    .font_3{
      font-size: .22rem;
      line-height: 1;
      padding-top: .2rem;
    }

  }

  .section_4{
    padding: .3rem;
    margin-top: .4rem;
  }
  .space-y-13 > *[data-v-135ffcc1]:not(:first-child){
    margin-top: .2rem;
  }
  .image_2{
    width: .3rem;
    height: auto;
  }
  .space-x-7 > *[data-v-135ffcc1]:not(:first-child){
    margin-left: .2rem;
  }
  .space-x-60{

    flex-wrap: wrap;
    justify-content: center;
    .space-y-13{
      width: 100%;

      position: relative;
      align-items: center;


      .image,.updata_img{
        width: 1.6rem;
        height: 1.6rem;
      }
      .space-x-7{
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -.2rem;
        top: 0;
        margin: 0;
        padding: 0 .2rem;

      }
      .font_3{
        font-size: .22rem;
        line-height: 1;
      }

      .sty_m{
        margin-top: .2rem;
        font-size: .22rem;
        line-height: 1;
      }
    }
  }
  .from_box{
    width: 100% !important;
    margin-top: .3rem ;
  }
  ::v-deep .el-form {
    .el-form-item__label {
      padding-right: .5em;
      height: .7rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 1.35;
    }

    .el-form-item__label, .el-form-item__content {
      font-size: .22rem;

    }
    .el-form-item__content{
      line-height: .7rem;
    }
    .el-form-item{
      margin-bottom: .3rem;
    }
    .el-input--prefix .el-input__inner{
      padding-left: .6rem;
    }
    .el-input__inner{
      height: .7rem;
      font-size: .22rem;
      padding: .2rem;
    }
    .el-input__icon{
      line-height: .7rem;
    }
    .el-button{
      padding: .2rem;
      font-size: .24rem;
    }
    .el-radio-group{
      margin-top: .2rem;
    }
    .el-radio__label{
      font-size: .22rem;
    }
    .el-radio__inner::after{
      width: 7px;
      height: 7px;
    }
  }

  .safe_box{
    &.section_4a{
      padding: .3rem;
    }
.section_5a{
  padding: .2rem;
}
    .image_2a{
      width: .4rem;
      height: auto;
    }
    .text_18a,.text_19a{
      line-height: 1;
      font-size: .24rem;
    }
    .text_21a{
      font-size: .2rem;
      line-height: 1.3;
      margin-top: .1rem;
    }
.space-x-22a{
  width: calc(100% - 1.4rem);
}
    .space-y-11a{
      margin: left !important;
      padding-left: .3rem;
      width: calc(100% - .5rem );
    }

    .text-wrapper_4a{
      width:  1.2rem;
      height: .6rem;
      .font_5a{
        font-size: .24rem;
      }
    }
  }


  .change_phone {
    > div{
      width: 95%;
    }
    .section_10s{
      padding: .3rem .3rem .2rem;
      align-items: center;
    }
    .text_18s{
      font-size: .28rem;
    }
    .image_2s{
      width: .4rem;
      height: auto;
  }
.font_9s{
  font-size: .24rem;
  line-height: 1;
}
   .text-wrapper_9s,.text-wrapper_8s{
     padding: .2rem;
     height: auto;

    }

     .form_box .code_box .el-form-item__content .btn_sty{
       height: .7rem;
       font-size: .2rem;
     }

  }


}

</style>
