<template>
  <div class="page">
    <nav-box></nav-box>


    <div class="pub_-flex-col pub_-justify-start pub_-self-center pub_case">
      <div class="pub_-flex-col pub_-justify-start group_2">
        <div class="pub_-flex-row space-x-40 pub_case">
          <left-nav tar="2"></left-nav>

          <div class="pub_-flex-col pub_-flex-auto section_9 space-y-59">
            <div class="pub_-flex-row space-x-20">
              <div class="pub_-flex-row pub_-flex-auto section_3 space-x-18">
                <div class="pub_-flex-col pub_-flex-auto pub_-self-center group_4 space-y-19">
                  <span class="pub_-self-start font_3 text_11">已学进度</span>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-start section_7">
                    <div class="section_8" :style="{ width: info.schedule + '%' }"></div>
                  </div>
                </div>
                <div class="pub_-self-start group_5">
                  <span class="text_12">{{ info.schedule }}</span>
                  <span class="text_14">%</span>
                </div>
              </div>
              <!--              <div @click="goHref($event, info.type,info.id)"-->
              <!--                   class="pub_-flex-col pub_-items-center pub_-shrink-0 pub_-relative section_4">-->
              <!--                <img class="image_2" src="../../assets/dded08348189d3ff13ac77e3cc856a16.png"/>-->
              <!--                <span class="text_15">继续学习</span>-->
              <!--                <img class="image pos" src="../../assets/bc6bc4fcd7ff2402d76ab0e847f5df0a.png"/>-->
              <!--              </div>-->
            </div>
            <div class="pub_-flex-col group_1 space-y-19 purchased_box">
              <span class="pub_-self-start font_3 text_18">课程目录</span>
              <div class="table_box" v-if="info.type === 1">

                <div class="table_top">
                  <div class="table_block">
                    <span class="table_n">可用课时：</span>
                    <span class="table_c str_ok">{{ info.canUseClassHour }}</span>
                  </div>
                  <div class="table_block">
                    <span class="table_n">已用课时：</span>
                    <span class="table_c str_end">{{ info.usedClassHour }}</span>
                  </div>

                  <span class=" btn_sty " v-if="info.canUseClassHour > 0"
                        @click="courseArranging(info.id,info.skuValue)">去排课</span>
                </div>

                <div class="table_cen" v-if="info.liveBroadcastList.length > 0">

                  <div class="table_tit">
                    <div>教师名称</div>
                    <div>开始时间</div>
                    <div>直播状态</div>
                    <div>签到状态</div>
                    <div>操作</div>
                  </div>

                  <div class="table_con" v-for="(item,i) in info.liveBroadcastList" :key="i">

                    <div><img :src="item.avatarPath" alt=""/><span>{{ item.nickname }}</span></div>
                    <div><span class="m_sty">开始时间：</span><span>{{ item.startTime }}</span></div>
                    <div><span class="m_sty">直播状态：</span>
                      <span class="str_ok " v-if="item.status === 1">待排课</span>
                      <span class="srt_on " v-else-if="item.status === 2">待教师确认</span>
                      <span class="str_all" v-else-if="item.status === 3">待开课</span>
                      <span class="str_all" v-else-if="item.status === 4">授课中</span>
                      <span class="str_end" v-else>已结束</span>
                    </div>
                    <div>
                      <span class="m_sty">签到状态：</span>
                      <span
                        :class="item.signInStatus === 1 ? 'str_end' : (item.signInStatus === 2 ? 'str_ok' : 'str_all')">{{
                          item.signInStatus === 1 ? '未开始' : (item.signInStatus === 2 ? '未签到' : '已签到')
                        }}</span>
                    </div>

                    <div>

                      <div v-if=" item.status === 4 && item.videoUrl !== null && item.videoUrl !== ''">

                        <div class="btn_sty btn_sty_line"
                             @click="watchVideos(item.urlArray,true,item.id)"
                             v-if="item.urlArray.length > 1">
                          观看录像
                        </div>
                        <div class="btn_sty btn_sty_line"
                             @click="goNew($event,item.id)" :data-url="item.videoUrl"
                             v-else>观看录像
                        </div>
                      </div>

                      <div class="btn_sty btn_sty_line" @click="goHref($event,item.id)" :data-url="item.videoUrl"
                           v-if="item.status === 4 ">去观看
                      </div>

                    </div>

                  </div>


                </div>


              </div>
              <div class="pub_-flex-col group_6" v-else>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_10">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/ce4a889920b52d8c26e70441fc94ccb9.png"/>
                    <span class="font_3 text_21"></span>
                  </div>
                  <!--                  <div class="pub_-flex-row pub_-items-center space-x-8">-->
                  <!--                    <img class="pub_-shrink-0 image_4" src="../../assets/ad2cc88537ceb286d3241f2c6031b5da.png" />-->
                  <!--                    <span class="font_2 text_22">下拉</span>-->
                  <!--                  </div>-->
                </div>
                <div class="pub_-flex-col section_11">
                  <div
                    class="coure_box"
                    v-for="(item, i) in timetableData"
                    :key="i"
                  >
                    <div
                      class="pub_-flex-row pub_-justify-between pub_-items-center group_8z"
                    >
                      <div class="pub_-flex-row pub_-items-center space-x-14z">
                        <img
                          class="pub_-shrink-0 image_3z"
                          src="../../assets/4752c903e4673c40768e66e39d1d0b37.png"
                        />
                        <span class="font_2z">{{ item.name }}</span>
                      </div>
                      <div
                        class="pub_-flex-row pub_-items-center space-x-9z"
                        @click="downBtn(i)"
                        :class="item.downShow == true ? 'on' : ''"
                      >
                        <img
                          class="pub_-shrink-0 image_4z"
                          src="../../assets/0312671bfb694883273997d54e6e8e45.png"
                        />
                        <span class="font_2z text_29z">下拉</span>
                      </div>
                    </div>
                    <div class="divider"></div>

                    <div class="coure_con" v-if="item.downShow == true">
                      <div
                        class="coure_li"
                        v-for="(items, j) in item.hourList"
                        :key="j"
                      >
                        <div
                          class="pub_-flex-row pub_-justify-between pub_-items-center group_9z"
                        >
                          <div class="pub_-flex-row space-x-14z">
                            <img
                              class="image_3z"
                              src="../../assets/513a254d5a73f54414be994c1d5c9034.png"
                            />
                            <div class="pub_-flex-col pub_-items-start space-y-7z">
                              <span class="font_2z">{{ items.coursewareName }}</span>
                              <span class="font_6z">{{ items.name }}</span>
                              <!-- <span class="font_6z"
                                >作业提交截止时间：2022.10.21-12.20</span
                              > -->
                            </div>
                          </div>
                          <div class="pub_-flex-row pub_-items-center">

                            <div class="cursor_s " v-if="items.coursewareId"
                                 @click="downloadBtn(items.coursewarePath)">
                              <img
                                class="pub_-shrink-0 image_5z"
                                src="../../assets/f69c904853130b775f5319952296b24a.png"
                              />
                              <span class="font_1z text_30z">学习资料</span>
                            </div>


                            <div
                              class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_5z btn_sty btn_sty_line"
                              @click="detVideo = items.urlArray,videoList = true,zdId = items.id"
                              v-if="items.type === 1 && items.videoUrl !== ''">查看直播记录
                            </div>
                            <span
                              class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_5z btn_sty btn_sty_line "
                              v-if="items.isArrange === 0  && items.type === 1"
                              @click="courseArranging(info.id,'',zdId = items.id)">去排课</span>

                            <div
                              class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_5z btn_sty btn_sty_line"
                              @click=" goNew($event,items.id) " :data-url="items.videoUrl"
                              v-if="items.type === 2">去观看
                            </div>

                          </div>


                        </div>
                        <div class="divider"></div>
                      </div>

                      <div v-if="item.audioPath">
                        <div
                          class="pub_-flex-row pub_-justify-between pub_-items-center group_10z"
                        >
                          <div class=" space-x-14z pub_-flex-row pub_-items-center">
                            <img class="image_3z" src="../../assets/ab3e688de26effc78fbcb84bce212d9a.png" />
                            <span class="font_2z">课后作业</span>
                          </div>

                          <div class="cursor_s " v-if="item.audioPath"
                               @click="downloadBtn(item.audioPath)">
                            <img
                              class="pub_-shrink-0 image_5z"
                              src="../../assets/f69c904853130b775f5319952296b24a.png"
                            />
                            <span class="font_1z text_30z">课后作业</span>
                          </div>
                        </div>


                        <!--                        <div class="pub_-flex-row group_11z space-x-19z">-->
                        <!--                          <div class="pub_-flex-col pub_-justify-start section_13z">-->
                        <!--                            <div-->
                        <!--                              class="pub_-flex-row pub_-justify-between pub_-items-center section_16z"-->
                        <!--                            >-->
                        <!--                              <span class="font_2z">{{ item.name }}</span>-->
                        <!--                              <div-->
                        <!--                                class="pub_-self-end image_9"-->
                        <!--                                :data-url="item.audioPath"-->
                        <!--                                :class="currentAudioIndex == i ? 'on' : ''"-->
                        <!--                              >-->
                        <!--                                <audio-->
                        <!--                                  ref="audio"-->
                        <!--                                  :src="item.audioPath"-->
                        <!--                                  :key="i"-->
                        <!--                                  style="position: absolute; opacity: 0"-->
                        <!--                                ></audio>-->
                        <!--                                <img @click="playAudio(i)"-->
                        <!--                                     class="image_7z"-->
                        <!--                                     src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"-->
                        <!--                                />-->
                        <!--                              </div>-->
                        <!--                            </div>-->
                        <!--                          </div>-->
                        <!--                          <div class="pub_-flex-col section_14z space-y-6z">-->

                        <!--                            <div-->
                        <!--                              class="pub_-flex-row pub_-justify-between pub_-items-center pub_-relative section_15z"-->
                        <!--                            >-->
                        <!--                              <span class="font_2z">{{ item.name }}</span>-->
                        <!--                              <div class="pub_-self-end image_9" :data-url="item.audioUrl"-->
                        <!--                                   :class="currentAudioIndexB == i ? 'on' : ''">-->
                        <!--                                <audio ref="audioB" :src="item.audioUrl" :key="i"-->
                        <!--                                       style="position: absolute; opacity: 0" autoplay ></audio>-->
                        <!--                                <img @click="playAudioB(i)" class="image_7z"-->
                        <!--                                     src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"/>-->
                        <!--                              </div>-->
                        <!--                              <img-->
                        <!--                                class="image_6z pos_2" src="../../assets/9b0af34e1142de0c3ab65dcedf18f5d2.png"/>-->
                        <!--                            </div>-->

                        <!--                         <div class="up_btn">-->
                        <!--                           <input type="file" ref="fileInput"-->

                        <!--                                  @change="handleFileUpload($event, i)">-->
                        <!--                           <img-->

                        <!--                                class=""-->
                        <!--                                src="../../assets/40ee75a345685dbf4abbf3468ce80f34.png"-->
                        <!--                           />-->
                        <!--                         </div>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <!--                      -->
                        <!--                      -->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="update_case" v-if="info.isFinish == 1">
              <div class="updata_t">
                <h3>补充资料</h3>
                <div class="el-upload__files btn_sty">

                  <el-upload
                    class="avatar-uploader"
                    action="/api.upload/upload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    accept=""
                    :data="{ fileType: 'file' }"
                  >
                    上传
                  </el-upload>
                </div>
              </div>

              <div class="update_con">
                <div class="updata_th">
                  <div class="updata_td">文件名</div>
                  <div class="updata_td">时间</div>
                  <div class="updata_td">下载</div>
                </div>

                <div class="updata_tr" v-for="(item,k) in info.profileList">
                  <div class="updata_td">{{ item.fileName }}</div>
                  <div class="updata_td">{{ item.createTime }}</div>
                  <div class="updata_td">
                    <div class=" btn_sty" @click="downloadBtn(item.filePath)">
                      下载
                    </div>
                  </div>
                </div>


              </div>

            </div>


            <!--       评价-->



            <div class=" btn_sty btn_sty_line qu_btn" v-if="info.canRefund == 1" @click="refund(info.id)">
              <span class="font_12">申请退款</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    排课-->
    <div class="cur_sha" v-if="curP === true">
      <div class="cur_mask"></div>
      <div class="cur_con">
        <div class="cup_top">
          <div class="cur_tit">排课</div>
          <img
            class=" image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="curP= false"
          />
        </div>
        <div class="cur_sel_case">
          <div class="cur_sel_box" v-if="teacherSel == true">
            <div class="cur_n">选择意向导师</div>
            <div class="tea_sel_box">
              <el-select v-model="value" placeholder="请选择" @change="teacherChanged">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!--          v-if="timeStr == true"-->
          <div class="cur_sel_box">
            <div class="cur_n">选择上课时间</div>
            <div class="tea_sel_box">
              <el-select v-model="time" placeholder="请选择">
                <el-option
                  v-for="item in timeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="btn_sty" @click="subCur(info.id)">提交</div>
      </div>
    </div>

    <!--    播放列表-->
    <div class="cur_sha" v-if="videoList === true">
      <div class="cur_mask"></div>

      <div class="cur_con">
        <div class="cup_top">
          <div class="cur_tit">视频列表</div>
          <img
            class=" image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="videoList= false"
          />
        </div>

        <div class="vie_sel_case">
          <div class="vid_list" v-for="(item,i) in detVideo" :key="i">
            <div class="vid_n">{{ item }}</div>
            <div class="btn_sty btn_sty_line" @click="goNew($event)" :data-url="item">观看录像</div>
          </div>


        </div>

      </div>
    </div>



    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import QRCode from "qrcode";

export default {
  components: {NavBox, FootBox, LeftNav},
  data() {
    return {
      id: '',
      info: '',
      timetableData: '',//课表数据
      currentAudioIndex: null,
      currentAudioIndexB: null,

      options: [
        {
          value: "",
          label: "",
        },
      ],
      value: "",

      timeList: [
        {
          value: "",
          label: "",
        },
      ],
      time: "",

      curP: false,
      timeStr: false,//是否显示时段选择
      teacherSel: false,//是否是1v1  可选老师

      videoList: false,
      detVideo: [],
      zdId: '',//章节id




      audioItems: [
        {audioUrl: null, audioPlaying: false},
        {audioUrl: null, audioPlaying: false},
        // 添加更多的项...
      ],



      imageUrl: '',



    };
  },
  created() {
    this.id = this.getParam('id')
    this.ajaxH(this.id);
  },
  methods: {
    //上传
    handleAvatarSuccess(res, file) {
      // console.log(res)

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }


      // this.form.avatar = res.data.fileId;

    },
    beforeAvatarUpload(file) {
      return true;
    },

    watchVideos(Array, sua, id) {//打开录像弹窗
      this.detVideo = Array;
      this.videoList = sua;
      this.zdId = id;
    },
    openFilePicker(index) {
      this.$refs.fileInput[index].click();
    },
    handleFileUpload(event, index) {
      const file = event.target.files[0];
      const mediaTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/flac', 'video/mp4', 'video/mov', 'video/avi', 'video/flv'];  // 常见的音频和视频类型

      if (!mediaTypes.includes(file.type)) {
        this.$message({
          message: '请上传音频',
          type: "warning",
        });
        return;
      }
      const audioUrl = URL.createObjectURL(file);


      this.timetableData[index].audioUrl = audioUrl;

      const audioElement = this.$refs.audio[index];

      this.$message({
        message: '上传成功',
        type: "success",
      });


    },

    playAudioB(index) {
      const audioUrl = this.timetableData[index].audioUrl;

      if (this.currentAudioIndex !== null && this.currentAudioIndex !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audio[this.currentAudioIndex].pause();
      }
      this.currentAudioIndex = null;

      if (audioUrl) {
        const audioT = this.$refs.audio[index];
        audioT.pause();
        if (this.currentAudioIndexB !== null && this.currentAudioIndexB !== index) {
          // 如果已经有音频在播放，则暂停它
          this.$refs.audioB[this.currentAudioIndexB].pause();
        }
        // 播放当前选中的音频
        const audioEl = this.$refs.audioB[index];
        if (audioEl.paused) {
          audioEl.play();
          this.currentAudioIndexB = index;
        } else {
          audioEl.pause();
          this.currentAudioIndexB = null;

        }
      } else {
        alert('请先上传音频文件');
      }

    },
    async ajaxH(id) {
      let param = {
        id: id,
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/getInfo",
        param
      );
      this.info = res.data;
      console.log(res.data)
      if (res.data.liveBroadcastList) {
        res.data.liveBroadcastList.map((item) => {
          let old = item.videoUrl;
          if (old !== null) {
            item.urlArray = old.split(';')
          } else {
            item.urlArray = []
          }
        })
      }
      this.timetableData = res.data.timetableData;
      if (this.timetableData) {
        this.timetableData.map((item) => {
          item.downShow = false;
          item.audioUrl = null;
        });
      }

      console.log(this.info)


      this.getRuleList();
    },
    downBtn(index) {
      this.timetableData[index].downShow = !this.timetableData[index].downShow;

      this.$forceUpdate();
    },
    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },
    goHref(e, id) {
      let path = '/room' + "?id=" + id;
      // console.log(path)
      // this.$router.push({path,target: '_blank'});
      this.$router.push(path, {target: '_blank'});
    },
    goNew(e, id) { //打开新窗口播放视频
      this.zdId = id;
      // console.log('去观看')
      this.isLearned();//播放视频的时候告诉后台播放的章节 用以监控进度
// console.log(e.currentTarget.dataset.url)
      let url = e.currentTarget.dataset.url
      window.open(url, '_blank',);
    },
    downloadBtn(str) {//下载课件
      const a = document.createElement('a'); // 创建一个HTML 元素
      let url = str;
      a.setAttribute('target', '_blank'); //target
      a.setAttribute('download', url); //download属性
      a.setAttribute('href', url); // href链接
      a.click(); // 自执行点击事件

    },
    playAudio(index) {

      if (this.currentAudioIndexB !== null && this.currentAudioIndexB !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audioB[this.currentAudioIndexB].pause();
      }
      this.currentAudioIndexB = null;
      const audioS = this.$refs.audioB[index];
      audioS.pause();

      if (this.currentAudioIndex !== null && this.currentAudioIndex !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audio[this.currentAudioIndex].pause();
      }
      // 播放当前选中的音频
      const audioEl = this.$refs.audio[index];
      if (audioEl.paused) {
        audioEl.play();
        this.currentAudioIndex = index;
      } else {
        audioEl.pause();
        this.currentAudioIndex = null;

      }
      // this.currentAudioIndex = index;
    },
    async refund(id) {
      let param = {
        courseId: id,
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/refund",
        param
      );

      console.log(res)

      this.$message({
        message: res.msg,
        type: "warning",
      });
    },
    async courseArranging(id, sku) {

      if (sku == '1v1') {
        let param = {
          id: id,
        };
        const {data: res} = await this.$http.post(  //获取老师
          "/api.myCourse/getOrderCourseTeacherList",
          param
        );
        this.teacherSel = true

        console.log(res)
        this.value = res.data[0].id;
        this.getTeacherTimeList(this.value)
        let newTeacher = [];
        res.data.forEach(item => {
          let linS = {};
          linS.value = item.id;
          linS.label = item.nickname;
          newTeacher.push(linS)
        })
        this.options = newTeacher;


      } else {
        this.getTeacherTimeList(0)
      }

      this.curP = true;

    },
    async getTeacherTimeList(id) {
      let param = {
        id: id,
      };
      const {data: res} = await this.$http.post(  //获取老师时间列表
        "/api.myCourse/getTeacherTimeList",
        param
      );

      // console.log(res)
      if (res.data.length > 0) {
        this.time = res.data[0].id;
        let newTime = [];
        res.data.forEach(item => {
          let linS = {};
          linS.value = item.id;
          linS.label = item.dateTime;
          newTime.push(linS)
        })


        this.timeList = newTime;

        // console.log(this.timeList)

        this.timeStr = true;

      } else {


        this.time = '';
        this.timeList = [];
        this.timeStr = false;
        this.$message({
          message: '没有可选时段',
          type: "warning",
        });
      }


    },
    teacherChanged() {

      this.getTeacherTimeList(this.value)
    },
    async subCur(id) {
      let param = {
        courseId: id,
        hourId: this.zdId,
        teacherTimeInfoId: this.time
      };
      const {data: res} = await this.$http.post(  //获取老师
        "/api.myCourse/arrange",
        param
      );

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.curP = false;

        this.ajaxH(this.id);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },

    async isLearned(id) { //告诉后台光看的课节
      let param = {
        id: this.zdId,
      };
      // console.log(param)
      const {data: res} = await this.$http.post(  //获取老师
        "/api.myCourse/isLearned",
        param
      );
      // console.log(res)


    },


  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.space-y-23 > *:not(:first-child) {
  margin-top: 0.23rem;
}

.section {
  padding: 0.17rem 0;
  background-color: #ffffff;
}

.space-y-17 > *:not(:first-child) {
  margin-top: 0.17rem;
}

.group {
  width: 11.81rem;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.font_2 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_2 {
  line-height: 0.15rem;
  font-weight: unset;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_3 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group_2 {
  padding: .4rem 0 .8rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.text-wrapper_1 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}

.text_10 {
  margin-left: 0.19rem;
}

.section_5 {
  background-color: #ffffff;
}

.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}

.section_6 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.text_13 {
  margin-right: 1.26rem;
  color: #2080f7;
}

.text_16 {
  margin-left: 0.16rem;
}

.text_17 {
  margin-left: 0.17rem;
}

.text_19 {
  margin-left: 0.16rem;
}

.text-wrapper_2 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_20 {
  line-height: 0.13rem;
}

.text_1 {
  margin-left: 0.17rem;
}

.text_23 {
  margin-left: 0.17rem;
}

.text_26 {
  margin-left: 0.16rem;
}

.section_9 {
  padding-bottom: .5rem;
  background-color: #ffffff;
  width: 9.4rem;
  min-height: 7rem;
}

.space-y-59 > *:not(:first-child) {
  margin-top: 0.59rem;
}

.space-x-20 > *:not(:first-child) {
  margin-left: 0.2rem;
}

.section_3 {
  padding: 0.4rem 0.39rem;
  background-color: #ffffff;
  height: 1.24rem;
}

.space-x-18 > *:not(:first-child) {
  margin-left: 0.18rem;
}

.group_4 {
  width: 6.37rem;
}

.text_11 {
  color: #000000;
  font-weight: 300;
  line-height: 0.18rem;
}

.section_7 {
  background-color: #f3f6fa;
  border-radius: 0.04rem;
}

.section_8 {
  background-color: #2080f7;
  border-radius: 0.04rem;
  width: 5.39rem;
  height: 0.08rem;
}

.group_5 {
  margin-top: 0.14rem;
  line-height: 0.28rem;
  height: 0.28rem;
}

.text_12 {
  color: #2080f7;
  font-size: 0.36rem;
  font-family: OPPOSans;
  line-height: 0.28rem;
  letter-spacing: 0.018rem;
}

.text_14 {
  color: #2080f7;
  font-size: 0.2rem;
  font-family: OPPOSans;
  line-height: 0.15rem;
}

.section_4 {
  padding: 0.44rem 0 0.4rem;
  background-color: #ffffff;
  width: 1.24rem;
  height: 1.24rem;
}

.image_2 {
  width: 0.15rem;
  height: 0.19rem;
}

.text_15 {
  margin-top: 0.06rem;
  color: #2080f7;
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  font-weight: 300;
  line-height: 0.13rem;
}

.image {
  border-radius: 0.42rem;
  width: 0.84rem;
  height: 0.84rem;
}

.pos {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.group_1 {
  padding: 0 0.38rem;
}

.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}

.text_18 {
  font-weight: 500;
  line-height: 0.18rem;
  text-transform: uppercase;
}

.group_6 {
  padding: 0 0.02rem;
}

.section_10 {
  padding: 0.18rem 0.16rem 0.18rem 0.2rem;
  background-color: #f3f6fa;
}

.image_3 {
  width: 0.34rem;
  height: 0.34rem;
}

.text_21 {
  font-weight: 300;
  line-height: 0.2rem;
}

.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}

.image_4 {
  width: 0.15rem;
  height: 0.09rem;
}

.text_22 {
  line-height: 0.13rem;
}

.section_11 {
  padding: 0 0.16rem 0.47rem 0.2rem;
  border: solid 0.01rem #f3f6fa;
}

.group_7 {
  padding: 0.15rem 0 0.2rem;
  border-bottom: solid 0.01rem #f3f6fa;
}

.text_24 {
  line-height: 0.15rem;
}

.font_6 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #a1a9b2;
}

.text_27 {
  line-height: 0.15rem;
}

.section_12 {
  background-color: #a1a9b2;
  width: 0.02rem;
  height: 0.08rem;
}

.text_28 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}

.text-wrapper_4 {
  margin-right: 0.05rem;
  padding: 0.08rem 0;
  background-color: #2080f7;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
}

.font_5 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.14rem;
  font-weight: 300;
}

.text_25 {
  color: #ffffff;
}

.group_8 {
  padding: 0.2rem 0;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}

.text_29 {
  line-height: 0.13rem;
}

.divider {
  margin-left: 0.34rem;
  margin-right: 0.05rem;
  background-color: #edeff2;
  height: 0.01rem;
}

.group_9 {
  padding: 0.2rem 0.06rem 0.2rem 0.34rem;
}

.space-y-7 > *:not(:first-child) {
  margin-top: 0.07rem;
}

.image_5 {
  width: 0.17rem;
  height: 0.17rem;
}

.text_30 {
  margin-left: 0.09rem;
  color: #1a1a1a;
}

.text-wrapper_5 {
  margin-left: 0.17rem;
  padding: 0.05rem 0 0.08rem;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #2080f7;
}

.text_31 {
  color: #2080f7;
}

.group_10 {
  margin-top: 0.2rem;
  padding: 0 0.34rem;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.group_11 {
  margin-top: 0.19rem;
  padding-left: 0.33rem;
  padding-right: 0.05rem;
}

.space-x-19 > *:not(:first-child) {
  margin-left: 0.19rem;
}

.section_13 {
  padding: 0.2rem 0 1.56rem;
  flex: 1 1 3.84rem;
  background-color: #edeff2;
  width: 3.84rem;
  height: 2.25rem;
}

.section_16 {
  margin: 0 0.2rem;
  padding-left: 0.14rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
  width: 3.44rem;
}

.image_7 {
  border-radius: 0.08rem;
  width: 0.8rem;
  height: 0.5rem;
}

.section_14 {
  padding: 0.2rem 0.13rem 1rem;
  flex: 1 1 3.84rem;
  background-color: #edeff2;
  width: 3.84rem;
  height: 2.25rem;
}

.space-y-6 > *:not(:first-child) {
  margin-top: 0.06rem;
}

.section_15 {
  margin: 0 0.06rem;
  padding-left: 0.2rem;
  background-color: #ffffff;
  border-radius: 0.08rem;
}

.image_8 {
  width: 0.8rem;
  height: 0.5rem;
}

.image_6 {
  width: 0.14rem;
  height: 0.14rem;
}

.pos_2 {
  position: absolute;
  right: 0;
  top: 0;
}

.purchased_box {
  &.group_1z {
    padding-top: 0.4rem;
  }

  .font_3z {
    font-size: 0.18rem;
    line-height: 1;
  }

  .text_18z {
    font-weight: 500;
    line-height: 0.18rem;
    text-transform: uppercase;
  }

  .group_6z {
    padding: 0 0.02rem;
    margin-top: 0.2rem;
  }

  .section_10z {
    padding: 0.18rem 0.16rem 0.18rem 0.2rem;
    background-color: #f3f6fa;
  }

  .image_3z {
    width: 0.34rem;
    height: 0.34rem;
  }

  .text_21z {
    font-weight: 300;
    line-height: 0.2rem;
  }

  .space-x-8z > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .image_4z {
    width: 0.15rem;
    height: 0.09rem;
  }

  .text_22z {
    line-height: 0.13rem;
  }

  .section_11z {
    padding: 0 0.16rem 0.47rem 0.2rem;
    border: solid 0.01rem #f3f6fa;
  }

  .group_7z {
    padding: 0.15rem 0 0.2rem;
    border-bottom: solid 0.01rem #f3f6fa;
  }

  .text_24z {
    line-height: 0.15rem;
  }

  .font_6z {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 300;
    color: #a1a9b2;
  }

  .text_27z {
    line-height: 0.15rem;
  }

  .section_12z {
    background-color: #a1a9b2;
    width: 0.02rem;
    height: 0.08rem;
  }

  .text_28z {
    margin-left: 0.08rem;
    line-height: 0.13rem;
  }

  .text-wrapper_4z {
    margin-right: 0.05rem;
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
  }

  .font_5z {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.02rem;
    line-height: 0.14rem;
    font-weight: 300;
  }

  .group_8z {
    padding: 0.2rem 0;
  }

  .space-x-9z > *:not(:first-child) {
    margin-left: 0.09rem;
  }

  .space-x-9z {
    cursor: pointer;

    img {

      transform: rotate(180deg);
      transition: transform .3s ease-in-out;
    }

    &.on {
      img {
        transform: none;
      }
    }
  }

  .text_29z {
    line-height: 0.13rem;
  }

  .divider {
    margin-left: 0.34rem;
    margin-right: 0.05rem;
    background-color: #edeff2;
    height: 0.01rem;
  }

  .group_9z {
    padding: 0.2rem 0.06rem 0.2rem 0.34rem;
  }

  .space-y-7z > *:not(:first-child) {
    margin-top: 0.07rem;
  }

  .image_5z {
    width: 0.17rem;
    height: 0.17rem;
  }

  .text_30z {
    margin-left: 0.09rem;
    color: #1a1a1a;
  }

  .text-wrapper_5z {
    margin-left: 0.17rem;
    padding: 0.05rem 0 0.08rem;
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
    border: solid 0.01rem #2080f7;
  }

  .group_10z {
    margin-top: 0.2rem;
    padding: 0 0.34rem;
  }

  .space-x-14z > *:not(:first-child) {
    margin-left: 0.14rem;
  }

  .group_11z {
    margin-top: 0.19rem;
    padding-left: 0.33rem;
    padding-right: 0.05rem;
  }

  .space-x-19z > *:not(:first-child) {
    margin-left: 0.19rem;
  }

  .section_13z {
    padding: 0.2rem 0 1.56rem;
    flex: 1 1 3.84rem;
    background-color: #edeff2;
    width: 3.84rem;
    height: 2.25rem;
  }

  .section_16z {
    margin: 0 0.2rem;
    padding-left: 0.14rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
    width: 3.44rem;
  }

  .image_7z {
    border-radius: 0.08rem;
    width: 0.8rem;
    height: 0.5rem;
  }

  .image_9 {

    border-radius: 0.08rem;
    width: 0.64rem;
    height: 0.34rem;
    margin: .08rem;

    img {
      cursor: pointer;
      width: 0.64rem;
      height: 0.34rem;
    }

    &.on {
      border: 2px solid #2080f7;
    }
  }

  .section_14z {
    padding: 0.2rem 0.13rem 1rem;
    flex: 1 1 3.84rem;
    background-color: #edeff2;
    width: 3.84rem;
    height: 2.25rem;
  }

  .space-y-6z > *:not(:first-child) {
    margin-top: 0.06rem;
  }

  .section_15z {
    margin: 0 0.06rem;
    padding-left: 0.2rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
  }

  .image_8z {
    width: 0.8rem;
    height: 0.5rem;
  }

  .image_6z {
    width: 0.14rem;
    height: 0.14rem;
  }

  .pos_2 {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.cursor_s {
  cursor: pointer;
}

.btn_sty.qu_btn {
  margin: .3rem auto .8rem;
  width: 2rem;
}

.table_box {
  .table_top {
    display: flex;
    align-items: center;

    .table_block {
      display: flex;
      align-items: center;
      font-size: .2rem;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

  }

  .table_cen {
    margin-top: .3rem;

    .table_tit {
      display: flex;
      align-items: center;
    }

    .table_con, .table_tit {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 15%;

        }
      }
    }

    .table_con {
      display: flex;
      align-items: center;
      padding: .3rem 0;
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      position: relative;

      .btn_sty {

        width: 1rem;
        margin: 0;
        font-size: .12rem;
      }

      img {
        width: .6rem;
      }

      > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-left: .15rem;
        }


      }

    }
  }

  .btn_sty {
    margin-left: auto;
    width: 1.5rem;
    font-size: 16px;
    line-height: 1.2;

  }
}

.cur_sha {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .cur_mask {
    background-color: rgba(5, 27, 55, 0.2);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;

  }

  .cur_con {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: .14rem;
    overflow: hidden;
    padding: .3rem;
    min-width: 6rem;

    .cup_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      padding-bottom: .2rem;

      .cur_tit {
        font-size: .18rem;
        line-height: 1;

      }

      .image_3a {
        width: 0.21rem;
        height: 0.2rem;
        cursor: pointer;
      }
    }

    .cur_sel_case {
      margin-top: .4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cur_sel_box {
        width: 48%;

        .cur_n {
          line-height: 1;
          font-size: .18rem;
          margin-bottom: .16rem;
        }

        .tea_sel_box {
          .el-select {
            width: 100%;
          }
        }

      }


    }

    .vie_sel_case {
      margin-top: .5rem;

      .vid_list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vid_n {
          font-size: .14rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 6rem;
        }

        .btn_sty {
          width: 1rem;
          margin: .15rem 0 .15rem .4rem;
        }

        &:first-child {
          .btn_sty {
            margin-top: 0;
          }
        }
      }
    }

    .btn_sty {
      margin: .3rem auto .2rem;
      width: 1.8rem;
      font-size: 14px;
      line-height: 1.5;

    }

  }

}


.update_case {
  padding: .41rem .38rem .2rem;
  margin-top: 0 !important;

  .updata_t {
    display: flex;
    align-items: center;

    h3 {
      font-size: .2rem;
      font-weight: 700;
    }

    .el-upload__files {
      width: 1rem;
      margin-left: .2rem;
    }

  }

  .update_con {
    margin-top: .3rem;

    .updata_th, .updata_tr {
      display: flex;

      .updata_td {
        padding: .1rem .2rem .1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .16rem;
        line-height: 1.5;

        &:nth-child(1) {
          width: 50%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 20%;

          .btn_sty {
            width: 1rem;
            max-width: 100%;
          }
        }
      }

      border-bottom: 1px solid #e5e5e5;
    }

  }


}

.up_btn {
  width: .8rem;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    left: 0;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
}


.m_sty{
  display: none;
}
@media screen and (orientation: portrait){
  .m_sty{
    display: block;
  }
  .group_2 .pub_case > div{
    display: flex;
  }
.space-x-40{
  flex-wrap: wrap;
  padding-right: 0;
  padding-left: 0;
  > *:not(:first-child){
    margin-left: 0;
  }
}

  .section_9{
  width: 100%;
    margin-top: .4rem;
}
  .font_3{
    font-size: .22rem;
    line-height: 1;
  }
  .section_3{
    padding: .3rem .3rem 0;
    height: auto;
    width: 100%;

  }
  .purchased_box {
    .text_18z {
      line-height: 1;
      font-size: .24rem;
    }

    .image_3z,.image_3 {
      width: .5rem;
      height: .5rem;
    }


    .font_2z {
      font-size: .22rem;
      width: calc(100% - .5rem);
      line-height: 1.3;
      margin-left: 0;
      padding-left: .1rem;
    }

    .space-x-14z {
      width: calc(100% - 1.2rem);
    }

    .space-x-9z {
      width: auto;

      .font_2z {
        width: auto;
      }
    }

    .font_6z {
      font-size: .2rem;
      line-height: 1.3;
    }

    .space-y-7z {
      width: calc(100% - 1rem);
    }

    .text-wrapper_5z {
      padding: .1rem .2rem;
      width: auto;
      height: auto;
      font-size: .24rem;
      line-height: 1;
    }

    .font_5 {
      font-size: .2rem;
    }

    .image_5z {
      width: .2rem;
      height: .2rem;
    }

    .text_30z {
      font-size: .2rem;
    }
    .cursor_s{
      display: flex;
      align-items: center;

    }
    .text-wrapper_5z{
      margin-left: .3rem;
    }
.group_9z{
  flex-wrap: wrap;


  .space-x-14z{
    width: 100%;
    + div{
      width: 100%;
      justify-content: center;
      margin-top: .2rem;
    }
  }
}
  }


  .table_box {
    .table_top {


      .table_block {
        font-size: .28rem;
        margin-right: .5rem;

        &:last-child {


        }
      }

    }

    .table_cen {
      margin-top: .3rem;

      .table_tit {
        display: none;

      }

      .table_con, .table_tit {

        flex-wrap: wrap;
        > div {
          justify-content: flex-start;
       margin-bottom: .2rem;


          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%
          }

          &:nth-child(3) {
            width: 50%;
          }

          &:nth-child(4) {
            width: 50%;
          }

          &:nth-child(5) {
            width: 100%;

          }
        }
      }

      .table_con {
        font-size: .22rem;



        .btn_sty {

          width: 1.5rem;
          margin: 0;
          font-size: .24rem;
          height: auto;
          padding: .2rem;
        }

        img {
          width: 1.6rem;
          + span{
            font-size: .36rem;
          }
        }

        > div {



          span {
            margin-left: .15rem;
          }


        }

      }
    }

    .btn_sty {
      margin-left: auto;
      width: 1.5rem;
      font-size: .24rem;
      line-height: 1;
      padding: .2rem;

    }
  }




  .cur_sha {




    .cur_mask {



    }

    .cur_con {
   width: 90%;
      padding: .3rem;
      min-width: 6rem;

      .cup_top {


        padding-bottom: .2rem;

        .cur_tit {
          font-size: .28rem;


        }

        .image_3a {
          width: 0.4rem;
          height: auto;


        }
      }

      .cur_sel_case {
        margin-top: .4rem;



        .cur_sel_box {


          .cur_n {

            font-size: .24rem;
            margin-bottom: .3rem;
          }

          .tea_sel_box {
            .el-select {


            }
          }

        }


      }

      .vie_sel_case {
        margin-top: .3rem;

        .vid_list {

margin-bottom: .3rem;

          .vid_n {
            font-size: .24rem;
            line-height: 1.2;
            width: calc(100% - 1.6rem);
          }

          .btn_sty {
            width: 1.4rem;
            margin: 0 0 0 .2rem;
            padding:  .2rem;
          }

          &:first-child {
            .btn_sty {


            }
          }
        }
      }

      .btn_sty {
        padding: .2rem;
        font-size: .24rem;
        line-height: 1;

      }

    }

   ::v-deep .el-input__inner{
     font-size: .24rem;
     height: .7rem;
     line-height: .68rem;
     padding-left: .14rem;
    }
    ::v-deep .el-select-dropdown__item{
      padding-left: .3rem;
      padding-right: .3rem;
      font-size: .24rem;
    }
  }



}
</style>
