<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col">
      <div class="pub_-flex-col pub_-self-center group_2">
        <div
          class="pub_-flex-row pub_-justify-between pub_-items-center section_2"
        >
          <div class="pub_-flex-row pub_-items-center space-x-11  sha_box">
            <img
              class="pub_-shrink-0 image"
              src="../../assets/8e844837e61b01c621820878e260fae4.png"
            />
            <input class="font_3 text_11" v-model="params.title" name="keywords" type="text" placeholder="托福英语"/>
          </div>
          <div
            class="
              pub_-flex-col pub_-justify-start pub_-items-center
              text-wrapper_2
              btn_sty
            "
            @click="seaBtn()"
          >
            <span>搜索</span>
          </div>
        </div>
        <div class="pub_-flex-col group_1">
          <div
            class="pub_-flex-row pub_-justify-between pub_-items-center group_5"
            v-for="(item,i) in searchList" :key="i"
          >
            <div class="pub_-flex-row pub_-items-center space-x-11 sel_box">
              <div
                class="
                  pub_-flex-col
                  pub_-justify-start
                  pub_-items-center
                  pub_-shrink-0
                  text-wrapper_3
                "
              >
                <span class="font_1">{{ item.name }}:</span>
              </div>
              <div class="search_con">
                <span v-for="(items,j) in item.enumerationList" :key="j"
                      :class="{ checked:  items.sel === true  }" @click="selectBtn(i,item.key,items.value,items.name,j)"
                      class="font_4">{{ items.name }}</span>

              </div>
            </div>


          </div>


        </div>
        <div class="pub_-flex-row pub_-self-start group_7">

          <div v-for="(item,i) in sortList" :key="i" class="sort_sty"
               :class="[sortC === i ? 'section_3' : 'section_4', item.type === 'ASC' ? 'img_deg':'']"
               @click="sortS(item.value,item.type,i)">
            <span class="font_5">{{ item.name }}</span>
            <div class="img_box">
              <img
                class="image_2"
                src="../../assets/b2d8d2d69f2af6a5215edab80256b5df.png"
              />
              <img
                class="image_2"
                src="../../assets/1fe67088e286a8730b80a8a8e3dc6b13.png"
              />
            </div>
          </div>


        </div>
        <div class="pub_-flex-col group_8 course_box" id="target">
          <div class="pub_-flex-row space-x-24" v-if="courseList.length !== 0">

            <div
              class="pub_-flex-col section_cou"
              :key="i"
              v-for="(item, i) in list_tzm43Yq0"
            >
              <img
                class="pub_-self-center image_03"
                :src="item.firstPicturePath"
              />
              <span class="pub_-self-center font_06 text_020">{{
                  item.title
                }}</span>
              <div class="pub_-flex-row group_09 view_02" v-if="item.rankSort !== 0 ">
                <div
                  class="
                            pub_-flex-col
                            pub_-justify-start
                            pub_-items-center
                            pub_-shrink-0
                            text-wrapper_05
                          "
                >
                  <span class="font_07">热门榜</span>
                </div>
                <div
                  class="
                            pub_-flex-col
                            pub_-justify-start
                            pub_-items-start
                            pub_-flex-auto
                            text-wrapper_06
                          "
                >
                          <span class="font_08 text_021">
                            {{
                              "热销课程第" + item.rankSort + "名"
                            }}
                          </span>
                </div>
              </div>

              <div class="bd_z" v-else></div>
              <div class="pub_-flex-row group_09 view_03 space-x-08">
                <div
                  class="
                            pub_-flex-col pub_-justify-start pub_-items-center
                            text-wrapper_07
                          "
                  v-if="item.isSecurity === 1"
                >
                  <span class="font_09">放心选</span>
                </div>

                <div class="pub_-flex-col pub_-justify-start text-wrapper_08">
                  <span class="font_010 text_022">好评{{ item.evaluatePercentage }}%</span>
                </div>
              </div>
              <div class="pub_-flex-row pub_-items-baseline group_010">
                <span class="font_012 text_024">￥</span>
                <span class="font_011 text_023">{{ item.price }}</span>
                <div class="group_011">
                  <span class="font_013">{{ item.saleNumber }}</span>
                  <span class="font_03">人报名</span>
                </div>
              </div>
              <span class="pub_-self-center font_014 text_025">
                    <template v-if="item.startTime != '' && item.startTime != undefined && item.startTime != null">
                          开播时间：{{ item.startTime }}
                        </template>
              </span>
              <div
                class="
                          pub_-flex-row pub_-justify-between pub_-items-center
                          group_012
                        "
              >
                <div class="pub_-flex-row pub_-items-center space-x-09">
                  <img
                    class="pub_-shrink-0 image_04"
                    :src="item.teacherData.avatarPath"
                  />
                  <span class="font_016">{{
                      item.teacherData.nickname
                    }}</span>
                </div>
                <div
                  class="
                            pub_-flex-col pub_-justify-start pub_-items-center
                            text-wrapper_09
                            btn_sty btn_sty_line_r
                          "
                  @click="goHref($event)"
                  :data-url="
                            '/courseDetails?id=' +
                            item.id +
                            '&&type=' +
                            item.type
                          "
                >
                  <span class="font_015 text_026">点击购买</span>
                </div>
              </div>


              <div
                class="m_sty btn_link"
                @click="goHref($event)"
                :data-url="
                            '/courseDetails?id=' +
                            item.id +
                            '&&type=' +
                            item.type
                          "
              >
              </div>
            </div>


          </div>
          <div class="notData" v-else>
            暂无内容
          </div>
          <Pagination
            @change="changePage"
            :current="pagination.p"
            :pageSize="pagination.pageSize"
            :total="total"
            :hideOnSinglePage="hideOnSinglePage"
            :showQuickJumper="true"
            :showTotal="true"
            placement="right"
            v-if="pagination && total"
          />
        </div>


      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import Pagination from "@/components/Pagination/Pagination";

export default {
  components: {NavBox, FootBox, Pagination},
  data() {
    return {

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      list_tzm43Yq0: [],
      courseList: [],
      searchList: [],
      params: {
        title: '',//关键词
        languageId: '',//语种
        type: '',//类型  1直播，2录播，3系统课
        attributeLabelId: '',//课程属性标签id
        orderField: 'updateTime',//排序字段：updateTime（综合），saleNumber（销量），minPrice（价格），id（新品）
        orderType: 'DESC',//DESC（降序），ASC（升序）
      },
      checkedIndex: 0,
      sortList: [
        {
          name: '综合',
          value: 'updateTime',
          type: 'DESC'

        },
        {
          name: '销量',
          value: 'saleNumber',
          type: 'DESC'

        },
        {
          name: '价格',
          value: 'minPrice',
          type: 'DESC'

        },
        {
          name: '新品',
          value: 'id',
          type: 'DESC'

        }
      ],
      sortC: 0,


    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.active = i;

      this.getHotSaleList({}); //获取热销课程列表接口
      this.getSearchData(); //获取搜索类目数据接口

    },
    async getHotSaleList(dataS) {
      // this.active = i;

      let param = dataS;
      console.log(param)
      // "/api.course/getHotSaleList",
      const {data: res} = await this.$http.post(
        "/api.course/getList",
        param
      );

      this.list_tzm43Yq0 = res.data.data.slice(0, res.data.per_page);
      // console.log(this.list_tzm43Yq0)


      this.courseList = res.data.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;
      console.log(res.data)
    },
    async getSearchData(id, i) {

      let param = {};
      const {data: res} = await this.$http.post(
        "/api.course/getSearchData",
        param
      );
      this.searchList = res.data;
      // console.log(this.searchList)
      this.searchList.map(item => {

        item.enumerationList.map((items, k) => {
          if (k === 0) {
            items.sel = true

          } else {
            items.sel = false

          }
        })

        if (item.key === 'type') {
          this.params.type = item.enumerationList[0].value

        }
        if (item.key === 'attributeLabelId') {
          this.params.attributeLabelId = item.enumerationList[0].value
        }
        if (item.key === 'languageId') {
          this.params.languageId = item.enumerationList[0].value
        }

      })
      // console.log(this.params)
    },
    selectBtn(i, k, v, j) {//选择属性
      this.searchList[i].enumerationList.map((items, k) => {
        if (items.name === j) {
          items.sel = true

        } else {
          items.sel = false

        }
      })
      this.searchList = JSON.parse(JSON.stringify(this.searchList));


      if (k === 'type') {
        this.params.type = v
      }
      if (k === 'attributeLabelId') {
        this.params.attributeLabelId = v
      }
      if (k === 'languageId') {
        this.params.languageId = v
      }
      this.getHotSaleList(this.params)

      // console.log(this.params)
    },
    sortS(val, type, on) { //排序
      if (this.sortC === on) {

        if (type === 'DESC') {
          this.sortList[on].type = 'ASC';
          type = 'ASC'
        } else {
          this.sortList[on].type = 'DESC';
          type = 'DESC'
        }

      } else {
        this.sortC = on;
      }
      this.params.orderField = val;
      this.params.orderType = type;

      this.getHotSaleList(this.params)

    },
    seaBtn() {//输入框
      this.getHotSaleList(this.params)
    },
    goHref(e, id) {
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.list_tzm43Yq0 = this.courseList.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      )

      console.log(this.list_tzm43Yq0)
      document.getElementById("target").scrollIntoView();
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group {
  padding: 0.17rem 3.7rem;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  color: #2b2c30;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.text_2 {
  line-height: 0.15rem;
}

.section {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group_2 {
  padding-top: 0.4rem;
  width: 11.82rem;
}

.section_2 {
  padding-left: 0.1rem;
  background-color: #fbfbfb;
  border: solid 0.01rem #2080f7;
}

.space-x-11 > *:not(:first-child) {
  margin-left: 0.11rem;
}

.sel_box {

  .search_con {
    display: flex;
    align-items: center;

    .font_4 {
      display: block;
      width: 1.33rem;
      padding-left: .11rem;
      margin-right: .04rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.checked {
        color: #2080f7;
      }

      transition: color .5s ease, background-color .5s ease;

      @media screen and (orientation: landscape) {


        &:hover {
          color: #2080f7;
        }
      }
    }

  }
}

.image {
  width: 0.22rem;
  height: 0.22rem;
}

.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 500;
  color: #a1a9b2;
  background-color: transparent;
}

.text_11 {
  color: #c5c5c5;
}

.sha_box {
  width: calc(100% - 1.24rem);

  input {
    width: calc(100% - .22rem);
    padding: 0 .11rem;
    margin: 0;
  }
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  width: 1.24rem;
  height: 0.48rem;
  margin: -0.01rem;

  &.btn_sty {
    border-radius: 0;

    span {
      font-size: 0.18rem;
    }
  }
}

.text_10 {
  color: #ffffff;
  font-weight: 500;
  line-height: 0.18rem;
}

.group_1 {
  margin-top: 0.4rem;
  border-top: solid 0.01rem #e0e0e0;

}

.group_3 {
  padding: 0 0.02rem;
  border-bottom: solid 0.01rem #e0e0e0;
}

.text-wrapper_3 {
  padding: 0.1rem 0;
  background-color: #f4f7fe;
  width: 1.1rem;
  height: 0.34rem;
}

.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #2b2c30;
}

.text-wrapper_4 {
  padding: 0.1rem 0;
  background-color: #f4f7fe8c;
  width: 1.3rem;
  height: 0.34rem;
}

.text_12 {
  margin-left: 0.11rem;
  line-height: 0.13rem;
}

.view {
  margin-left: 0.04rem;
}

.text_13 {
  margin-left: 0.15rem;
}

.group_4 {
  margin-right: 0.73rem;
}

.text_14 {
  line-height: 0.15rem;
}

.group_5 {
  padding: 0 0.02rem;
  border-bottom: solid 0.01rem #e0e0e0;
}

.text_15 {
  line-height: 0.13rem;
}

.text_16 {
  line-height: 0.13rem;
}

.group_6 {
  margin-right: 0.73rem;
}

.space-x-62 > *:not(:first-child) {
  margin-left: 0.62rem;
}

.text_17 {
  line-height: 0.15rem;
}

.group_7 {
  margin-top: 0.2rem;
  width: 2.27rem;
}


.text_18 {
  color: #ffffff;
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
}

.sort_sty {
  padding: 0.06rem .15rem;
  margin-right: .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.section_4 {
    color: #000000;
    background-color: #f9fbfe;

  }

  &.section_3 {
    background-color: #2080f7;
    color: #fff;

    .img_box {
      img {
        opacity: 1;

        &:nth-child(2) {
          opacity: 0;
        }
      }
    }

  }

  &.img_deg {
    .img_box {
      transform: rotate(180deg);
    }
  }

  .img_box {
    position: relative;
    margin-left: .05rem;
    width: 0.11rem;
    height: 0.13rem;
    transition: transform .3s ease;

    img {
      width: 100%;
      display: block;
      transition: opacity .3s ease;
      opacity: 0;

      &:nth-child(2) {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 1;

      }
    }
  }
}


.font_5 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  line-height: 0.12rem;
  font-weight: 300;
}

.text_19 {
  line-height: 0.13rem;
}

.group_8 {
  margin-top: 0.4rem;
}

.space-x-24 {
  //padding-bottom: .8rem;
}

.notData {
  font-size: .18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  text-align: center;
  color: #333;
  padding: .5rem 0;
}

.group_14 {
  margin-top: 0.25rem;
}

.image_5 {
  border-radius: 0.14rem 0.14rem 0 0;
  width: 2.77rem;
  height: 1.47rem;
}

@media screen and (orientation: portrait) {
  .group_2 {
    width: 100%;
    padding-left: .45rem;
    padding-right: .45rem;

    .space-x-24:last-child {
      padding-bottom: 0;
    }

    .section_2 {
      .text-wrapper_2 {
        width: 1.8rem;
        height: .7rem;

        &.btn_sty {
          span {
            font-size: .24rem;
          }
        }
      }

      .sha_box {
        width: calc(100% - 1.8rem);

        .image {
          width: .3rem;
          height: .3rem;
        }

        input {
          width: calc(100% - .3rem);
          margin-left: 0;
          padding-left: .2rem;
          font-size: .24rem;
        }
      }
    }

    .text-wrapper_3 {
      padding: .2rem 0;
      width: 6em;
      font-size: .2rem;
      line-height: 1;
      height: auto;

      .font_1 {
        font-size: inherit;
        line-height: 1;
      }
    }

    .sel_box {
      width: 100%;
      align-items: stretch;

      .search_con {
        font-size: .2rem;
        flex-wrap: wrap;
        width: calc(100% - 6em);
        margin-left: 0;

        .font_4 {
          font-size: .2rem;
          line-height: 1;
          width: 25%;
          margin-right: 0;
          padding-top: .2rem;
          padding-bottom: .2rem;

        }
      }
    }

    .sort_sty {
      padding: .1rem .2rem;

      .font_5 {
        font-size: .2rem;
        line-height: 1;

      }

      .img_box {
        width: .18rem;
        height: .22rem;
      }
    }

  }
  .notData{
    font-size: .2rem;
  }
}

</style>
