<template>
  <div class="pub_-flex-col pub_-relative page">
    <nav-box></nav-box>

    <div class="pub_-flex-col pub_-justify-start group_1">
      <div class="pub_-flex-col pub_-justify-start">
        <div class="pub_-flex-col">
          <div class="pub_-flex-col">
            <div class="pub_-flex-col pub_-justify-start pub_-self-center pub_case">
              <div class="pub_-flex-row pub_-items-center">
                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-flex-auto
                    image-wrapper
                  "
                >
                  <div class="lb_box">
                    <div class="swiper-container">
                      <div class="swiper-wrapper">
                        <div
                          class="swiper-slide"
                          v-for="(item, i) in config.homeCarouselData"
                          :key="i"
                        >
                          <img class="image" :src="item" />
                        </div>
                      </div>

                      <div class="swiper-pagination"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-shrink-0
                    section_2
                    hot_list
                  "
                >
                  <div class="pub_-flex-col section_3">
                    <div class="pub_-flex-row space-x-10">
                      <img
                        class="image"
                        src="../../assets/72e7e6a886c7e28089e78102d5b2eafe.png"
                      />
                      <span class="font_5 text_4">热销课程</span>
                    </div>
                    <div class="pub_-flex-col group_56 space-y-18">
                      <div
                        class="
                          pub_-flex-row pub_-items-center
                          group_4
                          space-x-14
                          cur_btn
                        "
                        :key="i"
                        v-for="(item, i) in noticeList"
                        @click="goHref($event)" :data-url="'/courseDetails?type=' + item.type +'&&id='+ item.id"
                      >
                        <span class="pub_-shrink-0 font_7 text_6"
                          >0{{ i + 1 }}</span
                        >
                        <span class="font_6 text_5">{{ item.title }}</span>
                      </div>
                    </div>
                    <div class="btn_sty"  @click="goHref($event)"  data-url="/courseList">
                      <span>查看更多</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pub_-flex-col section_5">
              <div class="pub_case">
                <div class="pub_title_box">
                  <img
                    src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"
                  />
                  <span>{{ aloneConfig.singlePlateAHeadLine }}</span>
                  <img
                    src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"
                  />
                </div>
                <span class="pub_info" >{{ aloneConfig.singlePlateABrief }}</span>

                <div class="pub_-flex-row list space-x-024 sys_con">
                  <a :href="item.url" class="pub_-flex-col section_03"
                    :key="i"
                    v-for="(item, i) in CourseList">
                    <img class="image_02" :src="item.picture" />
                    <span class="font_03 text_012">{{ item.title }}</span>
                    <div class="group_04">
                      <span class="font_04">{{ item.profile }}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col pub_-self-center group_13">
            <div class="pub_-flex-col group_14 space-y-19">
              <div class="pub_title_box">
                <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
                <span>{{ aloneConfig.singlePlateBHeadLine }}</span>
                <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
              </div>
              <span class="pub_info">{{ aloneConfig.singlePlateBBrief }}
              </span>
            </div>
            <a :href="sinImg1.url" class="pub_-flex-col pub_-justify-start section_7"
              :style="'background-image:url(' + sinImg1.picture + ')'" >
              <div class="
                  pub_-flex-col pub_-justify-start pub_-items-center
                  image-wrapper_2 ">
                <img class="image_6"
                  src="../../assets/ab8c94589b3eb646b53535c7e7797f69.png" />
              </div>
            </a>
            <a :href="sinImg2.url" class="space-y-8">
              <img :src="sinImg2.picture" alt="" />
            </a>
          </div>
          <div class="pub_-flex-col section_26 space-y-37">
            <div class="pub_case">
              <div class="pub_-flex-col group_20 space-y-19">
                <div class="pub_title_box">
                  <img
                    src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"
                  />
                  <span>系列课专区</span>
                  <img
                    src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"
                  />
                </div>
                <span class="pub_info">
                  为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
                </span>
              </div>
              <div class="pub_-flex-col">
                <div class="pub_-flex-col sys_box">
                  <div
                    class="
                      pub_-flex-row pub_-justify-between
                      list_011
                      group_014
                    "
                  >
                    <div
                      class="
                        pub_-flex-col pub_-justify-start pub_-items-center
                        text-wrapper_013
                      "
                      :key="i"
                      v-for="(item, i) in tabList"
                      :class="{ on: tabOn == i }"
                      @click="tabC(item.value, i)"
                    >
                      <span class="font_011">{{ item.name }}</span>
                    </div>
                  </div>
                  <div
                    class="
                      pub_-flex-col pub_-justify-start pub_-relative
                      group_0111
                    "
                  >
                    <div class="pub_-flex-row list group_011 pub_case">
                      <div class="pub_-flex-col section_011" :key="i"  v-for="(item, i) in DnfList" >
                        <span class="pub_-self-center font_014 text_0117">{{
                          item.title
                        }}</span>
                        <div class="pub_-flex-row group_015 view_011">
                          <div
                            class="
                              pub_-flex-col
                              pub_-justify-start
                              pub_-items-center
                              pub_-shrink-0
                              text-wrapper_015
                            "
                          >
                            <span class="font_015">热门榜</span>
                          </div>
                          <div
                            class="
                              pub_-flex-col
                              pub_-justify-start
                              pub_-items-start
                              pub_-flex-auto
                              text-wrapper_016
                            "
                          >
                            <span class="font_016 text_0131">{{
                          item.rankSort !== 0
                            ? "英语热销课程第" + item.rankSort + "名"
                            : "未上榜"
                        }}</span>
                          </div>
                        </div>
                        <div
                          class="pub_-flex-row group_015 view_015 space-x-018"
                        >
                          <div
                          v-if="item.isSecurity == 1"
                            class="pub_-flex-col pub_-justify-start pub_-items-center
                              text-wrapper_017">
                            <span class="font_017 text_0132">放心选</span>
                          </div>
                          <div
                            class="
                              pub_-flex-col pub_-justify-start
                              text-wrapper_018
                            "
                          >
                            <span class="font_018 text_0133">好评{{ item.evaluatePercentage }}%</span>
                          </div>
                        </div>
                        <div class="pub_-flex-row pub_-items-baseline group_06">
                          <span class="font_0110">￥</span>
                          <span class="font_019 text_0130">{{
                            item.price
                          }}</span>
                          <div class="group_017">
                            <span class="font_0111 text_0134">{{
                        item.saleNumber
                      }}</span>
                            <span class="font_0112">人报名</span>
                          </div>
                        </div>
                        <span class="pub_-self-center font_0113 text_011">开播时间：{{ item.startTime }}</span>
                        <div class="pub_-flex-row group_018 space-x-017">
                          <div class="text-wrapper_019 btn_sty btn_sty_line_r"
                          @click="goHref($event)"
                          :data-url="'/courseDetails?type=' + item.type +'&&id='+ item.id"
                          >
                            <span>点击购买</span>
                          </div>
                          <!-- <div class="text-wrapper_0110 btn_sty btn_sty_r">
                            <span>顾问咨询解答</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-self-center
                    text-wrapper_19
                    btn_sty
                  "
                  @click="goHref($event, null)"
                  :data-url="'/systemCourse?type='"
                >
                  <span class="font_30">点击查看全部</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col group_27 space-y-37">
            <div class="pub_case">
              <div class="pub_-flex-col group_20 space-y-19">
                <div class="pub_title_box">
                  <img
                    src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"
                  />
                  <span>真人精讲课专区</span>
                  <img
                    src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"
                  />
                </div>
                <span class="pub_info">
                  为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
                </span>
              </div>
              <div class="pub_-flex-col">
                <div class="pub_-flex-col sys_box">
                  <div
                    class="
                      pub_-flex-row pub_-justify-between
                      list_011
                      group_014
                    "
                  >
                    <div
                      class="
                        pub_-flex-col pub_-justify-start pub_-items-center
                        text-wrapper_013
                      "
                      :key="i"
                      v-for="(item, i) in tabList"
                      :class="{ on: tabOn == i }"
                      @click="tabC(item.value, i)"
                    >
                      <span class="font_011">{{ item.name }}</span>
                    </div>
                  </div>
                  <div
                    class="
                      pub_-flex-col pub_-justify-start pub_-relative
                      group_0111
                    "
                  >
                    <div class="pub_-flex-row list group_011 pub_case">
                      <div
                        class="pub_-flex-col section_011"
                        :key="i"
                        v-for="(item, i) in DnfList2"
                      >
                        <span class="pub_-self-center font_014 text_0117">{{
                          item.title
                        }}</span>
                        <div class="pub_-flex-row group_015 view_011">
                          <div
                            class="
                              pub_-flex-col
                              pub_-justify-start
                              pub_-items-center
                              pub_-shrink-0
                              text-wrapper_015
                            "
                          >
                            <span class="font_015">热门榜</span>
                          </div>
                          <div
                            class="
                              pub_-flex-col
                              pub_-justify-start
                              pub_-items-start
                              pub_-flex-auto
                              text-wrapper_016
                            "
                          >
                            <span class="font_016 text_0131">{{
                          item.rankSort !== 0
                            ? "英语热销课程第" + item.rankSort + "名"
                            : "未上榜"
                        }}</span>
                          </div>
                        </div>
                        <div
                          class="pub_-flex-row group_015 view_015 space-x-018"
                        >
                          <div
                          v-if="item.isSecurity == 1"
                            class=" pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_017 ">
                            <span class="font_017 text_0132">放心选</span>
                          </div>
                          <div class="pub_-flex-col pub_-justify-start text-wrapper_018
                            "
                          >
                            <span class="font_018 text_0133">好评{{ item.evaluatePercentage }}%</span>
                          </div>
                        </div>
                        <div class="pub_-flex-row pub_-items-baseline group_06">
                          <span class="font_0110">￥</span>
                          <span class="font_019 text_0130">{{
                            item.price
                          }}</span>
                          <div class="group_017">
                            <span class="font_0111 text_0134">{{
                        item.saleNumber
                      }}</span>
                            <span class="font_0112">人报名</span>
                          </div>
                        </div>
                        <span class="pub_-self-center font_0113 text_011">开播时间：{{ item.startTime }}</span>
                        <div class="pub_-flex-row group_018 space-x-017">
                          <div class="text-wrapper_019 btn_sty btn_sty_line_r"
                          @click="goHref($event)" :data-url="
                        '/courseDetails?type=' + item.type + '&&id=' + item.id
                      ">
                            <span>点击购买</span>
                          </div>
                          <!-- <div class="text-wrapper_0110 btn_sty btn_sty_r">
                            <span>顾问咨询解答</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-self-center
                    text-wrapper_19
                    btn_sty
                  "
                  @click="goHref($event, null)"
                  :data-url="'/systemCourse?type='"
                >
                  <span class="font_30">点击查看全部</span>
                </div>
              </div>
            </div>
          </div>

          <div class="pub_-flex-col section_28">
            <div class="pub_-flex-col group_20 space-y-19">
              <div class="pub_title_box">
                <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
                <span>训练营专区</span>
                <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
              </div>
              <span class="pub_info">
                为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
              </span>
            </div>
            <div class="pub_-flex-col list space-y-18a sys_cou_box">
          <div
            class="pub_-flex-col list-itema"
            :key="i"
            v-for="(item, i) in drillList"
            @click="goHref($event)" :data-url="
                        '/courseDetails?type=' + item.type + '&&id=' + item.id
                      ">
            <div
              class="
                pub_-flex-row pub_-justify-between pub_-items-start
                group_3a
              "
            >
              <div class="pub_-flex-row group_4a space-x-13a">
                <img class="pub_-shrink-0 image_3a" :src="item.firstPicturePath" />
                <div class="pub_-flex-col pub_-shrink-0 group_5a">
                  <span class="font_3a">{{ item.title }}</span>
                  <span class="pub_-self-start font_4a text_14a">开营时间{{
                    item.startTime
                  }}</span>
                  <span class="pub_-self-start font_4a text_15a">学习周期：{{
                    item.duration
                  }}天</span>
                </div>
              </div>
              <div v-if="item.levelName !== ''" class="pub_-flex-col pub_-justify-start text-wrapper_3a">
                <span class="font_1a text_13a">{{ item.levelName }}</span>
              </div>
            </div>
            <div class="pub_-flex-row pub_-justify-evenly group_6a">
              <span class="font_5a text_16a text_17a">课程内容：</span>
              <span class="font_6a text_16a">{{ item.profile }}</span>
              <div class="group_7a">
                <span class="font_7a text_16a text_19a">更多&gt;</span>
              </div>
            </div>
          </div>
        </div>

            <div
              class="
                pub_-flex-col
                pub_-justify-start
                pub_-items-center
                pub_-self-center
                text-wrapper_19
                view_31
                btn_sty
              "
              @click="goHref"
              data-url="/trainingCampCourse"
            >
              <span class="font_30">点击查看全部</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

export default {
  components: { NavBox, FootBox },
  data() {
    return {
      drillList: [],
      tabOn: 0,
      tabList: [],
      valur: 0,
      composingList: [
        {
          title: "微软 MSMarco 机器阅读理解排行榜荣获全球第一",
        },
        {
          title:
            "斯坦福问答数据集水平测试，荣获全球第一。成为 AI 实力获“互联网",
        },
        {
          title: "斯坦福问答数据集水平测试，荣获全球第一。成为 AI 实力获“....",
        },
        {
          title: "AI 研究院，向业界开源共享超过五项自主研发技术。",
        },
        {
          title: "AI 研究院，向业界开源共享超过五项自主研发技术。",
        },
        {
          title: "AI 研究院，向业界开源共享超过五项自主研发技术。",
        },
      ],
      prefectureList: [
        {
          title: "雅思口语流利说—哑巴英语逆袭native speaker",
          hotName: "英语热销课程第4名",
          markA: "放心选",
          markB: "好评100%",
          sum: "2849",
          num: "327",
          time: "开播时间：2022年12月22日 20:30",
          buttonB: "点击购买",
          buttonA: "顾问咨询解答",
        },
        {
          title: "雅思口语流利说—哑巴英语逆袭native speaker",
          hotName: "英语热销课程第4名",
          markA: "放心选",
          markB: "好评100%",
          sum: "2849",
          num: "327",
          time: "开播时间：2022年12月22日 20:30",
          buttonB: "点击购买",
          buttonA: "顾问咨询解答",
        },
        {
          title: "雅思口语流利说—哑巴英语逆袭native speaker",
          hotName: "英语热销课程第4名",
          markA: "放心选",
          markB: "好评100%",
          sum: "2849",
          num: "327",
          time: "开播时间：2022年12月22日 20:30",
          buttonB: "点击购买",
          buttonA: "顾问咨询解答",
        },
        {
          title: "雅思口语流利说—哑巴英语逆袭native speaker",
          hotName: "英语热销课程第4名",
          markA: "放心选",
          markB: "好评100%",
          sum: "2849",
          num: "327",
          time: "开播时间：2022年12月22日 20:30",
          buttonB: "点击购买",
          buttonA: "顾问咨询解答",
        },
      ],

      config: {},
      CourseList: [],
      hdc:{},
      sinImg1: [],
      sinImg2: [],
      noticeList: [],
      aloneConfig: {},

      DnfList: [],
      DnfList2: [],
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  mounted() {
    this.onLb();
  },
  methods: {
    async ajaxH(id, i) {
      this.getSingleConfig(); //获取独立页配置
      this.getHotSaleList(); //获取热销课程列表接口
      this.getConfig(); //获取设置接口

      this.getCourseAttributeLabelList(); //获取课程属性标签列表
      this.getList(); //获取列表
    },
    async getSingleConfig(id, i) {
      this.active = i;

      let param = {};
      const { data: res } = await this.$http.post(
        "/api.setUp/getSingleConfig",
        param
      );
      this.aloneConfig = res.data;
      // console.log(res);
      this.CourseList = res.data.singlePlateA;
      this.sinImg1 = res.data.singlePlateB[0];
      this.sinImg2 = res.data.singlePlateB[1];
    },

    async getHotSaleList(id, i) {
      this.active = i;

      let param = {
        number: 8,
      };
      const { data: res } = await this.$http.post(
        "/api.course/getHotSaleList",
        param
      );
      this.courseList = res.data;
      this.noticeList = this.courseList.slice(0, 7);
      // console.log( this.courseList )
    },
    async getConfig(id, i) {
      let param = {};
      const { data: res } = await this.$http.post("/api.setUp/getConfig", param);

      this.config = res.data;
    },
    async getCourseAttributeLabelList(id, i) {
      this.active = i;

      let param = {
        // number: 8,
      };
      const { data: res } = await this.$http.post(
        "/api.course/getCourseAttributeLabelList",
        param
      );
      this.tabList = res.data;
      // console.log( this.tabList )
    },
    async getList(id, i) {
      this.active = i;

      let param = {
        // limit:8,
        // type:3
      };

      const { data: res } = await this.$http.post("/api.course/getList", param);
      this.DnfList = res.data.data.slice(0, 8);
      // console.log( this.DnfList )
      this.drillList = res.data.data.slice(0, 4);
      console.log( this.drillList)
      let param2 = {
        // limit:8,
        // attributeLabelId:1,
        // isBoutique:1
      };

      const { data: res2 } = await this.$http.post("/api.course/getList", param2);
      this.DnfList2 = res2.data.data.slice(0, 8);
      // console.log( this.DnfList2 )


    },
    onLb() {
      new Swiper(".lb_box .swiper-container", {
        slideActiveClass: "active",
        autoplay: 5000,
        speed: 600,
        pagination: ".lb_box .swiper-pagination",
        paginationClickable: true,
        observer: true,
        observeParents: true,
      });
    },
    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
    tabC(v, i) {
      this.tabOn = i;
      this.valur = v;
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group {
  height: 0.8rem;
}
.section {
  padding: 0.3rem 3.66rem 0.02rem;
  background-color: #ffffff;
}
.space-x-6-reverse > *:not(:last-child) {
  margin-right: 0.06rem;
}
.image {
  border-radius: 0.18rem;
  width: 0.36rem;
  height: 0.36rem;
}
.font_2 {
  color: #2b2c30;
  font-size: 0.14rem;
  font-family: SourceHanSansSC;
  line-height: 0.13rem;
}
.text-wrapper {
  margin-left: 3.7rem;
  margin-top: -0.51rem;
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  color: #2b2c30;
}
.text {
  color: #ffffff;
  line-height: 0.15rem;
}
.section_2 {
  margin-top: 0.18rem;
}
.font_3 {
  font-size: 0.18rem;
  font-family: Source Han Sans CN;
  line-height: 0.16rem;
  color: #2b2c30;
}
.font_4 {
  font-size: 0.18rem;
  font-family: Source Han Sans CN;
  line-height: 0.18rem;
  color: #2b2c30;
}
.group_1 {
  overflow-y: auto;
}

.group_3 {
  padding-bottom: 0.29rem;
}
.image-wrapper {
  overflow: hidden;
  border-radius: 0.2rem;
  width: 8.7rem;
  height: 4.72rem;
  .lb_box {
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .swiper-pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0.16rem;
    }
  }
}
.image_3 {
  width: 0.52rem;
  height: 0.08rem;
}
.section_3 {
  padding: 0.31rem 0;
  border-radius: 0.2rem;
  background-image: url("../../assets/9b8c533779acb42cf462734a2573f50c.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 3.45rem;
  height: 5.34rem;
}
.section_4 {
  padding: 0.16rem;
  border-radius: 0.2rem;
  background-image: url("../../assets/b107136e5e4c55bbf6b85c43dd5d3273.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}
.image_2 {
  width: 0.16rem;
  height: 0.21rem;
}
.text_13 {
  color: #000000;
  font-weight: 700;
}
.group_42 {
  margin-top: 0.23rem;
}
.group_4 {
  margin-right: 0.11rem;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}
.font_6 {
  font-size: 0.24rem;
  font-family: OPPOSans;
  line-height: 0.2rem;
  color: #d9d9d9;
}
.text_15 {
  color: #f44a4a;
  line-height: 0.21rem;
  letter-spacing: 0.034rem;
}
.font_5 {
  font-size: 0.12rem;
  font-family: Adobe Heiti Std;
  line-height: 0.16rem;
  color: #323232;
}
.text_14 {
  width: 1.91rem;
}
.text_20 {
  width: 1.95rem;
}
.text_24 {
  margin-top: 0.18rem;
}
.text-wrapper_3 {
  margin: 0.29rem 0.08rem 0;
  padding: 0.1rem 0;
  background-color: #2080f7;
  border-radius: 0.08rem;
  width: 2.36rem;
}
.text_25 {
  color: #ffffff;
  font-size: 0.12rem;
  font-family: OPPOSans;
  line-height: 0.13rem;
}
.section_5 {
  padding: 0.6rem 0;
  background-image: url("../../assets/8bb94ffd19fd934a7f20f8b4b5eaf512.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-18 > *:not(:first-child) {
  margin-left: 0.18rem;
}
.image_4 {
  width: 0.53rem;
  height: 0.08rem;
}
.font_7 {
  font-size: 0.36rem;
  font-family: OPPOSans;
  line-height: 0.35rem;
  color: #2b2c30;
}
.font_8 {
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.2rem;
  color: #2b2c30;
}
.text_26 {
  margin-top: 0.19rem;
}

.space-y-18 > *:not(:first-child) {
  margin-top: 0.18rem;
}
.section_6 {
  flex: 1 1 2.77rem;
  padding-bottom: 0.2rem;
  background-color: #ffffff;
  border-radius: 0.14rem;
  height: 3.05rem;
  border: solid 0.01rem #eeeeee;
}
.image_5 {
  border-radius: 0.14rem 0.14rem 0px 0px;
  width: 2.77rem;
  height: 1.95rem;
}
.font_9 {
  font-size: 0.2rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.026rem;
  line-height: 0.2rem;
  font-weight: 700;
  color: #2b2c30;
}
.text_27 {
  margin-top: 0.2rem;
  line-height: 0.19rem;
}
.group_12 {
  line-height: 0.2rem;
  text-align: center;
}
.view {
  margin-top: 0.1rem;
}
.font_10 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.016rem;
  line-height: 0.2rem;
  font-weight: 300;
  color: #666666;
}
.group_13 {
  margin-top: 0.59rem;
  width: 11.8rem;
}
.group_14 {
  padding: 0 0 0.37rem 0;
}
.section_7 {
  border-radius: 0.14rem;
  background-image: url("../../assets/9e3082bceb6ebd4f1acc7533b36fdddc.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.image-wrapper_2 {
  padding: 2.08rem 0;
  background-color: #051b3766;
  border-radius: 0.14rem;
  width: 11.8rem;
}
.image_6 {
  width: 0.42rem;
  height: 0.55rem;
}
.group_15 {
  padding-top: 0.4rem;
}

.space-y-8 {
  > *:not(:first-child) {
    margin-top: 0.08rem;
  }
  > img {
    display: block;
    width: 100%;
  }
}
.group_16 {
  padding-left: 1.03rem;
  padding-right: 0.1rem;
}
.space-x-56 > *:not(:first-child) {
  margin-left: 0.56rem;
}
.group_19 {
  padding-left: 0.05rem;
  height: 0.16rem;
}
.font_12 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  line-height: 0.12rem;
  font-weight: 300;
  color: #2080f7;
}
.text_34 {
  margin-top: 0.05rem;
  line-height: 0.11rem;
}
.text_33 {
  margin-top: -0.16rem;
  line-height: 0.11rem;
}
.section_21 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  width: 0.5rem;
  height: 0.18rem;
}
.section_20 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  width: 0.5rem;
  height: 0.23rem;
}
.group_17 {
  padding: 0 0.05rem 1.33rem;
  width: 8.93rem;
}
.font_11 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  line-height: 0.09rem;
  font-weight: 300;
  color: #2080f7;
}
.text_31 {
  margin-right: -1.1rem;
}
.text_32 {
  margin-right: 0.76rem;
  margin-top: 0.1rem;
}
.space-x-28 > *:not(:first-child) {
  margin-left: 0.28rem;
}
.pos {
  position: absolute;
  left: 0;
  right: 0;
  top: 0.14rem;
}
.group_18 {
  flex: 1 1 0.49rem;
  width: 0.49rem;
}
.section_19 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.28rem;
}
.section_18 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.38rem;
}
.section_17 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.48rem;
}
.section_16 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.58rem;
}
.section_15 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.68rem;
}
.section_14 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.78rem;
}
.section_13 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.88rem;
}
.section_12 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 0.98rem;
}
.section_11 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 1.08rem;
}
.view_5 {
  margin-top: 0.06rem;
}
.space-y-5 > *:not(:first-child) {
  margin-top: 0.05rem;
}
.section_10 {
  background-color: #2080f7;
  border-radius: 0.06rem;
  height: 1.18rem;
}
.section_9 {
  flex: 1 1 0.49rem;
  background-color: #2080f7;
  border-radius: 0.06rem;
  width: 0.49rem;
  height: 1.28rem;
}
.section_8 {
  flex: 1 1 0.49rem;
  background-color: #2080f7;
  border-radius: 0.06rem;
  width: 0.49rem;
  height: 1.38rem;
}
.text-wrapper_4 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 0.58rem;
  height: 0.4rem;
}
.font_13 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  line-height: 0.12rem;
  font-weight: 300;
  color: #666666;
}
.text-wrapper_5 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 1.87rem;
  height: 0.4rem;
}
.text-wrapper_6 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 0.69rem;
  height: 0.4rem;
}
.text-wrapper_7 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 1.45rem;
  height: 0.4rem;
}
.text-wrapper_8 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 1.47rem;
  height: 0.4rem;
}
.text-wrapper_9 {
  padding: 0.14rem 0;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 2.21rem;
  height: 0.4rem;
}
.text-wrapper_10 {
  padding: 0.18rem 0 1.12rem;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 0.58rem;
  height: 1.4rem;
}
.section_22 {
  padding: 0.18rem 0.08rem 0.25rem 0.14rem;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 1.87rem;
  height: 1.4rem;
}
.font_14 {
  font-size: 0.16rem;
  font-family: Source Han Sans CN;
  line-height: 0.16rem;
  color: #666666;
}
.font_15 {
  font-size: 0.1rem;
  font-family: Source Han Sans CN;
  line-height: 0.16rem;
  font-weight: 300;
  color: #666666;
}
.section_23 {
  padding: 0.18rem 0.25rem 0.25rem 0.31rem;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 2.21rem;
  height: 1.4rem;
}
.section_24 {
  padding: 0.18rem 0.25rem 0.24rem 0.31rem;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 2.21rem;
  height: 1.4rem;
}
.section_25 {
  padding: 0.18rem 0.25rem 0.09rem 0.31rem;
  background-color: #edeff2;
  border-radius: 0.06rem;
  width: 2.21rem;
  height: 1.4rem;
}
.space-y-16 > *:not(:first-child) {
  margin-top: 0.16rem;
}
.section_26 {
  margin-top: 0.6rem;
  padding: 0.59rem 0;
  background-color: #f2f5f9;
}
.space-y-37 > *:not(:first-child) {
  margin-top: 0.37rem;
}

.text_35 {
  margin-left: 0.21rem;
}
.image_7 {
  margin-left: 0.16rem;
}
.group_21 {
  padding-bottom: 0.4rem;
}
.list_3 {
  align-self: flex-start;
}
.group_22 {
  width: 4.72rem;
}
.text-wrapper_11 {
  padding: 0.1rem 0;
  background-color: #ffffff;
  border-radius: 0.16rem;
  width: 0.88rem;
  height: 0.32rem;
}
.font_16 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  color: #2080f7;
}
.font_17 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.12rem;
  color: #666666;
}
.font_18 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  color: #666666;
}
.font_19 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.1rem;
  color: #666666;
}
.group_43 {
  margin-top: 0.2rem;
}
.section_27 {
  flex: 1 1 2.77rem;
  padding-top: 0.14rem;
  background-color: #ffffff;
  border-radius: 0.14rem;
  height: 2.68rem;
  border: solid 0.01rem #eeeeee;
}
.font_20 {
  font-size: 0.16rem;
  font-family: Source Han Sans CN;
  line-height: 0.24rem;
  font-weight: 300;
  color: #2b2c30;
}
.text_37 {
  width: 2.32rem;
}
.group_23 {
  padding: 0 0.12rem;
}
.view_6 {
  margin-top: 0.07rem;
}
.text-wrapper_13 {
  padding: 0.04rem 0;
  background-color: #f54a4a;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}
.font_21 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #ffffff;
}
.text-wrapper_14 {
  margin-right: 0.25rem;
  padding: 0.04rem 0;
  background-color: #f9eceb;
  border-radius: 0.04rem;
  height: 0.22rem;
}
.font_22 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #f54a4a;
}
.text_157 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_10 {
  margin-top: 0.08rem;
}
.text-wrapper_15 {
  padding: 0.04rem 0;
  background-color: #f4f7fe;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}
.font_23 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #2080f7;
}
.text_158 {
  line-height: 0.11rem;
}
.text-wrapper_16 {
  padding: 0.04rem 0;
  background-color: #edeff2;
  border-radius: 0.04rem;
  height: 0.22rem;
}
.font_24 {
  font-size: 0.12rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #666666;
}
.text_159 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.group_24 {
  margin-top: 0.2rem;
  padding: 0 0.08rem;
}
.font_26 {
  font-size: 0.16rem;
  font-family: Source Han Sans CN;
  line-height: 0.12rem;
  font-weight: 300;
  color: #f75746;
}
.font_25 {
  font-size: 0.2rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  font-weight: 500;
  color: #f75746;
}
.text_153 {
  line-height: 0.15rem;
}
.group_25 {
  margin-left: 0.05rem;
  line-height: 0.14rem;
  height: 0.14rem;
}
.font_27 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.12rem;
  color: #a1a9b2;
}
.text_160 {
  line-height: 0.11rem;
}
.font_28 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  font-weight: 500;
  color: #a1a9b2;
}
.font_29 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #a1a9b2;
}
.text_1 {
  margin-top: 0.12rem;
}
.group_26 {
  margin-top: 0.17rem;
  padding: 0.18rem 0.13rem;
  border-top: solid 0.01rem #eeeeee;
}
.space-x-7 > *:not(:first-child) {
  margin-left: 0.07rem;
}
.text-wrapper_17 {
  flex: 1 1 1.21rem;
  padding: 0.06rem 0 0.1rem;
  border-radius: 0.04rem;
  height: 0.29rem;
  border: solid 0.01rem #eeeeee;
}
.text-wrapper_18 {
  margin-right: 0.04rem;
  flex: 1 1 1.21rem;
  padding: 0.06rem 0 0.1rem;
  background-color: #f54a4a;
  border-radius: 0.04rem;
  height: 0.29rem;
}
.group_39 {
  margin-top: 0.24rem;
}
.view_14 {
  margin-top: 0.07rem;
}
.text_62 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_18 {
  margin-top: 0.08rem;
}
.text_66 {
  line-height: 0.11rem;
}
.text_67 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.text_74 {
  line-height: 0.15rem;
}
.text_78 {
  line-height: 0.11rem;
}
.text_82 {
  margin-top: 0.12rem;
}
.view_15 {
  margin-top: 0.07rem;
}
.text_63 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_19 {
  margin-top: 0.08rem;
}
.text_68 {
  line-height: 0.11rem;
}
.text_69 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.text_75 {
  line-height: 0.15rem;
}
.text_79 {
  line-height: 0.11rem;
}
.text_83 {
  margin-top: 0.12rem;
}
.view_16 {
  margin-top: 0.07rem;
}
.text_64 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_20 {
  margin-top: 0.08rem;
}
.text_70 {
  line-height: 0.11rem;
}
.text_71 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.text_76 {
  line-height: 0.15rem;
}
.text_80 {
  line-height: 0.11rem;
}
.text_84 {
  margin-top: 0.12rem;
}
.view_17 {
  margin-top: 0.07rem;
}
.text_65 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_21 {
  margin-top: 0.08rem;
}
.text_72 {
  line-height: 0.11rem;
}
.text_73 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.text_77 {
  line-height: 0.15rem;
}
.text_81 {
  line-height: 0.11rem;
}
.text_85 {
  margin-top: 0.12rem;
}
.text-wrapper_19 {
  border-radius: 0.04rem;
  width: 2.83rem;
  height: 0.47rem;
  margin-top: 0.2rem;
}
.font_30 {
  font-size: 0.18rem;
  font-family: Source Han Sans CN;
  letter-spacing: 0.025rem;
  line-height: 0.18rem;
  font-weight: 300;
}
.group_27 {
  padding: 0.59rem 0;
  .sys_box .text-wrapper_013 {
    border: 1px solid #eeeeee;
    &.on {
      border-color: #2080f7;
    }
  }
}
.space-y-36 > *:not(:first-child) {
  margin-top: 0.36rem;
}
.group_28 {
  padding-left: 1.02rem;
  padding-right: 0.92rem;
}
.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}
.group_29 {
  width: 4.74rem;
}
.text-wrapper_20 {
  padding: 0.08rem 0;
  background-color: #ffffff;
  border-radius: 0.16rem;
  width: 0.9rem;
  height: 0.34rem;
  border: solid 0.01rem #2080f7;
}
.text-wrapper_21 {
  padding: 0.08rem 0;
  background-color: #ffffffcc;
  border-radius: 0.16rem;
  width: 0.9rem;
  height: 0.34rem;
  border: solid 0.01rem #eeeeee;
}
.text_36 {
  line-height: 0.13rem;
}
.view_23 {
  margin-top: 0.07rem;
}
.text-wrapper_22 {
  padding: 0.04rem 0;
  background-color: #f9eceb;
  border-radius: 0.04rem;
  width: 1.73rem;
  height: 0.22rem;
}
.text_38 {
  margin-left: 0.07rem;
  line-height: 0.13rem;
}
.view_27 {
  margin-top: 0.08rem;
}
.text_42 {
  line-height: 0.11rem;
}
.text_43 {
  margin-left: 0.08rem;
  line-height: 0.13rem;
}
.text_50 {
  line-height: 0.15rem;
}
.text_54 {
  line-height: 0.11rem;
}
.text_58 {
  margin-top: 0.12rem;
}
.text-wrapper_23 {
  flex: 1 1 1.21rem;
  padding: 0.06rem 0 0.1rem;
  border-radius: 0.04rem;
  width: 1.21rem;
  height: 0.29rem;
  border: solid 0.01rem #eeeeee;
}
.text-wrapper_24 {
  margin-right: 0.04rem;
  flex: 1 1 1.21rem;
  padding: 0.06rem 0 0.1rem;
  background-color: #f54a4a;
  border-radius: 0.04rem;
  width: 1.21rem;
  height: 0.29rem;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}
.group_40 {
  margin-top: 0.19rem;
}
.space-x-24 > *:not(:first-child) {
  margin-left: 0.24rem;
}
.section_28 {
  padding: 0.6rem 3.7rem;
  background-color: #f2f5f9;
}
.section_29 {
  background-color: #ffffff;
  border-radius: 0.14rem;
  border: solid 0.01rem #eeeeee;
}
.group_30 {
  padding: 0 0.02rem 0.2rem 0.2rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.group_31 {
  margin-top: 0.19rem;
}
.space-x-13 > *:not(:first-child) {
  margin-left: 0.13rem;
}
.image_8 {
  border-radius: 0.08rem;
  width: 1.84rem;
  height: 1.02rem;
}
.group_32 {
  width: 2rem;
}
.font_32 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #2b2c30;
}
.text_87 {
  margin-top: 0.15rem;
}
.text_88 {
  margin-top: 0.1rem;
}
.text-wrapper_25 {
  padding: 0.14rem 0;
  background-color: #2080f7;
  border-radius: 0px 0.12rem 0px 0px;
  height: 0.42rem;
}
.font_31 {
  font-size: 0.14rem;
  font-family: Source Han Sans CN;
  line-height: 0.14rem;
  font-weight: 700;
  color: #ffffff;
}
.text_86 {
  margin-left: 0.14rem;
  margin-right: 0.08rem;
  line-height: 0.15rem;
}
.group_33 {
  padding: 0.2rem 0.07rem 0.2rem 0.17rem;
}
.font_33 {
  font-size: 0.16rem;
  font-family: Source Han Sans CN;
  line-height: 0.16rem;
  font-weight: 500;
  color: #2b2c30;
}
.font_34 {
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.16rem;
  color: #2b2c30;
}
.group_34 {
  line-height: 0.16rem;
  height: 0.16rem;
}
.font_35 {
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.16rem;
  color: #2080f7;
}
.text_89 {
  text-transform: uppercase;
}
.text_91 {
  line-height: 0.17rem;
}
.text_90 {
  line-height: 0.17rem;
}
.view_31 {
  margin-top: 0.4rem;
}
.footer {
  position: relative;
  margin-top: 54.52rem;
}
.group_35 {
  padding: 0 3.7rem 1.19rem;
  border-bottom: solid 0.02rem #e0e0e0;
}
.font_36 {
  font-size: 0.22rem;
  font-family: OPPOSans;
  line-height: 0.2rem;
  color: #2b2c30;
}
.text_94 {
  margin-top: 0.14rem;
  line-height: 0.19rem;
}
.text_99 {
  margin-top: 0.3rem;
}
.text_104 {
  margin-top: 0.14rem;
  line-height: 0.19rem;
}
.text_113 {
  margin-top: 0.3rem;
}
.group_37 {
  margin-top: 0.19rem;
}
.space-x-60 > *:not(:first-child) {
  margin-left: 0.6rem;
}
.section_31 {
  background-color: #1c3754;
  width: 1.08rem;
  height: 1.08rem;
}
.group_38 {
  margin-top: 0.14rem;
  padding: 0 0.32rem;
}
.space-x-123 > *:not(:first-child) {
  margin-left: 1.23rem;
}
.section_30 {
  background-color: #e0e0e0;
  width: 0.01rem;
  height: 3.22rem;
}
.group_41 {
  margin-right: 0.54rem;
}
.space-x-77 > *:not(:first-child) {
  margin-left: 0.77rem;
}
.text_95 {
  margin-top: 0.3rem;
}
.text_100 {
  margin-top: 0.19rem;
}
.text_105 {
  margin-top: 0.19rem;
}
.text_109 {
  margin-top: 0.19rem;
}
.text_114 {
  margin-top: 0.19rem;
}
.text_118 {
  margin-top: 0.19rem;
}
.text_122 {
  margin-top: 0.2rem;
  line-height: 0.13rem;
}
.space-x-53 > *:not(:first-child) {
  margin-left: 0.53rem;
}
.text_96 {
  margin-top: 0.3rem;
}
.text_101 {
  margin-top: 0.19rem;
}
.text_106 {
  margin-top: 0.19rem;
}
.text_110 {
  margin-top: 0.19rem;
}
.text_115 {
  margin-top: 0.19rem;
}
.text_119 {
  margin-top: 0.19rem;
}
.text_92 {
  line-height: 0.17rem;
}
.text_97 {
  margin-top: 0.3rem;
}
.text_102 {
  margin-top: 0.19rem;
}
.text_107 {
  margin-top: 0.19rem;
}
.text_111 {
  margin-top: 0.19rem;
}
.text_116 {
  margin-top: 0.19rem;
}
.text_120 {
  margin-top: 0.19rem;
}
.text_93 {
  line-height: 0.17rem;
}
.text_98 {
  margin-top: 0.3rem;
}
.text_103 {
  margin-top: 0.19rem;
}
.text_108 {
  margin-top: 0.19rem;
}
.text_112 {
  margin-top: 0.19rem;
}
.text_117 {
  margin-top: 0.19rem;
}
.text_121 {
  margin-top: 0.19rem;
}
.text_123 {
  margin-top: 0.19rem;
}
.text_124 {
  margin-top: 0.2rem;
}
.text_125 {
  margin-top: 0.2rem;
}
.lb_box {
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .swiper-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0.16rem;
  }
}

.hot_list {
  &.section_2 {
    padding: 0.31rem 0;
    border-radius: 0.2rem;
    background-image: url("../../assets/9b8c533779acb42cf462734a2573f50c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.45rem;
    height: 5.34rem;
    margin-top: 0;
    .section_3 {
      padding: 0.16rem;
      border-radius: 0.2rem;
      background-image: url("../../assets/b107136e5e4c55bbf6b85c43dd5d3273.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      min-height: 4.56rem;
      width: auto;
      height: auto;
    }
    .btn_sty {
      margin: 0.29rem 0.08rem 0;
    }
    .space-x-10 > *:not(:first-child) {
      margin-left: 0.1rem;
    }
    .font_5 {
      font-size: 0.18rem;
      font-family: Source Han Sans CN;
      line-height: 0.18rem;
      color: #666666;
    }
    .text_4 {
      color: #000000;
      font-weight: 700;
    }
    .image {
      width: auto;
      height: auto;
      border-radius: 0;
    }
    .group_56 {
      margin-top: 0.23rem;
    }
    .space-x-14 {
      justify-content: space-between;
    }

    .group_4 {
      margin-right: 0.11rem;
      &:nth-child(1) {
        .text_6 {
          color: rgba(244, 74, 74, 1);
        }
      }
      &:nth-child(2) {
        .text_6 {
          color: rgba(240, 108, 61, 1);
        }
      }
      &:nth-child(3) {
        .text_6 {
          color: rgba(243, 179, 54, 1);
        }
      }
    }
    .space-x-14 > *:not(:first-child) {
      margin-left: 0.14rem;
    }
    .font_6 {
      font-size: 0.12rem;
      font-family: Adobe Heiti Std;
      line-height: 0.16rem;
      color: #323232;
    }
    .text_5 {
      width: 1.91rem;
    }
    .font_7 {
      font-size: 0.24rem;
      font-family: OPPOSans;
      line-height: 1;
      color: #d9d9d9;
    }
    .text_6 {
      color: rgb(217, 217, 217);
      font-weight: 700;
      letter-spacing: 0;
    }
    .cur_btn {
      cursor: pointer;
      span {
        transition: color 0.5s ease;
      }
      &:hover {
        span {
          color: #2080f7;
        }
      }
    }
  }

}
.space-x-024{
    margin-top: .37rem;
  }
</style>
