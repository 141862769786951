<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col group space-y-80">

      <div class="pub_-flex-col pub_-self-center pub_case space-y-37">
        <div class="pub_-flex-col group_3 space-y-19">
          <div class="pub_title_box">
            <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
            <span >英语级别对照表</span>
            <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
          </div>
          <span class="pub_info">
            为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
          </span>
        </div>

        <div class="space-y-8">
          <img src="../../assets/d000.png" alt=""/>
        </div>
        <!-- <div class="pub_-flex-col space-y-8">
          <div class="pub_-flex-row pub_-items-end group_4 space-x-56">
            <div class="pub_-flex-col">
              <div class="pub_-flex-col group_7">
                <span class="pub_-self-start font_4 text_15">LV-入门</span>
                <span class="pub_-self-end font_4 text_14">LV-进阶</span>
              </div>
              <div class="pub_-flex-row pub_-items-center space-x-18">
                <div class="section_16"></div>
                <div class="section_15"></div>
              </div>
            </div>
            <div class="pub_-flex-row pub_-justify-end pub_-items-start pub_-flex-auto pub_-relative group_5">
              <span class="font_3 text_12">LV-12</span>
              <span class="font_3 text_13">LV-11</span>
              <div class="pub_-flex-row pub_-items-end space-x-28 pos">
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-01</span>
                  <div class="section_14"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-02</span>
                  <div class="section_13"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-03</span>
                  <div class="section_12"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-04</span>
                  <div class="section_11"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-05</span>
                  <div class="section_10"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-06</span>
                  <div class="section_9"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-07</span>
                  <div class="section_8"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-08</span>
                  <div class="section_7"></div>
                </div>
                <div class="pub_-flex-col group_6 space-y-5">
                  <span class="pub_-self-center font_3">LV-09</span>
                  <div class="section_6"></div>
                </div>
                <div class="pub_-flex-col group_6 view space-y-5">
                  <span class="pub_-self-center font_3">LV-10</span>
                  <div class="section_5"></div>
                </div>
                <div class="section_4"></div>
                <div class="section_3"></div>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col space-y-8">
            <div class="pub_-flex-row pub_-justify-evenly">
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3">
                <span class="font_5">考试</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
            </div>
            <div class="pub_-flex-row pub_-justify-evenly">
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3">
                <span class="font_5">考试</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col space-y-8">
            <div class="pub_-flex-row pub_-justify-evenly">
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3">
                <span class="font_5">考试</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_7">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8">
                <span class="font_5">pre-A1</span>
              </div>
            </div>
            <div class="pub_-flex-row pub_-justify-evenly">
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3">
                <span class="font_5">考试</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_7">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                <span class="font_5">pre-A1</span>
              </div>
              <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8">
                <span class="font_5">pre-A1</span>
              </div>
            </div>
          </div>
          <div class="pub_-flex-row pub_-justify-evenly">
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3">
              <span class="font_5">考试</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_7">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
              <span class="font_5">pre-A1</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8">
              <span class="font_5">pre-A1</span>
            </div>
          </div>
          <div class="pub_-flex-row pub_-justify-between">
            <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_9">
              <span class="font_5">考试</span>
            </div>
            <div class="pub_-flex-col section_17 space-y-16">
              <span class="pub_-self-center font_6">零基础</span>
              <span class="font_7">
                完成零基础入门、进阶全部课程的学习，可以顺利衔接过渡到L1级别的学习，为之后的级别提升奠定坚实的基础
                <br />
              </span>
            </div>
            <div class="pub_-flex-col section_18 space-y-16">
              <span class="pub_-self-center font_6">初级</span>
              <span class="font_7">
                能够交流生活和工作中的基本信息和观点:能够使用英语进行购物、讨论生活、活动建议等;能读懂并撰写简单的商务通知，邮件信函等
                <br />
              </span>
            </div>
            <div class="pub_-flex-col section_19 space-y-16">
              <span class="pub_-self-center font_6">中级</span>
              <span class="font_7">
                输入/练习/深入思考/讨论:通过对不同话题的深入探讨，不断加深语言的精准度，达到和外国人流利沟通的水平
                <br />
              </span>
            </div>
            <div class="pub_-flex-col section_20 space-y-16">
              <span class="pub_-self-center font_6">中高级</span>
              <span class="font_7">
                能够清西准确理解表达工作及生活中大部分话题:能够在适当帮助下在自己专长领域参加工作谈判:探讨商务活动中的各种常见问题及发表不同意见等
                <br />
              </span>
            </div>
            <div class="pub_-flex-col section_18 space-y-16">
              <span class="pub_-self-center font_6">高级</span>
              <span class="font_7">
                能够在社交、学术和工作中轻松交流大部分话题:可以对复杂事物用清晰流畅丰富的语直进行陈述:能够讨论社会工作生活等话题并深入交流等
                <br />
              </span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <foot-box></foot-box>

  </div>
</template>

<script>
import NavBox from '../../components/navbox/navbox.vue'
import FootBox from '../../components/footbox/footbox.vue'

  export default {
    components: {NavBox, FootBox},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #fbfbfb;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding: 0.17rem 3.7rem;
    background-color: #ffffff;
  }
  .text-wrapper {
    padding: 0.24rem 0;
    background-color: #000000;
    width: 1.34rem;
    height: 0.64rem;
  }
  .font_1 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.16rem;
  }
  .text {
    color: #ffffff;
    line-height: 0.15rem;
  }
  .text_2 {
    color: #2b2c30;
    line-height: 0.15rem;
  }

  .space-y-80  {
    margin-top: 0.8rem;
  }
  .section_2 {
    padding-left: 0.19rem;
    background-color: #f4f7fe;
  }
  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }
  .font_2 {
    font-size: 0.18rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    color: #2b2c30;
  }
  .text_4 {
    margin-left: 0.35rem;
  }
  .text_3 {
    margin-left: 0.42rem;
  }
  .text_5 {
    margin-left: 0.44rem;
  }
  .text_6 {
    margin-left: 0.35rem;
  }
  .text-wrapper_2 {
    margin-left: 0.16rem;
    padding: 0.26rem 0;
    background-color: #2080f7;
    width: 1.08rem;
    height: 0.68rem;
  }
  .text_7 {
    color: #ffffff;
  }
  .text_8 {
    margin-left: 0.26rem;
  }
  .space-x-43 > *:not(:first-child) {
    margin-left: 0.43rem;
  }
  .text_9 {
    line-height: 0.18rem;
  }
  .group{
    padding-bottom: .5rem;

  }
  .group_2 {
    width: 11.8rem;
  }
  .space-y-37 > *:not(:first-child) {
    margin-top: 0.37rem;
  }
  .group_3 {
  }
  .space-y-19 > *:not(:first-child) {
    margin-top: 0.19rem;
  }
  .space-x-18 > *:not(:first-child) {
    margin-left: 0.18rem;
  }
  .image {
    width: 0.53rem;
    height: 0.08rem;
  }
  .text_10 {
    color: #2b2c30;
    font-size: 0.36rem;
    font-family: OPPOSans;
    line-height: 0.35rem;
  }
  .text_11 {
    color: #2b2c30;
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.2rem;
  }
  .space-y-8{
    margin-top: .37rem;
  }
  .group_4 {
    padding-left: 1.03rem;
    padding-right: 0.1rem;
  }
  .space-x-56 > *:not(:first-child) {
    margin-left: 0.56rem;
  }
  .group_7 {
    padding-left: 0.05rem;
    height: 0.16rem;
  }
  .font_4 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    line-height: 0.12rem;
    font-weight: 300;
    color: #2080f7;
  }
  .text_15 {
    margin-top: 0.05rem;
    line-height: 0.11rem;
  }
  .text_14 {
    margin-top: -0.16rem;
    line-height: 0.11rem;
  }
  .section_16 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    width: 0.5rem;
    height: 0.18rem;
  }
  .section_15 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    width: 0.5rem;
    height: 0.23rem;
  }
  .group_5 {
    padding: 0 0.05rem 1.33rem;
    width: 8.93rem;
  }
  .font_3 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    line-height: 0.09rem;
    font-weight: 300;
    color: #2080f7;
  }
  .text_12 {
    margin-right: -1.1rem;
  }
  .text_13 {
    margin-right: 0.76rem;
    margin-top: 0.1rem;
  }
  .space-x-28 > *:not(:first-child) {
    margin-left: 0.28rem;
  }
  .pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0.14rem;
  }
  .group_6 {
    flex: 1 1 0.49rem;
    width: 0.49rem;
  }
  .section_14 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.28rem;
  }
  .section_13 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.38rem;
  }
  .section_12 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.48rem;
  }
  .section_11 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.58rem;
  }
  .section_10 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.68rem;
  }
  .section_9 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.78rem;
  }
  .section_8 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.88rem;
  }
  .section_7 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 0.98rem;
  }
  .section_6 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 1.08rem;
  }
  .view {
    margin-top: 0.06rem;
  }
  .space-y-5 > *:not(:first-child) {
    margin-top: 0.05rem;
  }
  .section_5 {
    background-color: #2080f7;
    border-radius: 0.06rem;
    height: 1.18rem;
  }
  .section_4 {
    flex: 1 1 0.49rem;
    background-color: #2080f7;
    border-radius: 0.06rem;
    width: 0.49rem;
    height: 1.28rem;
  }
  .section_3 {
    flex: 1 1 0.49rem;
    background-color: #2080f7;
    border-radius: 0.06rem;
    width: 0.49rem;
    height: 1.38rem;
  }
  .text-wrapper_3 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 0.58rem;
    height: 0.4rem;
  }
  .font_5 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    line-height: 0.12rem;
    font-weight: 300;
    color: #666666;
  }
  .text-wrapper_4 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 1.87rem;
    height: 0.4rem;
  }
  .text-wrapper_5 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 0.69rem;
    height: 0.4rem;
  }
  .text-wrapper_6 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 1.45rem;
    height: 0.4rem;
  }
  .text-wrapper_7 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 1.47rem;
    height: 0.4rem;
  }
  .text-wrapper_8 {
    padding: 0.14rem 0;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 2.21rem;
    height: 0.4rem;
  }
  .text-wrapper_9 {
    padding: 0.18rem 0 1.12rem;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 0.58rem;
    height: 1.4rem;
  }
  .section_17 {
    padding: 0.18rem 0.08rem 0.25rem 0.14rem;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 1.87rem;
    height: 1.4rem;
  }
  .font_6 {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.16rem;
    color: #666666;
  }
  .font_7 {
    font-size: 0.1rem;
    font-family: Adobe Heiti Std;
    line-height: 0.16rem;
    color: #666666;
  }
  .section_18 {
    padding: 0.18rem 0.25rem 0.25rem 0.31rem;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 2.21rem;
    height: 1.4rem;
  }
  .section_19 {
    padding: 0.18rem 0.25rem 0.24rem 0.31rem;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 2.21rem;
    height: 1.4rem;
  }
  .section_20 {
    padding: 0.18rem 0.25rem 0.09rem 0.31rem;
    background-color: #edeff2;
    border-radius: 0.06rem;
    width: 2.21rem;
    height: 1.4rem;
  }
  .space-y-16 > *:not(:first-child) {
    margin-top: 0.16rem;
  }
  @media screen and (orientation: portrait){
    .space-y-8{
      img{
        width: 100%;
      }
    }
  }
</style>
