<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col group_2">
      <div v-if="courseType == 3" class="pub_-flex-col group_3">
        <div class="section_2 space-x-20">
          <div class="pub_case pub_-flex-row cou_d_t">
            <div class="pub_-flex-col section_4_left">
              <div class="pub_-flex-row pub_-items-center section_3 space-x-18">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_2"
                >
                  <span class="text_12">精品课</span>
                </div>
                <span class="font_2 text_11"
                >开播时间：{{ info.startTime }}</span
                >
              </div>
              <div class="cou_swiper">
                <div class="lb_box">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, i) in info.carouselPathData"
                        :key="i"
                      >
                        <img class="image" :src="item"/>
                      </div>
                    </div>

                    <div class="swiper-pagination"></div>
                  </div>
                </div>


                <!--                <img class="imagex" :src="info.firstPicturePath" />-->
              </div>

            </div>

            <div class="pub_-flex-col section_4_right section_4">
              <span class="pub_-self-center text_10">{{ info.title }}</span>

              <div class="pub_-flex-row group_4 view" v-if="info.rankSort !== 0 ">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3"
                >
                  <span class="font_3 text_13">热门榜</span>
                </div>
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-start text-wrapper_4"
                >
                  <span class="font_4 text_14">
<!--                    {{-->
                    <!--                      info.rankSort !== 0-->
                    <!--                        ? "英语热销课程第" + info.rankSort + "名"-->
                    <!--                        : "未上榜"-->
                    <!--                    }}-->

                    {{
                      "热销课程第" + info.rankSort + "名"
                    }}
                  </span>
                </div>
              </div>
              <div class="bd_z" v-else></div>


              <div class="pub_-flex-row group_4 view_2 space-x-8">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5"
                  v-if="info.isSecurity === 1"
                >
                  <span class="font_4 text_15">放心选</span>
                </div>
                <div class="pub_-flex-col pub_-justify-start text-wrapper_6">
                  <span class="font_4 text_16"
                  >好评{{ info.evaluatePercentage }}%</span
                  >
                </div>
              </div>
              <div class="pub_-flex-row group_5 space-x-128">
                <div class="pub_-flex-row pub_-items-baseline pub_-shrink-0">
                  <span class="font_5 text_19">￥</span>
                  <span class="text_17">
                    <!-- {{info.price}} -->
                    <!-- {{ info.minPrice }}~
                    {{ info.maxPrice }} -->

                    {{ price }}
                  </span>
                </div>
                <div class="group_6">
                  <span class="font_6 text_20">{{ info.saleNumber }}</span>
                  <span class="font_1 text_18">人报名</span>
                </div>
              </div>
              <div
                class="btn_sty btn_sty_r pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_7 btn_sty btn_sty_r"
                @click="goHref($event)"
                :data-url="
                  '/confirmOrder?id=' +
                  info.id +
                  '&&type=' +
                  info.type +
                  '&&skuId=' +
                  sku
                "
              >
                <span class="text_21">点击购买</span>
              </div>
              <span class="pub_-self-start font_7 text_22">讲师介绍</span>
              <div class="pub_-flex-row pub_-items-center group_7 space-x-12">
                <img class="image_2" :src="teacherInfo.avatarPath"/>
                <div class="pub_-flex-col group_8 space-y-14">
                  <span class="pub_-self-start font_7 text_23">{{
                      teacherInfo.nickname
                    }}</span>
                  <div class="pub_-flex-row space-x-7">

                    <div class="pub_-flex-col pub_-items-start space-y-16">
                      <span
                        class="font_1"
                        v-for="(item, i) in teacherInfo.teacherLabelData"
                        :key="i"
                      ><img
                        class="image_3"
                        src="../../assets/sty001.png"
                      />{{ item.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="pub_-flex-col pub_-justify-start section_2x zhibo_box">
        <div class="pub_-flex-col section_3x">
          <div class="pub_-flex-row group_4x pub_case">
            <div class="pub_-flex-col pub_-flex-auto group_7x">
              <div class="pub_-flex-row pub_-items-center section_4x space-x-18x">
                <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_2x">
                  <span class="font_3x text_12x">精品课</span>
                </div>
                <span class="font_2x text_11x"
                >开播时间：{{ info.startTime }}</span
                >
              </div>
              <div class="cou_swiper">
                <div class="lb_box">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, i) in info.carouselPathData"
                        :key="i"
                      >
                        <img class="image" :src="item"/>
                      </div>
                    </div>

                    <div class="swiper-pagination"></div>
                  </div>
                </div>


                <!--                <img class="imagex" :src="info.firstPicturePath" />-->
              </div>
            </div>
            <div class="pub_-flex-col pub_-shrink-0 pub_-self-start group_5x">
              <span class="text_10x">{{ info.title }}</span>
              <div class="pub_-flex-row group_6x view" v-if="info.rankSort !== 0 ">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_3x"
                >
                  <span class="font_4x text_13x">热门榜</span>
                </div>
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-start text-wrapper_4x"
                >
                  <span class="font_4x text_14x">
                   {{
                      "热销课程第" + info.rankSort + "名"
                    }}
                  </span>
                </div>
              </div>

              <div class="bd_z" v-else></div>
              <div class="pub_-flex-row group_6x view_2x space-x-8x">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5x"
                  v-if="info.isSecurity === 1"
                >
                  <span class="font_4x text_15x">放心选</span>
                </div>
                <div class="pub_-flex-col pub_-justify-start text-wrapper_6x">
                  <span class="font_4x text_16x"
                  >好评{{ info.evaluatePercentage }}%</span
                  >
                </div>
              </div>
              <div
                class="pub_-flex-row pub_-items-center space-x-317x"
              >
                <div class="pub_-flex-row pub_-items-baseline pub_-shrink-0">
                  <span class="font_5x text_19x">￥</span>
                  <span class="text_17x">
                    <!-- {{ info.minPrice }}~ {{ info.maxPrice }} -->
                    {{ price }}
                  </span>
                </div>
                <div class="group_8x">
                  <span class="font_6x text_20x">{{ info.saleNumber }}</span>
                  <span class="font_3x text_18x">人报名</span>
                </div>
              </div>
              <div class="divider"></div>
              <div v-if="courseType == 1" class="pub_-flex-row group_9x">
                <span class="text_21x">课程分类</span>

                <div class="pub_-flex-row group_10x space-x-14x">
                  <div
                    class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_7x"
                    :class="{ 'active': classifyON == i }"
                    v-for="(item, i) in info.skuList"
                    :key="i"
                    @click="selSku(i, item.id, item.price)"
                  >
                    <span class="font_6x">{{ item.name }}</span>
                  </div>
                </div>
              </div>

              <div class="pub_-flex-row pub_-items-center section_5x">
                <div class="group_11x">
                  <span class="font_6x text_24x">课时:</span>
                  <span class="font_7x text_25x">
                     {{ classFee }}
                  </span>
                </div>
                <div class="section_6x view_4x"></div>
                <div class="group_11x view_6x">
                  <span class="font_6x text_26x">讲师:</span>
                  <span class="font_7x text_29x">{{
                      teacherInfo.nickname
                    }}</span>
                </div>
                <div class="section_6x view_5x"></div>
                <div class="group_8x view_7x">
                  <span class="font_6x text_27x">有效期:</span>
                  <span class="font_6x text_28x"
                  >{{
                      info.termOfValidity > 0
                        ? info.termOfValidity + "天"
                        : "永久有效"
                    }}</span
                  >
                </div>
              </div>

              <div
                class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_9x btn_sty btn_sty_r"
                @click="goHref($event)"
                :data-url="
                  '/confirmOrder?id=' +
                  info.id +
                  '&&type=' +
                  info.type +
                  '&&skuId=' +
                  sku
                "
              >
                <span class="text_30x">点击购买</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pub_-flex-col pub_-self-center group_9 pub_case">
        <div class="pub_-flex-row section_5">
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8"
            :class="{ 'text-wrapper_9': detOn == 0 }"
            @click="tabT(0)"
          >
            <span class="font_5 text_24">课程简介</span>
          </div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8"
            :class="{ 'text-wrapper_9': detOn == 1 }"
            @click="tabT(1)"
            v-if="info.hasTimetable == 1"
          >
            <span class="font_5 text_24">课程表</span>
          </div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8"
            :class="{ 'text-wrapper_9': detOn == 2 }"
            @click="tabT(2)"
            v-if="courseType != 3"
          >
            <span class="font_5 text_24">教师介绍</span>
          </div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_8"
            :class="{ 'text-wrapper_9': detOn == 3 }"
            @click="tabT(3)"
          >
            <span class="font_5 text_24">评论列表</span>
          </div>
        </div>

        <!-- 课程简介 -->
        <div v-if="detOn == 0" class="group_12q introduction_box">

          <div class="pub_-flex-row pub_-items-center group_21q">
            <div>
              <div class="section_8q"></div>
              <span class="font_9q text_33q">音频简介</span>


              <audio :src="info.profileAudioPath" controls="controls"></audio>
            </div>
          </div>


          <div
            class="pub_-flex-row pub_-items-center group_21q"
          >


            <div>
              <div class="section_8q"></div>
              <span class="font_9q text_33q">特色服务</span></div>
          </div>
          <div
            class="pub_-flex-row pub_-items-center pub_-self-start group_14q"
          >
            <div class="te_con" v-for="(lable, k) in info.labelData" :key="k">
              <img class="image_2q" :src="lable.picturePath"/>
              <span class="font_10q">{{ lable.name }}</span>
            </div>
          </div>
          <div
            class="pub_-flex-row pub_-items-center  group_21q"
          >
            <div>
              <div class="section_8q"></div>
              <span class="font_9q text_33q">课程参数</span>
            </div>
          </div>

          <div
            class="pub_-flex-row pub_-justify-between pub_-self-start group_16q"
          >

            <span v-for="(item,i) in info.paramsList" :key="i" class="font_5q text_36q">
             {{ i }} :{{ item }}</span>

          </div>


          <div
            class="pub_-flex-row pub_-items-center  group_21q"
          >
            <div>
              <div class="section_8q"></div>
              <span class="font_9q text_33q">课程介绍</span>
            </div>

            <template v-if="info.introductoryLessonPath">

              <div class="btn_sty" v-if="isMp4InPath(info.introductoryLessonPath)"
                   @click="playVideo(info.introductoryLessonPath)"
                   :data-url="info.introductoryLessonPath">先导课
              </div>

              <div class="btn_sty" v-else @click="downloadBtn(info.introductoryLessonPath)">先导课</div>


            </template>

          </div>
          <div class="int_info font_11q" v-html="info.richText"></div>
        </div>

        <!-- 课程表-->
        <div
          v-else-if="detOn == 1"
          class="pub_-flex-col group_1z space-y-19z purchased_box"
        >
          <span class="pub_-self-start font_3z text_18z">课程目录</span>

          <div class="pub_-flex-col group_6z">
            <div
              class="pub_-flex-row pub_-justify-between pub_-items-center section_10z"
            >
              <div class="pub_-flex-row pub_-items-center space-x-14z">
                <img
                  class="pub_-shrink-0 image_3z"
                  src="../../assets/ce4a889920b52d8c26e70441fc94ccb9.png"
                />
                <span class="font_3z text_21z">{{ info.title }}</span>
              </div>
              <!-- <div class="pub_-flex-row pub_-items-center space-x-8z">
                <img
                  class="pub_-shrink-0 image_4z"
                  src="../../assets/ad2cc88537ceb286d3241f2c6031b5da.png"
                />
                <span class="font_2z text_22z">下拉</span>
              </div> -->
            </div>

            <div class="pub_-flex-col section_11z">
              <!--              <div-->
              <!--                class="pub_-flex-row pub_-justify-between pub_-items-center group_7z"-->
              <!--              >-->
              <!--                <div class="pub_-flex-row space-x-14z">-->
              <!--                  <img-->
              <!--                    class="image_3z"-->
              <!--                    src="../../assets/4c981b622f637f68b6e732d1f7993a42.png"-->
              <!--                  />-->
              <!--                  <div class="pub_-flex-col space-y-6z">-->
              <!--                    <span class="font_2z text_24z">{{ info.profile }}</span>-->
              <!--                    <div class="pub_-flex-row pub_-items-center">-->
              <!--                      <span class="font_6z text_27z"-->
              <!--                        >教师：{{ teacherInfo.nickname }}</span-->
              <!--                      >-->
              <!--                      <div class="pub_-shrink-0 section_12z"></div>-->
              <!--                      <span class="font_6z text_28z"-->
              <!--                        >时间：{{ info.startTime }}</span-->
              <!--                      >-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4z btn_sty"-->
              <!--                >-->
              <!--                  <span class="font_5z text_25z">去观看</span>-->
              <!--                </div>-->
              <!--              </div>-->

              <div
                class="coure_box"
                v-for="(item, i) in timetableData"
                :key="i"
              >
                <div
                  class="pub_-flex-row pub_-justify-between pub_-items-center group_8z"
                >
                  <div class="pub_-flex-row pub_-items-center space-x-14z">
                    <img
                      class="pub_-shrink-0 image_3z"
                      src="../../assets/4752c903e4673c40768e66e39d1d0b37.png"
                    />
                    <span class="font_2z">{{ item.name }}</span>
                  </div>
                  <div
                    class="pub_-flex-row pub_-items-center space-x-9z"
                    @click="downBtn(i)"
                    :class="item.downShow == true ? 'on' : ''"
                  >
                    <img
                      class="pub_-shrink-0 image_4z"
                      src="../../assets/0312671bfb694883273997d54e6e8e45.png"
                    />
                    <span class="font_2z text_29z">下拉</span>
                  </div>
                </div>
                <div class="divider"></div>

                <div class="coure_con" v-if="item.downShow == true">
                  <div
                    class="coure_li"
                    v-for="(items, j) in item.hourList"
                    :key="j"
                  >
                    <div
                      class="pub_-flex-row pub_-justify-between pub_-items-center group_9z"
                    >
                      <div class="pub_-flex-row space-x-14z">
                        <img
                          class="image_3z"
                          src="../../assets/513a254d5a73f54414be994c1d5c9034.png"
                        />
                        <div class="pub_-flex-col pub_-items-start space-y-7z">
                          <span class="font_2z">{{ items.coursewareName }}</span>

                          <span class="font_6z">{{ items.name }}</span>
                          <!-- <span class="font_6z"
                            >作业提交截止时间：2022.10.21-12.20</span
                          > -->
                        </div>
                      </div>
                      <div class="pub_-flex-row pub_-items-center">
                        <!-- <img
                          class="pub_-shrink-0 image_5z"
                          src="../../assets/f69c904853130b775f5319952296b24a.png"
                        />
                        <span class="font_1z text_30z">学习资料</span> -->
                        <div
                          class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_5z btn_sty btn_sty_line"
                        >
                          <span class="font_5 text_31z">去观看</span>
                        </div>
                      </div>
                    </div>
                    <div class="divider"></div>
                  </div>

                  <div v-if="item.audioPath">
                    <!--                    <div-->
                    <!--                      class="pub_-flex-row pub_-items-center group_10z space-x-14z"-->
                    <!--                    >-->
                    <!--                      <img-->
                    <!--                        class="image_3z"-->
                    <!--                        src="../../assets/ab3e688de26effc78fbcb84bce212d9a.png"-->
                    <!--                      />-->
                    <!--                      <span class="font_2z">课后作业</span>-->
                    <!--                    </div>-->

                    <div
                      class="pub_-flex-row pub_-justify-between pub_-items-center group_10z"
                    >
                      <div class=" space-x-14z pub_-flex-row pub_-items-center">
                        <img class="image_3z" src="../../assets/ab3e688de26effc78fbcb84bce212d9a.png"/>
                        <span class="font_2z">课后作业</span>
                      </div>

                      <div class="cursor_s " v-if="item.audioPath"
                           @click="downloadBtn(item.audioPath)">
                        <img
                          class="pub_-shrink-0 image_5z"
                          src="../../assets/f69c904853130b775f5319952296b24a.png"
                        />
                        <span class="font_1z text_30z">课后作业</span>
                      </div>
                    </div>


                    <!--                    <div class="pub_-flex-row group_11z space-x-19z">-->
                    <!--                      <div class="pub_-flex-col pub_-justify-start section_13z">-->
                    <!--                        <div-->
                    <!--                          class="pub_-flex-row pub_-justify-between pub_-items-center section_16z"-->
                    <!--                        >-->
                    <!--                          <span class="font_2z">{{ item.name }}</span>-->
                    <!--                          <div-->
                    <!--                            class="pub_-self-end image_9"-->
                    <!--                            :data-url="item.audioPath"-->
                    <!--                            :class="currentAudioIndex == i ? 'on' : ''"-->
                    <!--                          >-->
                    <!--                            <audio-->
                    <!--                              ref="audio"-->
                    <!--                              :src="item.audioPath"-->
                    <!--                              :key="i"-->
                    <!--                              style="position: absolute; opacity: 0"-->
                    <!--                            ></audio>-->
                    <!--                            <img @click="playAudio(i)"-->
                    <!--                                 class="image_7z"-->
                    <!--                                 src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"-->
                    <!--                            />-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                      <div class="pub_-flex-col section_14z space-y-6z">-->
                    <!--                        <div-->
                    <!--                          class="pub_-flex-row pub_-justify-between pub_-items-center pub_-relative section_15z"-->
                    <!--                        >-->
                    <!--                          <span class="font_2z">{{ item.name }}</span>-->
                    <!--                          <div class="pub_-self-end image_9" :data-url="item.audioUrl"-->
                    <!--                               :class="currentAudioIndexB == i ? 'on' : ''">-->
                    <!--                            <audio ref="audioB" :src="item.audioUrl" :key="i"-->
                    <!--                                   style="position: absolute; opacity: 0"></audio>-->
                    <!--                            <img @click="playAudioB(i)" class="image_7z"-->
                    <!--                                 src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"/>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                        <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload($event, i)">-->
                    <!--                        <img @click="openFilePicker(i)"-->
                    <!--                             class="pub_-self-start image_7z"-->
                    <!--                             src="../../assets/40ee75a345685dbf4abbf3468ce80f34.png"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--教师介绍-->
        <div v-else-if="detOn == 2" class="teacher_box teacher_lb_box">
          <div class="swiper-container">
          <div class="tex_box swiper-wrapper">
            <div
              class="pub_-flex-col pub_-justify-start pub_-items-center section_36 swiper-slide"
              :key="i"
              v-for="(tea, i) in teacherList"
            >
              <div class="pub_-flex-col section_37">
                <div
                  class="pub_-flex-row pub_-items-center group_62 space-x-12"
                >
                  <img class="image_6" :src="tea.avatarPath"/>
                  <div class="pub_-flex-col">
                    <div class="pub_-flex-row pub_-items-center space-x-14">
                      <span class="font_16">{{ tea.teacherEnglishName }}</span>
                      <div
                        class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 pub_-relative group_20"
                      >
                        <div
                          class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_12"
                        >
                          <span v-if="tea.teacherStatus == 1" class="font_8"
                          >未认证</span
                          >
                          <span
                            v-else-if="tea.teacherStatus === 2"
                            class="font_8"
                          >人工审核中</span
                          >
                          <span v-else class="font_8">已认证</span>
                        </div>
                        <img
                          class="image_7 pos_12"
                          src="../../assets/19582b3202abe04fad927487e1694713.png"
                        />
                      </div>
                    </div>
                    <div class="pub_-flex-row group_63">
                      <div class="pub_-flex-col pub_-items-start group_64 group_60 space-y-16">
                        <span class="font_sty" :key="i" v-for="(teaI, i) in tea.teacherLabelData">
                          <img src="../../assets/sty001.png" alt=""/>{{ teaI.name }}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="pub_-flex-col section_16 view_4 space-y-23">
                  <span class="font_17">{{ tea.teacherProfile }}</span>

                  <div
                    class="pub_-self-end image_9"
                    :data-url="tea.teacherProfileAudioPath"
                    :class="currentAudioIndex == i ? 'on' : ''"
                  >
                    <audio
                      ref="audio"
                      :src="tea.teacherProfileAudioPath"
                      :key="i"
                      style="position: absolute; opacity: 0"
                    ></audio>
                    <img
                      @click="playAudio(i)"
                      src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"
                    />
                  </div>
                </div>

                <div class="pub_-flex-row group_24 space-x-52">
                  <div class="pub_-flex-col pub_-relative group_25">
                    <div class="pub_-flex-row pub_-items-baseline space-x-4">
                      <span class="font_18">{{
                          tea.teacherTeachingNumber
                        }}</span>
                      <span class="font_20">人</span>
                    </div>
                    <span class="pub_-self-start font_21 text_44"
                    >服务学生</span
                    >
                    <div class="section_17 pos_15"></div>
                  </div>
                  <div class="pub_-flex-col space-y-14">
                    <div
                      class="pub_-flex-row pub_-items-baseline group_27 space-x-7"
                    >
                      <span class="font_19">{{ tea.teacherSatisfaction }}</span>
                      <span class="font_20 text_43">%</span>
                    </div>
                    <span class="font_21">学生满意度</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="swiper-button-prev m_sty"></div>
          <div class="swiper-button-next m_sty"></div>

        </div>

        <!-- 评论列表 -->
        <div v-else class="pub_-flex-col group_26 space-y-41">
          <div class="pub_-flex-col pub_-justify-start evaluate_case">
            <div class="pub_-flex-col group_1 space-y-5">
              <div class="pub_-flex-row pub_-self-start space-x-36">
                <div class="pin_left">
                  <div class="pin_name">好评度</div>
                  <div class="group_12">
                    <span class="text_28">{{ info.evaluatePercentage }}</span>
                    <span class="text_29">%</span>
                  </div>
                </div>
                <div
                  class=" serve_case"
                >
                  <div class="serve_block">
                    <div
                      v-for="(item, i) in courseCommentLabelData"
                      :key="i"
                      class="pub_-flex-col pub_-justify-start text-wrapper_11"
                    >
                      <span class="font_4 text_27">{{ item.name }}</span>
                    </div>
                  </div>

                  <div class="show_case">
                    <div v-for="(item,k) in info.giftData" :key="k" v-if="item!=0">
                      <img v-if="k == 1" src="../../assets/l001.png" alt=""/>
                      <img v-if="k == 3" src="../../assets/l002.png" alt=""/>
                      <img v-if="k == 10" src="../../assets/l003.png" alt=""/>
                      <img v-if="k == 20" src="../../assets/l004.png" alt=""/>
                      * {{ item }}
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="pub_-flex-row section_6" v-if="selList[0].on">
            <span
              class="font_8"
              :class="selShow == k ? 'text_30' : ''"
              v-for="(itee, k) in selList"
              :key="k"
              @click="reviewScr(itee.id, k)"
            >{{ itee.name }} <span v-if="itee.num">({{ itee.num }})</span></span
            >
          </div>
          <div class="pub_-flex-col group_15">
            <div
              class="pub_-flex-col group_19"
              v-for="(item, i) in comment.data"
              :key="i"
            >
              <div class="pub_-flex-row view_16 space-x-81">
                <div
                  class="pub_-flex-row pub_-items-center pub_-shrink-0 pub_-self-start space-x-9"
                >
                  <img class="pub_-shrink-0 image_4" :src="item.avatarPath"/>
                  <span class="font_6">{{ item.nickname }}</span>
                </div>
                <div class="pub_-flex-col pub_-flex-auto group_14 view_17">
                  <div class="star_box">
                    <img
                      class="pub_-self-start image_5"
                      v-for="(items, j) in parseInt(item.star)"
                      :key="j"
                      src="../../assets/star.png"
                    />
                  </div>
                  <span class="font_10 text_1">{{ item.content }}</span>
                  <div
                    class="pub_-flex-row pub_-justify-between pub_-items-center group_16"
                  >
                    <span class="font_12">{{ item.createTime }}</span>
                    <div class="pub_-flex-row space-x-39">
                      <!-- <div class="pub_-flex-row space-x-11">
                        <img
                          class="pub_-shrink-0 image_6"
                          src="../../assets/c79fa9de244ac0baf0b2a786a0ec641e.png"
                        />
                        <span class="font_11">点赞</span>
                      </div> -->
                      <div
                        class="pub_-flex-row space-x-11"
                        @click="rely(item.id, i)"
                      >
                        <img
                          class="pub_-shrink-0 image_7"
                          src="../../assets/7bacdd41569cc8d6d3059ad13a0ef308.png"
                        />
                        <span class="font_11">评论</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="reply_edit" v-if="item.show == true">
                <!--                 <div class="pub_-flex-col section_7">-->
                <!--                  <div class="section_8"></div>-->
                <!--                  <div-->
                <!--                    class="pub_-flex-row pub_-justify-end pub_-items-center group_17 space-x-14-reverse"-->
                <!--                  >-->
                <!--                    <span class="font_12 text_31">还可以输入100字</span>-->
                <!--                    <div-->
                <!--                      class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_12"-->
                <!--                    >-->
                <!--                      <span class="text_32">评论</span>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="reply_box_case" v-if="item.replyList.length > 0">
                  <div
                    class="reply_box"
                    v-for="(items, j) in item.replyList"
                    :key="j"
                  >
                    <div class="reply_left">
                      <div class="reply_u">{{ items.nickname }}</div>
                      <div class="reply_time">{{ items.createTime }}</div>
                    </div>
                    <div class="reply_right">{{ items.content }}</div>
                  </div>
                </div>
                <div v-else class="reply_box_case">
                  <div class="reply_box">暂无回复</div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <foot-box></foot-box>


    <div class="sha_video" v-if="showVideo">

      <div class="sha_bg"></div>
      <div class="sha_video_con">
        <div class="sha_click" @click="showVideo = false">
          <img src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png" class="pub_-shrink-0 imagea">
        </div>
        <!--          :poster="videoPoster"-->
        <video
          class="sha_video_box"
          :src="videoUrl"
          controls="controls"
          autoplay="autoplay"

        ></video>


      </div>

    </div>
  </div>
</template>

<script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";

import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

export default {
  components: {NavBox, FootBox},
  data() {
    return {
      info: {},
      courseCommentLabelData: [],
      courseType: 3, //默认3
      teacherList: [], //教师介绍
      teacherInfo: {},
      classifyON: 0,
      detOn: 0,
      timetableData: [], //课表数据
      sku: 0, //课程属性
      price: 0, //价格
      comment: {},
      currentAudioIndex: null,
      currentAudioIndexB: null,
      classFee: '',//课时
      commentA: {},
      commentG: {},
      commentC: {},
      commentB: {},

      selList: [
        {
          name: "全部评论",
          id: 0,
          on: 0,
        },
        {
          name: "好评",
          id: 1,
          num: 0,
        },
        {
          name: "中评",
          id: 2,
          num: 0,
        },
        {
          name: "差评",
          id: 3,
          num: 0,
        },
      ],
      selShow: 0,

      audioItems: [
        {audioUrl: null, audioPlaying: false},
        {audioUrl: null, audioPlaying: false},
        // 添加更多的项...
      ],

      showVideo: false,

    };
  },
  created() {
    this.courseType = this.getParam("type");
    this.ajaxH(null, 0);
  },
  mounted() {
    this.onLb();


  },
  methods: {
    playVideo(url) {
      this.showVideo = true;
      this.videoUrl = url;
    },

    isMp4InPath(fileName) {//是否是.mp4文件
      const mp4Regex = /\.mp4$/i;
      return mp4Regex.test(fileName);
    },

    downloadBtn(str) {//下载课件
      const a = document.createElement('a'); // 创建一个HTML 元素
      let url = str;
      a.setAttribute('target', '_blank'); //target
      a.setAttribute('download', ''); //download属性
      a.setAttribute('href', url); // href链接
      a.click(); // 自执行点击事件

    },
    onLb() {
     let bannerSwiper = new Swiper(".lb_box .swiper-container", {
        slideActiveClass: "active",
        autoplay: 5000,
        speed: 600,
        pagination: ".lb_box .swiper-pagination",
        paginationClickable: true,
        observer: true,
        observeParents: true,
      });
    },
    teacherLb(){
     let teaSwiper = new Swiper(".teacher_lb_box .swiper-container", {
        slideActiveClass: "active",
        spaceBetween : 10,
        autoplay: 5000,
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.teacher_lb_box .swiper-button-prev',
        nextButton:'.teacher_lb_box .swiper-button-next',
      });
    },
    initSwiper() {
      // 确保在 DOM 完全加载后初始化 Swiper
      if (document.readyState === 'complete') {
        this.$nextTick(() => {
          let teaSwiper = new Swiper(".teacher_lb_box .swiper-container", {
            slideActiveClass: "active",
            spaceBetween : 10,
            autoplay: 5000,
            speed: 600,
            observer: true,
            observeParents: true,
            prevButton:'.teacher_lb_box .swiper-button-prev',
            nextButton:'.teacher_lb_box .swiper-button-next',


          });
        });
      } else {
        document.addEventListener('DOMContentLoaded', this.initSwiper);
      }
    },

    openFilePicker(index) {
      this.$refs.fileInput[index].click();
    },
    handleFileUpload(event, index) {
      const file = event.target.files[0];
      if (file.type !== 'audio/mpeg') {
        this.$message({
          message: '请上传MP3文件',
          type: "warning",
        });
        return;
      }
      const audioUrl = URL.createObjectURL(file);
      this.timetableData[index].audioUrl = audioUrl;

      const audioElement = this.$refs.audio[index];

      // 检查音频文件是否可以播放
      return new Promise((resolve, reject) => {
        const checkCanPlay = () => {
          if (audioElement.canPlayType('audio/mpeg')) {
            // 如果可以播放，则解决Promise并播放音频
            this.$message({
              message: '上传成功',
              type: "success",
            });

            resolve();

          } else {
            // 如果无法播放，则再等待一段时间后再次检查
            setTimeout(checkCanPlay, 500);
          }
        };

        // 开始检查是否可以播放
        checkCanPlay();
      }).then(() => {
        // 在Promise解决后播放音频

        // this.playAudioB(index);
      });
    },
    playAudioB(index) {
      const audioUrl = this.timetableData[index].audioUrl;

      if (this.currentAudioIndex !== null && this.currentAudioIndex !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audio[this.currentAudioIndex].pause();
      }
      this.currentAudioIndex = null;

      if (audioUrl) {
        const audioT = this.$refs.audio[index];
        audioT.pause();
        if (this.currentAudioIndexB !== null && this.currentAudioIndexB !== index) {
          // 如果已经有音频在播放，则暂停它
          this.$refs.audioB[this.currentAudioIndexB].pause();
        }
        // 播放当前选中的音频
        const audioEl = this.$refs.audioB[index];
        if (audioEl.paused) {
          audioEl.play();
          this.currentAudioIndexB = index;
        } else {
          audioEl.pause();
          this.currentAudioIndexB = null;

        }
      } else {
        alert('请先上传音频文件');
      }

    },

    async ajaxH(id, i) {
      this.getInfo(); //获取详情接口
    },
    async getInfo(id, i) {
      let param = {
        id: this.getParam("id"),
      };
      // console.log(param);
      const {data: res} = await this.$http.post("/api.course/getInfo", param);
      this.info = res.data;
      // console.log(this.info);

      this.classFee = res.data.paramsList["课时"];
      this.price = res.data.minPrice;
      this.teacherList = res.data.teacherData;
      this.teacherInfo = res.data.teacherInfo;
      this.timetableData = res.data.timetableData;
      this.courseCommentLabelData = res.data.courseCommentLabelData;
      if (res.data.skuList.length > 0) {
        this.classifyON = 0;
        this.sku = res.data.skuList[0].id;
        this.price = res.data.skuList[0].price;
      }

      if (this.timetableData) {
        this.timetableData.map((item) => {
          item.downShow = false;
        });
      }


      //获取评论分页列表

      const {data: com} = await this.$http.post(
        "/api.course/getCommentPageList",
        {courseId: this.getParam("id")}
      );
      // console.log(com);

      this.commentA = com.data;
      this.commentA.data.map((item) => {
        item.show = false;
      });
      this.comment = this.commentA;

      // console.log(this.comment )

      const {data: comG} = await this.$http.post(
        "/api.course/getCommentPageList",
        {courseId: this.getParam("id"), type: 1}
      );
      // console.log(com);
      this.commentG = comG.data;
      this.commentG.data.map((item) => {
        item.show = false;
      });
      this.selList[1].num = this.commentG.data.length;
      const {data: comC} = await this.$http.post(
        "/api.course/getCommentPageList",
        {courseId: this.getParam("id"), type: 2}
      );
      // console.log(com);
      this.commentC = comC.data;
      this.commentC.data.map((item) => {
        item.show = false;
      });
      this.selList[2].num = this.commentC.data.length;

      const {data: comB} = await this.$http.post(
        "/api.course/getCommentPageList",
        {courseId: this.getParam("id"), type: 3}
      );
      // console.log(com);
      this.commentB = comB.data;
      this.commentB.data.map((item) => {
        item.show = false;
      });
      this.selList[3].num = this.commentB.data.length;


      this.selList[0].on = this.selList[1].num + this.selList[2].num + this.selList[3].num;
      // console.log(this.comment);
    },
    playAudio(index) {

      // console.log(index)

      if (this.currentAudioIndexB !== null && this.currentAudioIndexB !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audioB[this.currentAudioIndexB].pause();
      }
      // this.currentAudioIndexB = null;
      // const audioS = this.$refs.audioB[index];
      // audioS.pause();

      if (this.currentAudioIndex !== null && this.currentAudioIndex !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audio[this.currentAudioIndex].pause();
      }
      // 播放当前选中的音频
      const audioEl = this.$refs.audio[index];
      if (audioEl.paused) {
        audioEl.play();
        this.currentAudioIndex = index;
      } else {
        audioEl.pause();
        this.currentAudioIndex = null;

      }
      // this.currentAudioIndex = index;
    },
    async rely(id, index) {

      this.$set(
        this.comment.data[index],
        "show",
        !this.comment.data[index].show
      );

      let param = {
        replyId: id,
      };

      //获取评论回复列表
      const {data: res} = await this.$http.post(
        "/api.course/getCommentReplyList",
        param
      );

      this.comment.data[index].replyList = res.data;

      console.log(this.comment)

      this.$forceUpdate();
    },
    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },
    reviewScr(id) {
      //筛选评论
      this.selShow = id;
      if (id == 1) {
        this.comment = this.commentG;
      } else if (id == 2) {
        this.comment = this.commentC;
      } else if (id == 3) {
        this.comment = this.commentB;
      } else {
        this.comment = this.commentA;
      }
    },
    downBtn(index) {
      this.timetableData[index].downShow = !this.timetableData[index].downShow;

      // console.log(this.timetableData)
      this.$forceUpdate();
    },
    tabT(index) {
      this.currentAudioIndex = null;
      this.currentAudioIndexB = null;
      this.detOn = index;
      if (index == 2) {
        if (window.innerWidth < window.innerHeight){
          this.initSwiper();
        }
      }
    },
    selSku(index, id, price) {
      this.classifyON = index;
      this.sku = id;
      this.price = price;
    },
    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
    goNew(e) { //打开新窗口播放视频
      let url = e.currentTarget.dataset.url
      window.open(url, '_blank',);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .pin_left {
    .pin_name {
      font-size: 0.12rem;
      font-weight: 300;
      color: #2b2c30;
    }

    .group_12 {
      display: flex;
      align-items: baseline;
      margin-top: 0.14rem;

      .text_28 {
        letter-spacing: 0;
      }
    }
  }
}

.group {
  padding: 0.17rem 3.7rem;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  color: #a1a9b2;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}

.group_2 {
  padding-bottom: 1.18rem;
}

.group_3 {
  padding-bottom: 0.39rem;
}

.section {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.section_2 {
  padding: 0.4rem 0;
  background-image: url("../../assets/f8b77653266d0f6d6a0eec6f1cc8baad.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.cou_d_t {
  justify-content: space-between;

  .section_4_left {
    width: 8.38rem;
  }

  .section_4_right {
    width: 3.22rem;
    padding: 0.15rem 0.15rem 0.2rem 0.2rem;
  }
}

.space-x-20 > *:not(:first-child) {
  margin-left: 0.2rem;
}

.section_3 {
  padding: 0.17rem 0.18rem;
  background-color: #f3f6fa;
  border-radius: 0.08rem 0.08rem 0px 0px;
}

.space-x-18 > *:not(:first-child) {
  margin-left: 0.18rem;
}

.text-wrapper_2 {
  padding: 0.06rem 0;
  background-color: #000000;
  border-radius: 0.06rem;
  width: 0.6rem;
  height: 0.26rem;
}

.text_12 {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  font-weight: 300;
  line-height: 0.14rem;
}

.text_11 {
  margin-right: 4.29rem;
  color: #a1a9b2;
  font-weight: 300;
  line-height: 0.18rem;
}

.image {
  border-radius: 0px 0px 0.08rem 0.08rem;
  width: 100%;
  display: block;
}

.section_4 {
  padding-top: 0.16rem;
  background-color: #f3f6fa;
  border-radius: 0.08rem;
  height: 5.3rem;
}

.text_10 {
  color: #000000;
  font-size: 0.22rem;
  font-family: Adobe Heiti Std;
  line-height: 0.28rem;
}

.view {
  margin-top: 0.17rem;
}

.text-wrapper_3 {
  padding: 0.04rem 0;
  background-color: #f54a4a;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}

.font_3 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #000000;
}

.text_13 {
  color: #ffffff;
}

.text-wrapper_4 {
  padding: 0.04rem 0;
  background-color: #f9eceb;
  border-radius: 0.04rem;
  width: 1.73rem;
  height: 0.22rem;
}

.font_4 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}

.text_14 {
  margin-left: 0.07rem;
  color: #f54a4a;
  line-height: 0.13rem;
}

.view_2 {
  margin-top: 0.08rem;
}

.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}

.text-wrapper_5 {
  padding: 0.04rem 0;
  background-color: #edeff2;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}

.text_15 {
  color: #2080f7;
  line-height: 0.11rem;
}

.text-wrapper_6 {
  padding: 0.04rem 0;
  background-color: #edeff2;
  border-radius: 0.04rem;
  height: 0.22rem;
}

.text_16 {
  margin-left: 0.08rem;
  color: #666666;
  line-height: 0.13rem;
}

.group_5 {
  margin-top: 0.4rem;
  justify-content: space-between;
}

.font_5 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.17rem;
  font-weight: 300;
  color: #000000;
}

.text_19 {
  color: #f75746;
  line-height: 0.13rem;
  letter-spacing: unset;
}

.text_17 {
  color: #f75746;
  font-size: 0.26rem;
  font-family: OPPOSans;
  line-height: 0.23rem;
  letter-spacing: 0.013rem;
}

.group_6 {
  line-height: 1;
  display: flex;
  align-items: center;
}

.font_6 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_20 {
  color: #a1a9b2;
  line-height: 0.11rem;
}

.text_18 {
  font-weight: 500;
}

.text-wrapper_7 {
  margin-top: 0.4rem;
  background-color: #f75746;
  border-radius: 0.04rem;
  width: 100%;
  height: 0.44rem;
}

.text_21 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  font-weight: 300;
  line-height: 0.17rem;
  letter-spacing: 0.025rem;
}

.font_7 {
  font-size: 0.18rem;
  font-family: OPPOSans;
  color: #000000;
}

.text_22 {
  margin-left: 0.19rem;
  margin-top: 0.4rem;
  line-height: 0.18rem;
}

.group_7 {
  margin-top: 0.19rem;
  padding: 0.2rem;
  border-top: solid 0.01rem #e0e0e0;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.12rem;
}

.image_2 {
  border-radius: 0.08rem;
  width: 0.9rem;
  height: 1.21rem;
}

.group_8 {
  margin-right: 0.41rem;
}

.text_23 {
  line-height: 0.2rem;
}

.space-x-7 > *:not(:first-child) {
  margin-left: 0.07rem;
}

.image_3 {
  width: 0.04rem;
  height: 0.14rem;
  margin-right: 0.07rem;
  vertical-align: middle;
}

.space-y-16 > *:not(:first-child) {
  margin-top: 0.12rem;
  line-height: 1.22;
}

.group_9 {
  border-bottom: solid 0.01rem #f3f6fa;
  padding-top: 0.4rem;
}

.section_5 {
  margin-left: 0.04rem;
  background-color: #f3f6fa;
  border-bottom: solid 0.01rem #2080f7;
}

.text-wrapper_8 {
  padding: 0.12rem 0;
  background-color: #f3f6fa;
  width: 0.97rem;
  height: 0.38rem;
  cursor: pointer;

  .font_5 {
    transition: color 0.5s ease;
  }

  @media screen and (orientation: landscape) {
    &:hover {
      .font_5 {
        color: #2080f7;
      }
    }
  }
}

.text_24 {
  line-height: 0.16rem;
}

.text-wrapper_9 {
  padding: 0.12rem 0;
  background-color: #2080f7;
  width: 0.97rem;
  height: 0.38rem;

  .font_5 {
    color: #fff !important;
  }
}

.text_25 {
  color: #ffffff;
  line-height: 0.16rem;
}

.group_26 {
  padding-top: 0.27rem;
}

.space-y-41 > *:not(:first-child) {
  margin-top: 0.41rem;
}

.group_1 {
  width: 11.84rem;
}

.space-y-5 > *:not(:first-child) {
  margin-top: 0.05rem;
}

.group_10 {
  padding: 0 0.04rem;
}

.text_26 {
  color: #2b2c30;
  line-height: 0.13rem;
  letter-spacing: 0.014rem;
}

.text-wrapper_10 {
  padding: 0.04rem 0;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
  border: solid 0.01rem #edeff2;
}

.view_3 {
  margin-left: 0.7rem;
}

.text-wrapper_11 {
  padding: 0.04rem 0;
  border-radius: 0.04rem;
  height: 0.22rem;
  border: solid 0.01rem #edeff2;
  margin-right: 0.14rem;

  &:last-child {
    margin-right: 0;
  }
}

.view_4 {
  margin-left: 0.13rem;
}

.text_27 {
  margin: 0 0.06rem;
  line-height: 0.13rem;
}

.view_5 {
  margin-left: 0.13rem;
}

.view_6 {
  margin-left: 0.13rem;
}

.view_7 {
  margin-left: 0.13rem;
}

.view_8 {
  margin-left: 0.13rem;
}

.view_9 {
  margin-left: 0.13rem;
}

.view_10 {
  margin-left: 0.13rem;
}

.view_11 {
  margin-left: 0.13rem;
}

.view_12 {
  margin-left: 0.13rem;
}

.group_11 {
  width: 5.11rem;
}

.space-x-36 > *:not(:first-child) {
  margin-left: 0.36rem;
}

.group_12 {
  line-height: 0.35rem;
  height: 0.35rem;
}

.text_28 {
  color: #e4393c;
  font-size: 0.45rem;
  font-family: OPPOSans;
  line-height: 0.35rem;
  letter-spacing: 0.054rem;
}

.text_29 {
  color: #e4393c;
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.13rem;
  letter-spacing: 0.019rem;
}

.serve_case {
  max-width: calc(100% - 1.6rem);
}

.serve_block {
  display: flex;
  flex-wrap: wrap;

  .text-wrapper_11 {
    margin-bottom: .1rem;
  }

}

.show_case {
  margin-top: .2rem;
  font-size: .16rem;
  font-weight: 700;
  color: #e4393c;
  display: flex;
  align-items: center;

  > div {
    margin-right: .2rem;
    line-height: 1.5;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;

    }

    img {
      width: .4rem;
      height: .4rem;
    }
  }

}

.section_6 {
  margin-left: 0.04rem;
  padding: 0.08rem 0.2rem;
  background-color: #f3f6fa;

  .font_8 {
    margin-left: 0.16rem;
    cursor: pointer;

    &:hover {
      color: #f54d49;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.font_8 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}

.text_30 {
  color: #f54d49;
}

.view_13 {
  margin-left: 0.25rem;
}

.font_9 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  letter-spacing: 0.017rem;
  line-height: 0.11rem;
  color: #000000;
}

.view_14 {
  margin-left: 0.12rem;
}

.view_15 {
  margin-left: 0.16rem;
  margin-right: 7.48rem;
}

.group_15 {
  padding-left: 0.03rem;
  padding-bottom: 0.22rem;
}

.view_16 {
  margin-right: 0.23rem;
  min-height: 0.78rem;
  border-bottom: 1px solid #f3f6fa;
  padding-bottom: 0.21rem;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}

.image_4 {
  width: 0.34rem;
  height: 0.34rem;
}

.group_14 {
  margin-right: 0.23rem;
  width: 9.5rem;
}

.space-y-14 > *:not(:first-child) {
  margin-top: 0.14rem;
}

.view_17 {
  margin-right: 0;
}

.star_box {
  display: flex;
  align-items: center;
}

.image_5 {
  width: 0.18rem;
  height: 0.15rem;
}

.font_10 {
  font-size: 0.14rem;
  font-family: Adobe Heiti Std;
  letter-spacing: 0.022rem;
  line-height: 0.24rem;
  color: #000000;
}

.text_1 {
  margin-top: 0.14rem;
}

.group_16 {
  margin-right: 0.16rem;
  margin-top: 0.36rem;
}

.font_12 {
  font-size: 0.12rem;
  font-family: OPPOSans;
  letter-spacing: 0.019rem;
  line-height: 0.1rem;
  color: #000000;
}

.space-x-39 > *:not(:first-child) {
  margin-left: 0.39rem;
}

.space-x-11 > *:not(:first-child) {
  margin-left: 0.11rem;
}

.image_6 {
  width: 0.16rem;
  height: 0.17rem;
}

.font_11 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  letter-spacing: 0.022rem;
  line-height: 0.14rem;
  color: #000000;
}

.image_7 {
  width: 0.17rem;
  height: 0.16rem;
}

.divider {
  background-color: #f3f6fa;
  height: 0.01rem;
}

.view_1 {
  margin-top: 0.74rem;
}

.section_7 {
  margin-top: 0.2rem;
  padding: 0.2rem 0.2rem 0;
  background-color: #f3f6fa;
}

.section_8 {
  background-color: #ffffff;
  height: 0.86rem;
  border: solid 0.01rem #e0e0e0;
}

.group_17 {
  padding: 0.2rem 0;
}

.space-x-14-reverse > *:not(:last-child) {
  margin-right: 0.14rem;
}

.text_31 {
  line-height: 0.12rem;
}

.text-wrapper_12 {
  padding: 0.07rem 0;
  background-color: #2080f7;
  border-radius: 0.08rem;
  width: 0.8rem;
  height: 0.26rem;
}

.text_32 {
  color: #ffffff;
  font-size: 0.12rem;
  font-family: OPPOSans;
  line-height: 0.12rem;
  letter-spacing: 0.019rem;
}

.group_18 {
  margin-right: 0.38rem;
  padding: 0.14rem 0;
}

.space-x-110 > *:not(:first-child) {
  margin-left: 1.1rem;
}

.space-y-6 > *:not(:first-child) {
  margin-top: 0.06rem;
}

.group_19 {
  margin-top: 0.39rem;
}

.group_20 {
  margin-right: 0.39rem;
  margin-top: 0.36rem;
  width: 9.34rem;
}

.view_18 {
  margin-top: 0.21rem;
}

.group_21 {
  margin-top: 0.39rem;
}

.space-x-81 > *:not(:first-child) {
  margin-left: 0.81rem;
}

.zhibo_box {
  &.section_2x {
    background-image: url("../../assets/3b233a72a222f0be4ccd74c62563f340.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .section_3x {
    padding: 0 0 0.4rem;
    background-color: #f3f6fa1a;
    box-shadow: inset -0.026rem -0.015rem 0.01rem #ffffff4d,
    inset 0px 0.03rem 0.01rem #ffffff4d;
  }

  .group_4x {
    padding-top: 0.4rem;
  }

  .section_4x {
    padding: 0.17rem 0.18rem;
    background-color: #ffffff;
    border-radius: 0.08rem 0.08rem 0px 0px;
  }

  .space-x-18x > *:not(:first-child) {
    margin-left: 0.18rem;
  }

  .text-wrapper_2x {
    padding: 0.06rem 0;
    background-color: #000000;
    border-radius: 0.06rem;
    width: 0.6rem;
    height: 0.26rem;
  }

  .font_3x {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.13rem;
  }

  .text_12x {
    color: #ffffff;
    font-weight: 300;
    line-height: 0.14rem;
  }

  .text_11x {
    color: #a1a9b2;
    font-weight: 300;
    line-height: 0.18rem;
  }

  .imagex {
    border-radius: 0px 0px 0.08rem 0.08rem;
    width: 6.64rem;
    height: 3.73rem;
  }

  .group_5x {
    margin: 0.13rem 0.08rem 0 0.17rem;
    width: 4.91rem;
  }

  .text_10x {
    color: #000000;
    font-size: 0.28rem;
    font-family: Adobe Heiti Std;
    line-height: 1.4;
    word-break: break-all;
  }

  .group_6x {
    padding: 0 0.04rem;
  }

  .view {
    margin-top: 0.12rem;
  }

  .text-wrapper_3x {
    padding: 0.04rem 0;
    background-color: #f54a4a;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .font_4x {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.13rem;
    font-weight: 300;
  }

  .text_13x {
    color: #ffffff;
    line-height: 0.12rem;
  }

  .text-wrapper_4x {
    padding: 0.04rem 0;
    background-color: #f9eceb;
    border-radius: 0.04rem;
    width: 1.73rem;
    height: 0.22rem;
  }

  .text_14x {
    margin-left: 0.07rem;
    color: #f54a4a;
  }

  .view_2x {
    margin-top: 0.08rem;
  }

  .space-x-8x > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text-wrapper_5x {
    padding: 0.04rem 0;
    background-color: #f4f7fe;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .text_15x {
    color: #2080f7;
    line-height: 0.11rem;
  }

  .text-wrapper_6x {
    padding: 0.04rem 0;
    background-color: #f4f7fe;
    border-radius: 0.04rem;
    height: 0.22rem;
  }

  .text_16x {
    margin-left: 0.08rem;
    color: #666666;
  }

  .group_7x {

    justify-content: space-between;
    width: calc(100% - 4.91rem - 0.25rem);
  }

  .font_5x {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_19x {
    color: #f75746;
    line-height: 0.13rem;
  }

  .text_17x {
    color: #f75746;
    font-size: 0.26rem;
    font-family: OPPOSans;
    line-height: 0.23rem;
    letter-spacing: 0.013rem;
  }

  .group_8x {
    line-height: 0.14rem;
    margin-left: .06rem;
  }

  .font_6x {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.13rem;
    color: #a1a9b2;
  }

  .text_20x {
    color: #f3f6fa;
    line-height: 0.11rem;
  }

  .text_18x {
    color: #f3f6fa;
    font-weight: 500;
    line-height: 0.14rem;
  }

  .dividerx {
    margin: 0.2rem 0.12rem 0 0.04rem;
    background-color: #f3f6fa;
    border-radius: 0.005rem;
    height: 0.01rem;
  }

  .group_9x {
    margin-top: 0.2rem;
    padding: 0 0.03rem;
    align-items: flex-start;
  }

  .text_21x {
    color: #000000;
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.18rem;
  }

  .text-wrapper_7x {
    padding: 0.06rem 0;
    background-color: #f3f6fa;
    border-radius: 0.04rem;
    width: 0.64rem;
    height: 0.26rem;
    margin-right: 0.14rem;
    cursor: pointer;

    &:nth-child(odd) {
      margin-left: 0.2rem;
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 0.14rem;
    }

    &.active {
      background-color: #2080f7;

      span {
        color: #fff;

      }
    }
  }

  .group_10x {
    width: 40%;
    flex-wrap: wrap;
  }

  .view_3x {
    margin-left: 0.18rem;
  }

  .text-wrapper_8x {
    margin-left: 0.13rem;
    padding: 0.06rem 0;
    background-color: #2080f7;
    border-radius: 0.04rem;
    width: 0.66rem;
    height: 0.28rem;
    border: solid 0.01rem #2080f7;
  }

  .text_22x {
    color: #f4f7fe;
  }

  .text_23x {
    line-height: 0.14rem;
    letter-spacing: 0.017rem;
  }

  .section_5x {
    margin: 0.2rem 0.12rem 0 0.04rem;
    padding: 0.2rem 0.19rem;
    background-color: #f3f6fa;
    border-radius: 0.08rem;
  }

  .group_11x {
    line-height: 0.15rem;
  }

  .text_24x {
    line-height: 0.14rem;
  }

  .font_7x {
    font-size: 0.14rem;
    font-family: OPPOSans;
    color: #000000;
  }

  .text_25x {
    line-height: 0.15rem;
  }

  .section_6x {
    background-color: #474747;
    border-radius: 0.01rem;
    width: 0.02rem;
    height: 0.22rem;
  }

  .view_4x {
    margin-left: 0.16rem;
  }

  .view_6x {
    margin-left: 0.2rem;
  }

  .text_26x {
    line-height: 0.15rem;
  }

  .text_29x {
    line-height: 0.08rem;
  }

  .view_5x {
    margin-left: 0.21rem;
  }

  .view_7x {
    margin-left: 0.19rem;
    margin-right: 0.59rem;
  }

  .text_27x {
    line-height: 0.14rem;
  }

  .text_28x {
    color: #000000;
  }

  .text-wrapper_9x {
    border-radius: 0.04rem;
    width: 2.83rem;
    margin-top: 0.4rem;
    height: 0.45rem;
  }

  .text_30x {
    font-size: 0.18rem;
    font-weight: 300;
    line-height: 0.17rem;
    letter-spacing: 0.025rem;
  }

  .divider {
    margin: 0.2rem 0.12rem 0 0.04rem;
  }
}

.introduction_box {
  &.group_12 {
  }

  .section_7q {
    background-color: #f3f6fa;
    border-bottom: solid 0.01rem #2080f7;
  }

  .text-wrapper_10q {
    padding: 0.12rem 0;
    background-color: #2080f7;
    width: 0.97rem;
    height: 0.38rem;
  }

  .font_8q {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #000000;
  }

  .text_31q {
    color: #ffffff;
    line-height: 0.16rem;
  }

  .text-wrapper_11q {
    padding: 0.12rem 0;
    background-color: #f3f6fa;
    width: 0.97rem;
    height: 0.38rem;
  }

  .text_32q {
    line-height: 0.16rem;
  }

  .view_8q {
    margin-right: 7.92rem;
  }

  .group_13q {
    margin-top: 0.4rem;
    width: 0.9rem;
  }

  .space-x-13q > *:not(:first-child) {
    margin-left: 0.13rem;
  }

  .section_8q {
    background-color: #2080f7;
    width: 0.04rem;
    height: 0.22rem;
  }

  .font_9q {
    font-size: 0.18rem;
    font-family: SourceHanSansSC;
    line-height: 0.17rem;
    font-weight: 500;
    color: #2b2c30;
  }

  .text_33q {
    line-height: 0.18rem;
    text-transform: uppercase;
  }

  .group_14q {
    flex-wrap: wrap;

    audio {
      margin-top: 0.18rem;
    }

    .te_con {
      display: flex;
      align-items: center;
      margin-left: 0.19rem;
      margin-top: 0.18rem;

      &:first-child {
        margin-left: 0;
      }

      span {
        margin-left: 0.09rem;
      }
    }
  }

  .image_2q {
    width: 0.28rem;
    height: 0.29rem;
  }

  .font_10q {
    font-size: 0.14rem;
    font-family: SourceHanSansSC;
    line-height: 0.13rem;
    font-weight: 300;
    color: #1a1a1a;
  }

  .text_34q {
    line-height: 0.14rem;
  }

  .group_15q {
    margin-top: 0.4rem;
    width: 0.91rem;
  }

  .divider_2q {
    background-color: #f3f6fa;
    height: 0.01rem;
  }

  .view_9q {
    margin-top: 0.2rem;
  }

  .group_16 {
    padding: 0.19rem 0;
    width: 7.03rem;
  }

  .text_35q {
    line-height: 0.16rem;
  }

  .text_36q {
    line-height: 0.16rem;
  }

  .group_17q {
    padding: 0.19rem 0;
    width: 7.37rem;
  }

  .text_37q {
    line-height: 0.16rem;
  }

  .text_38q {
    line-height: 0.16rem;
  }

  .group_18q {
    padding: 0.17rem 0 0.14rem;
    border-top: solid 0.01rem #f3f6fa;
    border-bottom: solid 0.01rem #f3f6fa;
  }

  .text_39q {
    margin-top: 0.04rem;
    line-height: 0.16rem;
  }

  .group_19q {
    margin-right: 0.14rem;
  }

  .space-x-7q > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .text_40q {
    margin-top: 0.04rem;
    line-height: 0.16rem;
  }

  .group_20q {
    line-height: 0.24rem;
    height: 0.48rem;
  }

  .font_11q {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.24rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .group_21q {
    padding-top: 0.4rem;
    justify-content: space-between;

    audio {
      margin-left: .2rem;
    }

    > div {
      &:first-child {
        display: flex;
        align-items: center;

        span {
          margin-left: .14rem;
        }
      }
    }

    .btn_sty {
      width: 1.5rem;
    }
  }

  .int_info {
    margin-top: 50px;

    ::v-deep p {
      height: auto !important;
    }
  }


  .font_5q {
    font-size: 0.16rem;
    line-height: 1;
    font-weight: 300;
  }

  .group_16q {
    margin-top: .18rem;
    flex-wrap: wrap;

    .font_5q {
      padding: 0.2rem 0;
      width: 50%;
      border-top: 1px solid #f3f6fa;
    }
  }
}

.purchased_box {
  &.group_1z {
    padding-top: 0.4rem;
  }

  .font_3z {
    font-size: 0.18rem;
    line-height: 1;
  }

  .text_18z {
    font-weight: 500;
    line-height: 0.18rem;
    text-transform: uppercase;
  }

  .group_6z {
    padding: 0 0.02rem;
    margin-top: 0.2rem;
  }

  .section_10z {
    padding: 0.18rem 0.16rem 0.18rem 0.2rem;
    background-color: #f3f6fa;
  }

  .image_3z {
    width: 0.34rem;
    height: 0.34rem;
  }

  .text_21z {
    font-weight: 300;
    line-height: 0.2rem;
  }

  .space-x-8z > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .image_4z {
    width: 0.15rem;
    height: 0.09rem;
  }

  .text_22z {
    line-height: 0.13rem;
  }

  .section_11z {
    padding: 0 0.16rem 0.47rem 0.2rem;
    border: solid 0.01rem #f3f6fa;
  }

  .group_7z {
    padding: 0.15rem 0 0.2rem;
    border-bottom: solid 0.01rem #f3f6fa;
  }

  .text_24z {
    line-height: 0.15rem;
  }

  .font_6z {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 300;
    color: #a1a9b2;
  }

  .text_27z {
    line-height: 0.15rem;
  }

  .section_12z {
    background-color: #a1a9b2;
    width: 0.02rem;
    height: 0.08rem;
  }

  .text_28z {
    margin-left: 0.08rem;
    line-height: 0.13rem;
  }

  .text-wrapper_4z {
    margin-right: 0.05rem;
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
  }

  .font_5z {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.02rem;
    line-height: 0.14rem;
    font-weight: 300;
  }

  .group_8z {
    padding: 0.2rem 0;
  }

  .space-x-9z > *:not(:first-child) {
    margin-left: 0.09rem;
  }

  .space-x-9z {
    cursor: pointer;

    img {

      transform: rotate(180deg);
      transition: transform .3s ease-in-out;
    }

    &.on {
      img {
        transform: none;
      }
    }
  }

  .text_29z {
    line-height: 0.13rem;
  }

  .divider {
    margin-left: 0.34rem;
    margin-right: 0.05rem;
    background-color: #edeff2;
    height: 0.01rem;
  }

  .group_9z {
    padding: 0.2rem 0.06rem 0.2rem 0.34rem;
  }

  .space-y-7z > *:not(:first-child) {
    margin-top: 0.07rem;
  }

  .image_5z {
    width: 0.17rem;
    height: 0.17rem;
  }

  .text_30z {
    margin-left: 0.09rem;
    color: #1a1a1a;
  }

  .text-wrapper_5z {
    margin-left: 0.17rem;
    padding: 0.05rem 0 0.08rem;
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
    border: solid 0.01rem #2080f7;
  }

  .group_10z {
    margin-top: 0.2rem;
    padding: 0 0.34rem;
  }

  .space-x-14z > *:not(:first-child) {
    margin-left: 0.14rem;
  }

  .group_11z {
    margin-top: 0.19rem;
    padding-left: 0.33rem;
    padding-right: 0.05rem;
  }

  .space-x-19z > *:not(:first-child) {
    margin-left: 0.19rem;
  }

  .section_13z {
    padding: 0.2rem 0 1.56rem;
    flex: 1 1 3.84rem;
    background-color: #edeff2;
    width: 3.84rem;
    height: 2.25rem;
  }

  .section_16z {
    margin: 0 0.2rem;
    padding-left: 0.14rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
  }

  .image_7z {
    border-radius: 0.08rem;
    width: 0.8rem;
    height: 0.5rem;
  }

  .section_14z {
    padding: 0.2rem 0.13rem 1rem;
    flex: 1 1 3.84rem;
    background-color: #edeff2;
    width: 3.84rem;
    height: 2.25rem;
  }

  .space-y-6z > *:not(:first-child) {
    margin-top: 0.06rem;
  }

  .section_15z {
    margin: 0 0.06rem;
    padding-left: 0.2rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
  }

  .image_8z {
    width: 0.8rem;
    height: 0.5rem;
  }

  .image_6z {
    width: 0.14rem;
    height: 0.14rem;
  }

  .pos_2 {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.teacher_box {
  .tex_box {
    margin-top: 0.4rem;
    display: flex;
    flex-wrap: wrap;
  }

  .view_4[data-v-087d42bb] {
    margin: 0.12rem 0.1rem 0;
  }

  .space-x-14 {
    justify-content: space-between;
  }

  .section_16 {
    padding: 0.17rem 0.11rem 0.14rem 0.14rem;
    border-radius: 0.08rem;
    background-image: url("../../assets/be027162f936462babeb6fa17898893c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .section_36 {
    border-radius: 0.2rem;
    background-image: url("../../assets/a7e9f0c215d52a7a2d9c1a46d18fc7ce.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 4.38rem;
    padding-bottom: 0.37rem;
    margin: 0 -0.23rem;
  }

  .section_37 {
    padding: 0.2rem 0.1rem 0;
    background-color: #ffffff;
    border-radius: 0.2rem;
    width: 3.76rem;
  }

  .group_62 {
    margin: 0 0.1rem;
  }

  .image_6 {
    border-radius: 0.08rem;
    width: 1.2rem;
    height: 1.6rem;
  }

  .space-x-14 {
    justify-content: space-between;
  }

  .font_16 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.2rem;
  }

  .group_20 {
    padding-bottom: 0.03rem;
    width: 0.84rem;
    margin: 0;
  }

  .space-x-14 > *:not(:first-child) {
    margin-left: 0.14rem;
  }

  .space-x-12 > *[data-v-c2896e52]:not(:first-child) {
    width: calc(100% - 1.32rem);
  }

  .text-wrapper_12 {
    padding: 0.04rem 0;
    border-radius: 0px 0.02rem 0.02rem 0px;
    background-image: url("../../assets/545541c7ee13cf03507f1f17b48d7229.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.74rem;
    height: auto;
    background-color: transparent;
  }

  .font_17 {
    font-size: 0.14rem;
    letter-spacing: 0.017rem;
    line-height: 0.22rem;
    color: #2b2c30;
    height: 1.32rem;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .image_7 {
    width: 0.24rem;
    height: 0.25rem;
  }

  .pos_12 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .group_63 {
    margin-top: 0.1rem;
    height: 0.5rem;
  }

  .group_64 {
    margin-bottom: -0.34rem;
  }


  .group_61 {
    margin-top: 0.19rem;
    width: 0.04rem;
  }

  .font_8 {
    font-size: 0.12rem;
    font-family: OPPOSans;
    line-height: 0.12rem;
    color: #f55f18;
    padding-left: 0.17rem;
  }

  .section_1 {
    background-color: #f55c13;
    border-radius: 0px 0.02rem 0.02rem 0px;
    height: 0.14rem;
  }

  .view_4 {
    margin: 0.12rem 0.1rem 0;
  }

  .image_8 {
    margin-top: 0.03rem;
    width: 0.04rem;
    height: 0.46rem;
  }

  .group_24 {
    margin-top: 0.19rem;
    padding: 0.24rem 0.32rem 0.19rem;
    border-top: solid 0.01rem #e0e0e0;
  }

  .group_25 {
    width: 1.47rem;
  }

  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }

  .font_21 {
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    line-height: 0.14rem;
    color: #666666;
  }

  .text_44 {
    margin-left: 0.24rem;
    margin-top: 0.09rem;
  }

  .font_18 {
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.3rem;
    color: #2b2c30;
  }

  .font_20 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.14rem;
  }

  .space-x-4 > *:not(:first-child) {
    margin-left: 0.04rem;
  }

  .font_19 {
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.25rem;
  }

  .font_20 {
    font-size: 0.14rem;
    line-height: 1;
  }

  .space-x-7 > *[data-v-087d42bb]:not(:first-child) {
    margin-left: 0.07rem;
  }
}

.reply_box_case {
  border-bottom: 1px solid rgba(243, 246, 250, 1);

  .reply_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.13rem 0;

    .reply_left {
      width: 1.93rem;
      padding-right: 0.2rem;

      .reply_time {
        font-size: 0.12rem;
      }
    }

    font-size: 0.14rem;
    line-height: 1.714;

    .reply_right {
      text-align: justify;
      width: calc(100% - 1.93rem);
      padding-right: 0.55rem;
    }
  }
}

.purchased_box {
  .image_9 {
    margin: .08rem;

    img {
      width: 0.64rem;
      height: 0.34rem;
    }
  }
}

.image_9 {
  margin-right: 0.03rem;
  border-radius: 0.08rem;
  width: 0.64rem;
  height: 0.34rem;
  margin-top: 0.23rem;

  img {
    cursor: pointer;

  }

  &.on {
    border: 2px solid #2080f7;
  }
}


.lb_box {
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .swiper-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0.16rem;
  }
}

.evaluate_case {
  margin-bottom: .41rem;

  + .section_6 {
    margin-top: 0 !important;
  }
}

.page {
  .font_sty {
    margin-top: 0.12rem;
    line-height: 1.22;
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    color: #a1a9b2;

    img {
      width: 0.04rem;
      height: 0.14rem;
      margin-right: 0.07rem;
      vertical-align: middle;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .space-x-317x {
    margin-top: .1rem;
  }

  .sha_video {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .sha_bg {
      background-color: rgba(0, 0, 0, .8);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .sha_video_con {
      position: absolute;
      //width: 75%;
      padding: .5rem;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: .4rem;

      .sha_click {
        width: .4rem;
        height: .4rem;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .3s ease;

        &:hover {
          transform: rotate(90deg);
        }

        img {

          display: block;
          width: 45%;
        }
      }

      .sha_video_box {
        max-width: 70vw;
        max-height: 70vh;
        display: block;
        object-fit: contain;


      }
    }

  }

  @media screen and (orientation: portrait) {

    .cou_d_t {
      flex-wrap: wrap;

      .section_4_left {
        width: 100%;

        .text-wrapper_2 {
          padding: 0.1rem 0;
          height: auto;
          width: .8rem;

          .text_12 {
            font-size: .2rem;
            line-height: 1;
          }
        }

        .text_11 {
          margin-right: 0;
          font-size: .2rem;
        }

      }

      .section_4_right {
        width: 100%;
        padding: .3rem;
        height: auto;

        .text_10 {
          font-size: .28rem;
          line-height: 1.35;
        }

        .view_2 {
          margin-top: .2rem;
        }

        .text-wrapper_5 {
          width: 1.1rem;
          height: 0.33rem;
          padding: 0;

          .font_4 {
            font-size: 0.2rem;
            line-height: 0.33rem;
          }
        }

        .space-x-8 > *[data-v-c2896e52]:not(:first-child) {
          margin-left: .2rem;
        }

        .text-wrapper_6 {
          height: 0.33rem;
          padding: 0 0.2rem;

          .text_16 {
            font-size: 0.2rem;
            margin: 0;
            line-height: 0.33rem;
          }
        }

        .font_5, .font_6, .font_1 {
          line-height: 1;
          font-size: .2rem;
        }

        .text_17 {
          font-size: .32rem;
          line-height: 1;
        }

        .group_5 {
          margin-top: .2rem;
        }

        .text-wrapper_7 {
          margin-top: .3rem;
          margin-bottom: .3rem;
          height: auto;
          padding: 0;
        }

        .text_21 {
          font-size: .24rem;
          line-height: .6rem;
        }

        .text_22 {
          font-size: .2rem;
          margin-left: 0;
        }

        .group_7 {
          padding-left: 0;
          padding-right: 0;

          .space-x-7 {
            margin-top: 0;
          }

          .group_8 {
            margin: 0;
            width: calc(100% - .9rem);
            padding-left: .2rem;

            .font_7 {
              font-size: .32rem;
            }

            .space-y-16 {
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;

              span {
                margin-top: .2rem;
                width: 50%;
                font-size: .2rem;
              }
            }

          }

        }

      }


    }

    .group_2 .pub_case > .section_5 {

      display: flex;

      .font_5 {
        font-size: .2rem;
        line-height: .6rem;
      }

      .text-wrapper_8 {
        width: auto;
        height: .6rem;
        padding: 0 .3rem;

      }

    }


    .introduction_box {
      .font_9q {
        font-size: .24rem;
        line-height: 1;


      }

      .group_21q {
        > div {

          max-width: 100%;

          &:first-child {
            flex-wrap: wrap;
            align-items: center;
          }
        }

        audio {
          margin-left: 0;
          margin-top: .2rem;
        }
      }

      .image_2q {
        width: .5rem;
        height: .5rem;
      }

      .group_14q {
        .te_con {
          margin-top: .2rem;
          margin-left: 0;
          margin-right: .3rem;

          span {
            margin-left: .16rem;
            font-size: .2rem;
          }
        }
      }

      .group_16q {
        margin-top: .2rem;

        .font_5q {
          width: 100%;
          font-size: .2rem;
          line-height: 1.5;
        }
      }

      .int_info {
        margin-top: .4rem;

        img {

        }
      }

    }

    .purchased_box {
      .text_18z {
        line-height: 1;
        font-size: .24rem;
      }

      .image_3z {
        width: .5rem;
        height: .5rem;
      }

      .font_3z {
        font-size: .22rem;
      }

      .font_2z {
        font-size: .22rem;
        width: calc(100% - .5rem);
        line-height: 1.3;
        margin-left: 0;
        padding-left: .1rem;
      }

      .space-x-14z {
        width: calc(100% - 1.2rem);
      }

      .space-x-9z {
        width: auto;

        .font_2z {
          width: auto;
        }
      }

      .font_6z {
        font-size: .2rem;
        line-height: 1.3;
      }

      .space-y-7z {
        width: calc(100% - 1rem);
      }

      .text-wrapper_5z {
        padding: .1rem .2rem;
        width: auto;
        height: auto;
        font-size: .24rem;
        line-height: 1;
      }

      .font_5 {
        font-size: .2rem;
      }

      .image_5z {
        width: .2rem;
        height: .2rem;
      }

      .text_30z {
        font-size: .2rem;
      }
      .cursor_s{
        display: flex;
        align-items: center;
      }
    }

    .evaluate_case {
      .group_1 {
        width: 100%;

        .space-x-36 {
          width: 100%;
        }
      }

      .pin_left {
        .pin_name {
          font-size: .2rem;
        }
      }

      .font_4 {
        font-size: .2rem;
        line-height: 1;
        margin: 0;
      }

      .text-wrapper_11 {
        padding: .1rem .16rem;
        margin-bottom: .1rem;
        margin-right: .1rem;
        height: auto;
      }


    }


    .zhibo_box {
      .group_4x {
        flex-wrap: wrap;
      }

      .group_7x {
        width: 100%;
      }

      .group_5x {
        width: 100%;
        padding: .3rem;
        margin: 0;
      }

      .text_10x {
        line-height: 1.35;
      }

      .space-x-8x > *[data-v-c2896e52]:not(:first-child) {
        margin-left: .2rem;
      }

      .view_2x {
        margin-top: .2rem;
        padding: 0;

        .text-wrapper_5x {
          width: 1.1rem;
          height: 0.45rem;
          padding: 0;

          .font_4x {
            font-size: 0.24rem;
            line-height: 0.45rem;
          }
        }

        .text-wrapper_6x {
          height: 0.45rem;
          padding: 0 0.2rem;

          .text_16x {
            font-size: 0.24rem;
            margin: 0;
            line-height: 0.45rem;
          }
        }


      }

      .space-x-317x{
        margin-top: .2rem;

      }

      .font_5x,.font_6x,.font_3x,.font_7x{
        font-size: .24rem;
        line-height: 1;
      }
      .text_17x{
        font-size: .46rem;
        line-height: 1;
      }
.group_8x{
  height: auto;
}
      .text-wrapper_9x{
        height: auto;
        padding: 0;
        width: 100%;
        .text_30x{
          font-size: 0.24rem;
          line-height: 0.6rem;
        }
      }
      .text-wrapper_2x{
        padding: 0.1rem 0;
        height: auto;
        width: 0.8rem;
      }
      .text_11x{
        font-size: .2rem;
      }
    }
    .teacher_box{
      .tex_box {
        flex-wrap: wrap;

        .section_36 {
          width: 100%;
          margin: 0 0 .4rem;
          padding: 0;
          box-shadow: 0 0 .12rem 0 rgba(0, 0, 0, .1);

          &:last-child {
            margin-bottom: 0;
          }

          .section_37 {
            width: 100%;
            padding: .3rem;
          }
        }
        .font_16 {
          font-size: .32rem;
          line-height: 1;
        }
        .font_sty {
          font-size: .2rem;
          width: 50%;
          margin-top: .1rem !important;
        }
        .group_60 {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: .1rem;
          margin-bottom: 0;
        }
        .image_8 {
          width: .057rem;
          height: .2rem;
        }
        .group_63 {
          height: auto;
          margin: 0;
        }
        .font_17 {
          font-size: .2rem;
          line-height: 1.5;
          display: block;
          height: calc(9em + .2rem);
          padding: .2rem .2rem 0;
          margin-bottom: .2rem;
        }
        .image_9 {
          width: 1.28rem;
          height: .68rem;

          img {
            width: 100%;
            height: 100%;
          }

        }
        .font_20, .font_21 {
          font-size: .2rem;
        }
        .text_44 {
          margin-left: 0;
        }
        .font_19 {
          font-size: .32rem;
        }
        .group_20 {
          width: 1.5rem;

          .image_7 {
            width: 0.36rem;
            height: 0.375rem;
          }

          .text-wrapper_12 {
            width: 1.3rem;
            padding: .05rem .15rem;
          }

          .font_8 {
            font-size: .2rem;
            line-height: 1;
          }
        }

      }
    }
    .zhibo_box {
      .text_21x{
        font-size: .28rem;
        line-height: .45rem;
      }
      .group_11x{
        height: auto;
      }
      .group_10x{
        width: calc(100% - 1.2rem);
        margin-left: .2rem;
      }
      .text-wrapper_7x{
        height: .45rem;
        padding:  0 .4rem;
        margin-left: 0;
        .font_6x{
          line-height: .45rem;
          font-size: .26rem;
        }
      }
    }

    .course_box .section_cou{
      margin-bottom: 0;
    }
    .teacher_lb_box{
      width: 100%;
      position: relative;
    }
   .teacher_box  .tex_box{
      flex-wrap: nowrap;
    }
    .swiper-button-prev,.swiper-button-next{
      width: .47rem;
      height: .7rem;
      margin-top: -.35rem;
      background-size: .27rem auto;
    }
    .swiper-button-prev{
      left: 0;
      margin-left: -.4rem !important;
    }
    .swiper-button-next{
      right: 0;
      margin-right: -.4rem !important;
    }

  }

}


</style>
