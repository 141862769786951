<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col space-y-40">


      <div class="pub_-flex-row pub_-self-center group_2 space-x-40 pub_case">

        <left-nav></left-nav>
        <div class="tim_con">
          <div class="sear_case">
            <div class="sear_box">
              <img
                class="sea_image"
                src="../../assets/8e844837e61b01c621820878e260fae4.png"
              /> <input type="text" placeholder="请输入订单号或课程名称进行搜索" v-model="search.name"/>
            </div>
            <button class="btn_sty" @click="ajaxH()">搜索</button>
          </div>


          <div class="con_con">
            <div class="con_block" v-for="(item,i) in showList" :key="i">

              <div class="con_left">

                <div class="con_det">
                  <img :src="item.firstPicturePath" class="con_img" alt="课程图片">
                  <div class="con_txt">
                    <div class="k_name ord_txt">课程名称：<span>{{ item.title }}</span></div>
                    <div class="con_l_btm">
                      <div class="ord_txt m_sty">

                        <span class="text_21" v-if="item.status == 1 || item.status == 2 ||item.status == 3">等待上课</span>
                        <span class="text_21_3" v-else-if="item.status == 4">上课中</span>
                        <span class="text_21_2" v-else>已结束</span>
                      </div>
                      <div class="ord_txt pc_sty">教师：<span>{{ item.teacherName }}</span></div>
                      <div class="ord_txt"
                           v-if="item.startTime != '' && item.startTime != undefined && item.startTime != null">
                        时间：<span>{{ item.startTime }}</span></div>
                    </div>

                    <div class="con_right m_sty">
                      <div  v-if="item.isComment == 0" class="btn_sty"
                            @click="handleClick(item.id)">去评价
                      </div>
                      <div v-else class="btn_sty" @click="handleClickLook(item.commentId, i)" >查看评价</div>


                      <div v-if="item.status == 1 || item.status == 2 ||item.status == 3" class=" btn_sty"
                           @click="cancelCooking($event,item.id)">取消预约
                      </div>
                      <div v-else-if="item.status == 4" class="btn_sty" @click="getAccessToken(item.id)">去上课</div>

                      <a v-else class="btn_sty" :href="item.videoUrl" target="_blank">观看录像</a>



                      <div  class="btn_sty" v-if="item.canUploadProfile  == 1" style="margin-top: .15rem" @click="showAAl(i)">补充资料</div>

                    </div>

                  </div>

                </div>

              </div>
              <div class="con_center pc_sty">
                <div class="ord_txt">
                  <span class="m_sty">上课状态：</span>
                  <span class="text_21" v-if="item.status == 1 || item.status == 2 ||item.status == 3">等待上课</span>
                  <span class="text_21_3" v-else-if="item.status == 4">上课中</span>
                  <span class="text_21_2" v-else>已结束</span>
                </div>
              </div>
              <div class="con_right pc_sty">

                <div  v-if="item.isComment == 0" class="btn_sty" style="margin-bottom: .15rem"
                     @click="handleClick(item.id)">去评价
                </div>
                <div v-else class="btn_sty" @click="handleClickLook(item.commentId, i)" style="margin-bottom: .15rem">查看评价</div>

                <div v-if="item.status == 1 || item.status == 2 ||item.status == 3" class="btn_sty"
                     @click="cancelCooking($event,item.id)">取消预约</div>
                <div v-else-if="item.status == 4" class="btn_sty" @click="getAccessToken(item.id)">去上课</div>
                <a v-else class="btn_sty" :href="item.videoUrl" target="_blank" >观看录像</a>

                <div class="btn_sty" v-if="item.canUploadProfile  == 1" style="margin-top: .15rem" @click="showAAl(i,item.id)">补充资料</div>

              </div>


            </div>


            <Pagination
              @change="changePage"
              :current="pagination.p"
              :pageSize="pagination.pageSize"
              :total="total"
              :hideOnSinglePage="hideOnSinglePage"
              :showQuickJumper="true"
              :showTotal="true"
              placement="right"
              v-if="pagination && total"
            />
          </div>

        </div>


        <div style="display: none" class="pub_-flex-col pub_-justify-start pub_-flex-auto pub_-relative group_3">
          <div class="pub_-flex-row pub_-items-center zind_2">
            <div class="pub_-flex-row pub_-justify-center pub_-items-center pos_2 space-x-9">
              <img class="image" src="../../assets/5b14ff003e58d39eaf5d022dc6f9287f.png"/>
              <span class="text_11">课堂目录</span>
            </div>
            <div class="pub_-flex-row pub_-justify-center pub_-items-center section_6 space-x-12">
              <img class="image_2" src="../../assets/ef7fcd2321b75af50cb33d60dc0dd3c5.png"/>
              <span class="font_2 text_12">直播列表</span>
            </div>
          </div>
          <div class="pub_-flex-col pub_-justify-start group_4 pos">
            <div class="pub_-flex-col section_7">
              <span class="pub_-self-start font_4 text_16">当前学习</span>
              <div class="pub_-flex-col group_5 view">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_8">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/3e8a48bc9cdaad94fddfbb3426f68413.png"/>
                    <span class="font_5 text_18">雅思口语流利说—哑巴英语逆袭native speaker</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-8">
                    <img class="pub_-shrink-0 image_4" src="../../assets/8e445195f8cc567d7bf64ffd9b317782.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_9">
                  <div class="pub_-flex-row space-x-14">
                    <img class="image_3" src="../../assets/b21f70f40c226b3dfc01620d7f6705d9.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="font_1">雅思口语流利说—哑巴英语逆袭native speaker</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_22">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                    <span class="font_7 text_21">去观看</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_9">
                  <div class="pub_-flex-row space-x-14">
                    <img class="image_3" src="../../assets/f79135d5de2c417318d5a18f5e68f504.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="font_1">雅思口语流利说—哑巴英语逆袭native speaker</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_25">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
              </div>
              <span class="pub_-self-start font_4 text_16 text_26">全部课程</span>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11 view_2">
                <div class="pub_-flex-row pub_-items-center space-x-14">
                  <img class="pub_-shrink-0 image_3" src="../../assets/11b1b88d00f52b45216691815aeebd59.png"/>
                  <span class="font_10 text_27">新概念·英语学习方法指导</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_5" src="../../assets/5216d70d677eae833a01798cbe022f00.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11">
                <div class="pub_-flex-row pub_-items-center space-x-15">
                  <img class="pub_-shrink-0 image_3" src="../../assets/2520415e619b5024bba8b01ba88cd354.png"/>
                  <span class="font_10 text_27">Excuse me主格和宾格的用法</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_4" src="../../assets/4e5400ad0956a832abe8fe86b163eabd.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-col group_5">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_12">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/076392ef0f4cb99166dc00199a175e66.png"/>
                    <span class="font_5">5-6国籍的表达,冠词的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/25352ac6c17ed5026ed9ef15bb57835d.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/7e4bf2e141e587237c60b353bae37489.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">5国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_22">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                    <span class="font_11">去回放</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/48e611bc412256ff8b1a4c46ec5f1818.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">6国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_25">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                    <span class="font_11">去回放</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_14">
                  <div class="pub_-flex-row pub_-items-center space-x-15">
                    <img class="pub_-shrink-0 image_3" src="../../assets/bc0ed5a869021a0616ce04ec1b97657c.png"/>
                    <span class="font_10 text_28">Excuse me主格和宾格的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/8d72c553844da03d2db4867e3c28459c.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
              </div>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11">
                <div class="pub_-flex-row pub_-items-center space-x-15">
                  <img class="pub_-shrink-0 image_3" src="../../assets/44ff3cf207f40a48520ed4f26ac20f21.png"/>
                  <span class="font_10 text_27">Excuse me主格和宾格的用法</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_4" src="../../assets/8e736d2645f5a445c3de971393e6b508.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-col group_6">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_12">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/cba82cccee082dfd5094c253e7b10238.png"/>
                    <span class="font_5">5-6国籍的表达,冠词的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/e2e5821d259871871c5ad93089a99928.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/f0b26363d2fa679ca2208416e321e0ab.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">5国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_29">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/4f0bb8a9385fc60523d1e147a0068114.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">6国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_30">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="aal_sha" v-if="aalCon == true">
      <div class="sha_bg"></div>
      <div class="aal_case">
        <div class="aal_t">
          <div class="cur_tit">补充资料</div>
          <img
            class=" image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="aalCon= false"
          />
        </div>

        <div class="aal_con_case">
          <ul class="all_con">
            <li v-for="(item,index) in aallist" :key="index">
              <h4 class="all_n">{{ item.name }}</h4>
              <div class="cursor_s "
                   @click="downloadBtn(item.filePath)">
                <img
                  class="pub_-shrink-0 image_5z"
                  src="../../assets/f69c904853130b775f5319952296b24a.png"
                />
                <span class="font_1z text_30z">学习资料</span>
              </div>

            </li>



            <li>
              <div class="el-upload__files btn_sty">
                <el-upload
                  class="avatar-uploader"
                  action="/api.upload/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  accept=""
                  :data="{ fileType: 'file' }"
                >
                  补充资料
                </el-upload>
              </div>
            </li>


          </ul>

        </div>

      </div>
    </div>


<!--    弹窗-->
    <div class="cur_sha" v-if="eval === true">
      <div class="cur_mask"></div>
      <div class="cur_con">
        <div class="cup_top">
          <div class="cur_tit"></div>
          <img
            class=" image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="eval = false"
          />
        </div>
        <div class="evaluate_box" >
          <div class="rod_case">
            <div class="rod_n">赠送礼物：</div>
            <div class="rod_con">
              <div class="btn_radio" :class="giftsS == item.num ? 'on':''" v-for="(item,i) in giftList" :key="i"
                   :label="item.id" @click="handleRadioClick(item.num,)">
                <i></i><img :src="item.img" alt="">{{ item.name }}
              </div>


            </div>
          </div>


          <div class="acsi_case">
            <div class="acsi_block">
              <div class="acsi_n">对教师授课情况是否满意？</div>
              <div class="roa_box">
                <template>
                  <el-radio v-model="teaSatisfaction" label="1">满意</el-radio>
                  <el-radio v-model="teaSatisfaction" label="0">不满意</el-radio>
                </template>
              </div>
            </div>
            <div class="acsi_block">
              <div class="acsi_n">对课程内容是否满意？</div>
              <div class="roa_box">
                <template>
                  <el-radio v-model="courseSatisfaction" checked label="1">满意</el-radio>
                  <el-radio v-model="courseSatisfaction" label="0">不满意</el-radio>
                </template>
              </div>
            </div>
            <div class="acsi_block">
              <div class="acsi_n">对网络情况是否满意？</div>
              <div class="roa_box">
                <template>
                  <el-radio v-model="networkSatisfaction" checked label="1">满意</el-radio>
                  <el-radio v-model="networkSatisfaction" label="0">不满意</el-radio>
                </template>
              </div>
            </div>


          </div>


          <div class="evaluate_con">
            <el-input
              type="textarea"
              placeholder="请对课程做出评价"
              v-model="textarea"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </div>
          <div class="evaluate_btm">
            <div class="block">
              <el-rate v-model="starN"></el-rate>
            </div>
            <div class="btn_sty" @click="evaSub()">评论</div>
          </div>
        </div>

      </div>
    </div>
    <div class="cur_sha" v-if="evaled === true">
      <div class="cur_mask"></div>
      <div class="cur_con">
        <div class="cup_top">
          <div class="cur_tit">评价信息</div>
          <img
            class=" image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="evaled = false"
          />
        </div>

        <div class="evaluate_list">

          <div class="evaluate_block" v-for="(item,i) in commentList" :key="i">
            <div class="eva_img">
              <img :src="item.avatarPath" alt="">
              <div class="u_names">{{ item.nickname }}</div>
            </div>
            <div class="eva_con">{{ item.content }}</div>
            <div class="eva_btm">
              <div class="eva_time">{{ item.createTime }}</div>

              <div class="eva_reply btn_sty" @click="replySub(item.id)">回复</div>

            </div>


          </div>


        </div>

      </div>
    </div>


    <!-- 充值 -->
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
      v-if="topUP == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row section_6a space-x-463a">
          <span class="font_4a text_14a">余额充值</span>
          <img
            class="pub_-shrink-0 imagea"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="closeSha"
          />
        </div>
        <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a">充值金额</span>
          <div class="pub_-flex-row group_3a space-x-477a">
            <span class="text_11a text_21a">{{ numP }}</span>
            <!-- <img class="pub_-shrink-0 image_2a" src="../../assets/9c674996042f411fd048987216946219.png" /> -->
          </div>
          <div class="divider_2a"></div>

          <div class="pub_-flex-row group_4a space-x-16a">
            <div
              class="pub_-flex-row pub_-items-center section_8a space-x-7a"
              v-for="(item, i) in ruleList"
              :key="i"
              @click="selRul(item.id, item.payMoney)"
              :class="pas.ruleId == item.id ? 'on' : ''"
            >
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 section_11a"
              >
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center section_13a"
                >
                  <div class="pub_-flex-col pub_-justify-start text-wrapper_5a">
                    <span class="font_8a text_11a text_28a">￥</span>
                  </div>
                </div>
              </div>
              <span class="font_7a text_11a text_24a">{{ item.name }}</span>
            </div>
          </div>
          <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a">充值方式</span>

            <el-radio
              v-for="(item, i) in payChanne"
              :key="i"
              v-model="pas.payChannelId"
              :label="item"
            >
              <template v-if="item == 2">
                <img
                  class="image_3a image_4a"
                  src="../../assets/aa8eeff952b2db86c0b4584a0132d942.png"
                />
                <span class="font_10a text_11a text_35a">支付宝</span>
              </template>
              <!--              <template v-else-if="item == 1">-->
              <!--                <img-->
              <!--                  class="image_3a image_5a"-->
              <!--                  src="../../assets/a129daff2b6c1c1917ddd5efe52c95f1.png"-->
              <!--                />-->
              <!--                <span class="font_10a text_11a text_35a">微信</span>-->
              <!--              </template>-->
              <template v-else>
                <img
                  class="image_3a image_5a"
                  src="../../assets/be355aa026f71df00d26fb262cf8278e.png"
                />
                <span class="font_10a text_11a text_35a">余额</span>
              </template>
            </el-radio>
          </div>
          <!-- <img
            class="pub_-self-center image_7a"
            src="../../assets/9f632cb4ede9b6d32741cf5348eaf96d.png"
          /> -->

          <div class="btn_sty" @click="confirmRecharge">确认充值</div>
        </div>
      </div>
    </div>
    <!-- 确认充值 -->
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
      v-if="ackShow == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row section_6a space-x-463a">
          <span class="font_4a text_14a">确认充值</span>
          <img
            class="pub_-shrink-0 imagea"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="beforeDestroy()"
          />
        </div>
        <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a"
          >充值金额:<span>{{ numP }}</span></span
          >

          <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a"
            >充值方式:
              <!--              <span v-if="pas.payChannelId == 1">微信</span>-->
              <span v-if="pas.payChannelId == 2">支付宝</span>
              <span v-else>余额</span>
            </span>
          </div>


          <img v-if="pas.payChannelId == 1" class="pub_-self-center image_7a" :src="codeImg"/>
          <div v-else-if="pas.payChannelId == 2" class="hint_pay">支付中...</div>

          <!-- <div class="btn_sty">确认充值</div> -->
        </div>
      </div>


    </div>

    <!--    回复评论-->
    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
      v-if="replyS == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row section_6a space-x-463a">
          <span class="font_4a text_14a">回复评论</span>
          <img
            class="pub_-shrink-0 imagea"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="replyClose()"
          />
        </div>
        <div class="pub_-flex-col section_7a">
          <div class="evaluate_con">
            <el-input
              type="textarea"
              placeholder="请对课程做出评价"
              v-model="textarea"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </div>


          <div class="btn_sty" @click="replyComment()">评论</div>
        </div>
      </div>

    </div>


    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";
import QRCode from "qrcode";

export default {
  components: {NavBox, FootBox, LeftNav, Pagination},
  data() {
    return {
      ordShow: false,

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      showList: [], //显示的列表
      conC: [], //完整的列表

      search: {
        name: ''
      },
      //补充列表
      aallist: [],
      aalCon: false,
      itemId: '',

      starN: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],

    //  礼物
      teaSatisfaction: '1',
      courseSatisfaction: '1',
      networkSatisfaction: '1',
    //  充值
      topUP: false,
      ruleList: [],
      numP: 0,
      pas: {
        ruleId: "",
        payChannelId: "1",
      },
      payChanne: [],
      radio: "1",
      ackShow: false,
      codeImg: "",
      apiUrl: "http://39.100.254.122/api.order/getPayStatus",
      interval: 1000, // 每隔5秒轮询一次
      timer: null,
      data: null,
      surplusNum: 0,
      replyS: false,//回复
      replyId: '',//回复id

      //评价
      giftsS: null,//选择礼物
      giftList: [
        {
          img: require('../../assets/l001.png'),
          name: '1元',
          num: 1
        },
        {
          img: require('../../assets/l002.png'),
          name: '3元',
          num: 3
        },

        {
          img: require('../../assets/l003.png'),
          name: '10元',
          num: 10
        },
        {
          img: require('../../assets/l004.png'),
          name: '20元',
          num: 20
        },


      ],
      textarea: '',

      evalId:'',//（直播详情id）
      commentId:'',//（直播详情id）

      commentList:[],//评论列表

      eval:false,
      evaled:false,

      info:'',

    }
  },
  mounted() {
    this.ajaxH();
    this.ajaxP();
  },
  methods: {
    //获取余额
    async ajaxP() {
      let param = {

      };
      const {data: res} = await this.$http.post(
        "/api.student/getInfo",
        param
      );
      this.info = res.data;

    },

    async ajaxH() {
      let param = {
        keyword: this.search.name,
        isEnd: 1
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/getLiveCourseList",
        param
      );


      this.showList = res.data.data.slice(0, res.data.per_page);
      this.conC = res.data;
      // console.log(this.conC)
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;


    },
    async cancelCooking(e, id) {
      let param = {
        id: id
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/cancelLiveCourse",
        param
      );
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.ajaxH();
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }

    },
    showAAl(i,id) {
      // console.log(i, this.conC.data[i].profileList)
      this.aallist = this.conC.data[i].profileList;
      this.aalCon = true;
      this.itemId = id;
    },

    async getAccessToken(id) {//获取看直播课的授权码
      let param = {
        liveCourseDetailId:id
      };
      const {data: res} = await this.$http.post(
        "/api.shengwang/getAccessToken",
        param
      );

      console.log(res)

      let path = 'https://likesay.cn/liveBroadcast.html' + "?accessToken=" + res.data.accessToken;
      window.open(this.$router.resolve(path).href, '_blank');
    },


    goHref(e, id) { //打开新窗口看直播课程
      let path = '/room' + "?id=" + id;
      window.open(this.$router.resolve(path).href, '_blank');
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.conC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    selS(n, t, id) {
      this.value = "";
      this.typeN = n;
      this.typeT = t;
      this.wordOrder.contactType = id;
      this.selOn = false;
    },
    selShow() {
      if (this.selOn === false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },
    downloadBtn(str) {//下载课件
      const a = document.createElement('a'); // 创建一个HTML 元素
      let url = str;
      a.setAttribute('target', '_blank'); //target
      a.setAttribute('download', url); //download属性
      a.setAttribute('href', url); // href链接
      a.click(); // 自执行点击事件

    },
    //添加补充资料
    async addProfile( id, fileId) {
      let param = {
        id: id,
        file:fileId
      };
      // console.log(param)
      const {data: res} = await this.$http.post(
        "/api.myCourse/addProfile",
        param
      );
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        });

        // console.log(res)

      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }

    },

    handleAvatarSuccess(res, file) {
      // console.log(res)

      if (res.code == 0) {
        // this.$message({
        //   message: res.msg,
        //   type: "success",
        // });

        this.addProfile(this.itemId,res.data.fileId)


        this.aalCon = false;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }


      // this.form.avatar = res.data.fileId;

    },
    beforeAvatarUpload(file) {
      return true;
    },

//选择礼物
    handleRadioClick(label) {

      // console.log(label)
      if (this.giftsS === label) {
        this.giftsS = null;  // 如果已选中，点击则取消选中
      } else {
        this.giftsS = label;  // 否则选中
      }

      // console.log(this.giftsS)
    },
//回复评论弹窗
    replySub(id) {
      this.replyS = true;
      this.replyId = id;
    },
    replyClose() {
      this.replyS = false;
      this.textarea = '';
    },

    //充值
    selRul(id, name) {
      this.numP = name;
      this.pas.ruleId = id;
    },
    closeSha() {
      this.beforeDestroy()
      this.topUP = false;

    },
    openSha() {
      this.topUP = true;
    },
    async confirmRecharge() {
      let that = this;
      this.topUP = false;
      let param = this.pas;
      const {data: res} = await this.$http.post(
        "/api.recharge/placeOrder",
        param
      );
      // console.log(res.data);

      if (that.pas.payChannelId == '2') {
        window.open(res.data.data.url, '_blank');
      } else {
        const QRCode = require("qrcode");
        const text = res.data.qrCode;
        QRCode.toDataURL(text, function (err, url) {
          that.codeImg = url;
        });


      }
      this.ackShow = true;
      this.timer = setInterval(() => {
        this.pollData(res.data.orderId);
      }, this.interval);

    },
    async pollData(id) {
      const {data: res} = await this.$http.post("/api.order/getPayStatus", {id: id})
      // console.log(res)
      if (res.data.payStatus == 2) {
        this.evaSub()//支付成功后 再次提交评价
        this.beforeDestroy();
      }
    },
    beforeDestroy() {
      this.ackShow = false;
      // 清除轮询定时器
      clearInterval(this.timer);
    },
    async getRuleList() {//获取充值规则
      let params = {};
      const {data: rul} = await this.$http.post(
        "/api.recharge/getRuleList",
        params
      );
      // console.log(rul);
      this.ruleList = rul.data.ruleList;
      this.numP = this.ruleList[0].payMoney;
      this.pas.ruleId = this.ruleList[0].id;
      this.payChanne = rul.data.payChannelId.split(",");
      this.pas.payChannelId = this.payChanne[0];


    },
    //充值结束
    //评论
    async replyComment(id) {//评论

      let param = {
        commentId: this.commentId,
        content: this.textarea,
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/commentReply",
        param
      );
      // console.log(res)

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });

        this.replyS = false;
        this.evaled = false;
        this.textarea = '';
        this.ajaxH();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async evaSub() {//评论
      if (this.giftsS !== null) {//判断是否选择了礼物
        if (this.giftsS > this.info.balance) { //判断余额是否够买礼物
          this.$message({
            message: '余额不足,请充值',
            type: "warning",
          });

          this.topUP = true;
          return;
        }
      }
      let param = {
        liveCourseDetailId: this.evalId,
        star: this.starN, //评分
        content: this.textarea,//内容
        gift: this.giftsS,//礼物
        isSatisfactionByTeacher: this.teaSatisfaction,
        isSatisfactionByCourse: this.courseSatisfaction,
        isSatisfactionByNetwork: this.networkSatisfaction,
      };

      const {data: res} = await this.$http.post(  //获取老师
        "/api.myCourse/appraiseCourse",
        param
      );
      // console.log(res)

      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.starN = null;
        this.eval = false;
        this.textarea = '';
        this.ajaxH(this.id);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleClick(id) {
      console.log(id)
      this.ajaxP(id)
      this.eval = true;
      this.evalId = id;
    },
    handleClickLook(id,index) {
      this.evaled = true;
      this.commentId = id;
     this.commentList = this.conC.data[index].commentList;
    }
  },
};
</script>

<style scoped lang="scss">
.page {

  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.text {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_2 {
  font-weight: unset;
}

.space-y-40 {
  padding: .4rem 0 .8rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group_2 {
  width: 11.8rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.section_3 {
  background-color: #ffffff;
}

.space-x-17 > *:not(:first-child) {
  margin-left: 0.17rem;
}

.section_4 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #000000;
}

.text_10 {
  margin-right: 1.37rem;
  color: #2080f7;
  line-height: 0.14rem;
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_13 {
  line-height: 0.14rem;
}

.text_14 {
  margin-left: 0.17rem;
}

.text_15 {
  margin-left: 0.16rem;
}

.text_17 {
  margin-left: 0.17rem;
}

.text_19 {
  margin-left: 0.16rem;
}

.text-wrapper_3 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_20 {
  margin-left: 0.17rem;
}

.text_23 {
  margin-left: 0.17rem;
}

.text_24 {
  margin-left: 0.16rem;
}

.group_3 {
  padding-bottom: 10.88rem;
  width: 9.42rem;
}

.pos_2 {
  padding: 0.23rem 0 0.41rem;
  flex: 1 1 4.71rem;
  background-image: url('../../assets/b7a2b5731f97b0ad5722106f489663fd.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 4.71rem;
  height: 0.86rem;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}

.image {
  width: 0.22rem;
  height: 0.22rem;
}

.text_11 {
  color: #ffffff;
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  letter-spacing: -0.009rem;
}

.section_6 {
  padding: 0.24rem 0;
  flex: 1 1 4.71rem;
  background-color: #f8f8f8;
  width: 4.71rem;
  height: 0.68rem;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.12rem;
}

.image_2 {
  width: 0.15rem;
  height: 0.21rem;
}

.text_12 {
  letter-spacing: -0.009rem;
}

.group_4 {
  width: 9.42rem;
}

.pos {
  position: absolute;
  left: 0;
  right: 0;
  top: 0.68rem;
}

.section_7 {
  padding: 0.41rem 0.38rem 0;
  background-color: #ffffff;
  width: 9.42rem;
}

.font_4 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 500;
  color: #2b2c30;
}

.text_16 {
  line-height: 0.18rem;
  text-transform: uppercase;
}

.group_5 {
  padding: 0 0.02rem;
}

.view {
  margin-top: 0.19rem;
}

.section_8 {
  padding: 0.16rem 0.18rem;
  background-color: #f3f6fa;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.image_3 {
  width: 0.34rem;
  height: 0.34rem;
}

.font_5 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.19rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_18 {
  line-height: 0.2rem;
}

.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}

.image_4 {
  width: 0.15rem;
  height: 0.09rem;
}

.font_6 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
  font-weight: 300;
  color: #2b2c30;
}

.section_9 {
  padding: 0.16rem 0.18rem;
  border: solid 0.01rem #f3f6fa;
}

.space-y-6 > *:not(:first-child) {
  margin-top: 0.06rem;
}

.font_8 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #a1a9b2;
}

.section_10 {
  background-color: #a1a9b2;
  width: 0.02rem;
  height: 0.08rem;
}

.font_9 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
  font-weight: 300;
  color: #a1a9b2;
}

.text_22 {
  margin-left: 0.08rem;
}

.text-wrapper_4 {
  padding: 0.08rem 0;
  background-color: #2080f7;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
}

.font_7 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #dbdbdb;
}

.text_21 {
  color: #ffffff;
  line-height: 0.14rem;
}

.text_25 {
  margin-left: 0.08rem;
}

.text-wrapper_5 {
  padding: 0.05rem 0 0.09rem;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #dbdbdb;
}

.text_26 {
  margin-top: 0.4rem;
}

.section_11 {
  padding: 0.18rem 0.17rem 0.18rem 0.2rem;
  background-color: #f3f6fa;
  border-bottom: solid 0.01rem #edeff2;
}

.view_2 {
  margin-top: 0.19rem;
}

.font_10 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_27 {
  line-height: 0.18rem;
}

.image_5 {
  width: 0.14rem;
  height: 0.09rem;
}

.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}

.section_12 {
  padding: 0.18rem;
  background-color: #f3f6fa;
}

.section_13 {
  padding: 0.11rem 0.18rem 0.18rem;
  border: solid 0.01rem #f3f6fa;
}

.text-wrapper_6 {
  padding: 0.05rem 0 0.09rem;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #2080f7;
}

.font_11 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #2080f7;
}

.section_14 {
  padding: 0.18rem;
  background-color: #f3f6fa;
  border-bottom: solid 0.01rem #edeff2;
}

.text_28 {
  line-height: 0.18rem;
}

.group_6 {
  padding: 0 0.02rem 0.4rem;
}

.text_29 {
  margin-left: 0.08rem;
}

.text_30 {
  margin-left: 0.08rem;
}

.zind_2 {
  position: relative;
  z-index: 2;
}

.tim_con {
  background-color: #fff;
  padding: .4rem;
  width: calc(100% - 1.98rem - .4rem);

}

.sear_case {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sear_box {
    width: calc(100% - 1.24rem);
    height: .48rem;
    display: flex;
    align-items: center;
    border: solid 0.01rem #2080f7;
    padding-left: .1rem;
    border-right: none;

    .sea_image {
      width: 0.22rem;
      height: 0.22rem;
    }

    input {
      width: calc(100% - .33rem);

      padding: .1rem .11rem;
      margin-left: .11rem;
      font-size: .16rem;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }

  button {
    width: 1.24rem;
    border-radius: 0;
    font-size: .16rem;
    height: .48rem;

  }

}

.text_21 {
  color: #f95d22;
}

.text_21_1 {
  color: rgba(32, 128, 247, 1);
}

.text_21_2 {
  color: rgba(161, 169, 178, 1);
}

.text_21_3 {
  color: rgba(0, 181, 146, 1);
}

.con_con {
  margin-top: .4rem;
  padding-bottom: calc(1rem + 32px);
  position: relative;
}

.con_block {
  margin-bottom: .3rem;
  background-color: rgba(32, 128, 247, .03);
  display: flex;
  justify-content: space-between;
  padding: .2rem;

  .ord_txt {
    font-size: .16rem;
    font-weight: 700;
    margin-bottom: .2rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 400;
    }
  }


  .con_left {
    width: 70%;

    .con_det {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .con_img {
        width: 1.8rem;
      }

      .con_txt {
        width: calc(100% - 2rem);

        > .ord_txt {
          &:nth-child(1) {
            margin-bottom: .1rem;
            height: 3em;
          }
        }

        .con_l_btm {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ord_txt {
            margin-bottom: 0;

            &:nth-child(2) {
              span {
                font-size: .14rem;
              }
            }
          }
        }
      }
    }

  }

  .con_center {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .58rem;
  }

  .con_right {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //padding-top: .58rem;

    &.m_sty {
      display: none;
    }

    .btn_sty {
      height: .4rem;
      width: 100%;
      border-radius: .06rem;
      font-size: .16rem;
    }
  }


}

.m_sty {
  display: none;
}

.aal_sha {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  .sha_bg {
    background-color: rgba(5, 27, 55, 0.2);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }

  .aal_case {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: .14rem;
    overflow: hidden;
    padding: .3rem;
    min-width: 6rem;

    .aal_t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      padding-bottom: .2rem;

      .cur_tit {
        font-size: .18rem;
        line-height: 1;

      }

      .image_3a {
        width: 0.21rem;
        height: 0.2rem;
        cursor: pointer;
      }
    }

    .aal_con_case {
      margin-top: .3rem;


      .all_con {
        display: block;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: .14rem;
          margin: .15rem 0;
          padding-left: 0;

          .all_n {
            width: 75%;
            padding-right: .2rem;
            margin: 0;

          }

          .cursor_s {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
              width: .16rem;
              height: .16rem;
              margin-right: .14rem;
            }
          }
        }
      }


    }


    .btn_sty {
      margin: .5rem auto 0;
      width: 1.8rem;
      font-size: 14px;
      line-height: 1.5;

    }

  }

}




.cur_sha {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .cur_mask {
    background-color: rgba(5, 27, 55, 0.2);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;

  }

  .cur_con {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: .14rem;
    overflow: hidden;
    padding: .3rem;
    min-width: 6rem;

    .cup_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
   position: relative;


      .cur_tit {
        font-size: .18rem;
        line-height: 1;

      }

      .image_3a {
        width: 0.21rem;
        height: 0.2rem;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .cur_sel_case {
      margin-top: .4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cur_sel_box {
        width: 48%;

        .cur_n {
          line-height: 1;
          font-size: .18rem;
          margin-bottom: .16rem;
        }

        .tea_sel_box {
          .el-select {
            width: 100%;
          }
        }

      }


    }

    .vie_sel_case {
      margin-top: .5rem;

      .vid_list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .vid_n {
          font-size: .14rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 6rem;
        }

        .btn_sty {
          width: 1rem;
          margin: .15rem 0 .15rem .4rem;
        }

        &:first-child {
          .btn_sty {
            margin-top: 0;
          }
        }
      }
    }

    .btn_sty {
      margin: .3rem auto .2rem;
      width: 1.8rem;
      font-size: 14px;
      line-height: 1.5;

    }

  }

}
.evaluate_box {
  margin-top: .3rem;
  padding-left: .38rem;
  padding-right: .38rem;

  ::v-deep .el-textarea__inner {
    height: 1rem;
    resize: none;
  }

  .evaluate_btm {
    margin-top: .2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn_sty {
      width: 1.5rem;
      font-size: 16px;
      line-height: 1.2;
      margin-left: 30px;
      margin: 0;
    }
  }
}
.evaluate_list {
  margin-top: .3rem;
  //padding-left: .38rem;
  //padding-right: .38rem;
  max-height: 70vh;
  overflow-y: auto;

  h3 {
    font-weight: 700;
    font-size: .2rem;
    line-height: 1.5;
    margin-bottom: .2rem;
  }

  .evaluate_block {
    border-bottom: 1px solid #e5e5e5;
    padding: .2rem 0;

    .eva_img {
      display: flex;
      align-items: center;
      margin-bottom: .2rem;

      img {
        width: .3rem;
        height: .3rem;
        margin-right: .1rem;
      }

      .u_names {
        font-size: .16rem;
        line-height: 1.2;
      }
    }

    .eva_con {
      line-height: 1.5;
      font-size: .16rem;
    }

    .eva_btm {
      margin-top: .1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .eva_time {
        font-size: .14rem;
        color: #999;

      }

      .eva_reply {
        width: 1rem;
        height: .4rem;
        border-radius: .06rem;
        font-size: .16rem;
        margin: 0;

      }
    }

  }


}

.rod_case {
  margin-bottom: .2rem;
  display: flex;
  align-items: flex-start;

  .rod_n {
    font-size: .16rem;
    line-height: .3rem;
    font-weight: 700;
  }

  .rod_con {
    padding-left: .2rem;
    font-size: .16rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    img {
      width: .3rem;
      height: .3rem;
      margin-right: 0;
    }

    .btn_radio {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: .3rem;

      i {
        border-radius: 50%;
        border: 1px solid #ccc;
        width: .12rem;
        height: .12rem;
        margin-left: .1rem;
        transition: border-width .3s ease, border-color .3s ease;
      }

      &.on {
        i {
          border-width: .03rem;
          border-color: #2080f7;
        }

      }

    }

  }
}
.acsi_case {
  margin-bottom: .2rem;

  .acsi_block {
    display: flex;
    align-items: center;

    font-size: .16rem;
    margin-bottom: .1rem;

    .acsi_n {
      width: 2.5rem;
    }
  }
}




.pos {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.wall_sha {
  width: 100%;
  height: 100%;

  &.section_3a {
    padding: 2.4rem 0 2.86rem;
    background-color: #051b3733;
  }

  .font_4a {
    font-size: 0.18rem;
    line-height: 0.17rem;
    font-weight: 500;
    color: #2b2c30;
  }

  .font_10a {
    font-size: 0.16rem;
    line-height: 0.1rem;
    color: #2b2c30;
  }

  &.pos {
    z-index: 99;
  }

  .group_1a {
    width: 6rem;
  }

  .section_6a {
    padding: 0.26rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0px 0px;
    border-bottom: solid 0.01rem #f3f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_14a {
    line-height: 0.18rem;
    width: 1.2rem;
    display: block;
  }

  .imagea {
    margin-right: 0.06rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .section_7a {
    padding: 0.34rem 0.2rem 0.39rem;
    background-color: #ffffff;
    border-radius: 0 0 0.14rem 0.14rem;

    .btn_sty {
      width: 1.5rem;
      margin: 0.8rem auto 0;
      height: 0.45rem;
      font-size: 0.18rem;
    }
  }

  .font_6a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_18a {
    line-height: 0.16rem;
  }

  .group_3a {
    margin-top: 0.13rem;
  }

  .space-x-477a > *:not(:first-child) {
    margin-left: 4.77rem;
  }

  .image_2a {
    width: 0.24rem;
    height: 0.24rem;
  }

  .divider_2a {
    margin-top: 0.06rem;
    background-color: #2080f7;
    height: 0.01rem;
  }

  .group_4a {
    margin-top: 0.2rem;
    flex-wrap: wrap;

  }

  .space-x-16a > *:not(:first-child) {
    margin-left: 0.16rem;
  }

  .section_8a {
    padding: 0.1rem 0;
    background-color: #f2f8ff;
    height: 0.44rem;
    width: calc((100% - .4rem) / 3);
    margin-right: .2rem;
    margin-left: 0 !important;
    margin-top: .2rem;
    justify-content: center;
    cursor: pointer;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &.on {
      cursor: auto;
      background-color: rgba(32, 128, 247, 1);

      .font_7a {
        color: #fff;
      }
    }
  }

  .space-x-7a > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .section_11a {
    background-image: url("../../assets/b39e5272ae1fa5384043d72be101830d.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .section_13a {
    padding: 0.02rem 0;
    background-image: url("../../assets/9807e0680566a77a665fabdbe54aace1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.2rem;
  }

  .text-wrapper_5a {
    padding: 0.02rem 0;
    background-image: url("../../assets/c7222962c8899b1a18e7d0ae663528a4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .font_8a {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.1rem;
    color: #ed8a18;
  }

  .font_7a {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.17rem;
    color: #2080f7;
  }

  .text_11a {
    text-transform: uppercase;
  }

  .text_28a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_21a {
    color: #2b2c30;
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.23rem;
  }

  .text_38a {
    line-height: 0.16rem;
  }

  .text_37a {
    line-height: 0.16rem;
  }

  .text_32a {
    line-height: 0.02rem;
  }

  .text_30a {
    line-height: 0.16rem;
  }

  .text_27a {
    margin-top: 0.4rem;
    line-height: 0.18rem;
  }

  .text_19a {
    color: #e4393c;
    font-size: 0.2rem;
    font-family: OPPOSans;
    line-height: 0.16rem;
  }

  .text_15a {
    font-weight: 300;
    line-height: 0.16rem;
  }

  .text_12a {
    line-height: 0.18rem;
  }

  .text_24a {
    line-height: 0.18rem;
  }

  .section_9a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #2080f7;
    height: 0.44rem;
  }

  .section_12a {
    background-image: url("../../assets/c3c557f93ff35c3de06a9b0c39f24b4c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .text_29a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_25a {
    color: #ffffff;
    line-height: 0.18rem;
  }

  .section_10a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #f2f8ff;
    height: 0.44rem;
  }

  .space-x-8a > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text_26a {
    line-height: 0.18rem;
  }

  .group_8a {
    margin-top: 0.4rem;

    .el-radio {
      display: flex;
      align-content: center;
      margin-left: 0.3rem;
      margin-right: 0;

      ::v-deep .el-radio__input {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-radio__label {
        display: flex;
        align-items: center;
      }
    }
  }

  .hint_pay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
    font-family: SourceHanSansCN;
    font-size: 0.2rem;
    color: #2b2c30;


  }

  .text_33a {
    line-height: 0.16rem;
  }

  .image_3a {
    border-radius: 0.04rem;
    width: 0.24rem;
    height: 0.24rem;
  }

  .image_6a {
    width: 0.16rem;
    height: 0.16rem;
  }

  .text_35a {
    margin-left: 0.08rem;
    line-height: 0.15rem;
  }

  .image_7a {
    margin-top: 0.4rem;
    width: 1.81rem;
    height: 1.8rem;
  }


  .evaluate_con {
    ::v-deep .el-textarea__inner {
      height: 1rem;
      resize: none;
    }

    + .btn_sty {
      margin-top: .3rem;
    }
  }
}


@media screen and (orientation: portrait) {
  .m_sty {
    display: block;
  }
  .group_2 {
    width: 100%;
    flex-wrap: wrap;
  }
  .space-x-40 > *:not(:first-child) {
    margin-left: 0;
  }

  .tim_con {
    width: 100%;
    padding: .3rem;
    margin-top: .4rem;
  }
  .sear_case {


    .sear_box {
      width: calc(100% - 1.8rem);
      height: .7rem;

      .sea_image {
        width: .3rem;
        height: .3rem;
      }

      input {
        width: calc(100% - .3rem);
        margin-left: 0;
        padding-left: .2rem;
        font-size: .24rem;
      }
    }

    button {
      width: 1.8rem;
      height: .7rem;
      font-size: .24rem;


    }

  }


  .con_con {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .con_block {
    margin-bottom: .3rem;
    width: 100%;

    .ord_txt {
      font-size: .22rem;
      margin-bottom: .15rem;
      line-height: 1.35;
      min-height: auto;

      &:last-child {
        margin-bottom: .15rem;
      }

      span {


      }
    }


    .con_left {
      width: 100%;

      .con_det {


        .con_img {
          width: 30%;
          height: 2rem;
        }

        .con_txt {
          width: 70%;
          margin-top: 0;
          padding-left: .2rem;

          .ord_txt {
            &:nth-child(1) {
              margin-bottom: .15rem;
              height: auto;
            }
          }

          .con_l_btm {
            .ord_txt {
              //width: 50%;
              margin-bottom: .15rem;

              &:nth-child(2) {
                span {

                  font-size: .22rem;
                }
              }
            }


          }
        }
      }

    }

    .con_center {
      width: 100%;
      padding-top: 0;
      justify-content: flex-start;

      .ord_txt {
        display: flex;

        .m_sty {
          font-weight: 700;
        }

        .text_21 {
          line-height: 1.35;
        }
      }
    }

    .con_right {
      width: 100%;
      margin-left: 0;
      padding-top: 0;
     &.m_sty{
       display: flex !important;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: space-between;
     }

      .btn_sty {
        height: .6rem;
        width: 48%;

        font-size: .2rem;
        &:last-child{
          width: 100%;
        }
      }
    }

  }


  .cur_sha {




    .cur_mask {



    }

    .cur_con {
      width: 90%;
      padding: .3rem;
      min-width: 6rem;

      .cup_top {




        .cur_tit {
          font-size: .28rem;


        }

        .image_3a {
          width: 0.4rem;
          height: auto;


        }
      }

      .cur_sel_case {
        margin-top: .4rem;



        .cur_sel_box {


          .cur_n {

            font-size: .24rem;
            margin-bottom: .3rem;
          }

          .tea_sel_box {
            .el-select {


            }
          }

        }


      }

      .vie_sel_case {
        margin-top: .3rem;

        .vid_list {

          margin-bottom: .3rem;

          .vid_n {
            font-size: .24rem;
            line-height: 1.2;
            width: calc(100% - 1.6rem);
          }

          .btn_sty {
            width: 1.4rem;
            margin: 0 0 0 .2rem;
            padding:  .2rem;
          }

          &:first-child {
            .btn_sty {


            }
          }
        }
      }

      .btn_sty {
        padding: .2rem;
        font-size: .24rem;
        line-height: 1;

      }

    }

    ::v-deep .el-input__inner{
      font-size: .24rem;
      height: .7rem;
      line-height: .68rem;
      padding-left: .14rem;
    }
    ::v-deep .el-select-dropdown__item{
      padding-left: .3rem;
      padding-right: .3rem;
      font-size: .24rem;
    }
  }

  .evaluate_box {

    padding-left: .3rem;
    padding-right: .3rem;

    ::v-deep .el-textarea__inner {
      height: 2.5rem;
      padding: .2rem .2rem .5rem;
      font-size: .22rem;
      line-height: 1.35;

    }
  }
  .evaluate_box .evaluate_btm .btn_sty{
    font-size: .24rem;
    margin-left: .3rem;
    padding: .2rem;
    line-height: 1;

  }
  .evaluate_list {

    //padding-left: .3rem;
    //padding-right: .3rem;

    h3 {
      font-size: .22rem;
      margin-bottom: .3rem;
    }

    .evaluate_block {
      border-bottom: 1px solid #e5e5e5;
      padding: .2rem 0;

      .eva_img {
        display: flex;
        align-items: center;
        margin-bottom: .2rem;

        img {
          width: .5rem;
          height: .5rem;
          margin-right: .2rem;
        }

        .u_names {
          font-size: .22rem;
        }
      }

      .eva_con {
        font-size: .22rem;
      }

      .eva_btm {
        margin-top: .2rem;

        .eva_time {
          font-size: .2rem;

        }

        .eva_reply {
          min-width: 1rem;
          height: auto;
          font-size: .24rem;
          padding: .2rem;
          line-height: 1;

        }
      }

    }


  }
  .rod_case {
    margin-bottom: .1rem;



    .rod_n {
      font-size: .28rem;
      line-height: .5rem;

    }

    .rod_con {
      padding-left: .2rem;
      font-size: .22rem;
      width: calc(100% - 1.4rem);




      img {
        width: .5rem;
        height: .5rem;

      }

      .btn_radio {
        width: 1.6rem;
        margin-bottom: .15rem;
        font-size: .24rem;



        i {
          width: .2rem;
          height: .2rem;
          margin-left: .2rem;


        }

        &.on {
          i {
            border-width: .04rem;

          }

        }

      }

    }
  }

  .acsi_case {
    margin-bottom: .4rem;

    .acsi_block {

      font-size: .28rem;
      margin-bottom: .3rem;
      flex-wrap: wrap;

      .acsi_n {
        width: 100%;
        margin-bottom: .1rem;
      }
    }

    ::v-deep .el-radio__label{
      font-size: .22rem;
    }
  }




  .wall_sha{
    .section_6a{
      padding: .3rem .3rem .2rem;
    }
    .text_14a{
      font-size: .28rem;
      width: auto;
      height: auto;
      line-height: 1;
    }
    .imagea{
      width: .4rem;
      height: .4rem;
    }

    .group_1a{
      width: 90%;
    }

    .evaluate_con{
      ::v-deep .el-textarea__inner {
        height: 3.5rem;
        padding: .2rem .2rem .5rem;
        font-size: .24rem;
        line-height: 1.35;
      }
      + .btn_sty{
        padding: .2rem;
        line-height: 1;
        font-size: .24rem;
        min-width: 2rem;
        height: auto;
      }
    }

  }
  //充值
  .wall_sha {



    .font_4a {
      font-size: 0.28rem;
      line-height:1;


    }

    .font_10a {
      font-size: .2rem;
      line-height:1;
    }

    &.pos {


    }

    .group_1a {


    }

    .section_6a {


    }

    .text_14a {


    }

    .imagea {
      margin-right: .1rem;
      width: 0.4rem;
      height: 0.4rem;


    }

    .section_7a {


      .btn_sty {


        height: 0.6rem;
        font-size: 0.24rem;
      }
    }

    .font_6a {
      font-size: .24rem;
      line-height: 1;

    }

    .text_18a {
      line-height: 1;
    }

    .group_3a {
      margin-top: .2rem;
    }

    .space-x-477a > *:not(:first-child) {
      margin-left: 4.77rem;
    }

    .image_2a {
      width: 0.5rem;
      height: 0.5rem;
    }

    .divider_2a {


    }

    .group_4a {


    }

    .space-x-16a > *:not(:first-child) {
      margin-left: 0.2rem;
    }

    .section_8a {


      height: 0.6rem;



    }

    .space-x-7a > *:not(:first-child) {
      margin-left: 0.1rem;
    }

    .section_11a {

    }

    .section_13a {

    }

    .text-wrapper_5a {


    }

    .font_8a {
      font-size: 0.2rem;


    }

    .font_7a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_11a {

    }

    .text_28a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_21a {
      font-size: 0.38rem;
      line-height: 1;
    }

    .text_38a {
      line-height: 0.2rem;
    }

    .text_37a {
      line-height: 1;
    }

    .text_32a {
      line-height: 1;
    }

    .text_30a {
      line-height: 1;
    }

    .text_27a {


      line-height: 1.2;
    }

    .text_19a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_15a {
      line-height: 1;
    }

    .text_12a {
      line-height:1;
    }

    .text_24a {
      line-height: 1;
    }

    .section_9a {

      height: .6rem;
    }

    .section_12a {

      width: 0.5rem;
      height: 0.5rem;
    }

    .text_29a {
      font-size: 0.2rem;
      line-height: 1;
    }

    .text_25a {
      line-height: 1;
    }

    .section_10a {

      height: 0.6rem;
    }

    .space-x-8a > *:not(:first-child) {
      margin-left: 0.08rem;
    }

    .text_26a {
      line-height:1;
    }

    .group_8a {


      .el-radio {


        ::v-deep .el-radio__input {

        }

        ::v-deep .el-radio__label {

        }
      }
    }

    .text_33a {
      line-height:1;
    }

    .image_3a {

      width: 0.5rem;
      height: 0.5rem;
    }

    .image_6a {
      width: 0.3rem;
      height: 0.3rem;
    }

    .text_35a {
      margin-left: 0.1rem;
      line-height: 1;
    }

    .image_7a {
      width: 2.3rem;
      height: 2.3rem;
    }
    .hint_pay{




    }
  }


}

</style>
