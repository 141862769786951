<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col pub_-justify-start pub_-self-center group_2">
      <div class="pub_-flex-row space-x-40 pub_case">
        <left-nav></left-nav>

        <div class="pub_-flex-col pub_-flex-auto group_4">
          <div class="pub_-flex-col">
            <div class="pub_-flex-row group_5 space-x-46">
              <span
                class="font_3 text_11"
                :class="{ text_10: active == 0 }"
                @click="getInfo(0)"
                >全部消息</span
              >
              <span
                class="font_3 text_11"
                :class="{ text_10: active == 1 }"
                @click="getInfo(1)"
                >系统通知</span
              >
              <span
                class="font_3 text_11"
                :class="{ text_10: active == 2 }"
                @click="getInfo(2)"
                >直播通知</span
              >
            </div>
          </div>
          <div class="pub_-flex-col list section_4">





            <div
              class="pub_-flex-col pub_-justify-start pub_-relative group_6"
              :key="i"
              v-for="(item, i) in InformList"
              @click="mailTap(true, item.id)"
            >
              <div class="group_7">
                <img
                  v-if="item.type == 1"
                  class="pub_-shrink-0 image image_2"
                  src="../../assets/89a2cea6c8ee8ba00ec4e406a39fe3e9.png"
                />
                <img
                  v-else
                  class="pub_-shrink-0 image image_2"
                  src="../../assets/86f301b7278ed80ec7d9e302f255b1cb.png"
                />

                <div class="mail_con">
                  <span class="pub_-flex-auto font_5 text_15">{{
                    item.title
                  }}</span>
                  <span class="font_8">{{ item.createTime }}</span>
                </div>

                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-shrink-0
                    text-wrapper_4
                    view
                    font_s
                  "
                  v-if="item.isRead == 1"
                >
                  <span class="font_6">已读</span>
                </div>
                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-shrink-0
                    text-wrapper_4
                    view
                  "
                  v-else
                >
                  <span class="font_6">未读</span>
                </div>

                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-shrink-0
                    text-wrapper_5
                    view_2
                    btn_sty btn_sty_line
                  "
                >
                  <span class="font_7">查看</span>
                </div>
              </div>
            </div>

            <div class="page_box">
              <Pagination
                @change="changePage"
                :current="pagination.p"
                :pageSize="pagination.pageSize"
                :total="total"
                :hideOnSinglePage="hideOnSinglePage"
                :showQuickJumper="true"
                :showTotal="true"
                placement="right"
                v-if="pagination && total"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>

    <div
      class="
        pub_-flex-col pub_-justify-start pub_-items-center
        section_2a
        pos
        mail_box
      "
      v-if="show == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row pub_-items-center section_5a space-x-414a">
          <div
            v-if="InfoD.type == 1"
            class="pub_-flex-row pub_-items-center space-x-13a"
          >
            <img
              class="pub_-shrink-0 imagea"
              src="../../assets/89a2cea6c8ee8ba00ec4e406a39fe3e9.png"
            />
            <span class="font_2a text_15a">系统通知</span>
          </div>

          <div v-else class="pub_-flex-row pub_-items-center space-x-13a">
            <img
              class="pub_-shrink-0 imagea"
              src="../../assets/86f301b7278ed80ec7d9e302f255b1cb.png"
            />
            <span class="font_2a text_15a">直播通知</span>
          </div>

          <img
            class="image_2a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="mailTap(false, null)"
          />
        </div>
        <div class="pub_-flex-col section_7a space-y-140a">
          <div class="pub_-flex-col group_5a space-y-15a">
            <span class="text_18a">{{ InfoD.title }}</span>
            <span class="pub_-self-start font_5a text_21a">{{
              InfoD.createTime
            }}</span>
            <span class="font_5a text_22a" v-html="InfoD.content"></span>
          </div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center group_8a"
          >
            <div
              class="
                pub_-flex-col pub_-justify-start pub_-items-center
                text-wrapper_7a
                btn_sty
              "
            >
              <span class="font_2a text_28a">去观看</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: { NavBox, FootBox, LeftNav, Pagination },
  data() {
    return {
      InformList: [],
      show: false,

      active: 0,

      mailC: [],
      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },

      InfoD: {},
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.getInfo(0); //获取站内信分页列表
    },
    async getInfo(type) {
      this.active = type;
      let param = {
        limit: "",
        page: "",
        type: type,
      };

      // console.log(param)
      const { data: res } = await this.$http.post(
        "/api.mail/getPageList",
        param
      );

      console.log(res);

      this.InformList = res.data.data.slice(0, res.data.per_page);
      // console.log(this.InformList);
      this.mailC = res.data.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;
      // this.pagination.pageSize = 9;
    },
    async getInfoDet(id) {
      //获取站内信详情
      let param = {
        id: id,
      };

      // console.log(param)
      const { data: res } = await this.$http.post("/api.mail/getInfo", param);

      console.log(res);

      this.InfoD = res.data;

      // this.pagination.pageSize = 9;
    },

    mailTap(sea, id) {
      if (id != null) {
        this.getInfoDet(id);
      }
      this.show = sea;
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.list_tzm43Yq0 = this.newC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },

    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}
.text {
  color: #ffffff;
  line-height: 0.15rem;
}
.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}
.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}
.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text_8 {
  margin-left: 0.43rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_9 {
  line-height: 0.18rem;
}
.group_2 {
  padding: 0.4rem 0 0.8rem;
}
.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}
.text-wrapper_3 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}
.text_13 {
  margin-left: 0.19rem;
}
.text_14 {
  margin-left: 0.17rem;
}
.text_16 {
  margin-left: 0.16rem;
}
.section_5 {
  background-color: #ffffff;
}
.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}
.section_6 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}
.text_17 {
  margin-right: 1.26rem;
  color: #2080f7;
}
.text_19 {
  margin-left: 0.16rem;
}
.text-wrapper_7 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.text_20 {
  line-height: 0.13rem;
}
.text_21 {
  margin-left: 0.17rem;
}
.text_23 {
  margin-left: 0.17rem;
}
.text_24 {
  margin-left: 0.16rem;
}
.group_4 {
  width: 9.42rem;
}
.group_5 {
  padding: 0 0.14rem 0;
  border-bottom: 0.02rem solid #e0e0e0;
}
.space-x-46 > *:not(:first-child) {
  margin-left: 0.46rem;
}
.font_3 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.17rem;
  font-weight: 300;
  color: #a1a9b2;
  padding-bottom: 0.1rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    background-color: #2080f7;
    width: 0.28rem;
    height: 0.02rem;
    left: 50%;
    margin-left: -0.14rem;
    top: 100%;
    position: absolute;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  &.text_10 {
    &::before {
      transform: none;
    }
  }
  transition: color 0.5s ease;
  cursor: pointer;
  &:hover {
    color: #2080f7;
    &::before {
      transform: none;
    }
  }
}
.text_10 {
  color: #2080f7;
  font-weight: 500;
  line-height: 0.16rem;
}
.text_11 {
  line-height: 0.16rem;
}
.text_12 {
  line-height: 0.16rem;
}

.list {
  margin-top: 0.19rem;
}
.section_4 {
  padding: 0 0.2rem calc(32px + 1rem);
  background-color: #ffffff;
  min-height: 65vh;
  position: relative;
  .page_box {
    position: absolute;
    width: 100%;
    padding: 0 0.2rem 0.5rem;
    left: 0;
    bottom: 0;
  }
}
.group_6 {
  padding: 0.38rem 0 0.28rem;
  border-bottom: solid 0.01rem #f3f6fa;
}
.group_7 {
  margin: 0 0.2rem;
  width: 8.62rem;
  display: flex;
  align-items: center;
}
.image {
  width: 0.34rem;
  height: 0.34rem;
}
.mail_con {
  display: flex;
  flex-direction: column;
  margin-left: 0.14rem;
  max-width: calc(100% - 2.26rem);
  .text_15 {
    overflow: hidden;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2;
  }
  .font_8 {
    margin-top: 0.06rem;
  }
}

.font_5 {
  font-size: 0.14rem;
  font-family: Adobe Heiti Std;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_15 {
  line-height: 1.2857;
}
.text-wrapper_4 {
  padding: 0.06rem 0;
  background-color: #00b592;
  border-radius: 0.04rem;
  width: 0.41rem;
  height: 0.23rem;
  &.font_s {
    background-color: rgba(249, 93, 34, 1);
  }
}
.view {
  margin-left: 0.21rem;
}
.font_6 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #ffffff;
}
.text-wrapper_5 {
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #2080f7;
}
.view_2 {
  margin-left: auto;
}
.font_7 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.14rem;
  font-weight: 300;
}
.font_8 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.1rem;
  font-weight: 300;
  color: #a1a9b2;
}
.pos {
  position: absolute;
  left: 0.67rem;
  bottom: 0.2rem;
}
.mail_box {
  &.section_2a {
    padding: 2.4rem 0 3.4rem;
    background-color: #051b3733;
  }
  &.pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
  .group_1a {
    width: 6rem;
  }
  .section_5a {
    padding: 0.18rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0px 0px;
    border-bottom: solid 0.01rem #f3f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .font_2a {
    font-size: 0.18rem;
    line-height: 0.17rem;
    font-weight: 700;
  }
  .text_15a {
    font-weight: 500;
    line-height: 0.18rem;
    margin-left: 0.13rem;
  }
  .image_2a {
    margin-right: 0.04rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;

    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
  .section_7a {
    padding-top: 0.18rem;
    background-color: #ffffff;
    border-radius: 0px 0px 0.14rem 0.14rem;
  }
  .group_5a {
    margin-left: 0.2rem;
    margin-right: 0.14rem;
  }
  .space-y-15a > *:not(:first-child) {
    margin-top: 0.15rem;
  }
  .text_18a {
    color: #2b2c30;
    font-size: 0.22rem;
    font-family: Adobe Heiti Std;
    line-height: 0.28rem;
  }
  .text_21a {
    color: #a1a9b2;
    line-height: 0.11rem;
  }
  .text_22a {
    line-height: 0.22rem;
    height: 1.62rem;
    overflow-y: auto;
  }
  .group_8a {
    padding: 0.21rem 0 0.18rem;
    border-top: solid 0.01rem #f3f6fa;
    margin-top: .35rem;
  }
  .text-wrapper_7a {
    border-radius: 0.04rem;
    width: 1.49rem;
    height: 0.45rem;
  }
  .text_28a {
    font-weight: 300;
    letter-spacing: 0.025rem;
  }
}

@media screen and (orientation: portrait){
  .nav_left{
    display: flex !important;
  }
  .space-x-40 {
    flex-wrap: wrap;
    > *[data-v-46c558ea]:not(:first-child){
      margin-left: 0;
    }

    .group_5{
      padding:  .2rem 0;
      .font_3{
        font-size: .22rem;
        line-height: 1;
        padding-top: .2rem;
      }
    }

    .section_4{
      margin-top: .4rem;
      padding: .3rem .3rem calc(32px + 1rem);
    }
  }

  .group_4{
    width: 100%;
  }
  .group_7{
   width: 100%;
    margin: 0;

  }
  .image{
    width: .4rem;
    height: .4rem;
  }
  .text-wrapper_4{
    height: auto;
    width: .4rem;
    margin-left: .2rem;

    .font_6{
      font-size: .16rem;
      line-height: 1;
    }
  }
  .text-wrapper_5{
    height: auto;
    padding: .1rem .2rem;
    width: .9rem;
    .font_7{
      font-size: .2rem;
      line-height: 1;
    }
  }
  .font_5{
    font-size: .22rem;
  }
  .mail_con .font_8{
    margin-top: .1rem;
    font-size: .2rem;
    line-height: 1;
  }

}
</style>
