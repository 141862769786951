import Vue from 'vue';
import App from './app.vue';
import router from './router';
import BaiduMap from 'vue-baidu-map';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

import cookies from 'vue-cookies'

Vue.prototype.$cookies = cookies;

import axios from 'axios';


// axios.defaults.baseURL = 'http://39.100.254.122/'
// axios.defaults.baseURL = 'https://www.likesay.cn/'
var $http=axios.create({
  baseURL: '',
  timeout: 3000
});
Vue.prototype.$http = axios;


axios.interceptors.request.use(
  config => {
    var thisToken = "", thisLanguage = 'zh',isMobile = 0;
    if (cookies.get('oatoken')) {
      thisToken = cookies.get('oatoken').token;
    }

    if (cookies.get('languageD') == 1) {
      thisLanguage = 'en'
    }

    if (cookies.get('isMobile') == 1) {
      isMobile = 1
    }

    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept-Language'] = thisLanguage
    config.headers['isMobile'] = isMobile
    config.headers['Authorization'] = thisToken
    config.headers['token'] = thisToken


    return config
  }
)


Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  ak: 'ouKbIhrUPqfcbfpUAEjYxm9G7QTG24CM'
})
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
