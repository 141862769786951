<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col space-y-80">
      <div class="pub_-flex-col pub_-self-center pub_case">
        <div class="pub_-flex-col group_2 space-y-19">
          <div class="pub_title_box">
            <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
            <span>训练营专区</span>
            <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
          </div>
          <span class="pub_info">
            为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
          </span>
        </div>
        <div class="pub_-flex-col list space-y-18a sys_cou_box">
          <div
            class="pub_-flex-col list-itema"
            :key="i"
            v-for="(item, i) in drillList"
            @click="goHref" :data-url="'/courseDetails?type=' + item.type +'&&id=' + item.id"
          >
            <div
              class="
                pub_-flex-row pub_-justify-between pub_-items-start
                group_3a
              "
            >
              <div class="pub_-flex-row group_4a space-x-13a">
                <img class="pub_-shrink-0 image_3a" :src="item.firstPicturePath" />
                <div class="pub_-flex-col pub_-shrink-0 group_5a">
                  <span class="font_3a">{{ item.title }}</span>
                  <span class="pub_-self-start font_4a text_14a" v-if="item.startTime != '' && item.startTime != undefined&& item.startTime != null">开营时间：{{item.startTime}}</span>

                  <span class="pub_-self-start font_4a text_15a" v-if="item.duration != '' && item.duration != undefined&& item.duration != null">学习周期：{{item.duration }}天</span>

                </div>
              </div>
              <div v-if="item.levelName !== ''" class="pub_-flex-col pub_-justify-start text-wrapper_3a">
                <span class="font_1a text_13a">{{ item.levelName }}</span>
              </div>
            </div>
            <div class="pub_-flex-row pub_-justify-evenly group_6a">
              <span class="font_5a text_16a text_17a">课程内容：</span>
              <span class="font_6a text_16a">{{ item.profile }}</span>
              <div class="group_7a" >
                <span class="font_7a text_16a text_19a">更多&gt;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";

export default {
  components: { NavBox, FootBox },
  data() {
    return {
      drillList: [],
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.active = i;

      this.getList(); //获取热销课程列表接口

    },
    async getList(id, i) {
      this.active = i;

      let param = {
      };
      const { data: res } = await this.$http.post(
        "/api.course/getList",
        param
      );
      this.drillList = res.data.data;

      console.log( this.drillList )
    },
    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },

  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #edeff2;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.font_1 {
  font-size: 0.14rem;
  line-height: 0.15rem;
  font-weight: 700;
  color: #ffffff;
}
.text {
  font-weight: unset;
}
.text_2 {
  color: #2b2c30;
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}
.space-y-80 {
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
}
.section_2 {
  padding-left: 0.19rem;
  background-color: #f4f7fe;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text-wrapper_2 {
  margin-left: 0.16rem;
  padding: 0.26rem 0;
  background-color: #2080f7;
  width: 1.08rem;
  height: 0.68rem;
}
.text_8 {
  color: #ffffff;
}
.text_9 {
  margin-left: 0.26rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_10 {
  line-height: 0.18rem;
}
.group {
  width: 11.8rem;
}

.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}
.image {
  width: 0.53rem;
  height: 0.08rem;
}
.text_11 {
  margin-left: 0.21rem;
  color: #2b2c30;
  font-size: 0.36rem;
  font-family: OPPOSans;
  line-height: 0.35rem;
}
.image_2 {
  margin-left: 0.16rem;
}
.text_12 {
  color: #2b2c30;
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.2rem;
}
.list {
  margin-top: 0.37rem;
}
.space-y-18 > *:not(:first-child) {
  margin-top: 0.18rem;
}
.list-item {
  background-color: #ffffff;
  border-radius: 0.14rem;
  border: solid 0.01rem #eeeeee;
}
.list-item:last-of-type {
  border-top: solid 0.01rem #eeeeee;
  border-bottom: solid 0.01rem #eeeeee;
}
.group_3 {
  padding: 0 0.02rem 0.2rem 0.2rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.group_4 {
  margin-top: 0.19rem;
}
.image_3 {
  border-radius: 0.08rem;
  width: 1.84rem;
  height: 1.02rem;
}
.group_5 {
  width: 2.02rem;
}
.font_3 {
  font-size: 0.16rem;
  font-family: Adobe Heiti Std;
  line-height: 0.24rem;
  color: #2b2c30;
}
.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #2b2c30;
}
.text_14 {
  margin-top: 0.15rem;
}
.text_15 {
  margin-top: 0.1rem;
}
.text-wrapper_3 {
  padding: 0.14rem 0;
  background-color: #2080f7;
  border-radius: 0px 0.12rem 0px 0px;
  height: 0.42rem;
}
.text_13 {
  margin-left: 0.14rem;
  margin-right: 0.08rem;
}
.group_6 {
  padding: 0.2rem 0.07rem 0.2rem 0.17rem;
  align-items: center;
}
.font_5 {
  font-size: 0.16rem;
  line-height: 1;
  font-weight: 500;
  color: #2b2c30;
}
.font_6 {
  font-size: 0.16rem;
  line-height: 1;
  color: #2b2c30;
  width: calc(100% - 1.23rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.group_7 {
  line-height: 1;
}
.font_7 {
  font-size: 0.16rem;
  color: #2080f7;
  display: block;
}
.text_16 {
  text-transform: uppercase;
}
.text_18 {
  line-height: 0.16rem;
}
.text_17 {
  line-height: 0.16rem;
}
.text_19 {
  line-height: 0.16rem;
}
.space-x-13 > *:not(:first-child) {
  margin-left: 0.13rem;
}

@media screen and (orientation: portrait){






}
</style>
