<template>
  <header class="header">
    <div class="pub_-flex-col pub_-justify-start pub_case">
      <RouterLink to="/">
        <img :src="config.logoPath" alt=""/>
      </RouterLink>

      <div class="pub_-flex-col pub_-justify-start pub_-items-end text-wrapper">
        <!--        <div class="lan_box" >-->
        <!--          <div v-if="lanStr == 1" @click="lanChange(0)" class="lan_show">中</div>-->
        <!--          <div v-else class="lan_show" @click="lanChange(1)">EN</div>-->

        <!--        </div>-->

        <!-- 登录前 -->
        <span v-if="stateLogin == false" class="font_2 text_2 befor_l">
          <RouterLink to="/login">登录</RouterLink>
          /
          <RouterLink to="/register">注册</RouterLink>
        </span>
        <span v-else class="after_l">
          <img :src="userImg" alt="UserImg"/>
          <span @click="getOut()">退出</span>
        </span>

        <div class="m_sty" :class="navStr === 1 ? 'open_on':''" @click="btnMenu()">

          <span></span>
          <span></span>
          <span></span>

        </div>
      </div>
    </div>
    <div class="pub_-flex-row pub_-self-center section nav_box" :class="navStr === 1 ? 'on':''">
      <div class="pub_case">
        <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4" v-for="(item, i) in navB"
             :key="i" :class="{'nav_active': navAdd.f === i}">
          <RouterLink :to="item.href" :class="{ 'active': isActivePath(item.href,i,0) || item.href === '' }">
            <span class="font_4">{{ item.name }}</span>
          </RouterLink>

          <div class="sec_box" v-if="item.subset.length > 0">
            <ul>
              <li v-for="(items, j) in item.subset" :key="j">
                <RouterLink :to="items.href"
                            :class="[{ 'active': isActivePath(items.href,i,j) || items.href === '' },{'nav_active': navAdd.z === j}]">
                  {{ items.name }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      navAdd: {
        f: 0,
        z: 0
      },
      navN: 0,
      active: 0,
      config: {},
      navB: [],
      navOn: 0,
      stateLogin: false,
      userImg: "",
      token: "",
      navStr: 0,//移动端导航开关 0 是关
      lanStr: 0,//语言切换显示 0  当前中文  点击切换英文
    };
  },
  created() {
    // let query=this.$route.query;
    // console.log(query)

    let getU = this.$cookies.get("oatoken");
    // let getU = this.$cookies.get("loggingStatus");

    if (!getU || getU == 0) {

      this.stateLogin = false;

    } else {
      // this.userImg = getU.avatarPath;

      this.stateLogin = true;
      this.token = getU.token;
    }
    this.getInfo();

    if (this.$cookies.get("languageD")) {
      this.lanStr = this.$cookies.get("languageD");
    }

    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.getConfig(); //获取设置接口
      this.getNav(); //获取导航接口
    },
    async getInfo() {
      let self = this;
      const {data: info} = await this.$http.post("/api.student/getInfo", {});
      this.userImg = info.data.avatarPath;


      // token 过期 登出
      const whiteList = ['/', '/courseList', '/systemCourse','/trainingCampCourse','/englishLevel','/courseDetails'];

      function isInWhiteList(route) {
        return whiteList.includes(route);
      }

      // console.log(info.code)

      if (info.code == 10401) {
        self.logout(false);

        if (!isInWhiteList(this.$router.currentRoute.path)) {  // 判断当前路由是否不在白名单中
          this.$router.push('/login'); // 跳转到首页
        }

      }
    },
    async getConfig(id, i) {
      this.active = i;

      let param = {};
      const {data: res} = await this.$http.post("/api.setUp/getConfig", param);
      this.config = res.data;


      // console.log(this.config);

    },

    async getNav(id, i) {
      let param = {};
      const {data: res} = await this.$http.post("/api.setUp/getNav", param);
      this.navB = res.data;
      // console.log(this.navB);
    },

    navO() {
      if (this.navN === 0) {
        this.navN = 1;
      } else {
        this.navN = 0;
      }
    },
    navC(i) {
      this.active = i;
    },
    getOut() {
      this.logout();
    },
    async logout(logSta) {
      let param = {
        token: this.token,
      };
      // console.log(param);
      const {data: res} = await this.$http.post("/api.student/logout", param);
      if (logSta != false) {
        if (res.code === 0) {
          this.$cookies.remove("oatoken");
          this.stateLogin = false;


          this.$message({
            message: "已退出",
          });
          const currentPath = this.$router.currentRoute.path;
          if (currentPath === '/') {
            location.reload();
          } else {
            this.$router.push('/');
          }

        } else {
          this.$message({
            message: res.msg,
          });
          let path = "/login";
          this.$router.push(path);
        }
      }
      // console.log(this.config);
    },
    isActivePath(path, i, j) {
      // 获取当前路径
      const currentPath = this.$route.path.split('?')[0];
      // 判断当前路径是否与给定的路径匹配
      if (currentPath === path.split('?')[0]) {
        this.navAdd.f = i;
        this.navAdd.z = j;
      }

      return currentPath === path;
    },
    btnMenu() {
      if (this.navStr === 0) {
        this.navStr = 1

      } else {
        this.navStr = 0
      }
    },
    lanChange(str) {

      this.lanStr = str;

      // console.log(this.lanStr)
      this.$cookies.set("languageD", str, "365d");

      location.reload()
    },
  },
};
</script>

<style scoped lang="scss">
$on_color1: rgba(1, 1, 1, 1);

header {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 55;

  > .pub_case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 1px 0;
    position: relative;
    z-index: 2;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0.16rem 0;
      width: 1.34rem;
      height: 0.64rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .nav_box {
    width: 100%;

    > .pub_case {
      display: flex;
    }
  }

  .text-wrapper {
    padding: 0.31rem 0 0.34rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .lan_box {
    position: relative;

    .lan_show {
      font-size: .14rem;
      cursor: pointer;
      padding: .2rem;
    }


    .lan_sel {
      position: absolute;

      width: 130%;
      background-color: #fff;
      border-radius: .1rem;
      padding: .1rem 0;
      left: -15%;

      opacity: 0;
      pointer-events: none;
      transform: translateY(10%);
      transition: opacity .5s ease-in-out, transform .5s ease-in-out;

      span {
        display: block;
        font-size: .14rem;
        line-height: 2.5;
        cursor: pointer;
        text-align: center;

        @media screen and (orientation: landscape) {
          transition: color .5s ease-in-out;

          &:hover {
            color: #2080f7;
          }
        }


      }
    }

    //@media screen and (orientation: landscape) {
    &:hover {
      .lan_sel {
        pointer-events: all;
        opacity: 1;
        transform: none;
      }

      //}
    }

  }

  .text_2 {
    line-height: 0.15rem;
  }

  .group {
    padding: 0.17rem 0;
  }

  .pos_20 {
    margin-left: 3.7rem;
    padding: 0.24rem 0;
    background-color: #000000;
    width: 1.34rem;
  }

  .text {
    color: #ffffff;
  }

  .section {
    background-color: #f4f7fe;
  }

  .font_3 {
    font-size: 0.18rem;
    line-height: 0.18rem;
    color: #ffffff;
  }

  .text-wrapper_4 {
    flex: 1 1 1.95rem;
    background-color: #3d6ef700;
    width: 1.95rem;
    height: 0.68rem;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      transition: all 0.5s ease;
      cursor: pointer;

      span {
        transition: color 0.5s ease;
      }

      @media screen and (orientation: landscape) {
        &:hover {
          span {
            color: #2080f7;
          }
        }
      }


    }

    &.nav_active {
      > a {
        background-color: #2080f7;

        span {
          color: #fff;
        }
      }

      .nav_active {
        color: #2080f7;
      }

    }

    position: relative;

    .sec_box {
      background-color: #fff;
      transform: translateY(0.5rem);
      opacity: 0;
      box-shadow: 0rem 0.2rem 0.6rem -0.15rem rgba($color: #000000, $alpha: 0.2);
      pointer-events: none;
      transition: all 0.5s ease;
      width: 120%;
      top: 100%;
      left: -10%;
      position: absolute;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #fff;
        width: 0.1rem;
        height: 0.1rem;
      }

      ul {
        display: block;
        margin: 0;
        padding: 0.1rem 0;

        li {
          display: block;

          a {
            display: flex;
            font-size: 0.14rem;
            line-height: 2.5;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: #000000;
            transition: color 0.5s ease;

            @media screen and (orientation: landscape) {
              &:hover {
                color: #2080f7;
              }
            }
          }
        }
      }
    }

    @media screen and (orientation: landscape) {
      &:hover {
        .sec_box {
          opacity: 1;
          transform: none;
          pointer-events: all;
        }
      }
    }
  }

  .text_3 {
    line-height: 0.17rem;
  }

  .font_4 {
    font-size: 0.18rem;
    line-height: 0.18rem;
    color: #2b2c30;
  }

  .font_2 {
    font-size: 0.14rem;
    line-height: 0.14rem;
    color: #2b2c30;
    height: 0.36rem;
    display: flex;
    align-items: center;
  }

  .befor_l {
    a {
      color: rgba(43, 44, 48, 1);
      transition: color 0.5s ease;
      cursor: pointer;

      @media screen and (orientation: landscape) {
        &:hover {
          color: #2080f7;
        }
      }
    }
  }

  .after_l {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 0.36rem;
      height: 0.36rem;
      border-radius: 100%;
    }

    span {
      cursor: pointer;
      margin-left: 0.07rem;
      color: rgba(43, 44, 48, 1);
      font-size: 0.14rem;

      &:hover {
        color: #2080f7;
      }
    }
  }

  .font_1 {
    font-size: 0.14rem;
    line-height: 0.16rem;
    color: #2b2c30;
  }

  .m_sty {
    display: none;
  }

  @media screen and (orientation: portrait) {
    z-index: 999;
    > div {
      &:nth-child(1) {
        position: relative;
        z-index: 3;
        background-color: #fff;
      }
    }
    .after_l {
      span {
        font-size: 0.24rem;
      }
    }
    .font_4 {
      font-size: 0.28rem;
    }
    > .pub_case {
      padding-left: 0.45rem;
      padding-right: 0.45rem;

      > a {
        width: 1.5rem;
      }

      .text-wrapper {
        padding: 0;

        span {
          font-size: 0.24rem;

          a {
            font-size: 0.24rem;
            padding: 0.2rem;
            line-height: 1;
          }
        }
      }
    }

    .nav_box {
      padding-top: 0.4rem;
      position: fixed;
      width: 100%;
      height: calc(100% - 1rem);
      left: 0;
      z-index: 2;
      top: 1rem;
      transform: translateY(-120%);
      transition: transform 0.5s ease-in-out;

      > .pub_case {
        display: block;

        .text-wrapper_4 {
          width: 100%;

          .sec_box {
            position: relative;
            left: 0;
            top: auto;
            width: 100%;
            transform: none;
            opacity: 1;
            display: none;
          }

          &:hover {
            .sec_box {
              display: block;
            }
          }
        }
      }

      &.on {
        transform: none;
      }
    }

    .lan_box {
      .lan_sel {
        width: 150%;
        left: -25%;
      }

      .lan_show {
        font-size: .24rem;
      }
    }
    .m_sty {
      width: .6rem;
      height: .6rem;
      display: flex;
      margin-left: .1rem;
      position: relative;

      span {
        position: absolute;
        width: 60%;
        height: 2px;
        left: 20%;
        top: 50%;
        background-color: #000;
        transform: translateY(-50%);
        transition: transform .5s ease, opacity .5s ease;

        &:nth-child(1) {
          transform: translateY(-350%);
        }

        &:nth-child(3) {
          transform: translateY(300%);
        }
      }

      &.open_on {
        span {
          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(1) {
            transform: translateY(-50%) rotate(45deg);
          }

          &:nth-child(3) {
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }

    .text-wrapper_4 {
      .sec_box {
        pointer-events: auto;

        ul li a {

          font-size: .24rem;
        }

      }
    }
  }
}
</style>
