<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col group space-y-40">
      <div class="pub_-flex-col pub_-self-center group_2 space-y-20 pub_case">
        <span class="pub_-self-start font_3 text_10 text_11">确认订单消息</span>
        <div class="pub_-flex-col section_3 space-y-40">
          <div class="pub_-flex-col">
            <div
              class="
                pub_-flex-row pub_-justify-around pub_-items-center
                section_4
              "
            >
              <div class="pub_-flex-row pub_-items-center space-x-83">
                <span class="font_4 text_10">课程标题</span>

                <span class="font_4 text_10">课程属性</span>

                <span class="font_4 text_10">订单总额</span>
                <span class="font_4 text_10">实付金额</span>
              </div>
            </div>
            <div class="pub_-flex-col pub_-justify-start section_5">
              <div
                class="pub_-flex-row group_8">
                <div
                  class="
                    pub_-flex-row pub_-items-center pub_-shrink-0
                    space-x-14
                  "
                >
                  <img class="image" :src="shopList.firstPicturePath"/>
                  <div class="pub_-flex-col pub_-justify-start group_1">
                    <div class="pub_-flex-col group_3">
                      <span class="text_12">{{ shopList.title }}</span>
                      <div
                        class="
                          pub_-flex-row pub_-items-center
                          group_6
                          space-x-9
                        "
                      >
                        <img class="image_2" :src="teacherInfo.avatarPath"/>
                        <span class="font_4 text_16">{{
                            teacherInfo.nickname
                          }}</span>
                      </div>
                      <span class="pub_-self-start font_1 text_17">{{ shopList.duration }}课时</span>
                    </div>
                  </div>
                </div>
                <span class="font_3 text_10 text_13">
                 <span class="m_sty">课程属性:</span> {{ grade }}
                </span>
                <span class="font_5 text_10 text_13"><span class="m_sty">订单总额:</span>￥{{ amount }}</span>
                <span class="font_5 text_10 text_13"><span class="m_sty">实付金额:</span>￥{{ sum }}</span>
              </div>
            </div>
          </div>

<!--          <div class="pub_-flex-col space-y-19">-->
<!--            <span class="pub_-self-start font_6 text_10 text_18"-->
<!--            >选择意向导师</span-->
<!--            >-->

<!--            <div class="tea_sel_box">-->
<!--              <el-select v-model="value" placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->
          <div class="pub_-flex-col space-y-19">
            <span class="pub_-self-start font_6 text_10 text_18"
            >选择付款方式</span
            >
            <div class="pub_-flex-row pub_-items-center">
              <el-radio-group v-model="radio">
<!--                <el-radio label="1"-->
<!--                ><img-->
<!--                  class="image_3"-->
<!--                  src="../../assets/09b21c58b9d82d059d2e492dfce27967.png"-->
<!--                />-->
<!--                  <span class="font_6 text_10 text_19">微信付款</span></el-radio-->
<!--                >-->
                <el-radio label="2">
                  <img
                    class="image_3"
                    src="../../assets/aa8eeff952b2db86c0b4584a0132d942.png"
                  />
                  <span class="font_6 text_10 text_19">支付宝</span>
                </el-radio>
                <el-radio label="3">
                  <img
                    class="image_3"
                    src="../../assets/be355aa026f71df00d26fb262cf8278e.png"
                  />
                  <span class="font_6 text_10 text_19">余额</span>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="pub_-flex-col space-y-18">
            <span class="pub_-self-start font_6 text_10 text_22"
            >需支付金额</span
            >
            <div
              class="
                pub_-flex-row pub_-justify-between pub_-items-center
                section_6
              "
            >
              <div class="pub_-flex-col space-y-20">
                <div class="pub_-flex-row pub_-items-center space-x-50">
                  <span class="font_4 text_10">总金额</span>
                  <span class="font_7 text_10">￥{{ sum }}</span>
                </div>
                <!-- <div class="pub_-flex-row space-x-37">
                  <span class="font_4 text_10">折扣优惠</span>
                  <span class="font_7 text_10 text_26">-￥511</span>
                </div> -->
                <div class="pub_-flex-row pub_-items-center space-x-36">
                  <span class="font_4 text_10">实付金额</span>
                  <span class="font_7 text_10">￥{{ sum }}</span>
                </div>
              </div>
              <div class="pub_-flex-row group_5">
                <span class="pub_-self-start text_10 text_24">￥</span>
                <div
                  class="
                    pub_-flex-col pub_-items-start pub_-shrink-0
                    space-y-20
                  "
                >
                  <span class="text_10 text_23">{{ sum }}</span>
                  <span class="font_6 text_10 text_27">应付金额</span>
                </div>
                <div
                  class="
                    pub_-flex-col
                    pub_-justify-start
                    pub_-items-center
                    pub_-shrink-0
                    pub_-self-center
                    text-wrapper_3
                    btn_sty
                  "
                  @click="payBtn()"
                >
                  <span class="font_2 text_25">确认支付</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 充值 -->
      <div
        class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
        v-if="topUP == true"
      >
        <div class="pub_-flex-col group_1a">
          <div class="pub_-flex-row section_6a space-x-463a">
            <span class="font_4a text_14a">余额充值</span>
            <img
              class="pub_-shrink-0 imagea"
              src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
              @click="closeSha"
            />
          </div>
          <div class="pub_-flex-col section_7a">
            <span class="pub_-self-start font_6a text_18a">充值金额</span>
            <div class="pub_-flex-row group_3a space-x-477a">
              <span class="text_11a text_21a">{{ numP }}</span>
              <!-- <img class="pub_-shrink-0 image_2a" src="../../assets/9c674996042f411fd048987216946219.png" /> -->
            </div>
            <div class="divider_2a"></div>

            <div class="pub_-flex-row group_4a space-x-16a">
              <div
                class="pub_-flex-row pub_-items-center section_8a space-x-7a"
                v-for="(item, i) in ruleList"
                :key="i"
                @click="selRul(item.id, item.payMoney)"
                :class="pas.ruleId == item.id ? 'on' : ''"
              >
                <div
                  class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 section_11a"
                >
                  <div
                    class="pub_-flex-col pub_-justify-start pub_-items-center section_13a"
                  >
                    <div class="pub_-flex-col pub_-justify-start text-wrapper_5a">
                      <span class="font_8a text_11a text_28a">￥</span>
                    </div>
                  </div>
                </div>
                <span class="font_7a text_11a text_24a">{{ item.name }}</span>
              </div>
            </div>
            <div class="pub_-flex-row pub_-items-center group_8a">
              <span class="font_6a text_33a">充值方式</span>

              <el-radio
                v-for="(item, i) in payChanne"
                :key="i"
                v-model="pas.payChannelId"
                :label="item"
              >
                <template v-if="item == 2">
                  <img
                    class="image_3a image_4a"
                    src="../../assets/aa8eeff952b2db86c0b4584a0132d942.png"
                  />
                  <span class="font_10a text_11a text_35a">支付宝</span>
                </template>
<!--                <template v-else-if="item == 1">-->
<!--                  <img-->
<!--                    class="image_3a image_5a"-->
<!--                    src="../../assets/a129daff2b6c1c1917ddd5efe52c95f1.png"-->
<!--                  />-->
<!--                  <span class="font_10a text_11a text_35a">微信</span>-->
<!--                </template>-->
                <template v-else>
                  <img
                    class="image_3a image_5a"
                    src="../../assets/be355aa026f71df00d26fb262cf8278e.png"
                  />
                  <span class="font_10a text_11a text_35a">余额</span>
                </template>
              </el-radio>
            </div>
            <!-- <img
              class="pub_-self-center image_7a"
              src="../../assets/9f632cb4ede9b6d32741cf5348eaf96d.png"
            /> -->

            <div class="btn_sty" @click="confirmRecharge">确认充值</div>
          </div>
        </div>
      </div>


      <!-- 确认支付 二维码 -->
      <div
        class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos wall_sha"
        v-if="ackShow == true"
      >

        <div v-if="suta == 1" class="pub_-flex-col group_1a">
          <div class="pub_-flex-row section_6a space-x-463a">
            <span class="font_4a text_14a">确认充值</span>
            <img
              class="pub_-shrink-0 imagea"
              src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
              @click="beforeDestroy()"
            />
          </div>
          <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a"
          >充值金额:<span>{{ numP }}</span></span
          >

            <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a"
            >充值方式:
<!--              <span v-if="pas.payChannelId == 1">微信</span>-->
              <span v-if="pas.payChannelId == 2">支付宝</span>
              <span v-else>余额</span>
            </span>
            </div>
            <img v-if="pas.payChannelId == 1" class="pub_-self-center image_7a" :src="codeImg" />
            <div v-else-if="pas.payChannelId == 2" class="hint_pay">支付中...</div>


          </div>
        </div>



        <div v-else class="pub_-flex-col group_1a">
          <div class="pub_-flex-row section_6a space-x-463a">
            <span class="font_4a text_14a">确认支付：</span>
            <img
              class="pub_-shrink-0 imagea"
              src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
              @click="beforeDestroy()"
            />
          </div>
          <div class="pub_-flex-col section_7a">
          <span class="pub_-self-start font_6a text_18a"
          >支付金额:<span>{{ sum }}</span></span>

            <div class="pub_-flex-row pub_-items-center group_8a">
            <span class="font_6a text_33a"
            >支付方式:
<!--              <span v-if="radio == 1">微信</span>-->
              <span v-if="radio == 2">支付宝</span>
              <span v-else>余额</span>
            </span>
            </div>


            <img v-if="radio == 1" class="pub_-self-center image_7a" :src="codeImg" />
            <div v-else-if="radio == 2" class="hint_pay">支付中...</div>

          </div>
        </div>


      </div>


    </div>
    <foot-box></foot-box>


  </div>
</template>

<script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";
import QRCode from "qrcode";

export default {
  components: {NavBox, FootBox},
  data() {
    return {
      shopList: [],
      teacherInfo: [],
      grade: "无",
      amount: '',
      sum: '',

      skuId: 0,

      options: [
        {
          value: "",
          label: "",
        },

      ],
      value: "选项1",

      radio: "1", //付款方式
      id: '',

      ackShow: false,
      codeImg: "",

      apiUrl: "http://39.100.254.122/api.order/getPayStatus",
      interval: 1000, // 每隔5秒轮询一次
      timer: null,
      data: null,

      topUP:false,//是否显示充值
      numP:0,//充值金额
      ruleList: [],//充值规则选择
      pas: {
        ruleId: "",
        payChannelId: "1",
      },
      payChanne: [],
      rechargeRadio:'1',//充值支付方式

      suta:2,//默认是支付订单

      jumPath: '',//支付完成跳转支付成功页面

    };
  },
  created() {
    this.getParam('type');
    this.ajaxH(null, 0);
  },
  methods: {
    //充值
    closeSha() { //关闭充值窗口
      this.topUP = false;
    },
    async getRuleList(id, i) {

      let params = {};
      const { data: rul } = await this.$http.post(
        "/api.recharge/getRuleList",
        params
      );
      this.ruleList = rul.data.ruleList;
      this.numP = this.ruleList[0].payMoney;
      this.pas.ruleId = this.ruleList[0].id;
      this.payChanne = rul.data.payChannelId.split(",");
      this.pas.payChannelId = this.payChanne[0];



      },
    selRul(id, name) {//选择充值金额
      this.numP = name;
      this.pas.ruleId = id;
    },
    async confirmRecharge() {
      let that = this;
      this.topUP = false;
      let param = this.pas;
      const { data: res } = await this.$http.post(
        "/api.recharge/placeOrder",
        param
      );
      console.log(res.data);

      if (that.pas.payChannelId == '2'){
        window.open(res.data.data.url, '_blank');
      }else {
        const QRCode = require("qrcode");
        const text = res.data.qrCode;
        QRCode.toDataURL(text, function (err, url) {
          that.codeImg = url;
        });

      }
      this.ackShow = true;
      this.timer = setInterval(() => {
        this.pollData(res.data.orderId, 'recharge');
      }, this.interval);

    },

    async ajaxH(id, i) {
      this.getInfo(); //获取详情接口
      this.getRuleList();//获取充值规则
    },
    async getInfo(id, i) {


      let param = {
        id: this.getParam('id')
      };

      // console.log(param)
      const {data: res} = await this.$http.post(
        "/api.course/getInfo",
        param
      );

      // console.log(res);

      this.shopList = res.data;
      this.teacherInfo = res.data.teacherInfo;
      if (res.data.skuList.length > 0){
        res.data.skuList.forEach(item => {
          if (item.id == this.getParam('skuId')) {

            this.grade = item.name;
            this.amount = item.price;
            this.sum = item.price

          } else {
            // this.grade = '系统课程';
            this.amount = res.data.minPrice;
            this.sum = res.data.minPrice
          }
        });
      }else {
        // this.grade = '系统课或录播';
        this.amount = res.data.minPrice;
        this.sum = res.data.minPrice
      }


      // this.value = res.data.teacherData[0].id;
      // let newTeacher = [];
      //
      // res.data.teacherData.forEach(item => {
      //   let linS = {};
      //   linS.value = item.id;
      //   linS.label = item.nickname;
      //   newTeacher.push(linS)
      // })
      // this.options = newTeacher;


    },
    async payBtn() {
      let self = this;

      let param = {
        courseId: this.getParam('id'), //课程id
        payChannelId: this.radio,//支付渠道id
        // intentionTeacherId: this.value,//意向教师
        skuId: this.getParam('skuId') == 0 ? '' : this.getParam('skuId'),  //课程属性
      }, that = this;


      const {data: res} = await this.$http.post(
        "/api.order/placeOrder",
        param
      );
      // console.log(res);



      if (res.code  != 0){
        this.$message({
          message: res.msg,
          type: 'error'
        });

        if (res.msg == '余额不足，请充值'){
          self.topUP = true;
          self.suta = 1;//充值二维码
        }

      }else {
        this.jumPath = res.data.data.jumpUrl;
        self.suta = 2;//支付二维码
        if(self.radio != 3){
          if (self.radio == 2){
            //支付宝支付跳链接
            window.open(res.data.data.url, '_blank');
          }else {
            const QRCode = require("qrcode");
            const text = res.data.qrCode;
            QRCode.toDataURL(text, function (err, url) {
              // 输出二维码图片的 base64 编码字符串
              that.codeImg = url;
            });

          }

          this.ackShow = true;
          // console.log(res.data.orderId)
          // 启动轮询函数
          // this.timer = setInterval(this.pollData(res.data.orderId), this.interval);
          this.timer = setInterval(() => {
            this.pollData(res.data.orderId);
          }, this.interval);

          // this.$router.push(path);

        }else {
          //余额支付


          window.location.href = this.jumPath ;  //支付成功跳转页面



        }


      }


    },
    async pollData(id,type) {

      const {data: res} = await this.$http.post("/api.order/getPayStatus", {id: id,type:1})

      // console.log(res)

      if (res.data.payStatus == 2) {
        if (type == 'recharge'){
          this.$message({
            message: '充值成功',
            type: 'success'
          });
          this.topUP = false;
        }else {

          window.location.href = this.jumPath;  //支付成功跳转页面

        }
        this.beforeDestroy();
      }


    },
    beforeDestroy() {
      // 清除轮询定时器
      this.ackShow = false;
      clearInterval(this.timer);
    },
    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },

    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #fbfbfb;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}

.text {
  color: #ffffff;
  line-height: 0.15rem;
}

.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}

.group {
  padding-bottom: 0.8rem;
  padding-top: 0.4rem;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.space-y-20 > *:not(:first-child) {
  margin-top: 0.2rem;
}

.font_3 {
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.17rem;
  color: #2b2c30;
}

.section_3 {
  padding: 0.42rem 0.4rem 0.04rem;
  background-color: #ffffff;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}

.section_4 {
  background-color: #edeff2;
  height: 0.54rem;
  align-items: center;
}

.space-x-83 {
  width: 100%;
  height: 100%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      width: 4.46rem;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: calc((100% - 4.46rem) / 3);
    }
  }
}

.text-wrapper_2 {
  padding: 0.2rem 0;
  background-color: #edeff2;
  width: 4.46rem;
  height: 0.54rem;
}

.font_4 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.14rem;
  color: #2b2c30;
}

.section_5 {
  padding: 0.17rem 0 0.41rem;
  background-color: #ffffff;
  border: solid 0.01rem #edeff2;
}

.space-x-14 {
  width: 4.46rem;
  padding-left: 0.2rem;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.image {
  border-radius: 0.08rem;
  width: 1.84rem;
  height: 1.03rem;
}

.group_1 {
  width: 2.02rem;
  height: 0.91rem;
}

.group_3 {
  width: 2.02rem;
}

.text_12 {
  color: #2b2c30;
  font-size: 0.16rem;
  font-family: Adobe Heiti Std;
  line-height: 0.24rem;
}

.group_6 {
  margin-top: 0.09rem;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}

.image_2 {
  width: 0.34rem;
  height: 0.34rem;
  border-radius: 100%;
}

.text_16 {
  line-height: 0.17rem;
}

.text_17 {
  margin-top: 0.19rem;
  color: #a1a9b2;
  font-weight: 300;
  line-height: 0.13rem;
}

.font_5 {
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.14rem;
  color: #e4393c;
}

.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}

.font_6 {
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.17rem;
  color: #2b2c30;
}

.image_3 {
  border-radius: 0.04rem;
  width: 0.24rem;
  height: 0.24rem;
}

.image_6 {
  width: 0.16rem;
  height: 0.16rem;
}

.image_4 {
  margin-left: 0.2rem;
}

.image_5 {
  margin-left: 0.19rem;
}

.space-y-18 > *:not(:first-child) {
  margin-top: 0.18rem;
}

.section_6 {
  padding: 0.78rem 0.18rem;
  background-color: #ffffff;
  border: solid 0.01rem #edeff2;
}

.space-x-50 > *:not(:first-child) {
  margin-left: 0.5rem;
}

.font_7 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.11rem;
  color: #2b2c30;
}

.text_10 {
  text-transform: uppercase;
}

.text_22 {
  line-height: 0.16rem;
}

.text_21 {
  margin-left: 0.08rem;
  line-height: 0.15rem;
}

.text_20 {
  margin-left: 0.08rem;
  line-height: 0.16rem;
}

.text_19 {
  margin-left: 0.08rem;
  line-height: 0.16rem;
}

.text_18 {
  line-height: 0.16rem;
}

.text_15 {
  margin-left: 1.53rem;
  margin-top: 0.55rem;
  line-height: 0.15rem;
}

.text_14 {
  margin-left: 1.57rem;
  margin-top: 0.55rem;
  line-height: 0.15rem;
}

.group_8 {
  align-items: center;
}

.text_13 {
  width: calc((100% - 4.46rem) / 3);
  padding: 0 0.1rem;
  text-align: center;
}

.text_11 {
  line-height: 0.18rem;
}

.space-x-37 > *:not(:first-child) {
  margin-left: 0.37rem;
}

.text_26 {
  line-height: 0.12rem;
}

.space-x-36 > *:not(:first-child) {
  margin-left: 0.36rem;
}

.group_5 {
  margin-right: 0.22rem;
}

.text_24 {
  margin-top: 0.09rem;
  color: #e4393c;
  font-size: 0.2rem;
  font-family: OPPOSans;
  line-height: 0.16rem;
}

.text_23 {
  margin-left: 0.04rem;
  color: #e4393c;
  font-size: 0.28rem;
  font-family: OPPOSans;
  line-height: 0.25rem;
}

.text_27 {
  line-height: 0.16rem;
}

.text-wrapper_3 {
  margin-left: 0.4rem;
  border-radius: 0.04rem;
  width: 1.41rem;
  height: 0.45rem;
}

.text_25 {
  line-height: 0.18rem;
  letter-spacing: 0.025rem;
}

.wall_sha {
  &.section_3a {
    padding: 2.4rem 0 2.86rem;
    background-color: #051b3733;
  }

  .font_4a {
    font-size: 0.18rem;
    line-height: 0.17rem;
    font-weight: 500;
    color: #2b2c30;
  }

  .font_10a {
    font-size: 0.16rem;
    line-height: 0.1rem;
    color: #2b2c30;
  }

  &.pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    margin-top: 0;
  }

  .group_1a {
    width: 6rem;
  }

  .section_6a {
    padding: 0.26rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0px 0px;
    border-bottom: solid 0.01rem #f3f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_14a {
    line-height: 0.18rem;
    width: 1.2rem;
    display: block;
  }

  .imagea {
    margin-right: 0.06rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .section_7a {
    padding: 0.34rem 0.2rem 0.39rem;
    background-color: #ffffff;
    border-radius: 0px 0px 0.14rem 0.14rem;

    .btn_sty {
      width: 1.5rem;
      margin: 0.8rem auto 0;
      height: 0.45rem;
      font-size: 0.18rem;
    }
  }

  .font_6a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_18a {
    line-height: 0.16rem;
  }

  .group_3a {
    margin-top: 0.13rem;
  }

  .space-x-477a > *:not(:first-child) {
    margin-left: 4.77rem;
  }

  .image_2a {
    width: 0.24rem;
    height: 0.24rem;
  }

  .divider_2a {
    margin-top: 0.06rem;
    background-color: #2080f7;
    height: 0.01rem;
  }

  .group_4a {
    margin-top: 0.2rem;
  }

  .space-x-16a > *:not(:first-child) {
    margin-left: 0.16rem;
  }

  .section_8a {
    padding: 0.1rem 0.32rem 0.1rem 0.39rem;
    background-color: #f2f8ff;
    height: 0.44rem;

    &.on {
      background-color: rgba(32, 128, 247, 1);

      .font_7a {
        color: #fff;
      }
    }
  }

  .space-x-7a > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .section_11a {
    background-image: url("../../assets/b39e5272ae1fa5384043d72be101830d.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .section_13a {
    padding: 0.02rem 0;
    background-image: url("../../assets/9807e0680566a77a665fabdbe54aace1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.2rem;
  }

  .text-wrapper_5a {
    padding: 0.02rem 0;
    background-image: url("../../assets/c7222962c8899b1a18e7d0ae663528a4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .font_8a {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.1rem;
    color: #ed8a18;
  }

  .font_7a {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.17rem;
    color: #2080f7;
  }

  .text_11a {
    text-transform: uppercase;
  }

  .text_28a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_21a {
    color: #2b2c30;
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.23rem;
  }

  .text_38a {
    line-height: 0.16rem;
  }

  .text_37a {
    line-height: 0.16rem;
  }

  .text_32a {
    line-height: 0.02rem;
  }

  .text_30a {
    line-height: 0.16rem;
  }

  .text_27a {
    margin-top: 0.4rem;
    line-height: 0.18rem;
  }

  .text_19a {
    color: #e4393c;
    font-size: 0.2rem;
    font-family: OPPOSans;
    line-height: 0.16rem;
  }

  .text_15a {
    font-weight: 300;
    line-height: 0.16rem;
  }

  .text_12a {
    line-height: 0.18rem;
  }

  .text_24a {
    line-height: 0.18rem;
  }

  .section_9a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #2080f7;
    height: 0.44rem;
  }

  .section_12a {
    background-image: url("../../assets/c3c557f93ff35c3de06a9b0c39f24b4c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.24rem;
    height: 0.24rem;
  }

  .text_29a {
    font-size: 0.13rem;
    line-height: 0.11rem;
  }

  .text_25a {
    color: #ffffff;
    line-height: 0.18rem;
  }

  .section_10a {
    padding: 0.1rem 0.32rem 0.1rem 0.35rem;
    background-color: #f2f8ff;
    height: 0.44rem;
  }

  .space-x-8a > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text_26a {
    line-height: 0.18rem;
  }

  .group_8a {
    margin-top: 0.4rem;

    .el-radio {
      display: flex;
      align-content: center;
      margin-left: 0.3rem;
      margin-right: 0;

      ::v-deep .el-radio__input {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-radio__label {
        display: flex;
        align-items: center;
      }
    }
  }

  .text_33a {
    line-height: 0.16rem;
  }

  .image_3a {
    border-radius: 0.04rem;
    width: 0.24rem;
    height: 0.24rem;
  }

  .image_6a {
    width: 0.16rem;
    height: 0.16rem;
  }

  .text_35a {
    margin-left: 0.08rem;
    line-height: 0.15rem;
  }

  .image_7a {
    margin-top: 0.4rem;
    width: 1.81rem;
    height: 1.8rem;
  }
  .hint_pay{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
    font-family: SourceHanSansCN;
    font-size: 0.2rem;
    color: #2b2c30;

  }
}

.m_sty{
  display: none;
}
 @media screen and (orientation: portrait){
   .m_sty{
     display: block;
   }
   .section_4{
     display: none;
   }
.font_3{
  font-size: .24rem;
  line-height: 1;
}
   .section_3{
     padding: .2rem;
   }
   .section_5{
     padding: .3rem;
   }
.group_8{
  flex-wrap: wrap;
  .space-x-14{
    width: 100%;
    flex-wrap: wrap;
    padding-left: 0;
    .image{
      width: 100%;
      height: 3.12rem;
    }
    .group_1{
      padding-top: .3rem;
      width: 100%;
      margin: 0;
      height: auto;
    }
    .group_3{
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      .text_12{
        font-size: .32rem;
        line-height: 1.3;
        width: 100%;
      }
      .group_6{
        margin-top: .2rem;
        width: 50%;
        .image_2{
          width: .5rem;
          height: .5rem;
        }
        .text_16{
          font-size: .2rem;
          line-height: 1;
        }
      }
      .font_1{
        line-height: .5rem;
        font-size: .2rem;
      }


    }
  }
  .text_13{
    width: 50%;
    display: flex;
  }
  .font_3{
    font-size: .2rem;
    line-height: .5rem;
  }
  .font_5{
    line-height: .5rem;
    font-size: .2rem;
  }
  .text_13{
    padding: 0;
  }.m_sty{
  color: #000;
  margin-right: .1rem;
     }




}
   .text_18{
     font-size: .2rem;
     line-height: 1;
   }

.image_3{
  width: .5rem;
  height: .5rem;
}
   .font_6{
     font-size: .2rem;
     line-height: 1;
   }
   .text_19{
     margin-left: .12rem;
   }
   .el-radio__inner::after{
     width: 7px;
     height: 7px;
   }
   .el-radio-group .el-radio{
     margin-right: .4rem;
   }


   .section_6{
     padding: .3rem;
     flex-wrap: wrap;
     >.space-y-20{
       width: 100%;
       display: flex;
       flex-direction: row;
       > div{
         width: 50%;
         align-items: baseline;
       }

       .space-x-36{
         margin-top: 0;
       }

       .font_4{
         font-size: .2rem;
         line-height: 1;
       }
       .font_7{
         font-size: .36rem;
         line-height: 1;
         margin-left: .2rem;
       }
     }

  .text-wrapper_3{
    width: auto;
    padding: 0 .3rem;
    height: auto;
    margin-left: auto;

    .font_2{
      font-size: .24rem;
      line-height: .6rem;
    }

  }
     .text_24{
       margin-top: .12rem;
     }
     .group_5{
       margin-right: 0;
       width: 100%;
       margin-top: .4rem;
       .text_23{
         font-size: .36rem;
         line-height: 1;
       }
       .font_6{
         margin-top: .1rem;
       }
     }

   }


   .wall_sha {
     &.section_3a {


     }

     .font_4a {
       font-size: 0.28rem;
       line-height:1;


     }

     .font_10a {
       font-size: .2rem;
       line-height:1;
     }

     &.pos {


     }

     .group_1a {


     }

     .section_6a {


     }

     .text_14a {


     }

     .imagea {
       margin-right: .1rem;
       width: 0.4rem;
       height: 0.4rem;


     }

     .section_7a {


       .btn_sty {


         height: 0.6rem;
         font-size: 0.24rem;
       }
     }

     .font_6a {
       font-size: .24rem;
       line-height: 1;

     }

     .text_18a {
       line-height: 1;
     }

     .group_3a {
       margin-top: .2rem;
     }

     .space-x-477a > *:not(:first-child) {
       margin-left: 4.77rem;
     }

     .image_2a {
       width: 0.5rem;
       height: 0.5rem;
     }

     .divider_2a {


     }

     .group_4a {


     }

     .space-x-16a > *:not(:first-child) {
       margin-left: 0.2rem;
     }

     .section_8a {


       height: 0.6rem;



     }

     .space-x-7a > *:not(:first-child) {
       margin-left: 0.1rem;
     }

     .section_11a {

     }

     .section_13a {

     }

     .text-wrapper_5a {


     }

     .font_8a {
       font-size: 0.2rem;


     }

     .font_7a {
       font-size: 0.2rem;
       line-height: 1;
     }

     .text_11a {

     }

     .text_28a {
       font-size: 0.2rem;
       line-height: 1;
     }

     .text_21a {
       font-size: 0.38rem;
       line-height: 1;
     }

     .text_38a {
       line-height: 0.2rem;
     }

     .text_37a {
       line-height: 1;
     }

     .text_32a {
       line-height: 1;
     }

     .text_30a {
       line-height: 1;
     }

     .text_27a {


       line-height: 1.2;
     }

     .text_19a {
       font-size: 0.2rem;
       line-height: 1;
     }

     .text_15a {
       line-height: 1;
     }

     .text_12a {
       line-height:1;
     }

     .text_24a {
       line-height: 1;
     }

     .section_9a {

       height: .6rem;
     }

     .section_12a {

       width: 0.5rem;
       height: 0.5rem;
     }

     .text_29a {
       font-size: 0.2rem;
       line-height: 1;
     }

     .text_25a {
       line-height: 1;
     }

     .section_10a {

       height: 0.6rem;
     }

     .space-x-8a > *:not(:first-child) {
       margin-left: 0.08rem;
     }

     .text_26a {
       line-height:1;
     }

     .group_8a {


       .el-radio {


         ::v-deep .el-radio__input {

         }

         ::v-deep .el-radio__label {

         }
       }
     }

     .text_33a {
       line-height:1;
     }

     .image_3a {

       width: 0.5rem;
       height: 0.5rem;
     }

     .image_6a {
       width: 0.3rem;
       height: 0.3rem;
     }

     .text_35a {
       margin-left: 0.1rem;
       line-height: 1;
     }

     .image_7a {
       width: 2.3rem;
       height: 2.3rem;
     }
     .hint_pay{




     }
   }


}



</style>
