<template>
  <footer class="pub_-flex-col group_54">
    <div class="pub_-flex-row pub_-justify-between pub_-self-center group_47">
      <div class="pub_-flex-row group_48 space-x-60">
        <div class="pub_-flex-col">
          <span class="pub_-self-start font_2 text_45">咨询热线</span>
          <span class="pub_-self-start font_28 text_46">{{
              config.consultPhone
            }}</span>
          <span class="pub_-self-start font_2 text_51">售后热线</span>
          <span class="pub_-self-start font_28 text_56">{{
              config.afterSalePhone
            }}</span>
          <span class="pub_-self-start font_2 text_65">关注我们</span>
          <div class="pub_-flex-row group_49 space-x-60">
            <div class="section_39">
              <img :src="config.weChatServiceQRCodePath" alt="服务号"/>
            </div>
            <div class="section_39">
              <img :src="config.weChatRecruitQRCodePath" alt="招聘号"/>
            </div>
          </div>
          <div class="pub_-flex-row group_50 space-x-123">
            <span class="font_2">服务号</span>
            <span class="font_2">招聘号</span>
          </div>
        </div>
        <div class="section_38"></div>
      </div>
      <div class="pub_-flex-row space-x-77">
        <div
          class="pub_-flex-col pub_-items-start"
          v-for="(item, i) in footB"
          :key="i"
        >
          <span class="font_4">{{ item.name }}</span>

          <a
            v-for="(items, j) in item.navList"
            :key="j"
            class="font_2 text_47"
            :href="items.href"
          ><span>{{ items.name }}</span></a
          >
        </div>
      </div>
    </div>
    <div class="divider"></div>

    <div class="pub_-flex-row pub_-self-start group_51">
      <span class="font_1">Copyright©{{ config.webName }}</span>
      <span class="font_1">{{ config.recordNo }}</span
      >
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      config: {},
      footB: [],
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.getConfig(); //获取设置接口
      this.getFooterNav(); //获取导航接口
    },
    async getConfig(id, i) {
      this.active = i;

      let param = {};
      const {data: res} = await this.$http.post("/api.setUp/getConfig", param);
      this.config = res.data;
    },
    async getFooterNav(id, i) {
      let param = {};
      const {data: res} = await this.$http.post(
        "/api.setUp/getFooterNav",
        param
      );
      this.footB = res.data;
      // console.log(this.footB);

    },

    goTab(e, id) {
      let fullPath = e.currentTarget.dataset.url;
      if (id) {
        fullPath = `${e.currentTarget.dataset.url}?id=${id}`;
      }
      window.location.href = fullPath;
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  padding-top: 0.8rem;
  padding-bottom: 0.44rem;
  background-color: #fff;

  .font_4 {
    font-size: 0.18rem;
    line-height: 0.18rem;
    color: #2b2c30;
  }

  .font_2 {
    font-size: 0.14rem;
    line-height: 0.14rem;
    color: #2b2c30;
  }

  .font_1 {
    font-size: 0.14rem;
    line-height: 0.16rem;
    color: #2b2c30;
  }

  .group_47 {
    padding-bottom: 0.28rem;
    width: 11.27rem;
  }

  .group_48 {
    margin-bottom: 0.04rem;
  }

  .text_45 {
    line-height: 0.15rem;
  }

  .font_28 {
    font-size: 0.22rem;
    font-family: OPPOSans;
    line-height: 0.18rem;
    color: #2b2c30;
  }

  .text_46 {
    margin-top: 0.14rem;
    line-height: 0.19rem;
  }

  .text_51 {
    margin-top: 0.29rem;
    line-height: 0.15rem;
  }

  .text_56 {
    margin-top: 0.14rem;
    line-height: 0.19rem;
  }

  .text_65 {
    margin-top: 0.29rem;
    line-height: 0.15rem;
  }

  .group_49 {
    margin-top: 0.19rem;
  }

  .space-x-60 > *:not(:first-child) {
    margin-left: 0.6rem;
  }

  .section_39 {
    background-color: #1c3754;
    width: 1.08rem;
    height: 1.08rem;

    img {
      display: block;
      width: 100%;
    }
  }

  .group_50 {
    margin-top: 0.14rem;
    padding-left: 0.33rem;
    padding-right: 0.3rem;
  }

  .space-x-123 > *:not(:first-child) {
    margin-left: 1.23rem;
  }

  .section_38 {
    background-color: #e0e0e0;
    width: 0.01rem;
    height: 3.22rem;
  }

  .space-x-77 > *:not(:first-child) {
    margin-left: 0.77rem;
  }

  .space-x-77 {
    a {
      cursor: pointer;
      transition: color 0.5s ease;

      @media screen and (orientation: landscape) {
        &:hover {
          color: #2080f7 !important;
        }
      }
    }
  }

  .text_47 {
    margin-top: 0.3rem;
  }

  .text_52 {
    margin-top: 0.19rem;
  }

  .text_57 {
    margin-top: 0.19rem;
  }

  .text_61 {
    margin-top: 0.19rem;
  }

  .text_66 {
    margin-top: 0.19rem;
  }

  .text_70 {
    margin-top: 0.19rem;
  }

  .text_74 {
    margin-top: 0.2rem;
  }

  .space-x-53 > *:not(:first-child) {
    margin-left: 0.53rem;
  }

  .text_48 {
    margin-top: 0.3rem;
  }

  .text_53 {
    margin-top: 0.19rem;
  }

  .text_58 {
    margin-top: 0.19rem;
  }

  .text_62 {
    margin-top: 0.19rem;
  }

  .text_67 {
    margin-top: 0.19rem;
  }

  .text_71 {
    margin-top: 0.19rem;
  }

  .text_49 {
    margin-top: 0.3rem;
  }

  .text_54 {
    margin-top: 0.19rem;
  }

  .text_59 {
    margin-top: 0.19rem;
  }

  .text_63 {
    margin-top: 0.19rem;
  }

  .text_68 {
    margin-top: 0.19rem;
  }

  .text_72 {
    margin-top: 0.19rem;
  }

  .text_50 {
    margin-top: 0.3rem;
  }

  .text_55 {
    margin-top: 0.19rem;
  }

  .text_60 {
    margin-top: 0.19rem;
  }

  .text_64 {
    margin-top: 0.19rem;
  }

  .text_69 {
    margin-top: 0.19rem;
  }

  .text_73 {
    margin-top: 0.18rem;
    line-height: 0.15rem;
  }

  .text_75 {
    margin-top: 0.19rem;
  }

  .text_76 {
    margin-top: 0.2rem;
  }

  .text_77 {
    margin-top: 0.2rem;
  }

  .divider {
    background-color: #e0e0e0;
    height: 0.02rem;
  }

  .group_51 {
    margin-left: 3.7rem;
    padding-top: 0.28rem;
  }

  @media screen and (orientation: portrait) {
    padding-right: .45rem;
    padding-left: .45rem;
    padding-bottom: .3rem;
    .group_47 {
      width: 100%;
      flex-direction: column-reverse;

      .font_4 {
        font-size: .24rem;
        line-height: 1;
      }

      .font_2 {
        font-size: .2rem;
        line-height: 1;
      }

      .space-x-77 {
        width: 100%;
        justify-content: space-between;

        > *:not(:first-child) {
          margin-left: 0;

        }
      }

    }

    .group_48 {
margin-top: .4rem;
      width: 100%;
      .section_38{
        display: none;
      }
      >.pub_-flex-col{
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;

        >span{
          width: 5em;
          line-height: 2;
          margin-top: 0;
          &:nth-child(2n){
            width: calc(100% - 5em);
            margin-top: 0;
            padding-left: .1rem;
          }
        }
      }

      .font_28{
        font-size: .24rem;
        line-height: 1;
      }
    }
.section_39{
  width: 1.2rem;
  height: 1.2rem;
}
.group_50{
  margin-top: .2rem;
  padding: 0;
  padding-left: 5em;
  font-size: .2rem;
  .font_2{
    width: 1.2rem;
    margin-right: .6rem;
    text-align: center;

    &:last-child{
      margin-right: 0;
      margin-left: 0;
    }
  }
}
    .group_51{
      margin-left: 0;
      display: block;

      .font_1{
        font-size: .2rem;
        line-height: 1.5;
      }
    }


  }

}
</style>
