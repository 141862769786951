<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col space-y-40">


      <div class="pub_-flex-row pub_-self-center group_2 space-x-40 pub_case">

        <left-nav></left-nav>
        <div class="tim_con">

          <div class="tab_box"  v-show="viewHen == true">
            <div :class="calendar == true? 'on':'' " @click="calendar=true">日历</div>
            <div :class="calendar != true? 'on':'' " @click="calendar=false">列表</div>
          </div>

          <div v-show="calendar==true">

            <div id="calendar"></div>

          </div>
          <div v-show="calendar==false">
            <div class="sear_case">
              <div class="sear_box">
                <img
                  class="sea_image"
                  src="../../assets/8e844837e61b01c621820878e260fae4.png"
                /> <input type="text" placeholder="请输入订单号或课程名称进行搜索" v-model="search.name"/>
              </div>
              <button class="btn_sty" @click="ajaxH()">搜索</button>
            </div>


            <div class="con_con">
              <div class="con_block" v-for="(item,i) in showList" :key="i">

                <div class="con_left">

                  <div class="con_det">
                    <img :src="item.firstPicturePath" class="con_img" alt="课程图片">
                    <div class="con_txt">
                      <div class="k_name ord_txt">课程名称：<span>{{ item.title }}</span></div>
                      <div class="con_l_btm">
                        <div class="ord_txt m_sty">

                          <span class="text_21"
                                v-if="item.status == 1 || item.status == 2 ||item.status == 3">等待上课</span>
                          <span class="text_21_3" v-else-if="item.status == 4">上课中</span>
                          <span class="text_21_2" v-else>已结束</span>
                        </div>
                        <div class="ord_txt pc_sty">教师：<span>{{ item.teacherName }}</span></div>
                        <div class="ord_txt"
                             v-if="item.startTime != '' && item.startTime != undefined && item.startTime != null">
                          时间：<span>{{ item.startTime }}</span></div>
                      </div>

                      <div class="con_right m_sty">
                        <div v-if="item.status == 1 || item.status == 2 ||item.status == 3" class=" btn_sty"
                             @click="cancelCooking($event,item.id)">取消预约
                        </div>
                        <div v-else-if="item.status == 4" class="btn_sty" @click="getAccessToken(item.id)">去上课</div>
                      </div>

                    </div>

                  </div>

                </div>
                <div class="con_center pc_sty">
                  <div class="ord_txt">
                    <span class="m_sty">上课状态：</span>
                    <span class="text_21" v-if="item.status == 1 || item.status == 2 ||item.status == 3">等待上课</span>
                    <span class="text_21_3" v-else-if="item.status == 4">上课中</span>
                    <span class="text_21_2" v-else>已结束</span>
                  </div>
                </div>
                <div class="con_right pc_sty">
                  <div v-if="item.status == 1 || item.status == 2 ||item.status == 3" class=" btn_sty"
                       @click="cancelCooking($event,item.id)">取消预约
                  </div>
                  <div v-else-if="item.status == 4" class="btn_sty" @click="getAccessToken(item.id)">去上课</div>
                </div>


              </div>


              <Pagination
                @change="changePage"
                :current="pagination.p"
                :pageSize="pagination.pageSize"
                :total="total"
                :hideOnSinglePage="hideOnSinglePage"
                :showQuickJumper="true"
                :showTotal="true"
                placement="right"
                v-if="pagination && total"
              />
            </div>

          </div>


        </div>


        <div style="display: none" class="pub_-flex-col pub_-justify-start pub_-flex-auto pub_-relative group_3">
          <div class="pub_-flex-row pub_-items-center zind_2">
            <div class="pub_-flex-row pub_-justify-center pub_-items-center pos_2 space-x-9">
              <img class="image" src="../../assets/5b14ff003e58d39eaf5d022dc6f9287f.png"/>
              <span class="text_11">课堂目录</span>
            </div>
            <div class="pub_-flex-row pub_-justify-center pub_-items-center section_6 space-x-12">
              <img class="image_2" src="../../assets/ef7fcd2321b75af50cb33d60dc0dd3c5.png"/>
              <span class="font_2 text_12">直播列表</span>
            </div>
          </div>
          <div class="pub_-flex-col pub_-justify-start group_4 pos">
            <div class="pub_-flex-col section_7">
              <span class="pub_-self-start font_4 text_16">当前学习</span>
              <div class="pub_-flex-col group_5 view">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_8">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/3e8a48bc9cdaad94fddfbb3426f68413.png"/>
                    <span class="font_5 text_18">雅思口语流利说—哑巴英语逆袭native speaker</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-8">
                    <img class="pub_-shrink-0 image_4" src="../../assets/8e445195f8cc567d7bf64ffd9b317782.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_9">
                  <div class="pub_-flex-row space-x-14">
                    <img class="image_3" src="../../assets/b21f70f40c226b3dfc01620d7f6705d9.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="font_1">雅思口语流利说—哑巴英语逆袭native speaker</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_22">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_4">
                    <span class="font_7 text_21">去观看</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_9">
                  <div class="pub_-flex-row space-x-14">
                    <img class="image_3" src="../../assets/f79135d5de2c417318d5a18f5e68f504.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="font_1">雅思口语流利说—哑巴英语逆袭native speaker</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_25">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
              </div>
              <span class="pub_-self-start font_4 text_16 text_26">全部课程</span>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11 view_2">
                <div class="pub_-flex-row pub_-items-center space-x-14">
                  <img class="pub_-shrink-0 image_3" src="../../assets/11b1b88d00f52b45216691815aeebd59.png"/>
                  <span class="font_10 text_27">新概念·英语学习方法指导</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_5" src="../../assets/5216d70d677eae833a01798cbe022f00.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11">
                <div class="pub_-flex-row pub_-items-center space-x-15">
                  <img class="pub_-shrink-0 image_3" src="../../assets/2520415e619b5024bba8b01ba88cd354.png"/>
                  <span class="font_10 text_27">Excuse me主格和宾格的用法</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_4" src="../../assets/4e5400ad0956a832abe8fe86b163eabd.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-col group_5">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_12">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/076392ef0f4cb99166dc00199a175e66.png"/>
                    <span class="font_5">5-6国籍的表达,冠词的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/25352ac6c17ed5026ed9ef15bb57835d.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/7e4bf2e141e587237c60b353bae37489.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">5国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_22">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                    <span class="font_11">去回放</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/48e611bc412256ff8b1a4c46ec5f1818.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">6国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_25">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_6">
                    <span class="font_11">去回放</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_14">
                  <div class="pub_-flex-row pub_-items-center space-x-15">
                    <img class="pub_-shrink-0 image_3" src="../../assets/bc0ed5a869021a0616ce04ec1b97657c.png"/>
                    <span class="font_10 text_28">Excuse me主格和宾格的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/8d72c553844da03d2db4867e3c28459c.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
              </div>
              <div class="pub_-flex-row pub_-justify-between pub_-items-center section_11">
                <div class="pub_-flex-row pub_-items-center space-x-15">
                  <img class="pub_-shrink-0 image_3" src="../../assets/44ff3cf207f40a48520ed4f26ac20f21.png"/>
                  <span class="font_10 text_27">Excuse me主格和宾格的用法</span>
                </div>
                <div class="pub_-flex-row pub_-items-center space-x-9">
                  <img class="pub_-shrink-0 image_4" src="../../assets/8e736d2645f5a445c3de971393e6b508.png"/>
                  <span class="font_6">下拉</span>
                </div>
              </div>
              <div class="pub_-flex-col group_6">
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_12">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="pub_-shrink-0 image_3" src="../../assets/cba82cccee082dfd5094c253e7b10238.png"/>
                    <span class="font_5">5-6国籍的表达,冠词的用法</span>
                  </div>
                  <div class="pub_-flex-row pub_-items-center space-x-9">
                    <img class="pub_-shrink-0 image_4" src="../../assets/e2e5821d259871871c5ad93089a99928.png"/>
                    <span class="font_6">下拉</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/f0b26363d2fa679ca2208416e321e0ab.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">5国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_29">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
                <div class="pub_-flex-row pub_-justify-between pub_-items-center section_13">
                  <div class="pub_-flex-row pub_-items-center space-x-14">
                    <img class="image_3" src="../../assets/4f0bb8a9385fc60523d1e147a0068114.png"/>
                    <div class="pub_-flex-col space-y-6">
                      <span class="pub_-self-start font_5">6国籍的表达,冠词的用法</span>
                      <div class="pub_-flex-row pub_-items-center">
                        <span class="font_8">教师：mier wangtao</span>
                        <div class="pub_-shrink-0 section_10"></div>
                        <span class="font_9 text_30">时间：2022.10.11-12:20</span>
                      </div>
                    </div>
                  </div>
                  <div class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5">
                    <span class="font_7">未开课</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";


//日历
import {Calendar} from 'fullcalendar'


export default {
  components: {NavBox, FootBox, LeftNav, Pagination},
  data() {
    return {

      viewHen:true,
      ordShow: false,

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      showList: [], //显示的列表
      conC: [], //完整的列表

      search: {
        name: ''
      },
      calendar: true,

      rl: [],

      currentMonth_d: 1,
      minDate: null,
      maxDate: null,
    };
  },

  mounted() {
    let self = this;
    this.viewZ()

    window.addEventListener("orientationchange", function() {
      self.viewZ()
    });



    const currentDate = new Date();
    this.currentMonth_d = currentDate.getMonth() + 1;

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth;
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 2;

    this.minDate = new Date(currentYear, lastMonth, 1).toISOString().split('T')[0];
    this.maxDate = new Date(currentYear, nextMonth, 0).toISOString().split('T')[0];


    this.ajaxH();
    this.ajaxHData(this.currentMonth_d);

  },
  methods: {
    //判断横竖屏
    viewZ(){
      if (window.innerWidth > window.innerHeight) {
        console.log("设备处于横屏状态");
        this.viewHen = true;
        this.calendar=true
      } else {

        this.viewHen = false;
        this.calendar=false
        console.log("设备处于竖屏状态");
      }
    },
    open(id,title) {
      this.$confirm('是否取消'+title  +'的排课?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      this.cancelCooking('', id)

      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    //日历
    async ajaxHData(month) {

      let param = {
        month: month
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/getLiveCourseList",
        param
      );
      console.log(res.data.data);

      let data_list = res.data.data;

      const modifiedArray = data_list.map(item => {
        return {
          ...item,
          start: item.startTime,
          groupId: item.status,
        };
      });
      this.rl = modifiedArray
      this.dataCj(this.rl)
    },


    dataCj(list) {
      let self = this;

      // console.log(this.minDate,this.maxDate)
      const calendarEl = document.getElementById('calendar')
      var calendar = new Calendar(calendarEl, {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        initialDate: new Date(),
        navLinks: true, // can click day/week names to navigate views
        // editable: true,
        // dayMaxEvents: true, // allow "more" link when too many events  不隐藏 有多少都显示出来  true是隐藏显示more
        // minDate: this.minDate,
        // maxDate: this.maxDate,

        businessHours: true, // display business hours
        // selectable: true,//日期可选
        // events: this.rl,
        events: list,

        eventClick: function (info) {
          // alert('Event: ' + info.event.groupId);
          // alert('Event: ' + info.event.title);
          // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
          // alert('View: ' + info.view.type);

          if (info.event.groupId == 3) {
            self.open( info.event.id ,info.event.title)
          }

          // change the border color just for fun
          // info.el.style.borderColor = 'red';
        }
      });


      calendar.render();

    },
    //页面
    async ajaxH() {
      let param = {
        keyword: this.search.name
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/getLiveCourseList",
        param
      );
      // console.log(res);
      this.showList = res.data.data.slice(0, res.data.per_page);
      this.conC = res.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;


    },
    async cancelCooking(e, id) {
      let param = {
        id: id
      };
      const {data: res} = await this.$http.post(
        "/api.myCourse/cancelLiveCourse",
        param
      );
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.ajaxH();
        this.ajaxHData(this.currentMonth_d);
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }

    },
    async getAccessToken(id) {//获取看直播课的授权码
      let param = {
        liveCourseDetailId:id
      };
      const {data: res} = await this.$http.post(
        "/api.shengwang/getAccessToken",
        param
      );

      console.log(res)

      let path = 'https://likesay.cn/liveBroadcast.html' + "?accessToken=" + res.data.accessToken;
      window.open(this.$router.resolve(path).href, '_blank');
    },

    goHref(e, id) { //打开新窗口看直播课程
      let path = '/room' + "?id=" + id;
      window.open(this.$router.resolve(path).href, '_blank');
      // this.$router.push(path);
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.conC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    selS(n, t, id) {
      this.value = "";
      this.typeN = n;
      this.typeT = t;
      this.wordOrder.contactType = id;
      this.selOn = false;
    },
    selShow() {
      if (this.selOn === false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },

  },
};
</script>

<style scoped lang="scss">
.page {

  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.text {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_2 {
  font-weight: unset;
}

.space-y-40 {
  padding: .4rem 0 .8rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 0.4rem;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group_2 {
  width: 11.8rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.section_3 {
  background-color: #ffffff;
}

.space-x-17 > *:not(:first-child) {
  margin-left: 0.17rem;
}

.section_4 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #000000;
}

.text_10 {
  margin-right: 1.37rem;
  color: #2080f7;
  line-height: 0.14rem;
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_13 {
  line-height: 0.14rem;
}

.text_14 {
  margin-left: 0.17rem;
}

.text_15 {
  margin-left: 0.16rem;
}

.text_17 {
  margin-left: 0.17rem;
}

.text_19 {
  margin-left: 0.16rem;
}

.text-wrapper_3 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.text_20 {
  margin-left: 0.17rem;
}

.text_23 {
  margin-left: 0.17rem;
}

.text_24 {
  margin-left: 0.16rem;
}

.group_3 {
  padding-bottom: 10.88rem;
  width: 9.42rem;
}

.pos_2 {
  padding: 0.23rem 0 0.41rem;
  flex: 1 1 4.71rem;
  background-image: url('../../assets/b7a2b5731f97b0ad5722106f489663fd.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 4.71rem;
  height: 0.86rem;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.09rem;
}

.image {
  width: 0.22rem;
  height: 0.22rem;
}

.text_11 {
  color: #ffffff;
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  letter-spacing: -0.009rem;
}

.section_6 {
  padding: 0.24rem 0;
  flex: 1 1 4.71rem;
  background-color: #f8f8f8;
  width: 4.71rem;
  height: 0.68rem;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.12rem;
}

.image_2 {
  width: 0.15rem;
  height: 0.21rem;
}

.text_12 {
  letter-spacing: -0.009rem;
}

.group_4 {
  width: 9.42rem;
}

.pos {
  position: absolute;
  left: 0;
  right: 0;
  top: 0.68rem;
}

.section_7 {
  padding: 0.41rem 0.38rem 0;
  background-color: #ffffff;
  width: 9.42rem;
}

.font_4 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 500;
  color: #2b2c30;
}

.text_16 {
  line-height: 0.18rem;
  text-transform: uppercase;
}

.group_5 {
  padding: 0 0.02rem;
}

.view {
  margin-top: 0.19rem;
}

.section_8 {
  padding: 0.16rem 0.18rem;
  background-color: #f3f6fa;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.image_3 {
  width: 0.34rem;
  height: 0.34rem;
}

.font_5 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.19rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_18 {
  line-height: 0.2rem;
}

.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}

.image_4 {
  width: 0.15rem;
  height: 0.09rem;
}

.font_6 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
  font-weight: 300;
  color: #2b2c30;
}

.section_9 {
  padding: 0.16rem 0.18rem;
  border: solid 0.01rem #f3f6fa;
}

.space-y-6 > *:not(:first-child) {
  margin-top: 0.06rem;
}

.font_8 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #a1a9b2;
}

.section_10 {
  background-color: #a1a9b2;
  width: 0.02rem;
  height: 0.08rem;
}

.font_9 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
  font-weight: 300;
  color: #a1a9b2;
}

.text_22 {
  margin-left: 0.08rem;
}

.text-wrapper_4 {
  padding: 0.08rem 0;
  background-color: #2080f7;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
}

.font_7 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #dbdbdb;
}

.text_21 {
  color: #ffffff;
  line-height: 0.14rem;
}

.text_25 {
  margin-left: 0.08rem;
}

.text-wrapper_5 {
  padding: 0.05rem 0 0.09rem;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #dbdbdb;
}

.text_26 {
  margin-top: 0.4rem;
}

.section_11 {
  padding: 0.18rem 0.17rem 0.18rem 0.2rem;
  background-color: #f3f6fa;
  border-bottom: solid 0.01rem #edeff2;
}

.view_2 {
  margin-top: 0.19rem;
}

.font_10 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_27 {
  line-height: 0.18rem;
}

.image_5 {
  width: 0.14rem;
  height: 0.09rem;
}

.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}

.section_12 {
  padding: 0.18rem;
  background-color: #f3f6fa;
}

.section_13 {
  padding: 0.11rem 0.18rem 0.18rem;
  border: solid 0.01rem #f3f6fa;
}

.text-wrapper_6 {
  padding: 0.05rem 0 0.09rem;
  border-radius: 0.04rem;
  width: 0.79rem;
  height: 0.29rem;
  border: solid 0.01rem #2080f7;
}

.font_11 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.02rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #2080f7;
}

.section_14 {
  padding: 0.18rem;
  background-color: #f3f6fa;
  border-bottom: solid 0.01rem #edeff2;
}

.text_28 {
  line-height: 0.18rem;
}

.group_6 {
  padding: 0 0.02rem 0.4rem;
}

.text_29 {
  margin-left: 0.08rem;
}

.text_30 {
  margin-left: 0.08rem;
}

.zind_2 {
  position: relative;
  z-index: 2;
}

.tim_con {
  background-color: #fff;
  padding: .4rem;
  width: calc(100% - 1.98rem - .4rem);

}

.sear_case {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sear_box {
    width: calc(100% - 1.24rem);
    height: .48rem;
    display: flex;
    align-items: center;
    border: solid 0.01rem #2080f7;
    padding-left: .1rem;
    border-right: none;

    .sea_image {
      width: 0.22rem;
      height: 0.22rem;
    }

    input {
      width: calc(100% - .33rem);

      padding: .1rem .11rem;
      margin-left: .11rem;
      font-size: .16rem;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }

  button {
    width: 1.24rem;
    border-radius: 0;
    font-size: .16rem;
    height: .48rem;

  }

}

.text_21 {
  color: #f95d22;
}

.text_21_1 {
  color: rgba(32, 128, 247, 1);
}

.text_21_2 {
  color: rgba(161, 169, 178, 1);
}

.text_21_3 {
  color: rgba(0, 181, 146, 1);
}

.con_con {
  margin-top: .4rem;
  padding-bottom: calc(1rem + 32px);
  position: relative;
}

.con_block {
  margin-bottom: .3rem;
  background-color: rgba(32, 128, 247, .03);
  display: flex;
  justify-content: space-between;
  padding: .2rem;

  .ord_txt {
    font-size: .16rem;
    font-weight: 700;
    margin-bottom: .2rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 400;
    }
  }


  .con_left {
    width: 70%;

    .con_det {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .con_img {
        width: 1.8rem;
      }

      .con_txt {
        width: calc(100% - 2rem);

        > .ord_txt {
          &:nth-child(1) {
            margin-bottom: .1rem;
            height: 3em;
          }
        }

        .con_l_btm {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ord_txt {
            margin-bottom: 0;

            &:nth-child(2) {
              span {
                font-size: .14rem;
              }
            }
          }
        }
      }
    }

  }

  .con_center {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .58rem;
  }

  .con_right {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: .58rem;

    &.m_sty {
      display: none;
    }

    .btn_sty {
      height: .4rem;
      width: 100%;
      border-radius: .06rem;
      font-size: .16rem;
    }
  }


}

.m_sty {
  display: none;
}

.tab_box {
  display: flex;
  padding: 0 .14rem;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: .4rem;

  > div {
    padding-bottom: .1rem;
    cursor: pointer;
    position: relative;
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 1;
    color: #a1a9b2;
    margin-left: .28rem;

    &:first-child {
      margin-left: 0;
    }

    &::before {
      content: "";
      display: block;
      height: 2px;
      width: 2em;
      background-color: #2080f7;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

    &.on {
      font-weight: 500;
      color: #2080f7;

      &::before {
        opacity: 1;

      }
    }
  }
}

#calendar {

  ::v-deep {
    .fc-h-event {
      border-color: #2080f7;
      background-color: #2080f7;
      padding: .05rem .1rem;
    }

    .fc-daygrid-dot-event .fc-event-title,
    .fc-h-event .fc-event-title {
      white-space: normal;
    }

  }
}

@media screen and (orientation: portrait) {
  .m_sty {
    display: block;
  }
  .group_2 {
    width: 100%;
    flex-wrap: wrap;
  }
  .space-x-40 > *:not(:first-child) {
    margin-left: 0;
  }

  .tim_con {
    width: 100%;
    padding: .3rem;
    margin-top: .4rem;
  }
  .sear_case {


    .sear_box {
      width: calc(100% - 1.8rem);
      height: .7rem;

      .sea_image {
        width: .3rem;
        height: .3rem;
      }

      input {
        width: calc(100% - .3rem);
        margin-left: 0;
        padding-left: .2rem;
        font-size: .24rem;
      }
    }

    button {
      width: 1.8rem;
      height: .7rem;
      font-size: .24rem;


    }

  }


  .con_con {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .con_block {
    margin-bottom: .3rem;
    width: 100%;


    .ord_txt {
      font-size: .22rem;
      margin-bottom: .15rem;
      line-height: 1.35;
      min-height: auto;

      &:last-child {
        margin-bottom: .15rem;
      }

      span {


      }
    }


    .con_left {
      width: 100%;

      .con_det {


        .con_img {
          width: 30%;
          height: 2rem;
        }

        .con_txt {
          width: 70%;
          margin-top: 0;
          padding-left: .2rem;

          .ord_txt {
            &:nth-child(1) {
              margin-bottom: .15rem;
              height: auto;
            }
          }

          .con_l_btm {
            .ord_txt {
              //width: 50%;
              margin-bottom: .15rem;

              &:nth-child(2) {
                span {

                  font-size: .22rem;
                }
              }
            }


          }
        }
      }

    }

    .con_center {
      width: 100%;
      padding-top: 0;
      justify-content: flex-start;

      .ord_txt {
        display: flex;

        .m_sty {
          font-weight: 700;
        }

        .text_21 {
          line-height: 1.35;
        }
      }
    }

    .con_right {
      width: 100%;
      margin-left: 0;
      padding-top: 0;

      .btn_sty {
        height: .6rem;
        width: 48%;

        font-size: .2rem;
      }
    }

  }


  .tab_box > div {
    font-size: .24rem;
  }


}

::v-deep {
  .fc .fc-button-primary:focus {
    box-shadow: rgba(76, 91, 106, 0.5) 0 0 0 0.05rem;
  }

  .fc .fc-button:focus {
    box-shadow: rgba(76, 91, 106, 0.25) 0 0 0 0.05rem;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: rgba(76, 91, 106, 0.5) 0 0 0 0.05rem;
  }
}
</style>
