<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="space-y-80">
      <div class="pub_case">
        <div class="pub_-flex-col pub_-self-center group_2 space-y-37">
          <div class="pub_-flex-col group_3 space-y-19">
            <div class="pub_title_box">
              <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png" />
              <span>系列课专区</span>
              <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png" />
            </div>
            <span class="pub_info">
              为每一名学员提供更有针对性的定制化教学。同时，精准的考评使你的成长清晰可量化，更有助于燃起你学习的动力与激情。
            </span>
          </div>
          <div class="pub_-flex-col sys_box">
            <div class="pub_-flex-row pub_-justify-between list_011 group_014">
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_013"
                :key="i"
                v-for="(item, i) in tabList"
                :class="{ on: tabOn == i }"
                @click="tabC(item.value, i)"
              >
                <span class="font_011">{{ item.name }}</span>
              </div>
            </div>
            <div
              class="pub_-flex-col pub_-justify-start pub_-relative group_0111"
            >
              <div class="pub_-flex-row list group_011 pub_case">
                <div
                  class="pub_-flex-col section_011"
                  :key="i"
                  v-for="(item, i) in DnfList"
                >
                  <span class="pub_-self-center font_014 text_0117">{{
                    item.title
                  }}</span>
                  <div class="pub_-flex-row group_015 view_011" v-if="item.rankSort !== 0 ">
                    <div
                      class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_015"
                    >
                      <span class="font_015">热门榜</span>
                    </div>
                    <div
                      class="pub_-flex-col pub_-justify-start pub_-items-start pub_-flex-auto text-wrapper_016"
                    >
                      <span class="font_016 text_0131">
                        {{
                           "热销课程第" + item.rankSort + "名"
                        }}</span>
                    </div>
                  </div>

                  <div class="bd_z" v-else></div>
                  <div class="pub_-flex-row group_015 view_015 space-x-018">
                    <div
                      v-if="item.isSecurity == 1"
                      class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_017"
                    >
                      <span class="font_017 text_0132">放心选</span>
                    </div>
                    <div
                      class="pub_-flex-col pub_-justify-start text-wrapper_018"
                    >
                      <span class="font_018 text_0133"
                        >好评{{ item.evaluatePercentage }}%</span
                      >
                    </div>
                  </div>
                  <div class="pub_-flex-row pub_-items-baseline group_06">
                    <span class="font_0110">￥</span>
                    <span class="font_019 text_0130">{{ item.price }}</span>
                    <div class="group_017">
                      <span class="font_0111 text_0134">{{item.saleNumber}}</span>
                      <span class="font_0112">人报名</span>
                    </div>
                  </div>
                  <span class="pub_-self-center font_0113 text_011">

                   <template v-if="item.startTime != '' && item.startTime != undefined && item.startTime != null">
                          开播时间：{{ item.startTime }}
                        </template>
                  </span>
                  <div class="pub_-flex-row group_018 space-x-017">
                    <div
                      class="text-wrapper_019 btn_sty btn_sty_line_r"
                      @click="goHref($event)"
                      :data-url="
                        '/courseDetails?type=' + item.type + '&&id=' + item.id
                      "
                    >
                      <span>点击购买</span>
                    </div>
                    <!-- <div class="text-wrapper_0110 btn_sty btn_sty_r">
                    <span >顾问咨询解答</span>
                  </div> -->
                  </div>

                  <div
                    class="m_sty btn_link"
                    @click="goHref($event)"
                    :data-url="
                        '/courseDetails?type=' + item.type + '&&id=' + item.id
                      "
                  >

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";

export default {
  components: { NavBox, FootBox },
  data() {
    return {
      tabOn: 0,
      tabList: [],
      value: 0,
      DnfList: [],
      config: {},
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {
    async ajaxH(id, i) {
      this.active = i;

      this.getCourseAttributeLabelList(); //获取课程属性标签列表

    },
    async getCourseAttributeLabelList(id, i) {
      this.active = i;

      let param = {
        // number: 8,
      };
      const { data: res } = await this.$http.post(
        "/api.course/getCourseAttributeLabelList",
        param
      );
      this.tabList = res.data;
      this.value = this.tabList[0].value;

      this.getList(); //获取列表


    },
    async getList(id, i) {
      this.active = i;

      let param = {
        number: 8,
        attributeLabelId:this.value
      };
      console.log(param)
      const { data: res } = await this.$http.post("/api.course/getList", param);
      this.config = res.data;
      this.DnfList = res.data.data;
      console.log( this.DnfList )
    },

    tabC(v, i) {
      this.tabOn = i;
      this.value = v;

      this.getList(); //获取列表
    },
    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #edeff2;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}
.text {
  color: #ffffff;
  line-height: 0.15rem;
}
.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}
.space-y-80 {
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
}
.section_2 {
  padding-left: 0.19rem;
  background-color: #f4f7fe;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text-wrapper_2 {
  margin-left: 0.16rem;
  padding: 0.26rem 0;
  background-color: #2080f7;
  width: 1.08rem;
  height: 0.68rem;
}
.text_8 {
  color: #ffffff;
}
.text_9 {
  margin-left: 0.26rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_10 {
  line-height: 0.18rem;
}
.group_2 {
  width: 11.8rem;
}
.space-y-37 > *:not(:first-child) {
  margin-top: 0.37rem;
}

.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}
.image {
  width: 0.53rem;
  height: 0.08rem;
}
.text_11 {
  margin-left: 0.21rem;
  color: #2b2c30;
  font-size: 0.36rem;
  font-family: OPPOSans;
  line-height: 0.35rem;
}
.image_2 {
  margin-left: 0.16rem;
}
.text_12 {
  color: #2b2c30;
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.2rem;
}
.list_1 {
  align-self: flex-start;
}
.group_4 {
  width: 4.72rem;
}
.text-wrapper_3 {
  padding: 0.1rem 0;
  background-color: #ffffff;
  border-radius: 0.16rem;
  width: 0.88rem;
  height: 0.32rem;
  cursor: pointer;
  transition: color 0.5s ease;

  @media screen and (orientation: landscape) {
    &:hover {
      color: #2080f7;
    }
  }
  &.on {
    color: #2080f7;
  }
}
.text_13 {
}
.group_11 {
  margin-top: 0.2rem;
}
.list {
  position: relative;
}
.group_1 {
  display: flex;
  flex-wrap: wrap;
}
.section_1 {
  padding-top: 0.14rem;
  background-color: #ffffff;
  border-radius: 0.14rem;
  border: solid 0.01rem #eeeeee;
  width: 2.77rem;
  margin-right: 0.24rem;
  margin-bottom: 0.2rem;
  margin-left: 0;
  margin-top: 0;
  @media screen and (orientation: landscape) {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.font_4 {
  font-size: 0.16rem;
  font-family: Adobe Heiti Std;
  line-height: 0.24rem;
  color: #2b2c30;
}
.text_17 {
  width: 2.33rem;
}
.group_5 {
  padding: 0 0.12rem;
}
.view_1 {
  margin-top: 0.07rem;
}
.text-wrapper_5 {
  padding: 0.04rem 0;
  background-color: #f54a4a;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}
.font_5 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #ffffff;
}
.text-wrapper_6 {
  padding: 0.04rem 0;
  background-color: #f9eceb;
  border-radius: 0.04rem;
  width: 1.73rem;
  height: 0.22rem;
}
.font_6 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #f54a4a;
}
.text_31 {
  margin-left: 0.07rem;
}
.view_5 {
  margin-top: 0.08rem;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 0.08rem;
}
.text-wrapper_7 {
  padding: 0.04rem 0;
  background-color: #f4f7fe;
  border-radius: 0.04rem;
  width: 0.55rem;
  height: 0.22rem;
}
.font_7 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #2080f7;
}
.text_32 {
  line-height: 0.11rem;
}
.text-wrapper_8 {
  padding: 0.04rem 0;
  background-color: #edeff2;
  border-radius: 0.04rem;
  height: 0.22rem;
}
.font_8 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #666666;
}
.text_33 {
  margin-left: 0.08rem;
}
.group_6 {
  margin-top: 0.2rem;
  padding: 0 0.08rem;
}
.font_10 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.13rem;
  font-weight: 300;
  color: #f75746;
}
.font_9 {
  font-size: 0.2rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 500;
  color: #f75746;
}
.text_30 {
  line-height: 0.15rem;
}
.group_7 {
  margin-left: 0.05rem;
  line-height: 0.14rem;
  display: flex;
  align-items: center;
}
.font_11 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.12rem;
  color: #a1a9b2;
}
.text_34 {
  line-height: 0.11rem;
}
.font_12 {
  font-size: 0.14rem;
  line-height: 1;
  font-weight: 500;
  color: #a1a9b2;
}
.font_13 {
  font-size: 0.14rem;
  line-height: 1;
  font-weight: 300;
  color: #a1a9b2;
  padding-left: 0.14rem;
  padding-right: 0.14rem;
  width: 100%;
}
.text_1 {
  margin-top: 0.12rem;
}
.group_8 {
  margin-top: 0.17rem;
  padding: 0.18rem 0.13rem;
  border-top: solid 0.01rem #eeeeee;
}
.space-x-7 > *:not(:first-child) {
  margin-left: 0.07rem;
}
.text-wrapper_9 {
  width: 1.21rem;
  height: 0.29rem;
  border-radius: 0.04rem;
}
.font_14 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.017rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #f54a4a;
}
.text-wrapper_10 {
  margin-right: 0.04rem;
  width: 1.21rem;
  height: 0.29rem;
  border-radius: 0.04rem;
}
.pos_6 {
  height: 2.68rem;
}
.pos_9 {
  position: absolute;
  left: 9.03rem;
  right: 0;
  top: 2.87rem;
}

.section_011{
position: relative;
}
@media screen and (orientation: portrait){
.group_2{
  width: 100%;
  .sys_box{
    .group_014{
      width: 100%;
    }
    .text-wrapper_013{
      width: 1.2rem;
      height: .5rem;
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .font_011{
      font-size: .2rem;
      line-height: 1;
    }

    .group_0111{
      margin-top: .4rem;
    }
    .list{
padding-left: 0;
      padding-right: 0;
    }
     .text_0117{
       width: 100%;
       font-size: .28rem;
       line-height: 1.5;
       //height: 3em;
       height: auto;
       display: block;
     }
    .view_015{
margin-top: .2rem;
    }
    .group_015{
      padding: 0;
    }

    .text-wrapper_017{
      width: 1.1rem;
      height: 0.33rem;
      padding: 0;
    }
    .font_017{
      font-size: 0.2rem;
      line-height: 0.33rem;
    }
    .text-wrapper_018{
      height: 0.33rem;
      padding: 0 0.2rem;
    }
    .text_0133{
      font-size: 0.2rem;
      margin: 0;
      line-height: 0.33rem;
    }
     .space-x-018 > *:not(:first-child){
       margin-left: .2rem;
     }
    .group_06{
      margin-top: .2rem;
      .font_0110,.font_0112,.font_0111 {
        font-size: .2rem;
      }

    }
    .font_019{
      font-size: .32rem;
      line-height: 1;
    }
    .text_011{
      line-height: 1;
      font-size: 0.2rem;
      margin-top: .12rem;
      display: block;
    }
    .group_018{
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .text-wrapper_019{
      height: .7rem;

    }
  }
 .pub_case {
   justify-content: space-between;
   > div{
width: 48%;
     margin-bottom: 5%;
     margin-right: 0;
     padding: .3rem .2rem;
     &:nth-child(2n){
       margin-right: 0;
     }
   }
 }




}
}


</style>
