<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "Icon",
  setup() {
    // 修改Favicon的方法
    const changeFavicon = (link) => {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    };
    return {
      changeFavicon, // 将changeFavicon方法暴露给组件的其他部分使用
    };

  },
  created() {
    this.getIcon();
    this.fontSize();
  },
  methods: {
    async getIcon() {
      let param = {};
      const {data: res} = await this.$http.post("/api.setUp/getConfig", param);
      this.navB = res.data;
      // console.log(this.navB)
      this.changeFavicon(res.data.icon); // 调用changeFavicon方法来修改Favicon图标

    },
    fontSize() {
      var docEl = document.documentElement,
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function () {
          var clientWidth = docEl.clientWidth;
          if (!clientWidth) return;
          if (clientWidth > 750) {
            // docEl.style.fontSize = '100px';
          } else {
            docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
          }
        };

      if (window.screen.width / window.screen.height < 1.1) {
        this.$cookies.set("isMobile", 1, "365d");
      } else {
        this.$cookies.set("isMobile", 0, "365d");

      }


      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, recalc, false);
      document.addEventListener("DOMContentLoaded", recalc, false);
    }
  }
}

</script>
<style lang="scss">
/************************************************************
  ** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
  ** 否则页面将无法正常显示                                  **
  ************************************************************/
$on_color: #2080f7;

html {
  font-size: 100px;
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 110px;
  }
}

@media screen and (min-width: 2561px) {
  html {
    font-size: 150px;
  }
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 90px;
  }
}

@media screen and (max-width: 1536px) {
  html {
    font-size: 82px;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 80px;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 75px;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 70px;
  }
}

@media screen and (max-width: 1100px) {
  html {
    font-size: 60px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 100px;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  html {
    font-size: 55px;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 70px;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 100px;
  }
}

@media screen and (max-width: 640px) {
  html {
    font-size: 85.33px;
  }
}

@media screen and (max-width: 591px) {
  html {
    font-size: 78.8px;
  }
}

@media screen and (max-width: 562px) {
  html {
    font-size: 74.933px;
  }
}

@media screen and (max-width: 540px) {
  html {
    font-size: 72px;
  }
}

@media screen and (max-width: 521px) {
  html {
    font-size: 69.4666px;
  }
}

@media screen and (max-width: 428px) {
  html {
    font-size: 57.066px;
  }
}

@media screen and (max-width: 414px) {
  html {
    font-size: 55.2px;
  }
}

@media screen and (max-width: 412px) {
  html {
    font-size: 54.933px;
  }
}

@media screen and (max-width: 375px) {
  html {
    font-size: 50px;
  }
}

@media screen and (max-width: 360px) {
  html {
    font-size: 48px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 42.66px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Microsoft Yahei", sans-serif;
  color: #2b2c30;
  font-size: 0.14rem;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

body .int_info {
  img, video {
    max-width: 100% !important;
    height: auto !important;
    display: block;
  }

  p {
    font-size: .24rem !important;
    line-height: 1.4 !important;
    margin-top: .34rem !important;
    margin-bottom: .34rem !important;
  }
}

#app {
  width: 100vw;
  height: 100vh;
}

.pub_-flex-row {
  display: flex;
  flex-direction: row;
}

.pub_-flex-col {
  display: flex;
  flex-direction: column;
}

.pub_-justify-start {
  justify-content: flex-start;
}

.pub_-justify-end {
  justify-content: flex-end;
}

.pub_-justify-center {
  justify-content: center;
}

.pub_-justify-between {
  justify-content: space-between;
}

.pub_-justify-around {
  justify-content: space-around;
}

.pub_-justify-evenly {
  justify-content: space-evenly;
}

.pub_-items-start {
  align-items: flex-start;
}

.pub_-items-end {
  align-items: flex-end;
}

.pub_-items-center {
  align-items: center;
}

.pub_-items-baseline {
  align-items: baseline;
}

.pub_-items-stretch {
  align-items: stretch;
}

.pub_-self-start {
  align-self: flex-start;
}

.pub_-self-end {
  align-self: flex-end;
}

.pub_-self-center {
  align-self: center;
}

.pub_-self-baseline {
  align-self: baseline;
}

.pub_-self-stretch {
  align-self: stretch;
}

.pub_-flex-1 {
  flex: 1 1 0%;
}

.pub_-flex-auto {
  flex: 1 1 auto;
}

.pub_-grow {
  flex-grow: 1;
}

.pub_-grow-0 {
  flex-grow: 0;
}

.pub_-shrink {
  flex-shrink: 1;
}

.pub_-shrink-0 {
  flex-shrink: 0;
}

.pub_-relative {
  position: relative;
}

.pub_case {
  width: 11.8rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.pub_title_box {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 0.54rem;
  }

  span {
    margin: 0 0.22rem;
    font-size: 0.36rem;
    line-height: 1;
    font-weight: 700;
    color: #2b2c30;
  }

  &.col_w {
    span {
      color: #fff;
    }

    + .pub_info {
      color: #fff;
    }
  }
}

.pub_info {
  text-align: center;
  margin-top: 0.15rem;
  line-height: 1.5;
  font-size: 0.18rem;
  font-weight: 700;
  color: #2b2c30;
  width: 100%;
  display: block;

  span {
    color: rgba(245, 90, 17, 1);
  }
}

.btn_sty {
  padding: 0.1rem 0;
  background-color: #2080f7;
  border-radius: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  line-height: 1;
  font-size: 0.12rem;
  text-align: center;
  overflow: hidden;
  transition: all 0.5s ease;
  border: 1px solid transparent;

  span {
    position: relative;
    z-index: 2;
  }

  @media screen and (orientation: landscape) {
    &:hover {
      color: #2080f7;
      background-color: #fff;
      border-color: #2080f7;
    }
  }

  &.btn_sty_line {
    border-color: rgba(238, 238, 238, 1);
    color: #2080f7;
    background-color: transparent;
    @media screen and (orientation: landscape) {
      &:hover {
        color: #fff;
        background-color: #2080f7;
        border-color: #2080f7;

        img {
          mix-blend-mode: color-dodge;
        }
      }
    }
  }

  &.btn_sty_line_r {
    border-color: rgba(238, 238, 238, 1);
    color: rgba(245, 74, 74, 1);
    background-color: transparent;
    @media screen and (orientation: landscape) {
      &:hover {
        color: #fff;
        background-color: rgba(245, 74, 74, 1);
        border-color: rgba(245, 74, 74, 1);
      }
    }
  }

  &.btn_sty_r {
    background-color: rgba(245, 74, 74, 1);
    @media screen and (orientation: landscape) {
      &:hover {
        background-color: transparent;
        border-color: rgba(245, 74, 74, 1);
        color: rgba(245, 74, 74, 1);
      }
    }
  }

  @media screen and (orientation: portrait) {
    font-size: .24rem;

  }
}

.swiper-pagination {
  span {
    height: 0.08rem;
    width: 0.08rem;
    background: #d3d7e6;
    opacity: 1;
    border-radius: 0.1rem;

    &.swiper-pagination-bullet-active {
      width: 0.2rem;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.teacher_box {
}

.news_box {
  margin-top: 0.4rem;
  width: 100%;

  .list-item {
    display: flex;
    align-items: center;
    padding: 0.18rem 0;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &:last-child {
      border-bottom: none;
    }

    .font_4 {
      transition: color 0.5s ease;
    }

    @media screen and (orientation: landscape) {
      &:hover {
        .font_4 {
          color: #2080f7 !important;
        }
      }
    }

    > img {
      width: 1.14rem;
      display: block;
      margin-right: 0.14rem;
    }

    .group_3 {
      width: calc(100% - 0.14rem - 1.14rem);

      .text_11 {
        font-size: 0.22rem;
        line-height: 1.63636;
        font-weight: 500;
        color: rgba(43, 44, 48, 1);
        white-space: nowrap; /*禁止换行*/
        overflow: hidden; /*溢出部分隐藏*/
        text-overflow: ellipsis; /*文本溢出部分显示省略号*/
      }

      .font_5 {
        font-size: 0.14rem;
        color: #2b2c30;
        line-height: 1.857143;
        white-space: nowrap; /*禁止换行*/
        overflow: hidden; /*溢出部分隐藏*/
        text-overflow: ellipsis; /*文本溢出部分显示省略号*/
      }

      .font_6 {
        margin-top: 0.11rem;
        line-height: 1;
        color: rgba(161, 169, 178, 1);
        font-size: 0.12rem;
      }
    }
  }
}

.m-pagination {
  width: 100%;
  bottom: .5rem;
  position: absolute;
  left: 0;
  padding-right: 0.5rem;
  @media screen and (orientation: portrait) {
    padding-right: .3rem !important;
  }
}

#target {
  position: relative;
  padding-bottom: calc(32px + 1rem) !important;
}


.BMap_shadow img {
  display: block;
}

.BMap_pop img {
  display: block;
}

a {
  text-decoration: none;
}

.nav_left {
  align-self: start;
  background-color: #fff;
  width: 1.98rem;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 0.44rem;
    padding-left: 0.18rem;
    color: rgba(0, 0, 0, 1);
    font-size: 0.14rem;
    line-height: 1;
    transition: color 0.5s ease;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 0.02rem;
      height: 100%;
      background-color: $on_color;
      position: absolute;
      left: 0;
      top: 0;

      opacity: 0;
      transition: opacity 0.5s ease;
    }

    &.on,
    &.router-link-exact-active {
      color: $on_color;

      &::before {
        opacity: 1;
      }
    }

    @media screen and (orientation: landscape) {
      &:hover {
        color: $on_color;
      }
    }
  }
}

input {
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.course_box {
  .space-x-24 {

    @media screen and (orientation: landscape) {
      flex-wrap: wrap;
    }
  }

  .section_cou {
    background-color: #ffffff;
    border-radius: 0.14rem;
    // width: 2.77rem;
    // height: 4.23rem;
    width: calc((100% - 0.75rem) / 4);
    border: solid 0.01rem #eeeeee;
    margin-top: 0.24rem;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }

    margin-right: 0.25rem;
    @media screen and (orientation: landscape) {
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .image_03 {
    border-radius: 0.14rem 0.14rem 0px 0px;
    width: 100%;
    max-height: 1.55rem;
    object-fit: cover;
  }

  .font_06 {
    font-size: 0.16rem;
    font-family: Adobe Heiti Std;
    line-height: 0.24rem;
    color: #2b2c30;
  }

  .text_020 {
    margin-top: 0.16rem;
    width: 85%;

    height: .48rem;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 显示的行数，这里设置为 3 行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .group_09 {
    padding: 0 0.13rem;
  }

  .view_02 {
    margin-top: 0.07rem;
  }

  .text-wrapper_05 {
    padding: 0.04rem 0;
    background-color: #f54a4a;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .font_07 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.12rem;
    font-weight: 300;
    color: #ffffff;
  }

  .text-wrapper_06 {
    padding: 0.04rem 0;
    background-color: #f9eceb;
    border-radius: 0.04rem;
    width: calc(100% - .55rem);
    height: 0.22rem;
  }

  .font_08 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.14rem;
    font-weight: 300;
    color: #f54a4a;
  }

  .text_021 {
    margin-left: 0.07rem;
    line-height: 0.13rem;
  }

  .view_03 {
    margin-top: 0.08rem;
  }

  .space-x-08 > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text-wrapper_07 {
    padding: 0.04rem 0;
    background-color: #f4f7fe;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .font_09 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.11rem;
    font-weight: 300;
    color: #2080f7;
  }

  .font_03 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 500;
    color: #a1a9b2;
  }

  .text-wrapper_08 {
    padding: 0.04rem 0;
    background-color: #edeff2;
    border-radius: 0.04rem;
    height: 0.22rem;
  }

  .font_010 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.14rem;
    font-weight: 300;
    color: #666666;
  }

  .text_022 {
    margin-left: 0.08rem;
    line-height: 0.13rem;
  }

  .group_010 {
    margin-top: 0.2rem;
    padding: 0 0.09rem;
  }

  .font_012 {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 300;
    color: #f75746;
  }

  .text_024 {
    line-height: 0.13rem;
  }

  .font_011 {
    font-size: 0.2rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 500;
    color: #f75746;
  }

  .text_023 {
    line-height: 0.15rem;
  }

  .group_011 {
    margin-left: 0.05rem;
    line-height: 0.14rem;
    height: 0.14rem;
    display: flex;
    align-items: center;
  }

  .font_013 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.11rem;
    color: #a1a9b2;
  }

  .font_014 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 300;
    color: #a1a9b2;
  }

  .text_025 {
    margin-top: 0.12rem;
    display: block;
    width: 100%;
    padding: 0 0.15rem;
    height: 1em;
  }

  .group_012 {
    margin-top: 0.17rem;
    padding: 0.15rem;
    border-top: solid 0.01rem #eeeeee;
  }

  .space-x-09 > *:not(:first-child) {
    margin-left: 0.09rem;
  }

  .image_04 {
    width: 0.34rem;
    height: 0.34rem;
    border-radius: 100%;
  }

  .font_016 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.17rem;
    color: #2b2c30;
  }

  .text-wrapper_09 {
    border-radius: 0.04rem;
    width: 0.79rem;
    height: 0.29rem;
    border: solid 0.01rem #eeeeee;
  }

  .font_015 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.02rem;
    line-height: 0.14rem;
    font-weight: 300;
  }

  .text_026 {
    line-height: 0.15rem;
  }
}

.el-message__icon {
  font-size: 0.3rem;
}

.sys_con {
  .space-x-024 > *:not(:first-child) {
    margin-left: 0.24rem;
  }

  .section_03 {
    width: calc((100% - 0.72rem) / 4);
    margin-right: 0.24rem;

    padding-bottom: 0.2rem;
    background-color: #ffffff;
    border-radius: 0.14rem;

    border: solid 0.01rem #eeeeee;

    &:last-child {
    }
  }

  .image_02 {
    border-radius: 0.14rem 0.14rem 0px 0px;
    width: 2.77rem;
    height: 1.95rem;
  }

  .font_03 {
    font-size: 0.2rem;
    letter-spacing: 0.026rem;
    line-height: 1;
    color: #2b2c30;
  }

  .text_012 {
    margin-top: 0.2rem;
    line-height: 0.19rem;
    font-weight: 700;
    text-align: center;
  }

  .group_04 {
    margin-top: 0.1rem;
    line-height: 0.2rem;
    text-align: center;
    padding: 0 0.25rem;
  }

  .font_04 {
    font-size: 0.12rem;
    letter-spacing: 0.016rem;
    line-height: 1.66;
    font-weight: 300;
    color: #666666;
    display: block;
  }
}

.sys_box {
  margin-top: 0.37rem;

  .list_011 {
    align-self: flex-start;
  }

  .font_011 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.12rem;
  }

  .group_014 {
    width: 4.72rem;
  }

  .list {
    margin-top: 0;
  }

  .text-wrapper_013 {
    padding: 0.1rem 0;
    background-color: #ffffff;
    border-radius: 0.16rem;
    width: 0.88rem;
    height: 0.32rem;
    cursor: pointer;
    transition: color 0.5s ease;
    color: #a1a9b2;

    @media screen and (orientation: landscape) {
      &:hover {
        color: #2080f7;
      }
    }

    &.on {
      color: #2080f7;
    }
  }

  .text_0113 {
  }

  .group_0111 {
    margin-top: 0.2rem;
  }

  .list {
    position: relative;
  }

  .group_011 {
    display: flex;
    flex-wrap: wrap;
  }

  .section_011 {
    padding-top: 0.14rem;
    background-color: #ffffff;
    border-radius: 0.14rem;
    border: solid 0.01rem #eeeeee;
    width: 2.77rem;
    margin-right: 0.24rem;
    margin-bottom: 0.2rem;
    margin-left: 0;
    margin-top: 0;
    @media screen and (orientation: landscape) {
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .font_014 {
    font-size: 0.16rem;
    font-family: Adobe Heiti Std;
    line-height: 0.24rem;
    color: #2b2c30;
  }

  .text_0117 {
    width: 2.33rem;
    height: .48rem;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 显示的行数，这里设置为 3 行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .group_015 {
    padding: 0 0.12rem;
  }

  .view_011 {
    margin-top: 0.07rem;
  }

  .text-wrapper_015 {
    padding: 0.04rem 0;
    background-color: #f54a4a;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .font_015 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.12rem;
    font-weight: 300;
    color: #ffffff;
  }

  .text-wrapper_016 {
    padding: 0.04rem 0;
    background-color: #f9eceb;
    border-radius: 0.04rem;
    width: 1.73rem;
    height: 0.22rem;
  }

  .font_016 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.13rem;
    font-weight: 300;
    color: #f54a4a;
  }

  .text_0131 {
    margin-left: 0.07rem;
  }

  .view_015 {
    margin-top: 0.08rem;
  }

  .group_06 {
    margin-top: 0.2rem;
    padding: 0 0.13rem;
  }

  .space-x-018 > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text-wrapper_017 {
    padding: 0.04rem 0;
    background-color: #f4f7fe;
    border-radius: 0.04rem;
    width: 0.55rem;
    height: 0.22rem;
  }

  .font_017 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.12rem;
    font-weight: 300;
    color: #2080f7;
  }

  .text_0132 {
    line-height: 0.11rem;
  }

  .text-wrapper_018 {
    padding: 0.04rem 0;
    background-color: #edeff2;
    border-radius: 0.04rem;
    height: 0.22rem;
  }

  .font_018 {
    font-size: 0.12rem;
    letter-spacing: 0.017rem;
    line-height: 0.13rem;
    font-weight: 300;
    color: #666666;
  }

  .text_0133 {
    margin-left: 0.08rem;
  }

  .group_016 {
    margin-top: 0.2rem;
    padding: 0 0.08rem;
  }

  .font_0110 {
    font-size: 0.16rem;
    line-height: 0.13rem;
    font-weight: 300;
    color: #f75746;
  }

  .font_019 {
    font-size: 0.2rem;
    line-height: 0.14rem;
    font-weight: 500;
    color: #f75746;
  }

  .text_0130 {
    line-height: 0.15rem;
  }

  .group_017 {
    margin-left: 0.05rem;
    line-height: 0.14rem;
    display: flex;
    align-items: center;
  }

  .font_0111 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.12rem;
    color: #a1a9b2;
  }

  .text_0134 {
    line-height: 0.11rem;
  }

  .font_0112 {
    font-size: 0.14rem;
    line-height: 1;
    font-weight: 500;
    color: #a1a9b2;
  }

  .font_0113 {
    font-size: 0.14rem;
    line-height: 1;
    font-weight: 300;
    color: #a1a9b2;
    padding-left: 0.14rem;
    padding-right: 0.14rem;
    width: 100%;
  }

  .text_011 {
    margin-top: 0.12rem;
    height: 1em;
  }

  .group_018 {
    margin-top: 0.17rem;
    padding: 0.18rem 0.13rem;
    border-top: solid 0.01rem #eeeeee;
  }

  .space-x-017 > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .text-wrapper_019 {
    // width: 1.21rem;
    width: 100%;
    height: 0.29rem;
    border-radius: 0.04rem;
  }

  .font_0114 {
    font-size: 0.12rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.017rem;
    line-height: 0.12rem;
    font-weight: 300;
    color: #f54a4a;
  }

  .text-wrapper_0110 {
    margin-right: 0.04rem;
    width: 1.21rem;
    height: 0.29rem;
    border-radius: 0.04rem;
  }
}

.sys_cou_box {
  margin-top: 0.37rem;

  .font_1a {
    font-size: 0.14rem;
    line-height: 0.15rem;
    font-weight: 700;
    color: #ffffff;
  }

  &.space-y-18a > *:not(:first-child) {
    margin-top: 0.18rem;
  }

  .list-itema {
    background-color: #ffffff;
    border-radius: 0.14rem;
    border: solid 0.01rem #eeeeee;
    cursor: pointer;

    .font_3a {
      transition: color 0.5s ease;
    }

    @media screen and (orientation: landscape) {
      &:hover {
        .font_3a {
          color: #2080f7;
        }
      }
    }
  }

  .list-itema:last-of-type:last-of-type {
    border-top: solid 0.01rem #eeeeee;
    border-bottom: solid 0.01rem #eeeeee;
  }

  .group_3a {
    padding: 0 0.02rem 0.2rem 0.2rem;
    border-bottom: solid 0.01rem #eeeeee;
  }

  .group_4a {
    margin-top: 0.19rem;
    width: 100%;

    .group_5a {
      width: calc(100% - 2rem);
    }
  }

  .image_3a {
    border-radius: 0.08rem;
    width: 1.84rem;
    height: 1.02rem;
  }

  .group_5a {
    width: 2.02rem;
  }

  .font_3a {
    font-size: 0.16rem;
    font-family: Adobe Heiti Std;
    line-height: 0.24rem;
    color: #2b2c30;
  }

  .font_4a {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_14a {
    margin-top: 0.15rem;
  }

  .text_15a {
    margin-top: 0.1rem;
  }

  .list-itema {
    position: relative;
  }

  .text-wrapper_3a {
    padding: 0.14rem 0;
    background-color: #2080f7;
    border-radius: 0px 0.12rem 0px 0px;
    height: 0.42rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  .text_13a {
    margin-left: 0.14rem;
    margin-right: 0.08rem;
  }

  .group_6a {
    padding: 0.2rem 0.07rem 0.2rem 0.17rem;
    align-items: center;
  }

  .font_5a {
    font-size: 0.16rem;
    line-height: 1;
    font-weight: 500;
    color: #2b2c30;
  }

  .font_6a {
    font-size: 0.16rem;
    line-height: 1;
    color: #2b2c30;
    width: calc(100% - 1.23rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .group_7a {
    line-height: 1;
  }

  .font_7a {
    font-size: 0.16rem;
    color: #2080f7;
    display: block;
  }

  .text_16a {
    text-transform: uppercase;
  }

  .text_18a {
    line-height: 0.16rem;
  }

  .text_17a {
    line-height: 0.16rem;
  }

  .text_19a {
    line-height: 0.16rem;
  }

  .space-x-13a > *:not(:first-child) {
    margin-left: 0.13rem;
  }
}

.bd_z {
  margin-top: 0.07rem;
  height: .22rem;
  display: none;
}

// 单选
.el-radio-group {
  display: flex !important;
  align-items: center;

  .el-radio__input {
    font-size: 0;
    margin-left: 0.04rem;
  }

  .el-radio {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 0.2rem;
  }

  .el-radio__label {
    display: flex;
    align-items: center;
    padding-left: 0;
  }
}

.m_sty {
  display: none;
}


.srt_on {
  color: #f95d22;
}

.str_ok {
  color: rgba(32, 128, 247, 1);
}

.str_end {
  color: rgba(161, 169, 178, 1);
}

.str_all {
  color: rgba(0, 181, 146, 1);
}

@media screen and (orientation: portrait) {
  .m_sty {
    display: block !important;
  }
  .pc_sty {
    display: none !important;
  }

  .pub_case {
    padding-left: 0.45rem;
    padding-right: 0.45rem;
  }
  .font_5 {
    font-size: 0.28rem;
  }


  .group_2 {
    .pub_case {
      > div {
        display: block;

        .image-wrapper {
          width: 100%;
          height: auto;
        }

        .section_2 {
          width: 100%;
          height: auto;

          .section_3 {
            width: 100%;
            padding: 0.3rem;

            .space-x-10 {
              img {
                width: 0.32rem;
              }

              .font_5 {
                line-height: 1;
                display: flex;
                align-items: center;
              }
            }

            .group_56 {
              margin-top: 0.3rem;

              .text_5 {
                width: calc(100% - 0.5rem);
                line-height: .32rem;
              }

              .text_6 {
                font-size: 0.3rem;
                height: auto;
                line-height: .32rem;
              }

              .group_4 {
                margin-right: 0;
                margin-top: .2rem;

                &:nth-child(1) {
                  margin-top: 0;
                }

                align-items: flex-start;

              }

            }
          }
        }
      }
    }
  }

  .course_box {
    .section_cou {
      width: 100%;
      margin-right: 0;
      margin-bottom: .4rem;
      margin-top: 0;
      position: relative;

    }

    .image_03 {
      max-height: 3.68rem;
    }

    .text_020 {
      font-size: .32rem;
      height: 2.4em;
      line-height: 1.2;
      width: 100%;
      padding-left: .3rem;
      padding-right: .3rem;
    }

    .text-wrapper_05 {
      height: 0.44rem;
      width: 1.1rem;
      padding: 0;
    }

    .text-wrapper_06 {
      height: .44rem;
      width: calc(100% - 1.1rem);
      font-size: .2rem;
      padding: 0 0 0 .2rem;
    }

    .font_07, .font_08 {
      font-size: .2rem;
      line-height: .44rem;

    }

    .view_02 {
      margin-top: .2rem;
      padding-left: .3rem;
      padding-right: .3rem;
    }

    .view_03 {
      margin-top: .2rem;
      padding-left: .3rem;
      padding-right: .3rem;
    }

    .text-wrapper_07 {
      width: 1.1rem;
      height: .33rem;
      padding: 0;
    }

    .font_09 {
      font-size: .2rem;
      line-height: .33rem;
    }

    .space-x-08 > *:not(:first-child) {
      margin-left: .2rem;
    }

    .text-wrapper_08 {
      height: .33rem;
      padding: 0 .2rem;
    }

    .text_022 {
      font-size: .2rem;
      margin: 0;
      line-height: .33rem;
    }

    .group_010 {
      padding-right: .3rem;
      padding-left: .3rem;
    }

    .font_012, .font_03, .font_013 {
      font-size: .2rem;
      line-height: 1;
    }

    .text_023 {
      font-size: .32rem;
      line-height: 1;
    }

    .text_025 {
      padding-left: .3rem;
      padding-right: .3rem;
      line-height: 1;
      font-size: .2rem;
    }

    .group_012 {
      margin-top: .2rem;
      padding: .2rem .3rem;
    }

    .image_04 {
      width: .5rem;
      height: .5rem;
    }

    .space-x-09 > *:not(:first-child) {
      margin-left: .2rem;
    }

    .font_016 {
      font-size: .2rem;
      line-height: 1;
    }

    .text-wrapper_09 {
      width: 1.6rem;
      height: .5rem;
    }

    .font_015 {
      line-height: .5rem;
      font-size: .24rem;
    }

    .space-x-24 {

    }
  }
  .btn_link {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .page:not(.home) {
    .course_box {
      > div {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .section_cou {
        width: 48%;

        .text_020 {
          font-size: .28rem;
          padding-left: .2rem;
          padding-right: .2rem;
        }

        .view_03, .group_010.bd, .group_012, .text_025, .group_010 {
          padding-left: .2rem;
          padding-right: .2rem;
        }

        .text_025 {
          font-size: .18rem;
        }
      }

      .space-x-09 > *:not(:first-child) {
        margin-left: .1rem;
      }

      .text-wrapper_09 {
        width: 1.2rem;

        .font_015 {
          font-size: .2rem;
        }
      }
    }
  }
  .news_box {
    .list-item {
      padding: .3rem 0;

      > img {
        width: 30%;
        margin-right: 0;
      }

      .group_3 {
        width: 70%;
        padding-left: .2rem;

        .font_4 {
          font-size: .28rem;
          white-space: pre-wrap;
          line-height: 1.2;
          overflow-wrap: break-word; /* 允许单词在任何地方断开以防止溢出 */
          text-overflow: ellipsis; /* 在溢出时显示省略号 */
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 设置显示的行数 */
          -webkit-box-orient: vertical;

        }

        .text_14 {
          font-size: .2rem;
          display: none;
        }

        .font_6 {
          font-size: .2rem;
          display: none;
        }
      }


    }
  }


  .nav_left {
    width: 100%;
    //flex-wrap: wrap;
    //display: flex;
    .swiper-slide {
      width: auto;
    }

    a {

      height: 0.6rem;
      font-size: 0.2rem;
      padding: 0 .3rem;


    }
  }

  .sys_cou_box {
    margin-top: 0.4rem;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;


    &.space-y-18a > *:not(:first-child) {
      margin-top: 0.4rem;
    }

    .group_3a {
      padding: 0 0 .3rem;
    }

    .text-wrapper_3a {
      height: auto;
      max-width: 100%;

      .text_13a {
        font-size: .2rem;
        margin: 0;
        padding: 0 .2rem;
        //word-break: break-all;
        //line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }
    }

    .group_4a {
      margin: 0;
      flex-wrap: wrap;

      .image_3a {
        width: 100%;
        //height: 3.64rem;
        height: 1.76rem;

      }

      .group_5a {
        width: 100%;
        padding: .3rem .2rem 0;
        margin: 0;
      }

    }

    .list-itema {
      width: 48%;
      margin-top: .4rem;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      .font_3a {
        font-size: .28rem;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .text_14a, .text_15a {
      //font-size: .2rem;
      font-size: .18rem;
      line-height: 1.5;
      display: none;
    }

    .group_6a {

      padding: .2rem .2rem .3rem;
      justify-content: space-between;
    }

    .font_6a {
      width: calc(100% - 8em);
    }

    .font_5a, .font_6a, .font_7a {
      font-size: .2rem;
      line-height: 1;
    }


  }
 .el-message-box__wrapper .el-message-box {
    width: 85% ;
  }
}
</style>
