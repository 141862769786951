<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col pub_-justify-start pub_-flex-auto group_53">
      <div class="pub_-flex-col pub_-justify-start">
        <div class="pub_-flex-col group_2">
          <div
            class="pub_case"
          >
            <p class="abo_con" v-html="config"></p>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

  <script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";

import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  components: { NavBox, FootBox },
  data() {
    return {
      config: null,
    };
  },
  created() {
    this.ajaxH();
  },
  mounted() {},
  methods: {
    async ajaxH() {
      this.getConfig();
    },

    async getConfig(id, i) {
      let param = {};
      const { data: res } = await this.$http.post("/api.setUp/getConfig", param);
      //   console.log(res)
      this.config = res.data.companyProfile;
    },

    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
  },
};
</script>

  <style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .abo_con {
    margin: 0.8rem auto;
    min-height: 2rem;
    font-size: 0.18rem;
    line-height: 1.5;
    text-align: justify;
    color: #000;
  }
}
</style>
