<template>
  <div class="page">
    <nav-box></nav-box>
    <div class="pub_-flex-col">
      <div class="pub_-flex-row pub_-self-center group space-x-40 pub_case">
        <left-nav></left-nav>
        <div class="pub_-flex-col pub_-flex-auto section_3" id="target">
          <div
            class="pub_-flex-row pub_-justify-between pub_-items-center group_2"
          >
            <span class="font_2 text_12">工单列表</span>
            <div class="pub_-flex-row space-x-12">
              <!--              <div-->
              <!--                class="pub_-flex-row pub_-items-center section_4 space-x-128"-->
              <!--              >-->
              <!--                <input class="font_4 text_13" placeholder="名称/时间" />-->
              <!--                <img-->
              <!--                  class="pub_-shrink-0 image"-->
              <!--                  src="../../assets/6b987e78e3f74edbb672d522d32d84b6.png"-->
              <!--                />-->
              <!--              </div>-->
              <div
                class="pub_-flex-row section_5 space-x-4 btn_sty btn_sty_line"
                @click="tapOrder(true)"
              >
                <img
                  class="pub_-shrink-0 image_2"
                  src="../../assets/9d6524753c2bb3dcd3ce08f7ac060690.png"
                  alt=""/>
                <span class="font_4 text_14">添加工单</span>
              </div>
            </div>
          </div>

          <div class="con_box">
            <div class="section_6">
              <span></span>
              <span class="font_5">工单名称</span>
              <span class="font_5">内容描述</span>
              <span class="font_5">状态</span>
              <span class="font_5">时间</span>
              <span class="font_5">操作</span>
            </div>
            <div class="group_3" v-for="(item, i) in showList" :key="i">
              <span class="font_8"><span class="m_sty">序号：</span>{{ i + 1 }}</span>
              <span class="font_6"><span class="m_sty">工单名称：</span>{{ item.name }}</span>
              <span class="font_7"><span class="m_sty">内容描述：</span>{{ item.content }}</span>

              <!-- text_21  处理中 text_21_1 已反馈 text_21_2 已关闭-->
              <span class="font_6 text_21" v-if="item.status == 1"><span class="m_sty">状态：</span>处理中</span>
              <span class="font_6 text_21_1" v-else-if="item.status == 2"
              ><span class="m_sty">状态：</span>已反馈</span
              >
              <span class="font_6 text_21_2" v-else><span class="m_sty">状态：</span>已关闭</span>
              <span class="font_10"><span class="m_sty">时间：</span>{{ item.createTime }}</span>

              <div class=" opt_box">
                <div
                  class=" font_9"
                  @click="examine(item.id)">查看
                </div>
                <div class="font_9 text_21_1" @click="closeW(item.id)" v-if="item.status == 2"
                >关闭
                </div>
              </div>

            </div>
          </div>

          <Pagination
            @change="changePage"
            :current="pagination.p"
            :pageSize="pagination.pageSize"
            :total="total"
            :hideOnSinglePage="hideOnSinglePage"
            :showQuickJumper="true"
            :showTotal="true"
            placement="right"
            v-if="pagination && total"
          />
        </div>
      </div>
    </div>
    <foot-box></foot-box>

    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos ord_sha"
      v-if="ordShow == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row pub_-items-center section_10a space-x-462a">
          <span class="font_4a text_19a">添加工单</span>
          <img
            class="pub_-shrink-0 image_3a" alt=""
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="tapOrder(false)"
          />
        </div>
        <div class="pub_-flex-col section_13a space-y-20a">
          <div class="pub_-flex-col group_7a space-y-19a">
            <div class="pub_-flex-row pub_-items-center space-x-11a">
              <span class="font_10a">工单名称</span>
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-start pub_-shrink-0 text-wrapper_3a"
              >
                <input
                  class="font_11a text_28a"
                  v-model="wordOrder.name"
                  placeholder="请输入工单名称"
                />
              </div>
            </div>
            <div class="pub_-flex-row space-x-10a">
              <span class="pub_-self-start font_10a text_31a">工单描述</span>
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-start pub_-shrink-0 text-wrapper_5a"
              >
                <textarea
                  class="font_13a text_32a"
                  v-model="wordOrder.content"
                  placeholder="请输入工单描述"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="pub_-flex-row pub_-items-center group_7a space-x-11a">
            <span class="font_13a text_35a">联系方式</span>
            <div class="pub_-flex-row tle_box pub_-relative">
              <div
                class="pub_-flex-row pub_-items-center pub_-shrink-0 section_5s"
                :class="{ on: selOn == true }"
              >
                <div class="sel_btn" @click="selShow">
                  <span class="font_1 text">{{ typeT }}</span>
                  <img alt=""
                       class="pub_-shrink-0 image_5"
                       src="../../assets/efdde20dd7b5d9af526b5c7bd481b651.png"
                  />
                </div>

                <div class="sel_box">
                  <ul>
                    <li v-for="(item,i) in relationList" :key="i"
                        @click="selS(item.type, item.name, item.value)"
                        :data-type="item.type"
                        :data-name="item.name"
                    >
                      {{ item.name }}
                    </li>

                  </ul>
                </div>
              </div>
              <span class="font_13a text_37a">
                <input
                  v-if="typeN == 'mobile'"
                  class="lx_input"
                  type="number"
                  placeholder="1**********"
                  v-model="wordOrder.contactInfo"
                />
                <input
                  v-else-if="typeN == 'email'"
                  class="lx_input"
                  type="email"
                  placeholder="****@**.com"
                  v-model="wordOrder.contactInfo"
                />
                 <input
                   v-else
                   class="lx_input"
                   type="text"
                   placeholder="请输入账号"
                   v-model="wordOrder.contactInfo"
                 />
              </span>
            </div>
          </div>
          <div class="divider"></div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center pub_-self-center text-wrapper_6a btn_sty"
            @click="addWorkOrder"
          >
            <span class="font_2a text_38a">添加</span>
          </div>
        </div>
      </div>
    </div>


    <div
      class="pub_-flex-col pub_-justify-start pub_-items-center section_3a pos ord_sha_see ord_sha"
      v-if="seeOrd == true"
    >
      <div class="pub_-flex-col group_1a">
        <div class="pub_-flex-row pub_-items-center section_10a space-x-462a">
          <span class="font_4a text_19a">查看工单</span>
          <img
            class="pub_-shrink-0 image_3a"
            src="../../assets/dfbd0c2c61678109215297d3b1ee5c50.png"
            @click="seeOrd = false" alt=""
          />
        </div>
        <div class="pub_-flex-col section_13a space-y-20a">
          <div class="pub_-flex-col group_7a space-y-19a">
            <div class="pub_-flex-row space-x-11a  pub_-items-center">
              <span class="font_10a">创建时间</span>
              <div class="ord_sha_mes">
                {{ seeOrdList.createTime }}
              </div>
            </div>
            <div class="pub_-flex-row space-x-11a  pub_-items-center">
              <span class="font_10a">工单名称</span>
              <div class="ord_sha_mes">
                {{ seeOrdList.name }}
              </div>
            </div>
            <div class="pub_-flex-row  ord_sha_case space-x-10a">
              <span class="font_10a">工单描述</span>
              <div class="ord_sha_mes">
                {{ seeOrdList.content }}
              </div>
            </div>
          </div>
          <div class="pub_-flex-row pub_-items-center group_7a space-x-11a">
            <span class="font_10a">联系方式</span>
            <div class="ord_sha_mes">
              <template v-if="seeOrdList.contactType == 1">手机号</template>
              <template v-else-if="seeOrdList.contactType == 2">邮箱</template>
              <template v-else-if="seeOrdList.contactType == 3">微信</template>
              <template v-else-if="seeOrdList.contactType == 4">QQ</template>
              <template v-else>whatsApp</template> &nbsp; {{ seeOrdList.contactInfo }}
            </div>
          </div>
          <div class="pub_-flex-row pub_-items-center group_7a space-x-11a">
            <span class="font_10a">状态</span>
            <div class="ord_sha_mes">
              <span class="text_21" v-if="seeOrdList.status == 1">处理中</span>
              <span class="text_21_1" v-else-if="seeOrdList.status == 2">已反馈</span>
              <span class="text_21_2" v-else>已关闭</span>

            </div>
          </div>
          <div class="pub_-flex-row  group_7a space-x-11a">
            <span class="font_10a">反馈详情</span>
            <div class="ord_sha_mes">
              <div v-if="seeOrdList.feedbackList.length >= 1">
                <block v-for="(item,i) in seeOrdList.feedbackList" :key="i">{{ item.feedbackRemark }}</block>
              </div>
              <div v-else>暂无反馈</div>

            </div>
          </div>
          <div class="divider"></div>
          <div
            class="pub_-flex-col pub_-justify-start pub_-items-center pub_-self-center text-wrapper_6a btn_sty">
            <span class="font_2a text_38a" @click="seeOrd = false">关闭</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: {NavBox, FootBox, LeftNav, Pagination},
  data() {
    return {
      ordShow: false,

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },

      relationList: [
        {
          type: "mobile",
          name: "手机号",
          value: "1",
        },
        {
          type: "email",
          name: "邮箱",
          value: "2",
        },
        {
          type: "text",
          name: "微信",
          value: "3",
        },
        {
          type: "text",
          name: "QQ",
          value: "4",
        },
        {
          type: "text",
          name: "whatsApp",
          value: "5",
        }
      ],

      showList: [], //显示的列表
      conC: [], //完整的列表

      typeN: "mobile",
      typeT: "手机号",
      selOn: false,
      email: "",
      phone: "",
      value: '',

      wordOrder: {
        name: "",
        content: "",
        contactType: "1",
        contactInfo: "",
      },


      //查看工单
      seeOrd: false,
      seeOrdList: {},
    };
  },

  mounted() {
    this.ajaxH();
  },
  methods: {
    async ajaxH() {
      let param = {};
      const {data: res} = await this.$http.post(
        "/api.workOrder/getPageList",
        param
      );
      // console.log(res);
      this.showList = res.data.data.slice(0, res.data.per_page);
      this.conC = res.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;
    },

    async examine(id) {
      let param = {
        id: id,
      };
      const {data: res} = await this.$http.post(
        "/api.workOrder/getInfo",
        param
      );
      console.log(res);

      this.seeOrdList = res.data;

      this.seeOrd = true;
    },
    async closeW(id) {
      let that = this;
      let param = {
        id: id,
      };
      const {data: res} = await this.$http.post("/api.workOrder/close", param);
      console.log(res);
      this.$message({
        message: res.msg,
      });
      if (res.code == 0) {
        that.ajaxH();
      }
    },
    tapOrder(str) {
      this.ordShow = str;
    },

    async addWorkOrder() {

      if (this.wordOrder.name == "") {
        this.$message({
          type: "error",
          message: "名称不能为空",
        });
        return false;
      }
      if (this.wordOrder.content == "") {
        this.$message({
          type: "error",
          message: "描述",
        });
        return false;
      }
      if (this.wordOrder.contactInfo == "") {
        // alert("请输入手机号码");
        this.$message({
          message: "联系方式",
          type: "warning",
        });
        return false;
      }
      if (this.wordOrder.contactType == 1 || this.wordOrder.contactType == 2) {
        const shouldContinue = this.verify(true);
        if (shouldContinue === false) {
          return; // 停止执行后续代码
        }
      }

      let param = this.wordOrder;
      // console.log(param);

      const {data: res} = await this.$http.post("/api.workOrder/add", param);
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.ordShow = false;
        this.wordOrder.name = "";
        this.wordOrder.content = "";
        this.wordOrder.contactType = "1";
        this.wordOrder.contactInfo = "";
        this.typeN = "mobile";
        this.typeT = "手机号";
        this.ajaxH();

      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    verify(str) {
      const result = this.typeN === 'mobile' ? this.sendcode(str) : this.sendEmail(str);

      if (result === false) {
        return false;
      }
    },
    //验证手机号码部分
    sendcode(str) {
      // var reg = 11 && /^((13|14|15|17|18)[0-9]{1}d{8})$/;
      var reg = 11 && /^[1][3,4,5,7,8,9][0-9]{9}$/;
      //var url="/nptOfficialWebsite/apply/sendSms?mobile="+this.ruleForm.phone;
      this.value = this.wordOrder.contactInfo;

      if (this.value == "") {
        // alert("请输入手机号码");
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
        return false;
      } else if (!reg.test(this.value)) {
        this.$message({
          message: "手机格式不正确",
          type: "error",
        });
        return false;
      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;
          this.timerC();
          this.sendVerifyCode();
        }
      }
    },
    //邮箱验证
    sendEmail(str) {
      var regEmail =
        /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/;
      this.value = this.wordOrder.contactInfo;

      if (this.value == "") {
        this.$message({
          message: "请输入邮箱",
          type: "warning",
        });
        return false;
      } else if (!regEmail.test(this.value)) {
        this.$message({
          message: "邮箱格式不正确",
          type: "error",
        });
        return false;
      } else {
        if (str != true) {
          this.time = 60;
          this.disabled = true;
          this.timerC();
          this.sendVerifyCode();
        }
      }
    },


    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url + "?active=" + this.active;
      this.$router.push(path);
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.conC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    selS(n, t, id) {
      this.value = "";
      this.typeN = n;
      this.typeT = t;
      this.wordOrder.contactType = id;
      this.selOn = false;
    },
    selShow() {
      if (this.selOn === false) {
        this.selOn = true;
      } else {
        this.selOn = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}

.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}

.text {
  color: #ffffff;
}

.text_2 {
  color: #2b2c30;
}

.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}

.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}

.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_4 {
  margin-left: 0.35rem;
}

.text_3 {
  margin-left: 0.42rem;
}

.text_5 {
  margin-left: 0.44rem;
}

.text_6 {
  margin-left: 0.35rem;
}

.text_7 {
  margin-left: 0.34rem;
}

.text_8 {
  margin-left: 0.43rem;
}

.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.text_9 {
  line-height: 0.18rem;
}

.group {
  padding-top: 0.4rem;
  margin-bottom: 0.5rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.15rem;
  font-weight: 300;
  color: #000000;
}

.text_10 {
  line-height: 0.14rem;
}

.text_11 {
  margin-left: 0.19rem;
}

.text_15 {
  margin-left: 0.17rem;
}

.text_16 {
  margin-left: 0.16rem;
}

.text_18 {
  margin-left: 0.17rem;
}

.section_7 {
  background-color: #ffffff;
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.section_8 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}

.text_24 {
  margin-right: 1.27rem;
  color: #2080f7;
  line-height: 0.14rem;
}

.text-wrapper_3 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}

.font_11 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.13rem;
  font-weight: 300;
  color: #000000;
}

.text_25 {
  margin-left: 0.17rem;
}

.text_31 {
  margin-left: 0.17rem;
}

.text_37 {
  margin-left: 0.16rem;
}

.section_3 {
  padding: 0 0.4rem calc(1rem + 32px);
  position: relative;
  background-color: #ffffff;
  width: 9.42rem;
  min-height: 8.74rem;

  .m-pagination {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    width: 100%;
    padding-right: 0.5rem;
  }
}

.group_2 {
  padding: 0.34rem 0 0.14rem;
}

.text_12 {
  font-weight: 700;
  text-transform: uppercase;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.12rem;
}

.section_4 {
  padding-left: 0.06rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  height: 0.28rem;
  border: solid 0.01rem #e0e0e0;
}

.font_4 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
}

.text_13 {
  color: #e0e0e0;
  line-height: 0.14rem;
}

.image {
  border-radius: 0.04rem;
  width: 0.28rem;
  height: 0.28rem;
}

.section_5 {
  padding: 0.04rem 0.06rem 0.07rem;
  border-radius: 0.04rem;
  height: 0.28rem;
  border: solid 0.01rem #2080f7;
}

.sel_btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.section_5s {
  padding: 0.12rem 0.06rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  height: 0.39rem;
  border: solid 0.01rem rgba(243, 246, 250, 1);
  margin: -0.01rem;
  position: relative;
  width: 1.1rem;

  .image_5 {
    width: 0.1rem;
    height: 0.06rem;
  }

  .sel_btn {
    cursor: pointer;

    .font_1 {
      transition: color 0.5s ease;
    }

    @media screen and (orientation: landscape) {
      .font_1 {
        color: rgba(43, 44, 48, 1);
      }
    }
  }

  &.on {
    .sel_box {
      transform: none;
      opacity: 1;
      pointer-events: all;
    }
  }

  .sel_box {
    position: absolute;
    width: 200%;
    left: 0;
    top: 100%;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0 0.2rem 0.6rem -0.15rem rgba($color: #000000, $alpha: 0.2);
    border-radius: 0.04rem;

    transition: all 0.5s ease;
    transform: translateY(0.5rem);
    opacity: 0;
    pointer-events: none;

    ul {
      display: block;
      padding: 0.1rem 0;
      margin: 0;

      li {
        display: block;
        font-size: 0.14rem;
        line-height: 2.5;
        padding: 0 0.2rem;
        cursor: pointer;
        transition: color 0.5s ease;

        @media screen and (orientation: landscape) {
          &:hover {
            color: #2080f7;
          }
        }
      }
    }
  }
}

.space-x-4 > *:not(:first-child) {
  margin-left: 0.04rem;
}

.image_2 {
  width: 0.15rem;
  height: 0.15rem;
}

.text_14 {
  line-height: 0.14rem;
}

.section_6 {
  background-color: #f3f6fa;
  display: flex;
  align-items: center;
  padding: 0.15rem 0.16rem;
  height: 0.7rem;
  text-align: left;

  span {
    padding-right: 0.4rem;

    &:last-child {
      padding-right: 0;
    }

    &:nth-child(1) {
      width: 0.62rem;
    }

    &:nth-child(2) {
      width: 1.58rem;
    }

    &:nth-child(3) {
      width: 3.07rem;
    }

    &:nth-child(4) {
      width: 1.09rem;
    }

    &:nth-child(5) {
      width: 1.39rem;
    }

    &:nth-child(6) {
      width: 0.55rem;
    }
  }
}

.group_3 {
  padding: 0.15rem 0.16rem;
  display: flex;
  min-height: 0.56rem;
  border-bottom: 1px solid rgba(243, 246, 250, 1);

  span {
    padding-right: 0.4rem;
    line-height: 1.5;

    &:last-child {
      padding-right: 0;
    }

    &:nth-child(1) {
      width: 0.62rem;
    }

    &:nth-child(2) {
      width: 1.58rem;
    }

    &:nth-child(3) {
      width: 3.07rem;
      display: -webkit-box;

      -webkit-line-clamp: 2;

      -webkit-box-orient: vertical;

      overflow: hidden;
    }

    &:nth-child(4) {
      width: 1.09rem;
    }

    &:nth-child(5) {
      width: 1.39rem;
    }

    &:nth-child(6) {
      width: 0.55rem;
      cursor: pointer;
    }
  }
}

.space-x-92 > *:not(:first-child) {
  margin-left: 0.92rem;
}

.font_5 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #2b2c30;
}

.font_6 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_17 {
  line-height: 0.16rem;
}

.space-x-105 > *:not(:first-child) {
  margin-left: 1.05rem;
}

.space-x-75 > *:not(:first-child) {
  margin-left: 0.75rem;
}

.group_4 {
  margin-top: 0.05rem;
}

.space-x-48 > *:not(:first-child) {
  margin-left: 0.48rem;
}

.font_8 {
  font-size: 0.16rem;
  font-family: OPPOSans;
  line-height: 0.13rem;
  color: #2b2c30;
}

.text_20 {
  line-height: 0.16rem;
}

.font_7 {
  font-size: 0.16rem;
  font-family: Adobe Heiti Std;
  line-height: 0.17rem;
  color: #2b2c30;
}

.text_19 {
  line-height: 0.24rem;
  width: 2.17rem;
}

.group_5 {
  margin-top: 0.05rem;
}

.text_21 {
  color: #f95d22;
}

.text_21_1 {
  color: rgba(32, 128, 247, 1);
}

.text_21_2 {
  color: rgba(161, 169, 178, 1);
}

.text_21_3 {
  color: rgba(0, 181, 146, 1);
}

.font_10 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.12rem;
  font-weight: 300;
  color: #2b2c30;
}

.text_23 {
  margin-left: 0.59rem;
}

.font_9 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
  font-weight: 300;
  color: #00b592;
}

.opt_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: .55rem;
  text-align: center;

  > div {
    cursor: pointer;
    width: 100%;
  }

  .text_21_1 {
    margin-top: .08rem;
    border-radius: .06rem;
    padding: .08rem 0;
    background-color: rgba(32, 128, 247, 1);
    color: #fff;
  }
}

.text_22 {
  margin-left: 0.54rem;
}

.group_6 {
  padding: 0.2rem 0.16rem;
  border-top: solid 0.01rem #f3f6fa;
  border-bottom: solid 0.01rem #f3f6fa;
}

.space-x-50 > *:not(:first-child) {
  margin-left: 0.5rem;
}

.text_26 {
  line-height: 0.16rem;
}

.text_27 {
  line-height: 0.16rem;
}

.group_7 {
  margin-right: 0.21rem;
}

.text_28 {
  color: #a1a9b2;
}

.text_30 {
  margin-left: 0.59rem;
}

.font_12 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  font-weight: 300;
  color: #2080f7;
}

.text_29 {
  margin-left: 0.54rem;
  line-height: 0.16rem;
}

.group_8 {
  padding: 0.2rem 0.17rem;
  border-bottom: solid 0.01rem #f3f6fa;
}

.space-x-49 > *:not(:first-child) {
  margin-left: 0.49rem;
}

.text_32 {
  line-height: 0.16rem;
}

.text_33 {
  line-height: 0.16rem;
}

.group_9 {
  margin-right: 0.2rem;
}

.text_34 {
  line-height: 0.16rem;
}

.text_36 {
  margin-left: 0.59rem;
}

.text_35 {
  margin-left: 0.54rem;
}

.ord_sha {
  &.section_3a {
    padding: 0;
    background-color: #051b3733;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }

  .group_1a {
    width: 6rem;
  }

  .section_10a {
    padding: 0.26rem 0.19rem;
    background-color: #ffffff;
    border-radius: 0.14rem 0.14rem 0 0;
    border-bottom: solid 0.01rem #f3f6fa;
  }

  .space-x-462a > *:not(:first-child) {
    margin-left: 4.62rem;
  }

  .text_19a {
    line-height: 0.18rem;
  }

  .image_3a {
    margin-right: 0.05rem;
    width: 0.21rem;
    height: 0.2rem;
    cursor: pointer;
  }

  .section_13a {
    padding: 0.2rem 0;
    background-color: #ffffff;
    border-radius: 0 0 0.14rem 0.14rem;
  }

  .space-y-20a > *:not(:first-child) {
    margin-top: 0.2rem;
  }

  .group_7a {
    padding: 0 0.2rem;
  }

  .tle_box {
    width: 4.78rem;

    .text_37a {
      width: calc(100% - 1.1rem);

      border: 1px solid #f3f6fa;
      border-left: none;

      .lx_input {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0.1rem 0.2rem;
      }
    }
  }

  .space-y-19a > *:not(:first-child) {
    margin-top: 0.19rem;
  }

  .space-x-11a > *:not(:first-child) {
    margin-left: 0.11rem;
  }

  .font_10a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 1;
    font-weight: 300;
    color: #a1a9b2;
  }

  .text-wrapper_3a {
    border: solid 0.01rem #f3f6fa;
    width: 4.77rem;

    input {
      display: block;
      width: 100%;
      padding-top: 0.12rem;
      padding-bottom: 0.12rem;
      height: 0.44rem;
      border: none;
      outline: none;
    }
  }

  .font_11a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #2b2c30;
  }

  .text_28a {
    padding-left: 0.14rem;
    line-height: 0.16rem;
  }

  .space-x-10a > *:not(:first-child) {
    margin-left: 0.1rem;
  }

  .text_31a {
    margin-top: 0.15rem;
  }

  .text-wrapper_5a {
    width: 4.78rem;

    border: solid 0.01rem #f3f6fa;

    textarea {
      display: block;
      resize: none;
      height: 1.33rem;
      width: 100%;
      padding: 0.15rem 0.14rem;
      border: none;
      outline: none;
      color: #2b2c30;
    }
  }

  .font_13a {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    letter-spacing: 0.019rem;
    line-height: 0.17rem;
    font-weight: 300;
    color: #dbdbdb;
  }

  .text_35a {
    color: #a1a9b2;
    line-height: 0.16rem;
  }

  .section_14a {
    width: 4.77rem;
    height: 0.44rem;
    border: solid 0.01rem #f3f6fa;
  }

  .space-x-14a > *:not(:first-child) {
    margin-left: 0.14rem;
  }

  .section_15a {
    padding: 0.12rem 0.08rem 0.12rem 0.14rem;
    height: 0.44rem;
    border: solid 0.01rem #f3f6fa;
  }

  .space-x-8a > *:not(:first-child) {
    margin-left: 0.08rem;
  }

  .text_36a {
    line-height: 0.16rem;
  }

  .image_4a {
    width: 0.1rem;
    height: 0.06rem;
  }

  .text_37a {
    line-height: 0.16rem;
  }

  .divider {
    background-color: #f3f6fa;
    border-radius: 0.01rem 0.01rem 0 0;
    height: 0.01rem;
  }

  .text-wrapper_6a {
    border-radius: 0.04rem;
    width: 1.49rem;
    height: 0.44rem;
  }

  .text_38a {
    letter-spacing: 0.025rem;
  }

  .font_4a {
    font-size: 0.18rem;
    line-height: 0.17rem;
    font-weight: 700;
  }

  .font_2a {
    font-size: 0.18rem;
    line-height: 0.17rem;
  }
}


.ord_sha_mes {
  font-size: .16rem;
  line-height: 1.5;
}

.ord_sha_see {
  .font_10a {
    line-height: 1.5;
    min-width: 6em;

  }

  .space-x-10a > *:not(:first-child),
  .space-x-11a > *:not(:first-child) {
    margin-left: 0;
  }

  .ord_sha_mes {
    width: calc(100% - 6em);
    padding-left: .12rem;
  }

  .ord_sha_case {
    align-items: flex-start;

    .text_31a,
    .ord_sha_mes {
      margin-top: 0;
      line-height: 1.5;
      white-space: normal;

    }

  }
}

.text_al_r {
  text-align: right;
  display: block;
}

.m_sty {
  display: none;
}

@media screen and (orientation: portrait) {
  .m_sty {
    display: block;
  }
  #target {
    width: 100%;
    margin-left: 0;
    padding: .3rem .3rem calc(32px + 1rem);
    margin-top: .4rem;
  }
  .group {
    flex-wrap: wrap;
  }
  .group_2 {
    padding: 0 0 .3rem;

    .font_2 {
      font-size: .22rem;
    }

    .section_5 {
      padding: .1rem .2rem;
      width: auto;
      height: auto;

      .image_2 {
        width: .22rem;
        height: .22rem;
      }

      .font_4 {
        font-size: .2rem;
        line-height: 1;
        margin-left: .1rem;
      }
    }
  }

  .section_6 {
    display: none;
  }
  .group_3 {
    flex-wrap: wrap;
    border-bottom: none;
   background-color:   rgb(243, 246, 250);
    padding: .2rem;
    border-radius: .1rem;

    > span {
      width: 60% !important;
      display: flex !important;
      align-items: center;
      font-size: .22rem;
      line-height: 1.35;
      padding-bottom: .2rem;

      span {
        width: auto !important;
        color: #000;
        font-weight: 400;
      }
      &:nth-child(1),
      &:nth-child(3){
        display: none !important;
      }

&:nth-child(2){
  width: 100% !important;
}
      &:nth-child(3),
      &:nth-child(6) {
        width: 100% !important;
      }

      &:nth-child(1),
      &:nth-child(4) {
        width: 40% !important;
      }

    }
  }

  .opt_box {
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
    width: 100%;

    > div {
      width: 1.2rem;
      text-align: center;
      height: .6rem;


    }

    .font_9 {
      font-size: .2rem;
      padding: .2rem;
      line-height: 1;
      margin-top: 0;
      margin-right: .2rem;

      &:first-child {
        border: 1px solid #00b592;
        border-radius: .06rem;
      }
    }

  }

  .ord_sha {
    .group_1a {
      width: 90%;
    }

    .section_10a {
      padding: .3rem .3rem .2rem;
    }

    .font_4a {
      font-size: .28rem;
      line-height: 1;
    }

    .image_3a {
      width: .4rem;
      height: auto;
      margin-left: auto !important;
    }

    .font_10a, .ord_sha_mes {
      font-size: .22rem;

    }

    .group_7a {
      padding-left: .3rem;
      padding-right: .3rem;
    }

    .text-wrapper_6a {
      width: 2rem;
      height: auto;
      padding: .2rem;

      .font_2a {
        line-height: 1;
        font-size: .24rem;
      }
    }

    .section_13a {
      padding-top: .3rem;
      padding-bottom: .3rem;
    }

    .space-y-20a > *[data-v-59f7dc20]:not(:first-child) {
      margin-top: .3rem;
    }

    .text-wrapper_3a {
      input {
        font-size: .22rem;
        height: .7rem;
      }
    }

    .text-wrapper_5a textarea {
      font-size: .22rem;
      line-height: 1.35;
    }

    .font_13a {
      font-size: .22rem;
      line-height: 1;
    }

    .tle_box .text_37a {
      width: calc(100% - 1.8rem);
      .lx_input{
        font-size: .22rem;
      }
    }

    .text-wrapper_3a, .text-wrapper_5a, .tle_box {
      font-size: .22rem;
      margin-left: 0.2rem !important;
      width: calc(100% - 4.2em - .2rem);
    }

    .font_1 {
      font-size: .22rem;
    }

  }
  .section_5s {
    height: .7rem;

    width: 1.8rem;
 .sel_box ul li {

   font-size: .2rem;
 }
    .sel_btn .font_1 {
      color: rgb(43, 44, 48);
      line-height: 1;
      padding-left: .1rem;

    }
  }

}


</style>
