<template>
  <div class=" page">
    <nav-box></nav-box>

    <div class="pub_-flex-col group space-y-40">

      <div class="pub_-flex-col pub_-self-center group_6 pub_case space-y-20">
        <span class="pub_-self-start font_3 text_10 text_11">确认订单消息</span>
        <div class="pub_-flex-col section_3">
          <div class="pub_-flex-col pub_-items-center pub_-self-center space-y-20">
            <img class="image" src="../../assets/96986dda6c3b9ff2ea1692eefc5882b6.png" />
            <span class="text_10 text_12">支付成功</span>
          </div>
          <div class="pub_-flex-col space-y-22">
            <span class="pub_-self-start text_10 text_13">确认订单状态</span>
            <div class="pub_-flex-col">
              <div class="pub_-flex-row pub_-items-center section_4">
                <div class="pub_-flex-row pub_-items-center space-x-83">
                <span class="font_4 text_10">订单类型</span>

                <span class="font_4 text_10">订单号</span>

                <span class="font_4 text_10 ">实付金额</span>
                <span class="font_4 text_10 ">查看订单</span>
                </div>
              </div>
              <div class="pub_-flex-col pub_-justify-start from_box  section_5">
                <div class="pub_-flex-row group_8">


                  <div
                  class="
                    pub_-flex-row pub_-items-center pub_-shrink-0
                    space-x-14
                  "
                >
                  <img class="image" :src="payList.picturePath" />
                  <div class="pub_-flex-col pub_-justify-start group_1">
                    <div class="pub_-flex-col group_3">
                      <span class="text_12">{{ payList.name}}</span>
                      <div
                        class="
                          pub_-flex-row pub_-items-center
                          group_6
                          space-x-9
                        "
                      >
                        <img class="image_2" :src="payList.teacherData.avatarPath" />
                        <span class="font_4 text_16">{{
                          payList.teacherData.nickname
                        }}</span>
                      </div>


                    </div>
                  </div>
                </div>


                  <span class="font_3 text_10 text_17 text_13">{{payList.orderNo}}</span>
                  <span class="font_3 text_10 text_18 text_13">￥{{payList.payMoney}}</span>
                  <span class="font_3 text_10 text_19 text_13" @click="goHref($event)" :data-url="'/orderDetails?id='+ payList.id">{{payList.pathButton}} 查看</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>


  </div>
</template>

<script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";
  export default {
    components: { NavBox, FootBox },
    data() {
      return {
        payList: [
          {
            img: 'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715392480602417191.png',
            title: '雅思口语流利说—哑巴英语逆袭native speaker',
            teacherImg:
              'https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a13036717ee3001132236b/16715392480747115661.png',
            teacherName: 'Ugo Marsilii',
            time: '',
            orderId: '1886065747904178',
            sum: '￥2489',
            pathButton: '查看',
          },
        ],
      };
    },
    created() {
    this.getParam('type');
    this.ajaxH(null, 0);
  },

    methods: {
      async ajaxH(id, i) {
      this.getInfo(); //获取订单详情
    },
 async getInfo(id, i) {


      let param = {
        id: this.getParam('id')
      };

      // console.log(param)
      const { data: res } = await this.$http.post(
        "/api.order/getInfo",
        param
      );

      console.log(res);

      this.payList= res.data;




    },
      getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },

    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
    },
  };
</script>

<style scoped lang="scss">
  .page {
    background-color: #fbfbfb;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding: 0.17rem 3.7rem;
    background-color: #ffffff;
  }
  .text-wrapper {
    padding: 0.24rem 0;
    background-color: #000000;
    width: 1.34rem;
    height: 0.64rem;
  }
  .font_1 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.14rem;
  }
  .text {
    color: #ffffff;
    line-height: 0.15rem;
  }
  .text_2 {
    color: #2b2c30;
    line-height: 0.15rem;
  }
  .group {
    padding-bottom: 1.68rem;
  padding-top: .4rem;
  }
  .space-y-40 > *:not(:first-child) {
    margin-top: 0.4rem;
  }
  .section_2 {
    padding: 0.24rem 0 0.24rem 0.19rem;
    background-color: #f4f7fe;
  }
  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }
  .font_2 {
    font-size: 0.18rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    color: #2b2c30;
  }
  .text_4 {
    margin-left: 0.35rem;
  }
  .text_3 {
    margin-left: 0.42rem;
  }
  .text_5 {
    margin-left: 0.44rem;
  }
  .text_6 {
    margin-left: 0.35rem;
  }
  .text_7 {
    margin-left: 0.34rem;
  }
  .text_8 {
    margin-left: 0.43rem;
  }
  .space-x-43 > *:not(:first-child) {
    margin-left: 0.43rem;
  }
  .text_9 {
    line-height: 0.18rem;
  }

  .space-y-20 > *:not(:first-child) {
    margin-top: 0.2rem;
  }
  .font_3 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.17rem;
    color: #2b2c30;
  }
  .section_3 {
    padding: 1.2rem 0.4rem 1.56rem;
    background-color: #ffffff;
  }
  .image {
    width: 0.99rem;
    height: 0.98rem;
  }
  .space-y-22 > *:not(:first-child) {
    margin-top: 0.22rem;
  }

  .section_4 {
  background-color: #edeff2;
  height: 0.54rem;
  align-items: center;
}
.space-x-83 {
  width: 100%;
  height: 100%;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      width: 4.46rem;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: calc((100% - 4.46rem) / 3);
    }
  }
}
  .text-wrapper_2 {
    padding: 0.2rem 0;
    background-color: #edeff2;
    width: 4.46rem;
    height: 0.54rem;
  }
  .font_4 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.14rem;
    color: #2b2c30;
  }
  .text_10 {
    text-transform: uppercase;
  }
  .text_13 {
    color: #2b2c30;
    font-size: 0.16rem;
    font-family: OPPOSans;
    line-height: 0.16rem;
  }
  .text_12 {
    color: #2b2c30;
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.28rem;
    letter-spacing: 0.011rem;
  }
  .text_11 {
    line-height: 0.18rem;
  }
  .text_14 {
    margin-left: 1.74rem;
  }
  .text_15 {
    margin-left: 1.6rem;
    margin-right: 0.77rem;
  }
  .section_5 {
    padding: 0.17rem 0 0.21rem;
    background-color: #ffffff;
    border: solid 0.01rem #edeff2;
  }
  .group_2 {
    margin-left: 0.18rem;
  }
  .group_4 {
    width: 1.84rem;
  }
  .image_2 {
    border-radius: 0.08rem;
    width: 1.84rem;
    height: 1.03rem;
  }
  .group_3 {
    margin-left: 0.14rem;
    width: 2.02rem;
  }
  .text_16 {
    color: #2b2c30;
    font-size: 0.16rem;
    font-family: Adobe Heiti Std;
    line-height: 0.24rem;
  }
  .group_7 {
    margin-top: 0.09rem;
  }
  .space-x-9 > *:not(:first-child) {
    margin-left: 0.09rem;
  }
  .image_3 {
    width: 0.34rem;
    height: 0.34rem;
    border-radius: 100%;
  }
  .text_20 {
    line-height: 0.17rem;
  }
  .text_21 {
    margin-top: 0.19rem;
    color: #a1a9b2;
    font-weight: 300;
    line-height: 0.13rem;
  }
  .text_17 {
    line-height: 0.16rem;
  }
  .text_18 {
    color: #e4393c;
    line-height: 0.15rem;
  }
  .text_19 {
    color: #2080f7;
  }

  .from_box{
    section_5 {
  padding: 0.17rem 0 0.41rem;
  background-color: #ffffff;
  border: solid 0.01rem #edeff2;
}
.space-x-14 {
  width: 4.46rem;
  padding-left: 0.2rem;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}
.image {
  border-radius: 0.08rem;
  width: 1.84rem;
  height: 1.03rem;
}
.group_1 {
  width: 2.02rem;
  height: 0.91rem;
}
.group_3 {
  width: 2.02rem;
}
.text_12 {
  color: #2b2c30;
  font-size: 0.16rem;
  font-family: Adobe Heiti Std;
  line-height: 0.24rem;
}

.text_13 {
  width: calc((100% - 4.46rem) / 3);
  padding: 0 0.1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_2 {
    width: 0.34rem;
    height: 0.34rem;
    border-radius: 100%;
}
.group_6{
    margin-top: 0.09rem;
}
  }
</style>
