<template>
  <div class="page">
    <div id="root"></div>
  </div>
</template>


<script src="https://download.agora.io/edu-apaas/release/edu_sdk@2.8.20.bundle.js"></script>
<script>

export default {
  components: {},
  data() {
    return {
      id: null,

    };
  },
  mounted() {
    console.log('956')
    this.id = this.getParam("id");
    this.getInfo(this.id);
  },
  methods: {
    async getInfo(id) {
      let param = {
        id: id,
      };
      const {data: result} = await this.$http.post("/api.shengwang/getRtmToken", param);
      if (result.code === 1) {

        this.$message({
          message: result.msg,
          type: "warning",
        });

        setTimeout(function () {
          window.close();
        }, 1000);

        return;

      }
      // 配置 SDK。
      AgoraEduSDK.config(result.data.config);
      // 启动在线课堂。
      AgoraEduSDK.launch(
        document.querySelector('#root'),
        {
          closeDelay: result.data.launch.closeDelay,
          courseWareList: result.data.launch.courseWareList,
          duration: result.data.launch.duration,
          language: result.data.launch.language,
          pretest: result.data.launch.pretest,
          recordUrl: result.data.launch.recordUrl,
          roleType: result.data.launch.roleType,
          roomName: result.data.launch.roomName,
          roomType: result.data.launch.roomType,
          roomUuid: result.data.launch.roomUuid,
          rtmToken: result.data.launch.rtmToken,
          uiMode: result.data.launch.uiMode,
          userName: result.data.launch.userName,
          userUuid: result.data.launch.userUuid,
          virtualBackgroundImages: result.data.launch.virtualBackgroundImages,
          webrtcExtensionBaseUrl: result.data.launch.webrtcExtensionBaseUrl,
          listener: (evt, args) => {

            if (evt == 201) {

            }
          },
        }
      );

    },


    getParam(name) {
      var url = window.location.href;
      var urlOne = url.split("#")[1];
      var urlParam = urlOne.split("?")[1];
      var paramArr = urlParam.split("&");
      var param = {};
      for (var i = 0; i < paramArr.length; i++) {
        param[paramArr[i].split("=")[0]] = paramArr[i].split("=")[1];
      }
      if (param[name]) {
        return param[name];
      } else {
        return null;
      }
    },



  },
};
</script>
<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;

  #root {
    width: 100%;
    height: 100%;
  }
}

</style>
