<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col">
      <div class="pub_-flex-row pub_-self-center pub_case space-x-40">
        <div class="nav_left">
          <RouterLink v-for="(item,i ) in column" :to="'/news?id='+ item.id" :key="i" :class="{ on: active == item.id }">
            <span>{{item.name}}</span>
          </RouterLink>

        </div>

        <div class="pub_-flex-col pub_-flex-auto section_5">
          <span class="pub_-self-center text_11 text_12"
            >{{ config.title }}</span
          >
          <div class="pub_-flex-row pub_-justify-center group_2 space-x-13">
            <span class="font_1 text_11">发布时间：{{ config.createTime }}</span>
            <!-- <span class="font_1 text_11">消息来源：XXX集团发布</span> -->
          </div>
          <div class="divider"></div>

          <div class="news_d_con int_info" v-html="config.content"></div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";

export default {
  components: { NavBox, FootBox },
  data() {
    return {
      active: null,
      column:[],
      id:null,
      config:{},
    };
  },
  mounted() {
    this.getParams();
  },
  methods: {
    getParams() {
      // 取到路由带过来的参数
      const routerParams = this.$route.query.active;
      this.active = routerParams;
      const id = this.$route.query.id;
      this.id = id;

      this.ajaxH(routerParams)
    },
    async ajaxH() {
      this.getColumnList(); //获取栏目列表接口
      this.getInfo(); //获取新闻详情
    },
    async getColumnList() {
      let param = {};
      const { data: res } = await this.$http.post(
        "/api.news/getColumnList",
        param
      );
      this.column = res.data;
      if (this.active == null || this.active ==  undefined)  {
        this.active = this.column[0].id;
      }
    },
    async getInfo() {
      let param = {
        id:this.id
      };
      const { data: res } = await this.$http.post(
        "/api.news/getInfo",
        param
      );

      console.log(res)
      this.config = res.data;

      // this.pagination.pageSize = 9;

    },

  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.text {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
  font-weight: 300;
  color: #2b2c30;
}
.text_2 {
  line-height: 0.15rem;
  font-weight: unset;
}
.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text_8 {
  margin-left: 0.43rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_9 {
  line-height: 0.18rem;
}
.group {
  padding-top: 0.4rem;
  width: 11.8rem;
}
.space-x-40 {
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
}
.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}
.section_3 {
  background-color: #ffffff;
}
.space-x-16 > *:not(:first-child) {
  margin-left: 0.16rem;
}
.section_4 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}
.font_3 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}
.text_10 {
  margin-right: 1.25rem;
  color: #2080f7;
  line-height: 0.13rem;
}
.text-wrapper_2 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.text_13 {
  margin-left: 0.17rem;
  line-height: 0.13rem;
}
.text-wrapper_3 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.text_14 {
  margin-left: 0.16rem;
}
.section_5 {
  padding: 0.39rem 0.32rem 1.27rem 0.4rem;
  background-color: #ffffff;
  width: 9.42rem;
  min-height: 8.74rem;
}
.group_2 {
  margin-top: 0.18rem;
}
.space-x-13 > *:not(:first-child) {
  margin-left: 0.13rem;
}
.divider {
  margin-right: 0.08rem;
  margin-top: 0.18rem;
  background-color: #f3f6fa;
  height: 0.02rem;
}
.font_4 {
  font-size: 0.14rem;
  font-family: Adobe Heiti Std;
  line-height: 0.24rem;
  color: #2b2c30;
}
.text_11 {
  text-transform: uppercase;
}
.text_12 {
  color: #2b2c30;
  font-size: 0.22rem;
  font-family: Adobe Heiti Std;
  line-height: 0.22rem;
}
.text_15 {
  margin-top: 0.33rem;
}
.group_3 {
  margin-top: 0.35rem;
}
.space-y-33 > *:not(:first-child) {
  margin-top: 0.33rem;
}
.image {
  width: 3.31rem;
  height: 2.64rem;
}
.text_16 {
  margin-top: 0.28rem;
}
.news_d_con{
  padding-top: .35rem;
  font-size: .14rem;
  img{
    display: block !important;
    margin:.1rem auto;
  }

}
@media screen and (orientation: portrait){
  .space-x-40{
    flex-wrap: wrap;
  }
  .space-x-40 > *[data-v-b03de2ea]:not(:first-child){
    margin-left: 0;
  }
  .section_5{
    width: 100%;
    margin-top: .4rem;
    padding-bottom: .4rem;
  }
  .text_12{
    font-size: .28rem;
    line-height: 1.35;
  }
  .font_1{
    font-size: .2rem;
    line-height: 1;
  }
}
</style>
