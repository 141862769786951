<template>
  <div class="pub_-flex-col page">

    <nav-box></nav-box>


    <div class="pub_-flex-col pub_-justify-start pub_-self-center group">
      <div class="pub_-flex-row group_2 space-x-40 pub_case">
        <left-nav></left-nav>

        <div class="pub_-flex-col pub_-flex-auto section_3 int_info" v-html="content">


        </div>


      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";

export default {
  components: {NavBox, FootBox, LeftNav},
  data() {
    return {
      content: '',
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  methods: {

    async ajaxH(id, i) {
      this.getInfo(); //获取站内信分页列表
    },
    async getInfo(type) {


      let param = {};

      // console.log(param)
      const {data: res} = await this.$http.post(
        "/api.setUp/getHelpDocument",
        param
      );

      console.log(res);

      this.content = res.data;

    },

  },
};
</script>

<style scoped lang="css">
.page {

  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}


.space-y-17 > *:not(:first-child) {
  margin-top: 0.17rem;
}


.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}


.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}

.group {
  width: 11.8rem;
}

.group_2 {
  padding: 0.4rem 0;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}

.text-wrapper_2 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}


.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}


.section_3 {
  padding: 0.38rem 0.35rem 0.38rem 0.39rem;
  background-color: #ffffff;
  width: 9.42rem;
  min-height: 65vh;
}


.space-y-7 > *:not(:first-child) {
  margin-top: 0.07rem;
}


.space-y-4 > *:not(:first-child) {
  margin-top: 0.04rem;
}

@media screen and (orientation: portrait) {
  .group {
    width: 100%;
  }

  .group_2 {
    padding: 0.4rem .45rem;
    flex-wrap: wrap;
  }
  .space-x-40 > *[data-v-5b1d0e26]:not(:first-child){
    margin-left: 0;
  }
  .section_3{
    padding: .3rem;
    margin-top: .4rem;
  }
}

</style>
