<template>
  <div class="pub_-flex-col pub_-justify-start page">
    <div class="pub_-flex-col pub_-justify-start section">
      <div class="pub_-flex-col pub_-justify-start pub_-items-start section_2">
        <div class="pub_-flex-col pub_-justify-start pub_-items-end section_3">
          <div class="pub_-flex-col section_4">
            <div class="pub_-flex-row pub_-items-center group space-x-102">
              <img
                class="image"
                src="../../assets/e74fac53b5573380c3c560bf98da05ce.png"
                @click="go_href()"
              />
              <span class="font_1 text text_2">微信扫码</span>
            </div>
            <div class="pub_-flex-col pub_-items-center group_2 space-y-19">
              <img
                class="image_2"
                src="../../assets/8adf1f2c5d62e8cf11e35e73623738f6.png"
              />
              <span class="font_1 text">请使用微信扫一扫二维码登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    go_href() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_2 {
  background-image: url("../../assets/log001.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.section_4 {
  margin: 0 auto;
  border-radius: 0.1rem;
  background-color: #fff;
  width: 3.46rem;
}
.group {
  padding: 0.2rem 0.27rem;
  border-bottom: solid 0.01rem #f0f2f4;
}
.space-x-102 > *:not(:first-child) {
  margin-left: 1.02rem;
}
.image {
  width: 0.09rem;
  height: 0.15rem;
  cursor: pointer;
  transition: transform 0.5s ease;
  @media screen and (orientation: landscape) {
    &:hover {
      transform: scale(1.2);
    }
  }
}
.font_1 {
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.18rem;
  color: #2b2c30;
}
.text {
  text-transform: uppercase;
}
.text_2 {
  margin-right: 1.09rem;
}
.group_2 {
  padding: 0.4rem 0 0.64rem;
}
.space-y-19 > *:not(:first-child) {
  margin-top: 0.19rem;
}
.image_2 {
  border-radius: 0.08rem;
  width: 2rem;
  height: 2rem;
}
</style>