<template>
  <div class="page home">
    <nav-box></nav-box>

    <div class="pub_-flex-col pub_-justify-start pub_-flex-auto group_53">
      <div class="pub_-flex-col pub_-justify-start">
        <div class="pub_-flex-col group_2">
          <div
            class="pub_-flex-col pub_-justify-start pub_-self-center group_1 pub_case"
          >
            <div class="pub_-flex-row pub_-items-center">
              <div
                class="pub_-flex-col pub_-justify-start pub_-items-center pub_-flex-auto image-wrapper"
              >
                <div class="lb_box">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, i) in config.homeCarouselData"
                        :key="i"
                      >
                        <img class="image" :src="item"/>
                      </div>
                    </div>

                    <div class="swiper-pagination"></div>
                  </div>
                </div>
              </div>
              <!--              <div-->
              <!--                class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 section_2"-->
              <!--              >-->
              <!--                <div class="pub_-flex-col section_3">-->
              <!--                  <div class="pub_-flex-row space-x-10">-->
              <!--                    <img-->
              <!--                      class="image"-->
              <!--                      src="../../assets/72e7e6a886c7e28089e78102d5b2eafe.png"-->
              <!--                    />-->
              <!--                    <span class="font_5 text_4">热销课程</span>-->
              <!--                  </div>-->
              <!--                  <div class="pub_-flex-col group_56 space-y-18">-->
              <!--                    <div-->
              <!--                      class="pub_-flex-row pub_-items-center group_4 space-x-14 cur_btn"-->
              <!--                      :key="i"-->
              <!--                      v-for="(item, i) in noticeList"-->
              <!--                      @click="goHref"-->
              <!--                      :data-url="-->
              <!--                        '/courseDetails?type=' + item.type + '&&id=' + item.id-->
              <!--                      "-->
              <!--                    >-->
              <!--                      <span class="pub_-shrink-0 font_7 text_6"-->
              <!--                        >0{{ i + 1 }}</span-->
              <!--                      >-->
              <!--                      <span class="font_6 text_5">{{ item.title }}</span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="btn_sty" @click="goHref" data-url="/courseList">-->
              <!--                    <span>查看更多</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--           -->


            </div>
          </div>

          <div
            class="pub_-flex-col pub_-self-center group_11 space-y-48 pub_case"
          >
            <div class="pub_-flex-col pub_-self-center space-y-18">
              <div class="pub_title_box">
                <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"/>
                <span>{{ hdc.headLine }}</span>
                <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"/>
              </div>
              <div class="pub_info" v-html="hdc.brief"></div>
            </div>
            <div class="pub_-flex-row space-x-26">
              <a :href="hd1.url" class="pub_-flex-col pub_-justify-start pc_sty row02_left">
                <img :src="hd1.picture" alt=""/>

                <div class="pub_-flex-col section_5 space-y-11">
                  <span class="font_14">{{ hd1.title }}</span>
                  <div class="group_13">
                    <span class="font_13" v-html="hd1.profile"></span>
                  </div>
                </div>
              </a>

              <div
                class="pub_-flex-col pub_-justify-start pub_-flex-auto group_57 swiper-container"
              >
                <div class="pub_-flex-row pub_-relative group_59 swiper-wrapper">
                  <a :href="hd1.url" class="pub_-flex-col m_sty pub_-justify-start swiper-slide row02_left">
                    <img :src="hd1.picture" alt=""/>

                    <div class="pub_-flex-col section_5 space-y-11">
                      <span class="font_14">{{ hd1.title }}</span>
                      <div class="group_13">
                        <span class="font_13" v-html="hd1.profile"></span>
                      </div>
                    </div>
                  </a>

                  <a
                    :href="item.url"
                    class="pub_-flex-col pub_-justify-start swiper-slide"
                    v-for="(item, i) in hd2"
                    :key="i"
                  >
                    <img :src="item.picture" alt=""/>

                    <div
                      class="pub_-flex-col pub_-items-start section_12 space-y-12"
                    >
                      <span class="font_14">{{ item.title }}</span>
                      <div class="group_13">
                        <span class="font_13" v-html="item.profile"></span>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- 如果需要导航按钮 -->

              </div>

              <div class="swiper-button-prev m_sty"></div>
              <div class="swiper-button-next m_sty"></div>



            </div>
          </div>
          <div class="pub_-flex-col section_13 space-y-57">
            <div class="pub_case">
              <div class="pub_-flex-col pub_-self-center group_18 space-y-18">
                <div class="pub_title_box">
                  <img
                    src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"
                  />
                  <span>热门课程推荐</span>
                  <img
                    src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"
                  />
                </div>
                <span class="pub_info"
                >言值与颜值齐飞，喜欢哪个选哪个，让你耳濡目染练就纯正发音和地道表达</span
                >
              </div>
              <div class="pub_-flex-col ">
                <div class="pro_lb_box">
                  <div class="course_box swiper-container">
                    <div class="pub_-flex-row space-x-24 swiper-wrapper">
                      <div
                        class="pub_-flex-col section_cou swiper-slide"
                        :key="i"
                        v-for="(item, i) in courseList"
                      >
                        <img
                          class="pub_-self-center image_03"
                          :src="item.firstPicturePath"
                        />
                        <span class="pub_-self-center font_06 text_020">{{
                            item.title
                          }}</span>
                        <div class="pub_-flex-row group_09 view_02">
                          <div
                            class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 text-wrapper_05"
                          >
                            <span class="font_07">热门榜</span>
                          </div>
                          <div
                            class="pub_-flex-col pub_-justify-start pub_-items-start pub_-flex-auto text-wrapper_06"
                          >
                          <span class="font_08 text_021">
                            {{
                              item.rankSort !== 0
                                ? "英语热销课程第" + item.rankSort + "名"
                                : "未上榜"
                            }}
                          </span>
                          </div>
                        </div>
                        <div class="pub_-flex-row group_09 view_03 space-x-08">
                          <div
                            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_07"
                            v-if="item.isSecurity === 1"
                          >
                            <span class="font_09">放心选</span>
                          </div>

                          <div
                            class="pub_-flex-col pub_-justify-start text-wrapper_08"
                          >
                          <span class="font_010 text_022"
                          >好评{{ item.evaluatePercentage }}%</span
                          >
                          </div>
                        </div>
                        <div class="pub_-flex-row pub_-items-baseline group_010">
                          <span class="font_012 text_024">￥</span>
                          <span class="font_011 text_023">{{ item.price }}</span>
                          <div class="group_011">
                            <span class="font_013">{{ item.saleNumber }}</span>
                            <span class="font_03">人报名</span>
                          </div>
                        </div>
                        <span class="pub_-self-center font_014 text_025"
                        >
                        <template v-if="item.startTime != '' && item.startTime != undefined && item.startTime != null">
                          开播时间：{{ item.startTime }}
                        </template>
                       </span
                       >
                        <div
                          class="pub_-flex-row pub_-justify-between pub_-items-center group_012"
                        >
                          <div class="pub_-flex-row pub_-items-center space-x-09">
                            <img
                              class="pub_-shrink-0 image_04"
                              :src="item.teacherData.avatarPath"
                            />
                            <span class="font_016">{{
                                item.teacherData.nickname
                              }}</span>
                          </div>
                          <div
                            class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_09 btn_sty btn_sty_line_r"
                            @click="goHref($event)"
                            :data-url="
                            '/courseDetails?id=' +
                            item.id +
                            '&&type=' +
                            item.type
                          "
                          >
                            <span class="font_015 text_026">点击购买</span>
                          </div>
                        </div>

                        <div
                          class="m_sty btn_link"
                          @click="goHref($event)"
                          :data-url="
                            '/courseDetails?id=' +
                            item.id +
                            '&&type=' +
                            item.type
                          "
                        >


                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="swiper-button-prev m_sty"></div>
                  <div class="swiper-button-next m_sty"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col section_18 space-y-102">
            <div class="pub_case">
              <div class="pub_-flex-col group_28 space-y-19">
                <div class="pub_title_box">
                  <img
                    src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"
                  />
                  <span>{{ hB.headLine }}</span>
                  <img
                    src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"
                  />
                </div>
                <span class="pub_info">
                  {{ hB.brief }}
                </span>
              </div>
              <div class="zn_box">
                <div
                  class="pub_-flex-col pub_-justify-start pub_-self-end pub_-relative section_19"
                >
                  <img :src="hB.picture"/>
                </div>

                <div class="pub_-flex-col group_29">
                  <span class="pub_-self-start font_12 text_24">{{
                      hB.title
                    }}</span>
                  <span class="font_2 text_25">{{ hB.profile }}</span>
                  <span
                    class="pub_-self-start font_2 text_26"
                    @click="goHref($event)"
                    data-url="/courseSystem"
                  >了解更多</span
                  >

                  <!-- <div
                class="
                  pub_-flex-col
                  pub_-justify-start
                  pub_-items-center
                  pub_-self-start
                  text-wrapper_10
                  btn_sty
                "
              >
                <span>点击使用</span>
              </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="pub_-flex-col pub_-relative group_17 space-y-49">
            <div class="pub_-flex-col pub_-justify-start section_24">
              <div class="pub_-flex-col section_25">
                <div class="pub_title_box col_w">
                  <img
                    src="../../assets/792df80f69a604b0ea316e056a6f8c5f.png"
                  />
                  <span>{{ hC.headLine }}</span>
                  <img
                    src="../../assets/93ac04b8f070f4d7e15a1ce06b5e35cd.png"
                  />
                </div>
                <span class="pub_info">{{ hC.brief }}</span>
                <div class="pub_-flex-row group_30">
                  <div class="pub_-flex-col pub_-shrink-0 group_31 space-y-54">
                    <span class="text_29">{{ hC.subjectProfile }}</span>
                    <div class="pub_-flex-col space-y-13">
                      <div class="pub_-flex-row space-x-61">
                        <img
                          class="image_13"
                          src="../../assets/68a20e29dfa0fe8f4a4e7f1e6ed50f16.png"
                        />
                        <img
                          class="image_13"
                          src="../../assets/253e9b5d0971f32ac1f6cdd1a346b821.png"
                        />
                      </div>
                      <div class="pub_-flex-col group_34">
                        <div class="pub_-flex-row space-x-3">
                          <span class="font_25">了解公司</span>
                          <img
                            class="image_14"
                            src="../../assets/bbab37c644bffd43d098675ec21764fa.png"
                          />
                        </div>
                        <div
                          class="pub_-flex-row pub_-relative group_35 space-x-3"
                        >
                          <span class="font_25 text_32">公司荣耀</span>
                          <img
                            class="image_14"
                            src="../../assets/bbab37c644bffd43d098675ec21764fa.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                 <div class="about_lb_box group_32">
                <div class="pub_-flex-row pub_-flex-auto
                 space-x-26 swiper-container">
                  <a
                    :href="hC1.url"
                    class="pub_-flex-col pub_-items-center section_26 space-y-12 pc_sty"
                    :style="'background-image:url(' + hC1.picture + ')'"
                  >
                    <span class="font_12 text_30">{{ hC1.title }}</span>
                    <span class="font_2 text_31">{{ hC1.profile }}</span>
                  </a>
                  <div class="pub_-flex-col group_33 space-y-26 swiper-wrapper">
                    <a
                      :href="hC1.url"
                      class="pub_-flex-col pub_-items-center section_26 space-y-12 m_sty swiper-slide"
                      :style="'background-image:url(' + hC1.picture + ')'"
                    >
                      <span class="font_12 text_30">{{ hC1.title }}</span>
                      <span class="font_2 text_31">{{ hC1.profile }}</span>
                    </a>

                    <a
                      :href="hC2.url"
                      class="pub_-flex-col pub_-justify-start section_27 swiper-slide"
                      :style="'background-image:url(' + hC2.picture + ')'"
                    >
                      <div
                        class="pub_-flex-col pub_-items-start section_28 space-y-8"
                      >
                        <span class="font_23">{{ hC2.title }}</span>
                        <span class="font_24">{{ hC2.profile }}</span>
                      </div>
                    </a>

                    <a
                      :href="hC3.url"
                      class="pub_-flex-col pub_-justify-start section_29 swiper-slide"
                      :style="'background-image:url(' + hC3.picture + ')'"
                    >
                      <div
                        class="pub_-flex-col pub_-items-start section_28 space-y-8"
                      >
                        <span class="font_23">{{ hC3.title }}</span>
                        <span class="font_24">{{ hC3.profile }}</span>
                      </div>
                    </a>
                  </div>
                </div>
                   <div class="swiper-button-prev m_sty"></div>
                   <div class="swiper-button-next m_sty"></div>

              </div>
                              </div>
              </div>
            </div>
          </div>
          <div
            class="pub_-flex-col pub_-self-center group_58 group_36 space-y-67"
          >
            <div class="pub_-flex-col pub_-self-center group_37 space-y-15">
              <div class="pub_title_box">
                <img src="../../assets/e8da93c5bb7898fc867730e0cbf07efc.png"/>
                <span>{{ hD.headLine }}</span>
                <img src="../../assets/5c5f6e2efca7caef7e2d8d71f15c57a3.png"/>
              </div>
              <span class="pub_info">{{ hD.brief }}</span>
            </div>
            <div class="pub_-flex-col pub_-justify-start">
              <div
                class="pub_-flex-col pub_-justify-start pub_-relative group_58"
              >
                <div class="pub_-flex-row space-x-44">
                  <div
                    class="pub_-flex-col section_30"
                    :key="i"
                    v-for="(item, i) in hD.list"

                    @click="goTab($event)"
                    :data-url="item.url"
                  >
                    <div
                      class="pub_-flex-col pub_-justify-start pub_-self-start text-wrapper_11"
                    >
                      <span class="font_26 text_35">{{ item.title }}</span>
                    </div>
                    <div class="pub_-flex-col group_39 space-y-39">
                      <div class="section_32">
                        <img :src="item.picture" alt=""/>
                      </div>
                      <span class="font_27 text_37">{{ item.profile }}</span>
                    </div>
                  </div>
                </div>
                <img
                  class="pos_1 pos_24"
                  src="../../assets/9611aca4661d60e578aadc5ffe05c399.png"
                />
              </div>
            </div>
          </div>
          <div class="pub_-flex-col section_34 space-y-29">
            <div class="pub_case">
              <div class="pub_-flex-col pub_-self-center group_41">
                <div class="pub_-flex-row group_42">
                  <span
                    class="font_9"
                    :class="{ text_40: tabOn == i }"
                    @click="tabQ(i)"
                    :key="i"
                    v-for="(tab, i) in tabList"
                  >{{ tab.tab }}</span
                  >
                </div>
              </div>
              <div class="pub_-flex-col pub_-justify-start">
                <div
                  v-if="tabOn === 0"
                  class="pub_-flex-col pub_-justify-start pub_-items-start list_3"
                >
                  <div class="pub_info">
                    言值与颜值齐飞，喜欢哪个选哪个，让你耳濡目染练就纯正发音和地道表达
                    <!-- <span class="font_10 text_42">了解更多&gt;</span> -->
                  </div>

                  <div class="teacher_lb_box">
                    <div class="swiper-container">
                      <div class="tex_box swiper-wrapper">
                        <div
                          class="pub_-flex-col pub_-justify-start pub_-items-center section_36 swiper-slide"
                          :key="i"
                          v-for="(tea, i) in teacherList"
                        >
                          <div class="pub_-flex-col section_37">
                            <div
                              class="pub_-flex-row pub_-items-center group_62 space-x-12"
                            >
                              <img class="image_6" :src="tea.avatarPath"/>
                              <div class="pub_-flex-col">
                                <div
                                  class="pub_-flex-row pub_-items-center space-x-14"
                                >
                              <span class="font_16">{{
                                  tea.teacherEnglishName
                                }}</span>


                                  <div v-if="tea.teacherStatus == 3"
                                       class="pub_-flex-col pub_-justify-start pub_-items-center pub_-shrink-0 pub_-relative group_20"
                                  >
                                    <div
                                      class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_12"
                                    >

                                      <span class="font_8">已认证</span>
                                    </div>

                                    <img
                                      class="image_7 pos_12"
                                      src="../../assets/19582b3202abe04fad927487e1694713.png"
                                    />
                                  </div>


                                </div>
                                <div class="pub_-flex-row group_63">

                                  <div
                                    class="pub_-flex-col pub_-items-start group_64 group_60 space-y-16"
                                  >
                                <span
                                  class="font_sty"
                                  :key="i"
                                  v-for="(teaI, i) in tea.teacherLabelData"
                                ><img
                                  class="image_8"
                                  src="../../assets/sty001.png"
                                />{{ teaI.name }}</span
                                >
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div class="pub_-flex-col section_16 view_4 space-y-23">
                              <span class="font_17">{{ tea.teacherProfile }}</span>

                              <div
                                class="pub_-self-end image_9"
                                :data-url="tea.teacherProfileAudioPath"
                                :class="currentAudioIndex == i ? 'on' : ''"
                              >
                                <audio
                                  ref="audio"
                                  :src="tea.teacherProfileAudioPath"
                                  :key="i"
                                  style="position: absolute; opacity: 0"
                                ></audio>
                                <img
                                  @click="playAudio(i)"
                                  src="../../assets/481eea94757ff2d52732c0e03b0b84aa.png"
                                />
                              </div>
                            </div>
                            <div class="pub_-flex-row group_24 space-x-52">
                              <div class="pub_-flex-col pub_-relative group_25">
                                <div
                                  class="pub_-flex-row pub_-items-baseline space-x-4"
                                >
                              <span class="font_18">{{
                                  tea.teacherTeachingNumber
                                }}</span>
                                  <span class="font_20">人</span>
                                </div>
                                <span class="pub_-self-start font_21 text_44"
                                >服务学生</span
                                >
                                <div class="section_17 pos_15"></div>
                              </div>
                              <div class="pub_-flex-col space-y-14">
                                <div
                                  class="pub_-flex-row pub_-items-baseline group_27 space-x-7"
                                >
                              <span class="font_19">{{
                                  tea.teacherSatisfaction
                                }}</span>
                                  <span class="font_20 text_43">%</span>
                                </div>
                                <span class="font_21">学生满意度</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-button-prev m_sty"></div>
                    <div class="swiper-button-next m_sty"></div>
                  </div>




                </div>

                <div
                  v-else-if="tabOn == 1"
                  class="pub_-flex-col pub_-justify-start pub_-items-start list_3"
                >
                  <div class="pub_info">
                    言值与颜值齐飞，喜欢哪个选哪个，让你耳濡目染练就纯正发音和地道表达
                    <span
                      class="font_10 text_42"
                      @click="goHref($event)"
                      :data-url="'/news'"
                    >了解更多&gt;</span
                    >
                  </div>
                  <div class="news_box">
                    <div
                      class="pub_-flex-row pub_-items-center list-item space-x-21"
                      @click="goHref($event, item.id)"
                      data-url="/newsDetails"
                      :key="i"
                      v-for="(item, i) in newsList"
                    >
                      <img
                        class="pub_-shrink-0 image"
                        :src="item.picturePath"
                      />
                      <div class="pub_-flex-col pub_-flex-auto group_3 view">
                        <span class="pub_-self-start font_4 text_11">{{
                            item.title
                          }}</span>
                        <span class="font_5 text_11 text_14">{{
                            item.profile
                          }}</span>
                        <span class="pub_-self-start font_6 text_11 text_16">{{
                            item.createTime
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="pub_-flex-col pub_-justify-start pub_-items-start list_3"
                >
                  <div class="pub_info">
                    言值与颜值齐飞，喜欢哪个选哪个，让你耳濡目染练就纯正发音和地道表达
                    <!-- <span class="font_10 text_42">了解更多&gt;</span> -->
                  </div>

                  <div class="join_box">
                    <div class="map_con">
                      <baidu-map
                        class="map"
                        :center="center"
                        :zoom="zoom"
                        @ready="handler"
                      >
                        <bm-marker
                          :position="center"
                          :dragging="true"
                          :icon="{
                            url: require('@/assets/icon005.png'),
                            size: { width: 48, height: 48 },
                          }"
                          @click="infoWindowOpen"
                        >
                          <bm-info-window
                            :show="show"
                            @close="infoWindowClose"
                            @open="infoWindowOpen"
                          >
                            <div class="com_name">{{ config.companyName }}</div>
                            <div class="com_add">
                              {{ config.companyAddress }}
                            </div>
                          </bm-info-window>
                        </bm-marker>
                      </baidu-map>
                    </div>
                    <div class="join_case">
                      <div class="join_t">{{ config.companyName }}</div>
                      <div class="join_con">
                        <div class="join_name">
                          <div>
                            <img src="../../assets/icon001.png" alt=""/>
                            <span class="join_n">地&#12288;&#12288;址：</span>
                            <span class="join_c">{{
                                config.companyAddress
                              }}</span>
                          </div>
                          <div>
                            <img src="../../assets/icon004.png" alt=""/>
                            <span class="join_n">咨询热线：</span>
                            <span class="join_c">{{
                                config.consultPhone
                              }}</span>
                          </div>
                          <div>
                            <img src="../../assets/icon002.png" alt=""/>
                            <span class="join_n">公司热线：</span>
                            <span class="join_c">{{
                                config.companyPhone
                              }}</span>
                          </div>
                          <div>
                            <img src="../../assets/icon003.png" alt=""/>
                            <span class="join_n">公司邮箱：</span>
                            <span class="join_c">{{
                                config.companyEmail
                              }}</span>
                          </div>
                        </div>
                        <div class="join_cade">
                          <div>
                            <img :src="config.weChatServiceQRCodePath" alt=""/>
                            <div class="code_name">服务号</div>
                          </div>
                          <div>
                            <img :src="config.weChatRecruitQRCodePath" alt=""/>
                            <div class="code_name">招聘号</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import FootBox from "../../components/footbox/footbox.vue";
import NavBox from "../../components/navbox/navbox.vue";

import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

export default {
  components: {NavBox, FootBox},
  data() {
    return {
      courseList: [],

      tabList: [
        {
          tab: "推荐教师",
        },

        {
          tab: "新闻资讯",
        },

        {
          tab: "加入我们",
        },
      ],
      tabOn: 0,

      noticeList: [],
      newsList: [],

      center: {lng: 0, lat: 0},
      zoom: 3,
      show: true,

      config: {},
      hdc: {},
      hd1: [],
      hd2: [],
      hB: {},
      hC: {},
      hC1: {},
      hC2: {},
      hC3: {},
      hD: [],
      profile: "",

      teacherList: [],
      currentAudioIndex: null,
    };
  },
  created() {
    this.ajaxH(null, 0);
  },
  mounted() {
    // this.ajaxH(null, 0);
    this.onLb();

    if (window.innerWidth < window.innerHeight){
      this.secondLb();
      this.proLb();
      this.aboutLb();
      this.teacherLb();
    }
  },
  methods: {
    async ajaxH(id, i) {
      this.active = i;

      this.getHotSaleList(); //获取热销课程列表接口
      this.getConfig(); //获取设置接口
      this.getNewList(); //获取新闻列表接口
      this.getRecommendList(); //获取推荐教师接口
    },
    async getHotSaleList(id, i) {
      this.active = i;

      let param = {
        number: 8,
      };
      const {data: res} = await this.$http.get(
        "/api.course/getHotSaleList"
      );
      // console.log(res);
      this.courseList = res.data;
      this.noticeList = this.courseList.slice(0, 7);
      // console.log( this.courseList )
    },
    async getConfig(id, i) {
      this.active = i;

      let param = {};
      const {data: res} = await this.$http.post("/api.setUp/getConfig", param);

      this.config = res.data;
      // console.log(this.config)

      this.hdc = res.data.homePlateA;
      // console.log(this.hd)
      const formattedString = res.data.homePlateA.brief.replace(
        /\d+/g,
        "<span>$&</span>"
      );
      this.hdc.brief = formattedString;
      const myObj = res.data.homePlateA.list;
      let hdc = Object.keys(myObj).map((key) => myObj[key]);
      this.hd1 = hdc[0];
      this.hd2 = hdc.splice(1);

      this.hB = res.data.homePlateB;

      this.hC = res.data.homePlateC;

      this.hD = res.data.homePlateD;
      // console.log( this.hD)

      this.hC1 = res.data.homePlateC.list[0];
      this.hC2 = res.data.homePlateC.list[1];
      this.hC3 = res.data.homePlateC.list[2];

      this.mapX = res.data.mapX;
      this.mapY = res.data.mapY;
    },
    async getNewList(id, i) {
      this.active = i;

      let param = {
        number: 5,
      };
      const {data: res} = await this.$http.post("/api.news/getList", param);
      this.newsList = res.data;
    },
    async getRecommendList(id, i) {
      let param = {
        number: 3,
      };
      const {data: res} = await this.$http.post(
        "/api.teacher/getRecommendList",
        param
      );
      this.teacherList = res.data;
      // console.log(this.teacherList);

    },
    playAudio(index) {
      if (this.currentAudioIndex !== null && this.currentAudioIndex !== index) {
        // 如果已经有音频在播放，则暂停它
        this.$refs.audio[this.currentAudioIndex].pause();
      }
      // 播放当前选中的音频
      const audioEl = this.$refs.audio[index];
      if (audioEl.paused) {
        audioEl.play();
        this.currentAudioIndex = index;
      } else {
        audioEl.pause();
        this.currentAudioIndex = null;
      }
      // this.currentAudioIndex = index;
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    tabQ(i) {
      this.tabOn = i;
    },
    onLb() {
      new Swiper(".lb_box .swiper-container", {
        slideActiveClass: "active",
        autoplay: 5000,
        speed: 600,
        pagination: ".lb_box .swiper-pagination",
        paginationClickable: true,
        observer: true,
        observeParents: true,
      });
    },
    secondLb() {
      new Swiper(".space-x-26 .swiper-container", {
        slideActiveClass: "active",
        spaceBetween : 10,
        autoplay: 5000,
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.space-x-26 .swiper-button-prev',
        nextButton:'.space-x-26 .swiper-button-next',
      });
    },
    proLb() {
      new Swiper(".pro_lb_box .swiper-container", {
        slideActiveClass: "active",
        spaceBetween : 10,
        autoplay: 5000,
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.pro_lb_box .swiper-button-prev',
        nextButton:'.pro_lb_box .swiper-button-next',
      });
    },
    aboutLb(){
      new Swiper(".about_lb_box .swiper-container", {
        slideActiveClass: "active",
        spaceBetween : 10,
        autoplay: 5000,
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.about_lb_box .swiper-button-prev',
        nextButton:'.about_lb_box .swiper-button-next',
      });
    },
    teacherLb(){
      new Swiper(".teacher_lb_box .swiper-container", {
        slideActiveClass: "active",
        spaceBetween : 10,
        autoplay: 5000,
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.teacher_lb_box .swiper-button-prev',
        nextButton:'.teacher_lb_box .swiper-button-next',
      });
    },
    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url;
      if (id) {
        path = e.currentTarget.dataset.url + "?id=" + id;
      }
      this.$router.push(path);
    },
    goTab(e, id) {
      let fullPath = e.currentTarget.dataset.url;
      if (id) {
        fullPath = `${e.currentTarget.dataset.url}?id=${id}`;
      }
      window.location.href = fullPath;
    },
    handler({BMap, map}) {
      // console.log(BMap, map);

      // this.center.lng = 121.473621;
      // this.center.lat = 31.23667;
      this.center.lng = this.mapX;
      this.center.lat = this.mapY;

      this.zoom = 12;
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .group_42 {
    justify-content: center;
  }

  .join_box {
    display: flex;
    width: 100%;
    margin-top: 0.57rem;

    .map_con {
      width: 50%;
      height: 5.52rem;

      > div {
        height: 100%;

        .com_name {
          font-size: 0.16rem;
          color: rgba(32, 128, 247, 1);
        }

        .com_add {
          font-size: 0.14rem;
          line-height: 1.5;
          color: rgba(43, 44, 48, 1);
        }
      }
    }

    .join_case {
      background-color: rgba(32, 128, 247, 1);
      width: 50%;
      padding: 0.6rem 1.2rem 0.2rem 0.4rem;
      color: #fff;

      .join_t {
        font-size: 0.24rem;
        font-weight: 300;
        line-height: 1;
        padding-bottom: 0.4rem;
        border-bottom: 1px solid #fff;
      }

      .join_name {
        padding-top: 0.15rem;

        > div {
          font-size: 0.16rem;
          line-height: 1;
          padding: 0.1rem 0;
          display: flex;
          align-items: center;

          img {
            width: 0.28rem;
            margin-right: 0.08rem;
          }

          .join_n {
            width: 0.8rem;
            margin-right: 0.1rem;
          }
        }
      }

      .join_cade {
        margin-top: 0.1rem;
        display: flex;
        align-items: flex-start;

        > div {
          width: 1.08rem;
          margin-right: 0.4rem;

          &:last-child {
            margin-right: 0;
          }

          > img {
            display: block;
            width: 100%;
          }

          .code_name {
            font-size: 0.14rem;
            text-align: center;
            padding-top: 0.15rem;
            line-height: 1;
          }
        }
      }
    }
  }

  .font_2 {
    font-size: 0.14rem;
    line-height: 0.14rem;
    color: #fff;
  }

  .font_3 {
    font-size: 0.18rem;
    font-family: Source Han Sans CN;
    line-height: 0.18rem;
    color: #ffffff;
  }

  .image-wrapper {
    overflow: hidden;
    border-radius: 0.2rem;
    width: 8.7rem;
    //height: 4.72rem;
    .lb_box {
      width: 100%;
      margin-top: .3rem;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      .swiper-pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0.16rem;
      }
    }
  }

  .image_2 {
    width: 0.52rem;
    height: 0.08rem;
  }

  .section_2 {
    padding: 0.31rem 0;
    border-radius: 0.2rem;
    background-image: url("../../assets/9b8c533779acb42cf462734a2573f50c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.45rem;
    height: 5.34rem;
  }

  .section_3 {
    padding: 0.16rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/b107136e5e4c55bbf6b85c43dd5d3273.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 4.56rem;
  }

  .space-x-10 > *:not(:first-child) {
    margin-left: 0.1rem;
  }

  .font_5 {
    font-size: 0.18rem;
    font-family: Source Han Sans CN;
    line-height: 0.18rem;
    color: #666666;
  }

  .text_4 {
    color: #000000;
    font-weight: 700;
  }

  .group_56 {
    margin-top: 0.23rem;
  }

  .group_4 {
    margin-right: 0.11rem;

    &:nth-child(1) {
      .text_6 {
        color: rgba(244, 74, 74, 1);
      }
    }

    &:nth-child(2) {
      .text_6 {
        color: rgba(240, 108, 61, 1);
      }
    }

    &:nth-child(3) {
      .text_6 {
        color: rgba(243, 179, 54, 1);
      }
    }
  }

  .space-x-14 > *:not(:first-child) {
    margin-left: 0.14rem;
  }

  .font_7 {
    font-size: 0.24rem;
    font-family: OPPOSans;
    line-height: 1;
    color: #d9d9d9;
  }

  .text_6 {
    color: rgba(217, 217, 217, 1);
    font-weight: 700;
    line-height: 0.21rem;
    letter-spacing: 0;
  }

  .cur_btn {
    cursor: pointer;

    span {
      transition: color 0.5s ease;
    }

    &:hover {
      span {
        color: #2080f7;
      }
    }
  }

  .font_6 {
    font-size: 0.12rem;
    font-family: Adobe Heiti Std;
    line-height: 1.3333;
    color: #323232;
  }

  .text_5 {
    width: 1.91rem;
  }

  .btn_sty {
    margin: 0.29rem 0.08rem 0;
  }

  .font_8 {
    font-size: 0.12rem;
    font-family: OPPOSans;
    line-height: 0.12rem;
    color: #f55f18;
    padding-left: 0.17rem;
  }

  .text_16 {
    color: #ffffff;
    line-height: 0.13rem;
  }

  .group_11 {
    margin-top: 0.48rem;
  }

  .space-y-48 > *:not(:first-child) {
    margin-top: 0.48rem;
  }

  .space-x-26 {
    > .pub_-justify-start {
      position: relative;

      > img {
        width: 100%;
        display: block;
      }

      .section_5 {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
      }
    }
  }

  .image_3 {
    width: 0.53rem;
    height: 0.08rem;
  }

  .font_9 {
    font-size: 0.36rem;
    font-family: OPPOSans;
    line-height: 0.35rem;
    color: #2b2c30;
    font-weight: 700;
    padding: 0 0.3rem;
    cursor: pointer;

    &:nth-child(2) {
      border-left: 0.02rem solid rgba(224, 224, 224, 1);
      border-right: 0.02rem solid rgba(224, 224, 224, 1);
    }
  }

  .tex_box {
    margin-top: 0.34rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  .text_17 {
    margin-left: 0.22rem;
  }

  .image_4 {
    margin-left: 0.34rem;
  }

  .group_12 {
    margin-left: 0.06rem;
    line-height: 0.18rem;
  }

  .font_11 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.14rem;
    color: #f55a11;
  }

  .font_10 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.18rem;
    color: #2b2c30;
  }

  .section_4 {
    padding-bottom: 2.43rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/b569bf61372a606dcbd1c7c44900b3fe.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
    height: 5.64rem;
  }

  .section_5 {
    padding: 0.9rem 0.51rem 1.78rem 0.57rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/b09dc568eda58be3fef84be4ddba776d.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .space-y-11 > *:not(:first-child) {
    margin-top: 0.11rem;
  }

  .font_12 {
    font-size: 0.24rem;
    line-height: 0.25rem;
    font-weight: 500;
    color: #fff;
  }

  .text_18 {
    margin-left: 0.19rem;
    color: #ffffff;
    font-weight: 700;
    line-height: 0.24rem;
  }

  .group_13 {
    line-height: 0.18rem;
    width: 100%;
  }

  .font_13 {
    font-size: 0.14rem;
    line-height: 1.2857;
    color: #ffffff;
    display: block;
  }

  .row02_left {
    width: 31.8644%;
  }

  .group_57 {
    //width: 7.78rem;
    width: 65.9322%;
  }

  .group_59 {

    width: 100%;

    display: flex;

    @media screen and (orientation: landscape){
      justify-content: space-between;
      flex-wrap: wrap;
      .swiper-slide{
        height: auto;
      }
    }
    > .pub_-justify-start {
      position: relative;
      width: 48.329%;

      > img {
        display: block;
        width: 100%;
      }

      &:nth-child(4),
      &:nth-child(5) {
        @media screen and (orientation: landscape){
          margin-top: 3.3%;

        }
      }
    }
  }

  .section_6 {
    padding-top: 1.65rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/8751e70c1f2e9089cdeedef5d50b0e60.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .pos_25 {
    position: absolute;
    left: 0;
    top: 0;
  }

  .section_8 {
    padding: 0.22rem 0.16rem 0.32rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/f21e98f36fd82dcab831aac22e33f691.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .font_14 {
    font-size: 0.2rem;
    font-family: Source Han Sans CN;
    line-height: 0.2rem;
    font-weight: 700;
    color: #ffffff;
  }

  .group_14 {
    line-height: 0.18rem;
  }

  .font_15 {
    font-size: 0.12rem;
    font-family: Source Han Sans CN;
    letter-spacing: 0.019rem;
    line-height: 0.18rem;
    color: #ffffff;
  }

  .section_7 {
    padding-top: 1.65rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/765d612b935e39769425650ba6133926.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .pos_26 {
    position: absolute;
    right: 0;
    top: 0;
  }

  .section_9 {
    padding: 0.22rem 0.16rem 0.32rem;
    background-color: #2080f7cc;
    border-radius: 0.2rem;
    width: 3.76rem;
  }

  .group_15 {
    line-height: 0.18rem;
  }

  .section_10 {
    padding-top: 1.65rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/b4f2ebe7aba76142dada06a33c248584.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .pos_27 {
    position: absolute;
    left: 0;
    top: 2.95rem;
  }

  .section_12 {
    padding: 0.2rem 0.16rem 0.34rem;
    background-color: #2080f7cc;
    border-radius: 0.2rem;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .group_16 {
    margin-left: 0.03rem;
    line-height: 0.18rem;
  }

  .section_11 {
    padding-top: 1.65rem;
    border-radius: 0.2rem;
    background-image: url("../../assets/9df5cc14fcc3d40be2f21b18d22384a3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.76rem;
  }

  .pos_28 {
    position: absolute;
    right: 0;
    top: 2.95rem;
  }

  .section_13 {
    margin-top: 0.8rem;
    padding: 0.79rem 0 0.71rem;
    background-image: url("../../assets/80e891d972e18c70778d02de8b3da597.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .space-y-57 > *:not(:first-child) {
    margin-top: 0.57rem;
  }

  .group_18 {
    width: 100%;
  }
.pro_lb_box{
  position: relative;
}
  .course_box {
    margin-top: 0.37rem;
  }

  .space-y-18 > *:not(:first-child) {
    margin-top: 0.15rem;
  }

  .text_19 {
    margin-left: 0.2rem;
    line-height: 0.37rem;
  }

  .image_5 {
    margin-left: 0.15rem;
  }

  .font_16 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.2rem;
    color: #2b2c30;
  }

  .space-x-28 > *:not(:first-child) {
    margin-left: 0.28rem;
  }

  .section_14 {
    flex: 1 1 3.76rem;
    padding: 0 0.1rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
    height: 5.52rem;
  }

  .group_19 {
    padding: 0.2rem 0.1rem 0.12rem;
  }

  .image_6 {
    border-radius: 0.08rem;
    width: 1.2rem;
    height: 1.6rem;
  }

  .group_20 {
    padding-bottom: 0.03rem;
    width: 0.84rem;
  }

  .text-wrapper_6 {
    padding: 0.04rem 0;
    border-radius: 0px 0.02rem 0.02rem 0px;
    background-image: url("../../assets/7fa665405e523339b497d74e42635e32.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.74rem;
  }

  .image_7 {
    width: 0.24rem;
    height: 0.25rem;
  }

  .pos {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .group_21 {
    margin-top: 0.1rem;
  }

  .image_8 {

    width: 0.04rem;
    height: 0.14rem;
    margin-right: 0.07rem;
    vertical-align: middle;
  }

  .font_sty {
    margin-top: 0.12rem;
    line-height: 1.22;
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    color: #a1a9b2;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .group_22 {
    padding: 0 0.07rem;
  }

  .group_23 {
    margin-top: 0.16rem;
  }

  .section_15 {
    background-color: #f55c13;
    border-radius: 0px 0.02rem 0.02rem 0px;
    width: 0.04rem;
    height: 0.14rem;
  }

  .section_16 {
    padding: 0.17rem 0.11rem 0.14rem 0.14rem;
    border-radius: 0.08rem;
    background-image: url("../../assets/be027162f936462babeb6fa17898893c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .view_1 {
    margin: 0 0.1rem;
  }

  .font_17 {
    font-size: 0.14rem;
    letter-spacing: 0.017rem;
    line-height: 0.22rem;
    color: #2b2c30;
    height: 1.32rem;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .image_9 {
    margin-right: 0.03rem;
    border-radius: 0.08rem;
    width: 0.64rem;
    height: 0.34rem;

    img {
      cursor: pointer;
    }

    &.on {
      border: 2px solid #2080f7;
    }
  }

  .group_24 {
    margin-top: 0.19rem;
    padding: 0.24rem 0.32rem 0.19rem;
    border-top: solid 0.01rem #e0e0e0;
  }

  .group_25 {
    width: 1.47rem;
  }

  .space-x-4 > *:not(:first-child) {
    margin-left: 0.04rem;
  }

  .font_18 {
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.3rem;
    color: #2b2c30;
  }

  .font_20 {
    font-size: 0.14rem;
    font-family: OPPOSans;
    line-height: 0.14rem;
    color: #2b2c30;
  }

  .font_21 {
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    line-height: 0.14rem;
    color: #666666;
  }

  .text_21 {
    margin-left: 0.24rem;
    margin-top: 0.09rem;
  }

  .section_17 {
    background-color: #e0e0e0;
    width: 0.01rem;
    height: 0.25rem;
  }

  .pos_4 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .group_26 {
    margin-right: 0.21rem;
  }

  .group_27 {
    padding: 0 0.06rem;
  }

  .font_19 {
    font-size: 0.28rem;
    font-family: OPPOSans;
    line-height: 0.25rem;
    color: #2b2c30;
  }

  .text_20 {
    line-height: 0.13rem;
  }

  .group_55 {
    margin-top: 0.3rem;
  }

  .image_10 {
    width: 0.6rem;
    height: 0.6rem;
  }

  .text-wrapper_7 {
    padding: 0.14rem 0;
    background-color: #2080f7;
    border-radius: 0.08rem;
    width: 0.6rem;
    height: 0.43rem;
  }

  .font_22 {
    font-size: 0.18rem;
    font-family: OPPOSans;
    line-height: 0.16rem;
    color: #2b2c30;
  }

  .text_22 {
    color: #ffffff;
  }

  .text-wrapper_8 {
    margin-left: 0.3rem;
    padding: 0.14rem 0;
    background-color: #ffffff;
    border-radius: 0.08rem;
    width: 0.6rem;
    height: 0.43rem;
  }

  .text-wrapper_9 {
    margin-left: 0.3rem;
    padding: 0.2rem 0;
    background-color: #ffffff;
    border-radius: 0.08rem;
    width: 0.6rem;
    height: 0.43rem;
  }

  .text_23 {
    line-height: 0.03rem;
  }

  .section_18 {
    padding: 0.8rem 0.44rem;
    background-image: url("../../assets/fad3fbf6260da1115a715ad232e99148.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .space-y-102 > *:not(:first-child) {
    margin-top: 1.02rem;
  }

  .space-y-19 > *:not(:first-child) {
    margin-top: 0.19rem;
  }

  .space-x-18 > *:not(:first-child) {
    margin-left: 0.18rem;
  }

  .group_29 {
    width: 3.44rem;

    .font_12 {
      font-weight: 500;
    }

    .font_12,
    .font_2 {
      color: rgba(43, 44, 48, 1);
    }
  }

  .text_24 {
    line-height: 0.24rem;
  }

  .text_25 {
    margin-top: 0.24rem;
    line-height: 0.24rem;
  }

  .text_26 {
    margin-top: 0.24rem;
    cursor: pointer;

    &.font_2 {
      color: #f55a11;

      &:hover {
        color: #2080f7;
      }
    }
  }

  .text-wrapper_10 {
    margin-top: 0.39rem;
    margin-left: 0;
    width: 1.48rem;
    height: 0.4rem;
  }

  .space-y-49 > *:not(:first-child) {
    margin-top: 0.49rem;
  }

  .zn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 0.2rem;
  }

  .section_19 {
    border-radius: 0.14rem;
    background-image: url("../../assets/0d26fbd969de36283ad0369698e36c47.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 6.9rem;
  }

  .section_20 {
    margin: 0 0.31rem;
    padding-top: 0.22rem;
    background-color: #354467;
    border-radius: 0.14rem;
    width: 6.28rem;
  }

  .section_21 {
    margin-left: 0.32rem;
    background-color: #fafbfc;
    border-radius: 0.08rem 0px 0.08rem 0.08rem;
  }

  .space-x-71 > *:not(:first-child) {
    margin-left: 0.71rem;
  }

  .image_11 {
    border-radius: 0.08rem 0px 0px 0px;
    width: 1.82rem;
    height: 3.43rem;
  }

  .section_23 {
    margin-right: 0.95rem;
    background-color: #ffffff;
    border-radius: 0.04rem;
    width: 2.48rem;
    height: 2.01rem;
  }

  .pos_19 {
    background-color: #fafbfc;
    border-radius: 0.04rem;
    height: 2.01rem;
    border: solid 0.02rem #ffffff;
  }

  .pos_22 {
    position: absolute;
    left: -0.03rem;
    right: 4.45rem;
    top: 1.19rem;
  }

  .section_24 {
    background-image: url("../../assets/49e9019bc5c04502c8d70ace767add16.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #f661126b;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .section_25 {
    padding: 0.8rem 0;
    width: 10.07rem;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .text_27 {
    margin-left: 0.2rem;
    color: #ffffff;
    font-size: 0.36rem;
    font-family: OPPOSans;
    line-height: 0.39rem;
  }

  .image_12 {
    margin-left: 0.11rem;
  }

  .text_28 {
    margin-top: 0.15rem;
    color: #ffffff;
  }

  .group_30 {
    margin-top: 0.53rem;
  }

  .group_31 {
    width: 3.45rem;
  }

  .space-y-54 > *:not(:first-child) {
    margin-top: 0.54rem;
  }

  .text_29 {
    color: #ffffff;
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    line-height: 0.24rem;
  }

  .space-y-13 > *:not(:first-child) {
    margin-top: 0.13rem;
  }

  .space-x-61 > *:not(:first-child) {
    margin-left: 0.61rem;
  }

  .image_13 {
    width: 0.38rem;
    height: 0.38rem;
  }

  .group_34 {
    height: 0.11rem;
  }

  .font_25 {
    font-size: 0.1rem;
    font-family: Source Han Sans CN;
    line-height: 0.1rem;
    color: #ffffff;
  }

  .image_14 {
    width: 0.05rem;
    height: 0.1rem;
  }

  .group_35 {
    margin-top: -0.11rem;
  }

  .space-x-3 > *:not(:first-child) {
    margin-left: 0.03rem;
  }

  .text_32 {
    margin-left: 0.99rem;
  }

  .group_32 {
    margin: 0.05rem 0.4rem 0 1rem;

  }
  .about_lb_box{
    width: calc(100% - .8rem -  3.45rem);
  }

  .space-x-26 > *:not(:first-child) {
    margin-left: 0.26rem;
  }

  .section_26 {
    padding: 1.1rem 0 0.24rem;
    flex: 1 1 2.46rem;
    background-color: #ffffff;
    height: 1.84rem;
    background-size: cover;
    background-position: center;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1.28rem;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
          0,
          rgba(32, 128, 247, 1),
          rgba(32, 128, 247, 0)
      );
    }
  }

  .space-y-12 > *:not(:first-child) {
    margin-top: 0.12rem;
  }

  .text_30 {
    line-height: 0.24rem;
    position: relative;
    z-index: 2;
  }

  .text_31 {
    font-weight: 500;
    position: relative;
    z-index: 2;
  }

  .group_33 {
    flex: 1 1 2.46rem;

    >.swiper-slide{
      margin-top: .26rem;
      &:nth-child(1),
      &:nth-child(2){
        margin-top: 0;
      }
      @media screen and (orientation: portrait){
        &.m_sty{
          display: flex !important;
        }
      }
    }
    @media screen and (orientation: portrait){
      flex-direction: row;

      >.swiper-slide{
        margin-top: 0 ;
        flex: none;
      }
    }
  }



.section_27 {
    background-image: url("../../assets/0489eb3dcdfca20eb4f2a4da57ced8a2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .section_28 {
    padding: 0.18rem 0.17rem;
    background-image: url("../../assets/624bec710c315f60f0f1ba82b6a6a532.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .space-y-8 > *:not(:first-child) {
    margin-top: 0.08rem;
  }

  .font_23 {
    font-size: 0.32rem;
    font-family: Source Han Sans CN;
    letter-spacing: 0.013rem;
    line-height: 0.25rem;
    font-weight: 500;
    color: #ffffff;
  }

  .font_24 {
    font-size: 0.1rem;
    font-family: Source Han Sans CN;
    line-height: 0.1rem;
    font-weight: 500;
    color: #ffffff;
  }

  .section_29 {
    background-image: url("../../assets/cb4a34806e4704e41e6cae92b9930d3c.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .group_58 {
    width: 11.6rem;
  }

  .group_36 {
    margin-top: 0.8rem;
  }

  .space-y-67 > *:not(:first-child) {
    margin-top: 0.67rem;
  }

  .group_37 {
    width: 6.11rem;
  }

  .space-y-15 > *:not(:first-child) {
    margin-top: 0.15rem;
  }

  .text_33 {
    line-height: 0.39rem;
  }

  .text_34 {
    margin-left: 0.12rem;
  }

  .space-x-44 > *:not(:first-child) {
    margin-left: 0.44rem;
  }

  .section_30 {
    flex: 1 1 2.56rem;
    padding: 0.18rem 0.16rem 0;
    background-color: #f5f7fa;
    border-radius: 0.08rem;
    width: 2.56rem;
    height: 3.2rem;
    border: solid 0.01rem #2080f7;
  }

  .text-wrapper_11 {
    padding: 0.05rem 0;
    background-color: #2080f7;
    border-radius: 0.04rem;
  }

  .font_26 {
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    line-height: 0.14rem;
    font-weight: 500;
    color: #ffffff;
  }

  .text_35 {
    margin-left: 0.04rem;
  }

  .group_39 {
    padding: 0.46rem 0 0.1rem;
  }

  .section_32 {
    img {
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 1.24rem;
      height: 1.24rem;
    }
  }

  .font_27 {
    font-size: 0.18rem;
    line-height: 1;
    font-weight: 500;
    color: #2b2c30;
  }

  .text_37 {
    text-align: center;
  }

  .space-y-39 > *:not(:first-child) {
    margin-top: 0.1rem;
  }

  .pos_1 {
    width: 6.29rem;
    height: 0.18rem;
  }

  .pos_24 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .section_34 {
    margin-top: 0.8rem;
    padding: 0.8rem 0 0;
    background-image: url("../../assets/f506435d981026d5a98b32a3cdf56f7e.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .text_40 {
    color: #2080f7;
  }

  .group_43 {
    margin-top: 0.18rem;
    line-height: 0.18rem;
  }

  .text_42 {
    color: #2080f7;
    line-height: 0.17rem;
  }

  .list_3 {
    position: relative;
  }

  .section_36 {
    border-radius: 0.2rem;
    background-image: url("../../assets/a7e9f0c215d52a7a2d9c1a46d18fc7ce.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 4.38rem;
    padding-bottom: 0.37rem;
    margin: 0 -0.21rem;
  }

  .section_37 {
    padding: 0.2rem 0.1rem 0;
    background-color: #ffffff;
    border-radius: 0.2rem;
    width: 3.76rem;
  }

  .group_62 {
    margin: 0 0.1rem;
  }

  .space-x-14 {
    justify-content: space-between;
  }

  .space-x-12 > *:not(:first-child) {
    margin-left: 0.12rem;
    width: calc(100% - 1.32rem);
  }

  .text-wrapper_12 {
    padding: 0.04rem 0;
    border-radius: 0px 0.02rem 0.02rem 0px;
    background-image: url("../../assets/545541c7ee13cf03507f1f17b48d7229.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 0.74rem;
  }

  .pos_12 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .group_63 {
    margin-top: 0.1rem;
    height: 0.5rem;
  }

  .group_64 {
    margin-bottom: -0.34rem;
  }

  .group_60 {
    padding-left: 0.07rem;
  }

  .group_61 {
    margin-top: 0.19rem;
    width: 0.04rem;
  }

  .section_1 {
    background-color: #f55c13;
    border-radius: 0px 0.02rem 0.02rem 0px;
    height: 0.14rem;
  }

  .view_4 {
    margin: 0.12rem 0.1rem 0;
  }

  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }

  .text_44 {
    margin-left: 0.24rem;
    margin-top: 0.09rem;
  }

  .pos_15 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .text_43 {
    line-height: 0.13rem;
  }

  .space-y-16 > *:not(:first-child) {
    margin-top: 0.16rem;
  }

  .space-x-7 > *:not(:first-child) {
    margin-left: 0.07rem;
  }

  .space-y-23 > *:not(:first-child) {
    margin-top: 0.23rem;
  }

  .space-y-14 > *:not(:first-child) {
    margin-top: 0.14rem;
  }

  .text-wrapper_09 {
    margin: 0;
  }

  @media screen and (orientation: portrait) {
    .pub_info {
      font-size: .2rem;
    }
    .font_14 {
      font-size: .32rem;
      line-height: 1;
    }
    .row02_left {
      width: 100%;
    }
    .font_13 {
      font-size: .2rem;
      margin-top: .1rem;
    }
    .space-x-26 > *:not(:first-child) {
      margin-left: 0;
    }

    .group_57 {
      width: 100%;
    }
    .group_59 {

      > .pub_-justify-start {
        width: 100%;

      }
    }
    .section_12 {
      padding: .4rem .3rem;
    }
    .section_18 {
      padding-left: 0;
      padding-right: 0;
    }
    .zn_box {
      flex-wrap: wrap;

      .section_19 {
        width: 100%;
      }
    }
    .group_29 {
      width: 100%;
      margin-top: .4rem;
    }
    .text_24 {
      font-size: .28rem;
    }
    .text_25 {
      font-size: .2rem;
      line-height: 1.5;
    }
    .text_26 {
      font-size: .24rem;
      line-height: 1;
    }
    .section_25 {
      width: 100%;
    }
    .group_30 {
      padding-right: .45rem;
      padding-left: .45rem;
      flex-direction: column-reverse;

    }
    .group_32 {
      width: 100%;
      margin: 0;
      display: block;
    }
    .about_lb_box{
      position: relative;
    }
    .section_26 {
      width: 100%;
      //height: 1.72rem;
      height: 2.22rem;

      .font_2 {
        font-size: .2rem;
        line-height: 1;
      }
    }
    .text_30 {
      font-size: .28rem;
    }
    .group_33 {
      .section_27, .section_29 {
        height: 2.22rem;
        //margin-top: .26rem;
      }

      .section_28 {
        height: 100%;
        justify-content: center;

        .font_23 {
          font-size: .64rem;
          height: auto;
          line-height: 1;
        }

        .font_24 {
          font-size: .2rem;
          margin-top: .15rem;
          line-height: 1;
        }
      }
    }

    .group_31 {
      width: 100%;
      margin-top: .5rem;

      .text_29 {
        font-size: .2rem;
        line-height: 1.5;
      }
    }
    .group_34 {
      height: auto;
      display: flex;
      flex-direction: row;
    }
    .group_35 {
      margin-top: 0;
    }
    .space-y-13 {
      .image_13 {
        width: .76rem;
        height: .76rem;

      }

      .font_25 {
        font-size: .2rem;
        line-height: 1;
      }

      .image_14 {
        width: .1rem;
        height: .2rem;
        margin-left: .1rem;
      }

      .text_32 {
        margin-left: .3rem;
      }
    }
    .group_58 {
      width: 100%;
    }
    .pos_24 {
      display: none;
    }
    .space-x-44 {
      width: 100%;
      padding-left: .45rem;
      padding-right: .45rem;
      flex-wrap: wrap;

      > *[data-v-087d42bb]:not(:first-child) {
        margin-left: 0;
      }

      justify-content: space-between;

      .section_30 {
        flex: none;
        width: 47.5%;
        margin: 0 0 5%;

        .text-wrapper_11 {
          padding: 0;

          .font_26 {
            font-size: .2rem;
            margin: 0;
            padding: .05rem .1rem;
            line-height: 1;
          }
        }

        .font_27 {
          font-size: .22rem;
        }
      }
    }

    .tex_box {
      flex-wrap: wrap;

      .section_36 {
        width: 100%;
        margin: 0 0 .4rem;
        padding: 0;
        box-shadow: 0 0 .12rem 0 rgba(0, 0, 0, .1);

        &:last-child {
          margin-bottom: 0;
        }

        .section_37 {
          width: 100%;
          padding: .3rem;
        }
      }
    }
    .font_16 {
      font-size: .32rem;
      line-height: 1;
    }
    .font_sty {
      font-size: .2rem;
      width: 50%;
      margin-top: .1rem !important;
    }
    .group_60 {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: .1rem;
      margin-bottom: 0;
    }
    .image_8 {
      width: .057rem;
      height: .2rem;
    }
    .group_63 {
      height: auto;
      margin: 0;
    }
    .font_17 {
      font-size: .2rem;
      line-height: 1.5;
      display: block;
      height: calc(9em + .2rem);
      padding: .2rem .2rem 0;
      margin-bottom: .2rem;
    }
    .image_9 {
      width: 1.28rem;
      height: .68rem;

      img {
        width: 100%;
        height: 100%;
      }

    }
    .font_20, .font_21 {
      font-size: .2rem;
    }
    .text_44 {
      margin-left: 0;
    }
    .font_19 {
      font-size: .32rem;
    }
    .group_20 {
      width: 1.5rem;

      .image_7 {
        width: 0.36rem;
        height: 0.375rem;
      }

      .text-wrapper_12 {
        width: 1.3rem;
        padding: .05rem .15rem;
      }

      .font_8 {
        font-size: .2rem;
        line-height: 1;
      }
    }

    .text_42 {
      font-size: .2rem;
    }

    .join_box {
      flex-wrap: wrap;

      .map_con {
        width: 100%;

        > div {
          .com_name {
            font-size: .22rem;
          }

          .com_add {
            font-size: .2rem;
          }
        }
      }

      .join_case {
        width: 100%;
        padding: .3rem;
        display: none;

        .join_t {
          font-size: .3rem;
        }

        .join_name {
          > div {

            font-size: .24rem;
            line-height: .4rem;
            align-items: flex-start;

            img {
              width: .4rem;
            }

            .join_n {
              width: 5em;
            }

            .join_c {
              width: calc(100% - 5em - .5rem);
            }
          }


        }

        .join_cade {
          > div {
width: 1.6rem;
            .code_name{
              font-size: .2rem;
              padding-top: .2rem;
            }
          }
        }
      }

    }


     .image-wrapper .lb_box img {
       height: calc((100vw - .9rem) / 3 * 2);
     }


    .swiper-button-prev,.swiper-button-next{
      width: .47rem;
      height: .7rem;
      margin-top: -.35rem;
      background-size: .27rem auto;
    }
    .swiper-button-prev{
      left: 0;
      margin-left: -.4rem !important;
    }
    .swiper-button-next{
      right: 0;
      margin-right: -.4rem !important;
    }
    .space-x-26{
      position: relative;


    }
     .group_59  {
       >.pub_-justify-start > img{
         height: calc((100vw - .9rem) * 0.71543);
       }
     }
    .course_box .section_cou{
      margin-bottom: 0;
    }
    .teacher_lb_box{
      width: 100%;
      position: relative;
    }
    .tex_box{
      flex-wrap: nowrap;
    }
  }
}
</style>
