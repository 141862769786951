<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="pub_-flex-col pub_-justify-start pub_-self-center pub_case">
      <div class="pub_-flex-col pub_-justify-start group_2">
        <div class="pub_-flex-col pub_-justify-start group_12">
          <div class="pub_-flex-row pub_-relative group_11 space-x-40 pub_case">
            <left-nav></left-nav>

            <div
              class="pub_-flex-col pub_-flex-auto pub_-self-stretch group_3 space-y-20"
            >
              <div class="pub_-flex-col pub_-justify-start">
                <div class="pub_-flex-col group_3">
                  <div class="pub_-flex-row group_4 space-x-28">
                    <div
                      class="pub_-flex-row pub_-items-baseline"
                      @click="isExpired = 0"
                      :class="isExpired == 0 ? 'on' : ''"
                    >
                      <span class="font_3 text_10">我的课程</span>
                      <span class="font_4 text_12">({{ showMyLen }})</span>
                    </div>
                    <div
                      class="pub_-flex-row pub_-items-baseline"
                      @click="isExpired = 1"
                      :class="isExpired == 1 ? 'on' : ''"
                    >
                      <span class="font_3 text_11">过期课程</span>
                      <span class="font_4 text_13">({{ showExpiredLen }})</span>
                    </div>
                  </div>

                </div>
              </div>
              <div class="pub_-flex-col pub_-justify-start">
                <div class="pub_-flex-row list group_1" v-if="isExpired == 0">
                  <div
                    class="pub_-flex-col section_9"
                    :key="i"
                    v-for="(item, i) in showList"
                  >
                    <span class="font_6">{{ item.title }}</span>
                    <span class="pub_-self-start font_7 text_1" >

                      <template  v-if="item.expiredTime != '' && item.expiredTime != undefined && item.expiredTime != null">
                      有效期：{{ item.expiredType == 1 ? "永久有效" : item.expiredTime }}
                        </template>
                    </span
                    >
                    <div class="pub_-flex-row group_6 space-x-4">
                      <div
                        class="pub_-flex-col pub_-items-start pub_-flex-auto space-y-8"
                      >
                        <span class="font_8">已学进度</span>

                        <div class="pru_step">
                          <span :style="{ width: item.schedule + '%' }"></span>
                        </div>
                      </div>
                      <div class="group_7">
                        <span class="font_9">{{
                          parseInt(item.schedule)
                        }}</span>
                        <span class="font_10">%</span>
                      </div>
                    </div>
                    <div class="divider_2"></div>
                    <div
                      class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5 btn_sty"
                      @click="goHref"
                      :data-url="'purchasedCourseDetails?id=' + item.id"
                    >
                      <span class="font_12">进入学习</span>
                    </div>


                  </div>

                  <Pagination
                    @change="changePage"
                    :current="pagination.p"
                    :pageSize="pagination.pageSize"
                    :total="total"
                    :hideOnSinglePage="hideOnSinglePage"
                    :showQuickJumper="true"
                    :showTotal="true"
                    placement="right"
                    v-if="pagination && total"
                  />
                </div>
                <div class="pub_-flex-row list group_1" v-else>
                  <div
                    class="pub_-flex-col section_9"
                    :key="i"
                    v-for="(item, i) in showList2"
                  >
                    <span class="font_6">{{ item.title }}</span>
                    <span class="pub_-self-start font_7 text_1">
<!--                     <template  v-if="item.expiredType != '' || item.expiredType != undefined">-->
<!--                      有效期：{{ item.expiredType == 1 ? "永久有效" : item.expiredTime }}-->
<!--                        </template>-->
                        <template  v-if="item.expiredTime != '' && item.expiredTime != undefined && item.expiredTime != null">
                      有效期：{{ item.expiredType == 1 ? "永久有效" : item.expiredTime }}
                        </template>
                    </span>
                    <div class="pub_-flex-row group_6 space-x-4">
                      <div
                        class="pub_-flex-col pub_-items-start pub_-flex-auto space-y-8"
                      >
                        <span class="font_8">已学进度</span>

                        <div class="pru_step">
                          <span :style="{ width: item.schedule + '%' }"></span>
                        </div>
                      </div>
                      <div class="group_7">
                        <span class="font_9">{{
                          parseInt(item.schedule)
                        }}</span>
                        <span class="font_10">%</span>
                      </div>
                    </div>
                    <div class="divider_2"></div>
                    <!-- <div
                      class="pub_-flex-col pub_-justify-start pub_-items-center text-wrapper_5 btn_sty"
                      @click="goHref"
                      :data-url="'purchasedCourseDetails?id=' + item.id"
                    >
                      <span class="font_12">进入学习</span>
                    </div> -->
                  </div>

                  <Pagination
                    @change="changePage2"
                    :current="pagination2.p"
                    :pageSize="pagination2.pageSize"
                    :total="total2"
                    :hideOnSinglePage="hideOnSinglePage2"
                    :showQuickJumper="true"
                    :showTotal="true"
                    placement="right"
                    v-if="pagination2 && total2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: { NavBox, FootBox, LeftNav, Pagination },
  data() {
    return {
      showList: [], //显示的列表
      showList2: [], //显示的列表

      showExpired: {},
      showExpiredLen: 0,
      showMy: {},
      showMyLen: 0,
      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },
      hideOnSinglePage2: false,
      total2: 0,
      pagination2: {
        pageSize: 10,
        p: 1,
      },

      isExpired: 0,
    };
  },
  created() {
    this.ajaxH();
  },
  methods: {
    async ajaxH() {
      let param = {
        isExpired: 0,
      };
      const { data: res } = await this.$http.post(
        "/api.myCourse/getList",
        param
      );
      // console.log(res);
      this.showMy = res.data;
      this.showList = this.showMy.data.slice(0, this.showMy.per_page);
      console.log(this.showList)
      this.total = this.showMy.total;
      this.pagination.pageSize = this.showMy.per_page;
      this.showMyLen = this.showMy.data.length;

      // console.log(this.pagination)
      // console.log(this.total)



      let params = {
        isExpired: 1,
      };
      const { data: ress } = await this.$http.post(
        "/api.myCourse/getList",
        params
      );
      // console.log(ress);
      this.showExpired = ress.data;
      this.showList2 = this.showExpired.data.slice(0, this.showExpired.per_page);
      this.total2 = this.showExpired.total;
      this.pagination2.pageSize = this.showExpired.per_page;
      this.showExpiredLen = this.showExpired.data.length;
    },
    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList = this.showMy.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    changePage2(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.showList2 = this.showExpired.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      );

      document.getElementById("target").scrollIntoView();
    },
    goHref(e, id) {
      // console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url + "&&active=" + this.active;
      this.$router.push(path);
    },
 },
};
</script>

<style scoped lang="scss">
.page {

  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.space-y-152 > *:not(:first-child) {
  margin-top: 1.52rem;
}
.section {
  padding-top: 0.17rem;
  background-color: #ffffff;
  height: 0.98rem;
}
.group {
  width: 11.81rem;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.14rem;
}
.text {
  color: #ffffff;
  line-height: 0.15rem;
}
.text_2 {
  color: #2b2c30;
  line-height: 0.15rem;
}
.section_2 {
  margin: 0.17rem 0 -0.68rem;
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f4f7fe;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text_8 {
  margin-left: 0.43rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_9 {
  line-height: 0.18rem;
}
.group_2 {
}
.group_12 {
  padding-top: 0.4rem;
  padding-bottom: 0.8rem;

  min-height: 7.28rem;
}

.space-x-40 > *:not(:first-child) {
  margin-left: 0.4rem;
}
.text-wrapper_2 {
  padding-top: 0.15rem;
  background-color: #ffffff;
  width: 1.98rem;
}
.font_5 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.14rem;
  font-weight: 300;
  color: #000000;
}
.text_14 {
  margin-left: 0.19rem;
}
.section_4 {
  margin-top: 0.15rem;
  background-color: #ffffff;
}
.space-x-15 > *:not(:first-child) {
  margin-left: 0.15rem;
}
.section_5 {
  background-color: #2080f7;
  width: 0.02rem;
  height: 0.44rem;
}
.text_15 {
  margin-right: 1.26rem;
  color: #2080f7;
}
.text-wrapper_3 {
  padding: 0.15rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.text_16 {
  margin-left: 0.16rem;
}
.text_18 {
  margin-left: 0.17rem;
}
.text_19 {
  margin-left: 0.16rem;
}
.text-wrapper_4 {
  padding: 0.16rem 0;
  background-color: #ffffff;
  width: 1.98rem;
}
.font_11 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  letter-spacing: -0.007rem;
  line-height: 0.12rem;
  font-weight: 300;
  color: #000000;
}
.text_20 {
  line-height: 0.13rem;
}
.text_25 {
  margin-left: 0.17rem;
}
.text_22 {
  margin-left: 0.17rem;
}
.text_23 {
  margin-left: 0.16rem;
}
.group_3 {
  width: 9.43rem;
}
.space-y-20 > *:not(:first-child) {
  margin-top: 0.2rem;
}
.group_4 {
  padding: 0 0.14rem 0;
  border-bottom: 2px solid #e0e0e0;
  .pub_-items-baseline {
    padding-bottom: 0.1rem;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display:block;
      height: 2px;
      width: 2em;
      background-color: #2080f7;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      position: absolute;
      opacity: 0;
      transition: opacity .5s ease-in-out;
    }
    &.on {
      &::before{
        opacity: 1;

      }
    }
  }
}
.space-x-28 > *:not(:first-child) {
  margin-left: 0.28rem;
}
.font_3 {
  font-size: 0.16rem;
  font-family: SourceHanSansCN;
  letter-spacing: 0.019rem;
  line-height: 0.17rem;
}
.text_10 {
  color: #2080f7;
  font-weight: 500;
  line-height: 0.16rem;
}
.font_4 {
  font-size: 0.1rem;
  font-family: OPPOSans;
  letter-spacing: 0.012rem;
  line-height: 0.12rem;
}
.text_12 {
  color: #2080f7;
  line-height: 0.11rem;
}
.text_11 {
  color: #a1a9b2;
  font-weight: 300;
  line-height: 0.16rem;
}
.text_13 {
  color: #a1a9b2;
  line-height: 0.11rem;
}
.divider {
  background-color: #e0e0e0;
}
.section_3 {
  margin-left: 0.35rem;
  background-color: #2080f7;
  width: 0.28rem;
  height: 0.02rem;
}
.list {
  position: relative;
}

.font_6{
  height: .4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* 显示的行数，这里设置为 3 行 */
  -webkit-box-orient: vertical;
  overflow: hidden;

}
.group_1 {
  width: 9.42rem;
  min-height: 8.74rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 0 1rem;
  position: relative;
  .m-pagination {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    width: 100%;
    padding-right: 0.5rem;
  }
}
.section_9 {
  padding: 0.16rem 0.14rem;
  background-color: #ffffff;
  border-radius: 0.14rem;
  width: 2.28rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;

  &:nth-child(4n) {
    margin-right: 0;
  }
}
.pos_20 {
  position: absolute;
  left: 0;
  top: 0;
}
.font_6 {
  font-size: 0.14rem;
  font-family: Adobe Heiti Std;
  line-height: 0.2rem;
  color: #2b2c30;
}
.font_7 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  line-height: 0.12rem;
  height: .12rem;
  font-weight: 300;
  color: #a1a9b2;
}
.text_1 {
  margin-top: 0.08rem;
}
.group_6 {
  margin-top: 0.38rem;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 0.04rem;
}
.space-y-8 > *:not(:first-child) {
  margin-top: 0.08rem;
}
.font_8 {
  font-size: 0.12rem;
  font-family: SourceHanSansCN;
  line-height: 0.12rem;
  font-weight: 300;
  color: #000000;
}
.pru_step {
  border-radius: 0.02rem;
  width: 1.38rem;
  height: 0.04rem;
  overflow: hidden;
  background-color: rgba(243, 246, 250, 1);
  span {
    display: block;
    background-color: #2080f7;
    border-radius: 0.02rem;
    height: 100%;
    width: 0;
  }
}
.group_7 {
  margin-right: 0.07rem;
  line-height: 1;
  display: flex;
  align-items: baseline;
}
.font_9 {
  font-size: 0.28rem;
  font-family: OPPOSans;
  letter-spacing: 0.014rem;
  line-height: 0.22rem;
  color: #2080f7;
}
.font_10 {
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.12rem;
  color: #2080f7;
}
.divider_2 {
  margin-right: 0.06rem;
  margin-top: 0.2rem;
  background-color: #f3f6fa;
  border-radius: 0.005rem;
  height: 0.01rem;
}
.text-wrapper_5 {
  margin-right: 0.06rem;
  margin-top: 0.2rem;
  padding: 0.12rem 0;
  border-radius: 0.08rem;
  width: 1.94rem;
}
.font_12 {
  font-size: 0.14rem;
  letter-spacing: 0.02rem;
  line-height: 0.14rem;
}
@media screen and (orientation: portrait){
  .group_2 .pub_case > div{
    display: flex;
  }
  .space-x-40{
    padding: 0;
    flex-wrap: wrap;
  }
  .space-x-40 > *[data-v-633df006]:not(:first-child){
    margin-left: 0;

  }
  .space-y-20 > *[data-v-633df006]:not(:first-child){
    margin-top: .4rem;
  }
  .group_3{
    width: 100%;
    .group_4{
      padding: .2rem 0;
      .pub_-items-baseline{
        padding-top: .2rem;
      }
      .font_3{
        font-size: .22rem;
        line-height: 1;
      }
      .font_4{
        font-size: .18rem;
      }
    }
.group_1{
  width: 100%;
  justify-content: space-between;
  .section_9{
    width: 100%;
    margin-bottom: .4rem;
    padding: .3rem;

    .font_6{
      font-size: .24rem;
      line-height: 1.5;
      //height: 3em;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .font_7{
      margin-top: .1rem;
      font-size: .2rem;
      line-height: 1;
    }
    .font_8{
      font-size: .2rem;
      line-height: 1;
    }
    .font_9{
      font-size: .36rem;
      line-height: 1;
    }
    .font_10{
      font-size: .2rem;
      line-height: 1;
    }
    .group_7{
      width: 1.1rem;
      justify-content: flex-end;
    }
    .pru_step{
      width: calc(100%  - .2rem);
      height: .06rem;
      margin-top: .2rem;

    }
  }

  .text-wrapper_5{
    margin-top: .3rem;
    margin-right: 0;
    width: 100%;
    padding: .2rem;

    .font_12{
      font-size: .24rem;
      line-height: 1;
    }
  }
}

  }
}

</style>
