<template>
  <div class="pub_-flex-col page">

    <nav-box></nav-box>


    <div class="pub_-flex-col pub_-justify-start pub_-self-center group">
      <div class="pub_-flex-row group_2 space-x-40 pub_case">


        <div class="pub_-flex-col pub_-flex-auto section_3" v-html="content" >


        </div>



      </div>
    </div>

    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import LeftNav from "../../components/leftNav/leftNav.vue";

  export default {
    components: {NavBox, FootBox},
    data() {
      return {
        content:'',
      };
    },
    created() {
    this.ajaxH(null, 0);
  },
    methods: {

      async ajaxH(id, i) {
      this.getInfo(); //获取站内信分页列表
    },
    async getInfo(type) {
      let param = {
      };
      // console.log(param)
      const { data: res } = await this.$http.post(
        "/api.setUp/getCustomPage",
        param
      );

      console.log(res);

this.content = res.data;

    },

    },
  };
</script>

<style scoped lang="css">
  .page {

    background-color: #f4f4f4;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding-top: 0.17rem;
    background-color: #ffffff;
  }
  .space-y-17 > *:not(:first-child) {
    margin-top: 0.17rem;
  }
  .group_1 {
    width: 11.81rem;
  }
  .text-wrapper {
    padding: 0.24rem 0;
    background-color: #000000;
    width: 1.34rem;
    height: 0.64rem;
  }
  .font_1 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    line-height: 0.15rem;
  }
  .text {
    color: #ffffff;
  }
  .text_2 {
    color: #2b2c30;
  }
  .section_2 {
    padding: 0.24rem 0 0.24rem 0.19rem;
    background-color: #f3f6fa;
  }
  .space-x-52 > *:not(:first-child) {
    margin-left: 0.52rem;
  }
  .font_2 {
    font-size: 0.18rem;
    font-family: SourceHanSansCN;
    line-height: 0.17rem;
    color: #2b2c30;
  }
  .text_4 {
    margin-left: 0.35rem;
  }
  .text_3 {
    margin-left: 0.42rem;
  }
  .text_5 {
    margin-left: 0.44rem;
  }
  .text_6 {
    margin-left: 0.35rem;
  }
  .text_7 {
    margin-left: 0.34rem;
  }
  .text_8 {
    margin-left: 0.43rem;
  }
  .space-x-43 > *:not(:first-child) {
    margin-left: 0.43rem;
  }
  .text_9 {
    line-height: 0.18rem;
  }
  .group {
    width: 11.8rem;
  }
  .group_2 {
    padding: 0.4rem 0;
  }
  .space-x-40 > *:not(:first-child) {
    margin-left: 0.4rem;
  }
  .text-wrapper_2 {
    padding: 0.15rem 0;
    background-color: #ffffff;
    width: 1.98rem;
  }
  .font_3 {
    font-size: 0.14rem;
    font-family: SourceHanSansCN;
    letter-spacing: -0.007rem;
    line-height: 0.14rem;
    font-weight: 300;
    color: #000000;
  }
  .text_10 {
    margin-left: 0.19rem;
  }
  .text_13 {
    margin-left: 0.17rem;
  }
  .text_15 {
    margin-left: 0.16rem;
  }
  .text_17 {
    margin-left: 0.17rem;
  }
  .text_18 {
    margin-left: 0.16rem;
  }
  .section_4 {
    background-color: #ffffff;
  }
  .space-x-15 > *:not(:first-child) {
    margin-left: 0.15rem;
  }
  .section_5 {
    background-color: #2080f7;
    width: 0.02rem;
    height: 0.44rem;
  }
  .text_20 {
    margin-right: 1.26rem;
    color: #4a95f2;
    line-height: 0.13rem;
  }
  .text_22 {
    margin-left: 0.17rem;
  }
  .text-wrapper_3 {
    padding: 0.16rem 0;
    background-color: #ffffff;
    width: 1.98rem;
  }
  .text_23 {
    margin-left: 0.16rem;
    line-height: 0.13rem;
  }
  .section_3 {
    padding: 0.38rem 0.35rem 0.38rem 0.39rem;
    background-color: #ffffff;
    width: 9.42rem;
    min-height: 65vh;
  }
  .font_4 {
    font-size: 0.16rem;
    font-family: SourceHanSansCN;
    line-height: 0.16rem;
    color: #2b2c30;
  }
  .font_5 {
    font-size: 0.14rem;
    font-family: Adobe Heiti Std;
    line-height: 0.2rem;
    color: #a1a9b2;
  }
  .font_6 {
    font-size: 0.14rem;
    font-family: Adobe Heiti Std;
    line-height: 0.14rem;
    color: #a1a9b2;
  }
  .text_11 {
    text-transform: uppercase;
  }
  .text_19 {
    margin-top: 0.36rem;
  }
  .text_16 {
    margin-top: 0.09rem;
  }
  .text_14 {
    margin-top: 0.41rem;
  }
  .text_12 {
    font-weight: 500;
    line-height: 0.18rem;
  }
  .text_21 {
    margin-top: 0.13rem;
  }
  .group_3 {
    margin-top: 0.08rem;
  }
  .space-y-7 > *:not(:first-child) {
    margin-top: 0.07rem;
  }
  .text_24 {
    margin-top: 0.36rem;
  }
  .text_25 {
    margin-top: 0.09rem;
  }
  .text_26 {
    margin-top: 0.36rem;
  }
  .text_27 {
    margin-top: 0.09rem;
  }
  .text_28 {
    margin-top: 0.36rem;
  }
  .group_4 {
    margin-right: 0.06rem;
    margin-top: 0.09rem;
  }
  .space-y-4 > *:not(:first-child) {
    margin-top: 0.04rem;
  }
  .group_5 {
    margin-right: 0.03rem;
    padding-bottom: 0.2rem;
  }
  .group_6 {
    line-height: 0.2rem;
  }
  .pos {
    position: absolute;
    left: 0.36rem;
    right: 0;
    bottom: 0;
  }
  .text_29 {
    line-height: 0.15rem;
  }
  .text_30 {
    color: #f95d22;
  }
  .text_31 {
    margin-top: 0.37rem;
  }
  .group_7 {
    margin-top: 0.09rem;
    line-height: 0.2rem;
  }
  .text_32 {
    color: #2080f7;
  }
  .text_33 {
    margin-top: 0.34rem;
  }
  .text_34 {
    margin-top: 0.13rem;
  }
  .text_35 {
    margin-top: 0.39rem;
  }
  .group_8 {
    margin-top: 0.09rem;
    line-height: 0.2rem;
  }
  .text_36 {
    margin-top: 0.37rem;
  }
  .text_37 {
    margin-top: 0.13rem;
  }
  .text_38 {
    margin-top: 0.4rem;
  }
  .text_39 {
    margin-top: 0.13rem;
  }
</style>
