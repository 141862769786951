
<template>
  <div class="nav_left">



    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide"><RouterLink to="/myTimetable" :class="{on:active == 0}">
          <span>我的课表</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/lectureRecord" :class="{on:active == 1}">
          <span>授课记录</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/purchasedCourse" :class="{on:active == 2}">
          <span>已购课程</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/order" :class="{on:active == 3}">
          <span>订单管理</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/myWallet" :class="{on:active == 4}">
          <span>我的钱包</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/workOrder" :class="{on:active == 5}">
          <span>工单管理</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/mail" :class="{on:active == 6}">
          <span>站内信息</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/setup" :class="{on:active == 7}">
          <span>用户设置</span>
        </RouterLink></div>
        <div class="swiper-slide"><RouterLink to="/help" :class="{on:active == 8}">
          <span>帮助文档</span>
        </RouterLink></div>
      </div>


    </div>


    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev m_sty"></div>
    <div class="swiper-button-next m_sty"></div>


  </div>

</template>
  <script>
import Swiper from "swiper";

export default {
  name: "LeftNav",
  props: ['tar'],
  data() {
    return {
        active:0
    };
  },
  computed: {

  },

  created() {
    // 监听键盘Enter按键
    this.active = this.tar
    // console.log(this.active)
  },
  mounted() {

    if (window.innerWidth < window.innerHeight){

      this.leftNavLb();

    }
  },
  methods: {
    leftNavLb() {
     let navSwiper = new Swiper(".nav_left .swiper-container", {
        slideActiveClass: "active",
        slidesPerView : 'auto',
        speed: 600,
        observer: true,
        observeParents: true,
        prevButton:'.nav_left .swiper-button-prev',
        nextButton:'.nav_left .swiper-button-next',
      });
      // let detOn = $('.nav_left .router-link-exact-active ').parent('.swiper-slide').index();
      //


      const activeElements = document.querySelectorAll('.nav_left .router-link-exact-active');


// 遍历这些元素，找到它们的父级swiper-slide元素并获取其索引
      for (const activeElement of activeElements) {
        const parentSwiperSlide = activeElement.closest('.swiper-slide');
        if (parentSwiperSlide) {
          const index = Array.from(parentSwiperSlide.parentNode.children).indexOf(parentSwiperSlide);
          console.log(index);

          navSwiper.slideTo(index, 60, false);
        }
      }

    },

  },
};
</script>
  <style lang="less" scoped>
  .nav_left{
    position: relative;
    .swiper-wrapper{
      @media screen and (orientation: landscape){
        display: block;
      }
    }

    .swiper-button-prev,.swiper-button-next{
      width: .47rem;
      height: .7rem;
      margin-top: -.35rem;
      background-size: .27rem auto;
    }
    .swiper-button-prev{
      left: 0;
      margin-left: -.4rem !important;
    }
    .swiper-button-next{
      right: 0;
      margin-right: -.4rem !important;
    }


  }

</style>
