<template>
  <div class="pub_-flex-col pub_-justify-start page">
    <div class="pub_-flex-col pub_-justify-start section">
      <div class="pub_-flex-col pub_-justify-start pub_-items-start section_2">
        <div class="pub_-flex-col pub_-items-end section_3">
          <div class="pub_-flex-col section_4 space-y-86">
            <div class="pub_-flex-col">
              <span class="pub_-self-center text text_2">账号密码</span>
              <div class="divider"></div>
              <div
                class="
                  pub_-flex-row pub_-justify-between
                  section_5
                  view
                  space-x-98
                "
              >
                <div class="pub_-flex-row pub_-items-center space-x-14">
                  <img
                    class="pub_-shrink-0 image"
                    src="../../assets/08ca9c8c2eaed39dbcaa5e50ec48d4a5.png"
                  />
                  <input
                    class="font_1 text"
                    type="number"
                    placeholder="请输入手机号或邮箱"
                    v-model="account01"
                  />
                </div>
                <img
                  class="image_2"
                  @click="empty_2()"
                  src="../../assets/ce177567b6f6f507693e20e23fb3b9eb.png"
                />
              </div>
              <div
                class="
                  pub_-flex-row pub_-justify-between
                  section_5
                  view_2
                  space-x-159
                "
              >
                <div class="pub_-flex-row pub_-items-center space-x-14">
                  <img
                    class="pub_-shrink-0 image"
                    src="../../assets/01a65ec011bc9c28be9bc8ed92180bc5.png"
                  />
                  <input
                    class="text text_3"
                    placeholder="********"
                    type="password"
                    v-model="password01"
                  />
                </div>
                <img
                  class="image_2"
                  @click="empty_1()"
                  src="../../assets/ce177567b6f6f507693e20e23fb3b9eb.png"
                />
              </div>
              <div class="pub_-flex-row group space-x-192">

                <RouterLink to="/forgetPassword" class="font_1 text">忘记密码</RouterLink>

                <RouterLink to="/register" class="font_1 text text_4"
                >立即注册
                </RouterLink
                >
              </div>
              <div
                class="
                  pub_-flex-col pub_-justify-start pub_-items-center
                  text-wrapper
                  btn_sty
                "
                @click="ajaxH"
              >
                <span class="text text_5">登录</span>
              </div>
            </div>
            <div
              class="pub_-flex-row pub_-items-center group_2 space-x-14 hend_c"
            >
              <!-- <RouterLink to="/ewm"
                ><img
                  class="image_3"
                  src="../../assets/a129daff2b6c1c1917ddd5efe52c95f1.png"
                />
                <span class="font_1 text text_6">微信</span></RouterLink
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from "vue-cookies";

export default {
  components: {},
  data() {
    return {
      account01: "",
      password01: "",
    };
  },
  created() {


  },
  methods: {
    async ajaxH(id, i) {
      this.logout(false); //退出
      this.login(); //登录接口

    },
    async logout(logSta) {
      let param = {
        token: this.token,
      };
      // console.log(param);
      const {data: res} = await this.$http.post("/api.student/logout", param);
      if (logSta != false) {
        if (res.code === 0) {
          this.$cookies.remove("oatoken");
          this.stateLogin = false;


          this.$message({
            message: "已退出",
          });
          const currentPath = this.$router.currentRoute.path;
          if (currentPath === '/') {
            location.reload();
          } else {
            this.$router.push('/');
          }

        } else {
          this.$message({
            message: res.msg,
          });
          let path = "/login";
          this.$router.push(path);
        }
      }
      // console.log(this.config);
    },

    async login(id, i) {
      let param = {
        account: this.account01,
        password: this.password01,
      };
      if (this.account01 == "") {
        this.$message({
          message: "请输入账号",
          type: "error",
        });
        return false;
      }
      if (this.password01 == "") {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
        return false;
      }
      const {data: res} = await this.$http.post("/api.student/login", param);
      if (res.code != 0) {
        this.$message({
          message: res.msg,
          type: "error",
        });
      } else {
        //将后端传递的token存到浏览器的cookie中，key=oatoken，过期时间7天
        // console.log(res.data)
        this.$cookies.set("oatoken", res.data, '365d');
        // this.$cookies.set("loggingStatus", 1, { expires: 7 });
        //登录成功后，跳转到首页页面
        // this.$router.push("/");
        history.go(-1);

      }
    },

    empty_1() {
      this.password01 = "";
    },
    empty_2() {
      this.account01 = "";
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
  height: 100%;

  .hend_c {
    cursor: pointer;
  }
}

.section_2 {
  background-image: url("../../assets/log001.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.section_4 {
  margin: 0 auto;
  padding: 0.2rem 0 0.1rem;
  border-radius: 0.1rem;
  background-image: url("../../assets/1e3f75b209f66f1dfffd325194986e79.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 3.46rem;
}

.divider {
  margin-top: 0.2rem;
  background-color: #f0f2f4;
  height: 0.01rem;
}

.section_5 {
  background-color: #ffffff;
  border-radius: 0.04rem;
  border: solid 0.01rem #c2c2c2;
  align-items: center;
}

.view {
  margin: 0.28rem 0.2rem 0;
}

.image {
  border-radius: 0.04rem;
  width: 0.39rem;
  height: 0.39rem;
  margin: -0.01rem;
}

.font_1 {
  font-size: 0.14rem;
  line-height: 0.14rem;
}

.text {
  text-transform: uppercase;
}

.text_2 {
  color: #2b2c30;
  font-size: 0.18rem;
  font-family: OPPOSans;
  line-height: 0.18rem;
}

.image_2 {
  margin-right: 0.13rem;
  width: 0.13rem;
  height: 0.13rem;
  cursor: pointer;
  transition: transform 0.5s ease;
  @media screen and (orientation: landscape) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

.view_2 {
  margin: 0.18rem 0.2rem 0;
}

.text_3 {
  color: #082c47;
  font-size: 0.14rem;
  font-family: OPPOSans;
  line-height: 0.07rem;
  letter-spacing: 0.014rem;
  width: 100%;
}

.group {
  padding: 0.18rem 0.2rem;
}

.space-x-192 > *:not(:first-child) {
  margin-left: 1.92rem;
}

.text_4 {
  color: #2080f7;
  line-height: 0.15rem;
}

.text-wrapper {
  margin: 0 0.2rem;
  border-radius: 0.04rem;
  width: 3.05rem;
  height: 0.38rem;
  padding: 0;
}

.text_5 {
  font-size: 0.2rem;
}

.group_2 {
  padding: 0 0.21rem;
  margin-top: 0.75rem;

  a {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.14rem;
      transition: color 0.5s ease;
    }

    @media screen and (orientation: landscape) {
      &:hover {
        span {
          color: #2080f7;
        }
      }
    }
  }
}

.space-x-14 > *:not(:first-child) {
  margin-left: 0.14rem;
}

.image_3 {
  border-radius: 0.14rem;
  width: 0.28rem;
  height: 0.28rem;
}

.text_6 {
  color: #082c47;
}

input {
  width: 2rem;
}


@media screen and (orientation: portrait) {
  .section_2 {
    background-size: cover;
  }
  .section_4{
    width: 80vw;
    padding: .4rem 0 .2rem;
  }
  .text_2{
    font-size: .32rem;
    line-height: 1;
  }
  .view,.view_2{
    margin: .3rem .4rem 0;
  }
  .font_1{
    font-size: .24rem;
    line-height: 1;

  }
  .image{
    width: .7rem;
    height: .7rem;
  }
  .group{
    padding: .2rem .4rem;
    justify-content: space-between;

  }
  .text-wrapper{
    width: calc(100% - .8rem);
    margin: 0 .4rem;
    height: .7rem;

  }
.text_3,.text_5{
    font-size: .24rem;
  }
}
</style>
