<template>
  <div class="page">
    <nav-box></nav-box>

    <div class="new_case">
      <div class="pub_-flex-row pub_-self-center pub_case">
        <div class="nav_left">
          <RouterLink v-for="item,i in column" :to="'/news?id='+ item.id" :key="i" :class="{ on: active == item.id }">
            <span>{{item.name}}</span>
          </RouterLink>

        </div>

        <div class="pub_-flex-col list section_5" id="target">
          <div class="news_t">新闻消息</div>
          <div>
            <div
              class="pub_-flex-row pub_-items-center list-item space-x-21"
              @click="goHref($event)"
              :data-url="'/newsDetails?active='+active+'&&id='+item.id"
              :key="i"
              v-for="(item, i) in list_tzm43Yq0"
            >
              <img class="pub_-shrink-0 image" :src="item.picturePath" />
              <div class="pub_-flex-col pub_-flex-auto group_3 view">
                <span class="pub_-self-start font_4 text_11">{{
                  item.title
                }}</span>
                <span class="font_5 text_11 text_14">{{ item.profile }}</span>
                <span class="pub_-self-start font_6 text_11 text_16">{{
                  item.createTime
                }}</span>
              </div>
            </div>

            <Pagination
              @change="changePage"
              :current="pagination.p"
              :pageSize="pagination.pageSize"
              :total="total"
              :hideOnSinglePage="hideOnSinglePage"
              :showQuickJumper="true"
              :showTotal="true"
              placement="right"
              v-if="pagination && total"
            />
          </div>
        </div>
      </div>
    </div>
    <foot-box></foot-box>
  </div>
</template>

<script>
import NavBox from "../../components/navbox/navbox.vue";
import FootBox from "../../components/footbox/footbox.vue";
import Pagination from "../../components/Pagination/Pagination.vue";

export default {
  components: { NavBox, FootBox, Pagination },
  data() {
    return {
      active: null,
      list_tzm43Yq0: [],
      item: "新闻消息",

      hideOnSinglePage: false,
      total: 0,
      pagination: {
        pageSize: 10,
        p: 1,
      },


      column:[],
      newC:{},
    };
  },
  watch: {
    // $route(to, from) {
    //     if (this.$route.query.参数) {
    //          重新加载一次created或者mounted钩子中的渲染的数据
    //     }
    //   },
    // 或者
    $route() {
      if (this.$route.query.id) {
        this.getParams();
      }
    },
  },
  mounted() {
    this.getParams();
  },
  methods: {
    getParams() {
      // 取到路由带过来的参数
      const routerParams = this.$route.query.id;
      this.active = routerParams;

      this.ajaxH();
    },
    async ajaxH() {
      this.getColumnList(); //获取栏目列表接口
      this.getPageList(); //获取分页列表接口
    },
    async getColumnList() {
      let param = {};
      const { data: res } = await this.$http.post(
        "/api.news/getColumnList",
        param
      );
      this.column = res.data;
      if (this.active == null || this.active ==  undefined)  {
        this.active = this.column[0].id;
      }
    },
    async getPageList() {
      let param = {
        columnId:this.active
      };
      const { data: res } = await this.$http.post(
        "/api.news/getPageList",
        param
      );
      this.list_tzm43Yq0 = res.data.data.slice(0,res.data.per_page);
      console.log(this.list_tzm43Yq0)
      this.newC = res.data;
      this.total = res.data.total;
      this.pagination.pageSize = res.data.per_page;
      // this.pagination.pageSize = 9;

    },
    goHref(e, id) {
      console.log(e.currentTarget.dataset.url);
      let path = e.currentTarget.dataset.url + "?active=" + this.active;
      this.$router.push(path);
    },

    changePage(pager) {
      // console.log(pager)
      // 分页器回调
      this.$emit("change", pager);
      this.list_tzm43Yq0 = this.newC.data.slice(
        (pager.page - 1) * pager.pageSize,
        pager.page * pager.pageSize
      )

      document.getElementById("target").scrollIntoView();
    },
  },
};
</script>

<style scoped lang="scss">
$on_color: #2080f7;

.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .new_case {
    padding-top: 0.4rem;
    padding-bottom: 0.5rem;
  }
}
.section {
  padding: 0.17rem 3.7rem;
  background-color: #ffffff;
}
.text-wrapper {
  padding: 0.24rem 0;
  background-color: #000000;
  width: 1.34rem;
  height: 0.64rem;
}
.font_1 {
  font-size: 0.14rem;
  font-family: SourceHanSansCN;
  line-height: 0.15rem;
}
.text {
  color: #ffffff;
}
.text_2 {
  color: #2b2c30;
}
.section_2 {
  padding: 0.24rem 0 0.24rem 0.19rem;
  background-color: #f3f6fa;
}
.space-x-52 > *:not(:first-child) {
  margin-left: 0.52rem;
}
.font_2 {
  font-size: 0.18rem;
  font-family: SourceHanSansCN;
  line-height: 0.17rem;
  color: #2b2c30;
}
.text_4 {
  margin-left: 0.35rem;
}
.text_3 {
  margin-left: 0.42rem;
}
.text_5 {
  margin-left: 0.44rem;
}
.text_6 {
  margin-left: 0.35rem;
}
.text_7 {
  margin-left: 0.34rem;
}
.text_8 {
  margin-left: 0.43rem;
}
.space-x-43 > *:not(:first-child) {
  margin-left: 0.43rem;
}
.text_9 {
  line-height: 0.18rem;
}
.group {
  padding-top: 0.4rem;
  width: 11.8rem;
}

.list {
  flex: 1 1 auto;
  margin-left: 0.4rem;
}
.section_5 {
  padding: 0.39rem 0.33rem 1.33rem 0.4rem;
  background-color: #ffffff;
  min-height: 8.74rem;
}
.list-item {
  margin-top: 0.2rem;
  cursor: pointer;
  @media screen and (orientation: landscape) {
    &:hover {
      .font_4 {
        color: $on_color;
      }
    }
  }
}
.list-item:not(:first-of-type) {
  border-bottom: solid 0.02rem #f3f6fa;
}
.image {
  border-radius: 0.08rem;
  width: 1.8rem;
  height: 1.31rem;
}
.group_3 {
  margin-top: 0.12rem;
  width: 6.68rem;
}
.view {
  margin-top: 0;
}
.font_4 {
  font-size: 0.22rem;
  line-height: 0.22rem;
  color: #2b2c30;
  transition: color 0.5s ease;
}
.font_5 {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: #2b2c30;
}
.font_6 {
  font-size: 0.14rem;
  line-height: 0.12rem;
  color: #2b2c30;
}
.text_11 {
  text-transform: uppercase;
}
.text_14 {
  margin-top: 0.12rem;
}
.text_16 {
  margin-top: 0.28rem;
}
.space-x-21 > *:not(:first-child) {
  margin-left: 0.21rem;
}
.news_t {
  font-size: 0.18rem;
  line-height: 1;
  font-weight: 500;
  color: #2b2c30;
}

@media screen and (orientation: portrait){
  .pub_case{
    flex-wrap: wrap;
    .space-x-21 > *[data-v-a942bee2]:not(:first-child){
      margin-left: 0;
    }
    .section_5{
      margin-top: .4rem;
      margin-left: 0;
      padding: .3rem;
      width: 100%;

      .news_t{
        font-size: .32rem;
      }
.list-item{
  margin-top: .3rem;

  padding-bottom: .3rem;
  border-bottom:solid 0.02rem #f3f6fa;
  .image{
    width: 30%;
    margin-right: 0;
    height: auto;
    margin-bottom:0;
  }
  .group_3{
    width: 70%;
    padding-left: .2rem;
    .font_4{
      font-size: .28rem;
      white-space: pre-wrap;
      line-height: 1.2;
      overflow-wrap: break-word; /* 允许单词在任何地方断开以防止溢出 */
      text-overflow: ellipsis; /* 在溢出时显示省略号 */
      display: -webkit-box;
      -webkit-line-clamp: 3; /* 设置显示的行数 */
      -webkit-box-orient: vertical;
    }
    .text_14{
      font-size: 0.2rem;
      line-height: 1.5;
      margin-top: 0;
      display: none;
    }
    .font_6{
      font-size: .2rem;
      line-height: 1;
      margin-top: .11rem;
      display: none;
    }
  }

}

    }

  }
}

</style>
