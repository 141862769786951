import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/home/home.vue';
import Yonghuzhongxin_yecheng_xiangqing from '../pages/yonghuzhongxin_yecheng_xiangqing/purchasedCourseDetails.vue';
import Yonghuzhongxin_yigoukecheng from '../pages/yonghuzhongxin_yigoukecheng/purchasedCourse.vue';
import Yonghuzhongxin_bangzhuwendang from '../pages/yonghuzhongxin_bangzhuwendang/help.vue';
import Yonghuzhongxin_xnzixun_xiangqing from '../pages/yonghuzhongxin_xnzixun_xiangqing/newsDetails.vue';
import Yonghuzhongxin_xinwenzixun from '../pages/yonghuzhongxin_xinwenzixun/news.vue';
import Yonghuzhongxin_yueguanli from '../pages/yonghuzhongxin_yueguanli/myWallet.vue';
import Yonghuzhongxin_tijiaogongdan from '../pages/yonghuzhongxin_tijiaogongdan/workOrder.vue';
import Yonghuzhongxin_zhanneixiaoxi from '../pages/yonghuzhongxin_zhanneixiaoxi/mail.vue';
import Yonghuzhongxin_yonghushezhi from '../pages/yonghuzhongxin_yonghushezhi/setup.vue';
import Yonghuzhongxin_wodekebiao from '../pages/yonghuzhongxin_wodekebiao/myTimetable.vue';
import Xunlianying from '../pages/xunlianying/trainingCampCourse.vue';
import Xilieke from '../pages/xilieke/systemCourse.vue';
import Yingdengdengji from '../pages/yingdengdengji/englishLevel.vue';
import Kechengtixi from '../pages/kechengtixi/courseSystem.vue';
import Querendingdan from '../pages/querendingdan/confirmOrder.vue';
import Kechengxiangqing from '../pages/kechengxiangqing/courseDetails.vue';
import Kechengliebiao from '../pages/kechengliebiao/courseList.vue';
import Zhuce from '../pages/zhuce/register.vue';
import Denglu_weixinsaoma from '../pages/denglu_weixinsaoma/ewm.vue';
import Denglu_zhanghaomima from '../pages/denglu_zhanghaomima/login.vue';
import Querendingdan_zhifuchenggong from '../pages/querendingdan_zhifuchenggong/completePayment.vue';
import Duliye1920banben from '../pages/duliye1920banben/single.vue';
import about from '../pages/about/about.vue';
import forgetPassword from '../pages/forgetPassword/forgetPassword.vue';
import room from '../pages/room/room.vue';
import customPage from '../pages/customPage/customPage.vue';
import order from '../pages/order/order.vue';
import orderDetails from '../pages/order/orderDetails.vue';
import lectureRecord from '../pages/lectureRecord/lectureRecord.vue';


Vue.use(VueRouter);

const routes = [

  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/purchasedCourseDetails',
    name: 'yonghuzhongxin_yecheng_xiangqing',
    component: Yonghuzhongxin_yecheng_xiangqing,
  },
  {
    path: '/purchasedCourse',
    name: 'yonghuzhongxin_yigoukecheng',
    component: Yonghuzhongxin_yigoukecheng,
  },
  {
    path: '/help',
    name: 'yonghuzhongxin_bangzhuwendang',
    component: Yonghuzhongxin_bangzhuwendang,
  },

  {
    path: '/newsDetails',
    name: 'yonghuzhongxin_xnzixun_xiangqing',
    component: Yonghuzhongxin_xnzixun_xiangqing,
  },
  {
    path: '/news',
    name: 'yonghuzhongxin_xinwenzixun',
    component: Yonghuzhongxin_xinwenzixun,
  },
  {
    path: '/myWallet',
    name: 'yonghuzhongxin_yueguanli',
    component: Yonghuzhongxin_yueguanli,
  },
  {
    path: '/workOrder',
    name: 'yonghuzhongxin_tijiaogongdan',
    component: Yonghuzhongxin_tijiaogongdan,
  },
  {
    path: '/mail',
    name: 'yonghuzhongxin_zhanneixiaoxi',
    component: Yonghuzhongxin_zhanneixiaoxi,
  },
  {
    path: '/setup',
    name: 'yonghuzhongxin_yonghushezhi',
    component: Yonghuzhongxin_yonghushezhi,
  },
  {
    path: '/myTimetable',
    name: 'yonghuzhongxin_wodekebiao',
    component: Yonghuzhongxin_wodekebiao,
  },
  {
    path: '/trainingCampCourse',
    name: 'xunlianying',
    component: Xunlianying,
  },
  {
    path: '/systemCourse',
    name: 'xilieke',
    component: Xilieke,
  },
  {
    path: '/englishLevel',
    name: 'yingdengdengji',
    component: Yingdengdengji,
  },
  {
    path: '/courseSystem',
    name: 'kechengtixi',
    component: Kechengtixi,
  },
  {
    path: '/confirmOrder',
    name: 'querendingdan',
    component: Querendingdan,
  },
  {
    path: '/courseDetails',
    name: 'kechengxiangqing',
    component: Kechengxiangqing,
  },
  {
    path: '/courseList',
    name: 'kechengliebiao',
    component: Kechengliebiao,
  },
  {
    path: '/register',
    name: 'zhuce',
    component: Zhuce,
  },
  {
    path: '/ewm',
    name: 'denglu_weixinsaoma',
    component: Denglu_weixinsaoma,
  },
  {
    path: '/login',
    name: 'denglu_zhanghaomima',
    component: Denglu_zhanghaomima,
  },
  {
    path: '/completePayment',
    name: 'querendingdan_zhifuchenggong',
    component: Querendingdan_zhifuchenggong,
  },
  {
    path: '/single',
    name: 'duliye1920banben',
    component: Duliye1920banben,
  },
  {
    path: '/about',
    name: 'about',
    component: about,
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: forgetPassword,
  },
  {
    path: '/room',
    name: 'room',
    component: room,
  },
  {
    path: '/customPage',
    name: 'customPage',
    component: customPage,
  },
  {
    path: '/order',
    name: 'order',
    component: order,
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: orderDetails,
  },
  {
    path: '/lectureRecord',
    name: 'lectureRecord',
    component: lectureRecord,
  },

];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
